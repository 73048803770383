import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { SwUpdate } from '@angular/service-worker';
import { interval, Observable, Subject, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { eq, lt } from 'semver'
import { LocalStorageStrategy, Cacheable } from 'ts-cacheable';
import { environment } from 'src/environments/environment';
import packageJson from '../../../../package.json';
import { MessageResponse, MessageType, MessageEvent, MessageParametersBuilder } from "src/app/components/messages/message-handler/message-handler.component";
import { MessageHandlerService } from "src/app/components/messages/message-handler/message-handler.service";
import { SessionAttribute, SessionService } from 'src/app/core/services/session.service';
import { ApiEndpoint } from "../models/constants";
import { promiseTimeout } from "src/app/core/utils/promise-timeout";
import { TelemetryService } from "./telemetry.service";
/**
 * This provides a central place to look up version information for client & server code & manage update actions
 */

const serverVersionCacheBuster$ = new Subject<void>();

@Injectable({providedIn: 'root'})
export class VersionService {

   private static readonly VERSION_CHECK_INTERVAL_MS = VersionService.safeVCI(environment.versionCheckIntervalMinutes * 60 * 1000);
   private serverVersion: string;
   private updateRequested: boolean = false;

   constructor(private http: HttpClient,
                private messageHandlerService: MessageHandlerService,
                private session: SessionService,
                private telemetry: TelemetryService,
                private swUpdate: SwUpdate) {
       
      this.session.attributeChange$.subscribe((csa) => {
          if(csa && csa.name === SessionAttribute.UpdateRequested && csa.newValue && csa.otherWindow)
          {
             // Only process the request if we aren't already updating
             if(!this.updateRequested)
             {
                this.doVersionCheck(true);
             }
          }
          
          if(csa && csa.name === SessionAttribute.MaxSeenVersion && csa.otherWindow)
          {
             // A new max was discovered elsewhere, so we should do a check of our own now
             this.doVersionCheck(false);
          }
      });
      
      interval(VersionService.VERSION_CHECK_INTERVAL_MS).subscribe(() => {
          if(!this.session.getAttribute(SessionAttribute.UpdateAvailable))
          {
             this.loadServerVersion(true).subscribe();
          }
      });
   }
   
   private static safeVCI(millis: number)
   {
      // Sanity check to prevent going full ham with the version checks
      return millis < 10000 ? 10000 : millis;
   }

   public static getClientVersion(): string {
      return packageJson.version
   }
   
   public getServerVersion(): string {
      return this.serverVersion;
   }
   
   public isUpToDate(): boolean {
      return eq(this.getServerVersion(), VersionService.getClientVersion());
   }

   /**
    * This will obtain a version from the server, potentially cached
    * Calling this also implies a check for updates
    * Once loaded, a copy is retained for easy reference (e.g. displaying the value)
    */
   public loadServerVersion(allowCached: boolean): Observable<void> {

      if(!allowCached)
      {
         serverVersionCacheBuster$.next();
      }

      return this.fetchServerVersion()
         .pipe(
            map(version => {
               if(version)
               {
                  this.serverVersion = version;
                  console.debug(`VersionService.loadServerVersion(allowCached: ${allowCached}): ${this.serverVersion}`);
               }
               
               if(this.serverVersion && lt(this.serverVersion, VersionService.getClientVersion()))
               {
                  // Server is older than client, don't keep that in cache as it is likely to change very soon
                  serverVersionCacheBuster$.next();
               }
         
               this.doVersionCheck(!allowCached);
            })
         )                                    
   }

   @Cacheable({
      storageStrategy: LocalStorageStrategy,
      maxAge: (VersionService.VERSION_CHECK_INTERVAL_MS * 3) - 5000,
      slidingExpiration: false,
      cacheBusterObserver: serverVersionCacheBuster$
   })
   private fetchServerVersion(): Observable<string> {
      return this.http.get<string>(environment.apiUrl + ApiEndpoint.ServerVersion)
         .pipe(
            map((data: any) => data.response),
            catchError((err) => {
               console.warn(`Server version check failed: (${err.status}) ${err.message}`);
               return of(undefined);
            })            
         );
   }

   /**
    * Perform a version check & take action if there is a mismatch
    * When forcedUpdate is true, the application will perform the update immediately
    * Otherwise, the user is notified & they may choose to update or not
    */
   public doVersionCheck(forceUpdate: boolean)
   {
      console.debug(`Doing version check.  forceUpdate: ${forceUpdate}  this.updateRequested: ${this.updateRequested}`);
      
      let maxVersion = this.session.getAttribute(SessionAttribute.MaxSeenVersion);
      if(this.serverVersion && (!maxVersion || lt(maxVersion, this.serverVersion)))
      {
         // We know about a newer/higher max version, so make sure other windows are aware of this too
         this.session.setAttribute(SessionAttribute.MaxSeenVersion, this.serverVersion);
         console.debug("Letting other windows know about new/higher max version");
      }
      else if(this.serverVersion && maxVersion && lt(this.serverVersion, maxVersion))
      {
         // Another window is aware of a higher version, so use that one
         this.serverVersion = maxVersion;
         console.debug("Updated our version to max from other window");
      }
       
      const clientVersion: string = VersionService.getClientVersion();
      if(this.serverVersion && lt(clientVersion, this.getServerVersion()))
      {
         // Version mismatch
         if(forceUpdate)
         {
            this.doUpdate();
         }
         else if(!this.session.getAttribute(SessionAttribute.UpdateAvailable))
         {
            this.session.setAttribute(SessionAttribute.UpdateAvailable, true);

            this.messageHandlerService.show("There is a newer version of the application available.  Would you like to load it now?",
               MessageType.INFO_CONFIRM,
               (resp: MessageResponse): void => {
                  if (resp.event === MessageEvent.YES) {
                     this.doUpdate();
                  }
               }
            );
         }         
      }
      else
      {
         this.session.removeAttribute(SessionAttribute.UpdateAvailable);
         
         const tokenVersion = this.session.getAttribute(SessionAttribute.ServerAuthTokenVersion);
         if(tokenVersion && lt(tokenVersion, clientVersion))
         {
            // The token needs to be updated
            const versionData = { clientVersion: clientVersion };
            this.http.post(environment.apiUrl + ApiEndpoint.TokenVersion, versionData)
               .pipe(
                   map((resp: any) => {
                       const newToken: string = resp.response;
                       if(newToken !== null && newToken.length > 0)
                       {
                          this.session.setAttribute(SessionAttribute.ServerAuthToken, newToken);
                          this.session.setAttribute(SessionAttribute.ServerAuthTokenVersion, clientVersion);
                       }
                   })            
               )
               .subscribe();                                    
         }
      }
   }

   public doUpdate(): void {
      
      if(this.updateRequested)
      {
         console.debug("Update already requested, ignoring new request");
      }
      else
      {
         this.updateRequested = true;
         
         // Let other instances know about this
         this.session.setAttribute(SessionAttribute.UpdateRequested, true);
         this.session.removeAttribute(SessionAttribute.UpdateRequested);

         console.debug("Update requested");
         
         if(this.swUpdate.isEnabled)
         {
            const clientVersion: string = VersionService.getClientVersion();
            const serverVersion: string = this.getServerVersion();
            this.telemetry.versionUpdate(clientVersion, serverVersion);
            
            this.messageHandlerService.destroyAllDialogs();
            this.messageHandlerService.show(`Software update (${clientVersion} -> ${serverVersion}) in progress...`, MessageType.ALERT_TYPE_SYSTEM_MESSAGE);

            console.info("Service Worker detected, preloading new version");
            promiseTimeout(5000, this.swUpdate.checkForUpdate()).then(() => {
               console.info("Preload complete, activating new version");
               promiseTimeout(5000, this.swUpdate.activateUpdate()).then(() => {
                  console.info("Reloading with new version");
                  this.session.removeAttribute(SessionAttribute.UpdateAvailable);
                  window.location.reload();
               }).catch((reason) => {
                  this.notifyUpdateFailure(`Cannot activate: ${reason}`);
               });
            }).catch((reason) => {
               this.notifyUpdateFailure(`Cannot check/download: ${reason}`);
            });         
         }
         else
         {
            this.notifyUpdateFailure("Cannot perform automated update, Service Worker is not enabled/available.  Please clear browser cache & reload to manually update.");
         }
      }
   }

   private notifyUpdateFailure(reason: string): void {
      const logMsg = `Update failure: ${reason}`;
      console.error(logMsg);
      this.telemetry.uiError(logMsg);

      this.messageHandlerService.destroyAllDialogs();
      this.messageHandlerService.open(
         new MessageParametersBuilder()
            .type(MessageType.CUSTOM_MESSAGE)
            .message("Unable to automatically apply update. Please manually reload this page, or click the button below.")
            .footer("If this message persists, try clearing your cache.")
            .addButton({ label: "Try Manual Update", event: MessageEvent.OK })
            .onClose((resp: MessageResponse): void => {
               if (resp.event === MessageEvent.OK) {
                  this.updateRequested = false;
                  window.location.reload();
               }
            })
            .build()
         );
   }
}