import { AssetsProjects } from "../save/assets-projects";

export class AssetsProjectsDisplay extends AssetsProjects {
    previousProjectId: number;
    projectName: string;
    projectTiv: number;
    projectKickoffDate: number;
    projectCompletionDate: string;
    projectStatus: string;

    constructor(entity: any) {
        super(entity);
        this.previousProjectId = entity.projectId;
        this.projectName = entity && entity.projectName || null;
        this.projectTiv = entity && entity.projectTiv || null;
        this.projectKickoffDate = entity && entity.projectKickoffDate || null;
        this.projectCompletionDate = entity && entity.projectCompletionDate || null;
        this.projectStatus = entity && entity.projectStatus || null;
        this.initialData = entity;
    }

    static CreateInstance(entity: any): AssetsProjectsDisplay {
        return new AssetsProjectsDisplay(entity);
    }

    static BuildFromList(entity: Array<any>): Array<AssetsProjectsDisplay> {
        return entity.map(item => AssetsProjectsDisplay.CreateInstance(item));
    }
}
