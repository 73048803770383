<div class="main-section">
    <div class="header mb-3">
        <h3>{{'alerts.title'| translate}}</h3>
    </div>
    <form [formGroup]="alertsFormGroup">

            <div class="grid">
                <!-- Buttons -->
                <div class="grid mt-1 col-12 justify-content-center">
                    <button
                        [tabindex]="baseIndex"
                        (click)="createDiscard()"
                        type="button"
                        *ngIf="true"
                        pTooltip="{{createTooltip}}"
                        [disabled]="disabledButtons || stateSendAlert || entityId === null"
                        class="btn-row">{{createLabel}}</button>
                    <button
                        [tabindex]="baseIndex + 1"
                        (click)="send()"
                        type="button"
                        pTooltip=" {{'alerts.sendTooltip'| translate}}"
                        [disabled]="disabledButtons || disabledSend"
                        class="btn-row ml-2">{{'alerts.send'| translate}}</button>
                </div>

                <!-- Label "Alert subject" + Input -->
                <div class="col-12 flex -ml-5">
                        <label class="col-3 font-xs font-bold text-xs text-right mt-1">{{'alerts.subject'| translate}}:</label>
                        <div class="col-9 px-0">
                            <input class="form__input-field-style w-full"
                                type="text"
                                pTooltip="{{getTooltipText()}}"
                                [escape]="false"
                                tooltipStyleClass="tooltip-alert-info"
                                tooltipPosition="right"
                                fitContent = "false"
                                formControlName="alertSubject"
                                pInputText maxlength="100"
                                [tabindex]="baseIndex + 2"/>
                        </div>
                </div>
                <!-- Label "Delay By" + Select  &&  Label "Alerty Type + Select" -->
                <div class="col-12 flex -mt-4 pt-1 -ml-5">
                    <label class="col-3 font-xs font-bold text-xs text-right mt-1 min-w-max">{{'alerts.delay'|translate}}:</label>
                    <div class="col-3 px-0">
                        <p-dropdown
                        class="form__container-fields-layout--selects"
                        [options]="alertDelays"
                        optionLabel="desc"
                        optionValue="id"
                        [filter]="true"
                        showClear="true"
                        [placeholder]="'common.selectOne' | translate"
                        formControlName="alertDelay"
                        [tabindex]="baseIndex + 3">
                        </p-dropdown>
                    </div>
                    <label class="col-3 font-xs font-bold text-xs text-right mt-1 min-w-max">{{'alerts.type'|translate}}:</label>
                    <div class="col-3 px-1">
                        <p-dropdown
                        class="form__container-fields-layout--selects"
                        [options]="alertTypes"
                        optionLabel="desc"
                        optionValue="id"
                        [filter]="true"
                        showClear="true"
                        [placeholder]="'common.selectOne' | translate"
                        formControlName="alertType"
                        [tabindex]="baseIndex + 4">
                        </p-dropdown>
                    </div>
                </div>

                <!-- Label Comments + TextBox -->
                    <div class="col-12">
                        <label class="font-bold text-xs mt-1">{{'alerts.comments'|translate}}:</label>
                    </div>

                    <div class="col-12 -mt-3">
                        <textarea [rows]="5"
                            [cols]="30"
                            class="w-full text-xs"
                            autoResize="true"
                            pInputTextarea
                            maxlength="1000"
                            formControlName="alertComments"
                            [tabindex]="baseIndex + 5">
                        </textarea>
                    </div>

                <!-- Select Related Units for This Alerts + Select Related Offline Event for this Alert -->
                    <div class="col-6" *ngIf="!isOfflineEventAlert">
                        <label
                            class="font-bold text-xs mt-1">{{'alerts.relatedUnits'|translate}}:
                        </label>
                        <div class="container-list mt-3 sm:mt-0 md:mt-0 lg:mt-0 xl:mt-0">
                            <p-listbox
                                formControlName="acPlantUnitsAlerts"
                                [listStyle]="{'height':'150px', 'font-size': '12px'}"
                                emptyFilterMessage=" "
                                emptyMessage=" "
                                [multiple]="true"
                                optionValue="unitId"
                                [options]="units"
                                (onChange)="onUnitChange()"
                                [tabindex]="-1">
                                <ng-template let-item pTemplate="item">
                                    {{item.unitId}} - {{item.unitName}}
                                </ng-template>
                            </p-listbox>
                        </div>
                    </div>
                    <div class="col-6" *ngIf="!isOfflineEventAlert">
                        <label
                            class="font-bold text-xs mt-1">{{'alerts.relatedEvents'|translate}}:
                        </label>
                        <div class="container-list">
                            <p-listbox
                                formControlName="acUnitOfflineEventsAlerts"
                                [listStyle]="{'height':'150px', 'font-size': '12px'}"
                                emptyFilterMessage=" "
                                emptyMessage=" "
                                [multiple]="true"
                                optionValue="eventID"
                                [options]="offlineEvents"
                                [tabindex]="-1">
                                <ng-template let-item pTemplate="item">
                                    {{item.eventID}} - S: {{ item.eventStartDate | date: 'dd-MMM-y'}} - E: {{ item.eventEndDate | date: 'dd-MMM-y'}} - {{item.unitName}}
                                </ng-template>
                            </p-listbox>
                        </div>
                    </div>

            </div>

    </form>


    <div class="main-section mt-3">
        <div class="header mb-3">
            <h3>{{'alerts.history.title'| translate}}</h3>
        </div>
        <app-message-dialog></app-message-dialog>
        <div class="plant-activity-table">
            <p-table 
                [value]="alertHistory" 
                focusable
                [focusManager]="alertsFocusManager"
                [columns]="cols" 
                [resizableColumns]="true"
                [reorderableColumns]="true" 
                [customSort]="true"
                [rows]="rowsPerPage" 
                (onPage)="onPageChange($event)"
                [showCurrentPageReport]="true" 
                responsiveLayout="scroll"
                styleClass="p-datatable-gridlines p-datatable-striped"
                [rowsPerPageOptions]="rowsPerPageOptions" 
                [paginator]="true" 
                (sortFunction)="customSort($event)"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [tabindex]="baseIndex + 8">

                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" [style.text-align]="col.isAmount ? 'right':'left'" tabindex="-1"
                            [style.flex-basis]="col.width" pReorderableColumn pResizableColumn pSortableColumn="{{col.field}}">
                            {{col.header}}
                            <p-sortIcon *ngIf="col.field !== 'action'" field="{{col.field}}"></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="colgroup" let-columns>
                    <colgroup>
                        <col *ngFor="let col of columns" [style.flex-basis]="col.width">
                    </colgroup>
                </ng-template>

                <ng-template  pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
                    <tr (dblclick)="openAlertModal(rowData)">
                      <td *ngFor="let col of columns" [style.white-space]="col.wSpace || 'normal'">
                        <div *ngIf="col.isDate && rowData[col.field] !== null" class="px-2">
                            {{ rowData[col.field] | date: 'dd-MMM-y HH:mm:ss'}} by {{rowData[col.field+'User']}}
                        </div>

                        <div *ngIf="!col.isDate" class="px-2">
                            {{ rowData[col.field]}}
                            <div class="flex align-items-center justify-content-center" >
                                <img *ngIf="col.field === 'action' &&
                                        (rowData['statusDesc'] === ALERT_STATUS_UNSCHEDULED
                                        || rowData['statusDesc'] === ALERT_STATUS_SCHEDULED)"
                                    class="cursor-pointer"
                                    src="assets/icons/delete.png"
                                    (click)="deleteAlert(rowData)" width="18px"
                                    />
                            </div>
                            <div *ngIf="col.field === 'action' && rowData['statusDesc'] === ALERT_STATUS_COMPLETED && rowData['iseScId'] !== null" class="flex justify-content-center">
                                <button (click)="retryAlert(rowData)" type="button" class="btn-custom">
                                    {{'alerts.history.resend'| translate}}
                                </button>
                            </div>
                            <div *ngIf="col.field === 'action' && rowData['statusDesc'] === ALERT_STATUS_ERROR" class="flex justify-content-center">
                                <button (click)="retryAlert(rowData)" type="button" class="btn-custom">
                                    {{'alerts.history.retry'| translate}}
                                </button>
                            </div>
                        </div>
                    </tr>
                </ng-template>

                <ng-template pTemplate="emptymessage" let-columns>
                    <tr>
                        <td [attr.colspan]="columns.length + 1">
                            <div class="not-result-msg">
                                <span>{{ "alerts.history.notFound" | translate }}</span>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>


</div>





