<div class="main-content">
    <div class="grid">
        <div class="col-12 flex">
            <label class="col-2 font-bold text-xs flex justify-content-end mt-1">{{'sicCode.sicCode'|translate}}:</label>
            <div class="col-4 -ml-2">
                <input #firstInputField class="form__input-field-style w-full" type="text" name="sicCode"
                    (keyup.enter)="search()" [(ngModel)]="sicCode" pInputText appFocusElement #tabIndexSetted />
            </div>
            <div class="col-6"></div>
        </div>
        <div class="col-12 flex -mt-4">
            <label class="col-2 font-bold text-xs flex justify-content-end mt-1">{{'description'|translate}}:</label>
            <div class="col-4 -ml-2">
                <input secondInputField class="form__input-field-style w-full" type="text" name="sicDescription"
                    (keyup.enter)="search()" [(ngModel)]="sicDescription" pInputText #tabIndexSetted />
            </div>
            <div class="col-6">
                <button (click)="search()" class="btn-row" #tabIndexSetted #searchBtn>{{'search'|translate}}</button>
            </div>
        </div>
    </div>
</div>
<div class="container-modal">
    <div class="grid">
        <div class="col-5">
            <label class="font-bold text-xs">{{'lookups.sicCode.available'| translate}}:</label>
            <p-listbox [options]="availableSicCodes" [multiple]="true" [(ngModel)]="selectedInAvailableSicCodes"
                styleClass="selector-box-medium" (keydown)="handleListboxKeydown($event)"
                (keydown.tab)="addBtn.focus()">
                <ng-template let-item pTemplate="item">
                    <div #tabIndexSetted class="border-none">
                        {{ item.sicCodeDescription}}
                    </div>
                </ng-template>
            </p-listbox>
        </div>

        <div class="col-2 flex justify-content-center align-items-center">
            <div>
                <button (click)="add()" class="btn-row mb-2" #tabIndexSetted #addBtn>{{'lookups.addButton'|translate}}</button>
                <button (click)="remove()" class="btn-row" #tabIndexSetted #removeBtn
                    (keydown.tab)="saveBtn.focus()">{{'lookups.removeButton'|translate}}</button>
            </div>
        </div>

        <div class="col-5">
            <div class="container-table-modal">
                <label class="font-bold text-xs mt-1">{{'lookups.sicCode.selected'| translate}}:</label>
                <p-listbox [options]="selectedSicCodes" [multiple]="true" [(ngModel)]="selectedInSelectedSicCodes"
                    emptyFilterMessage=" " emptyMessage=" " styleClass="selector-box-medium">
                    <ng-template let-item pTemplate="item">
                        <div>
                            {{ item.sicCodeDescription }}
                        </div>
                    </ng-template>
                </p-listbox>
            </div>
        </div>
    </div>

    <br>
    <div class="grid justify-content-center mt-2">
        <button class="btn-row" (click)="addRowSingleClick()" #tabIndexSetted #saveBtn>{{'save'| translate}}</button>
        <button class="btn-row ml-2" (click)="closeModal()" #tabIndexSetted>{{'cancel'| translate}}</button>
    </div>
    <br>
</div>