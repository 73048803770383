import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/core/services/shared.service';
import { RelatedInfoBase } from '../related-info.base';
import { ColumnEntityInfo, ColumnEntityInfoEditableType } from 'src/app/shared/components/base/mvt-entity-associator/mvt-entity-associator.component';
import { EntityName } from 'src/app/core/models/enumerations/entity-name';

@Component({
    selector: 'app-drive',
    templateUrl: './drive.component.html',
    styleUrls: ['./drive.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RelatedInfoDriveComponent extends RelatedInfoBase implements OnInit {
    entityTableKey =EntityName.TURBINE;
    constructor(
        public sharedService: SharedService) {
        super(sharedService);
    }

    ngOnInit(): void {
        this.initBase();
    }

    getColumnsInfo(): ColumnEntityInfo[] {
        let columnsInfo: ColumnEntityInfo[] = [];
        if(this.callerIsProject) {
            columnsInfo.push({ entityPropName: 'driveId', columnHeader: 'relatedInfo.drive.driveId', routerEntityName: EntityName.TURBINE, widthPercentage: 8});
            columnsInfo.push({ entityPropName: 'driveName', columnHeader: 'relatedInfo.drive.driveName', widthPercentage: 12});
            columnsInfo.push({ entityPropName: 'manufacturerName', columnHeader: 'relatedInfo.drive.manufacturerName', widthPercentage: 12});
            columnsInfo.push({ entityPropName: 'unitId', columnHeader: 'relatedInfo.drive.unitId', widthPercentage: 8});
            columnsInfo.push({ entityPropName: 'unitName', columnHeader: 'relatedInfo.drive.unitName', widthPercentage: 12});
            columnsInfo.push({ entityPropName: 'driveType', columnHeader: 'relatedInfo.drive.driveType', widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'driveModel', columnHeader: 'relatedInfo.drive.driveModel', widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'drivenMfg', columnHeader: 'relatedInfo.drive.drivenMfg', widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'drivenModel', columnHeader: 'relatedInfo.drive.drivenModel', widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'releaseDate', columnHeader: 'relatedInfo.common.releaseDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 8});

        } else if(this.callerIsUnit) {
            columnsInfo.push({ entityPropName: 'driveId', columnHeader: 'relatedInfo.drive.driveId', routerEntityName: EntityName.TURBINE, widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'driveName', columnHeader: 'relatedInfo.drive.driveName', widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'driveType', columnHeader: 'relatedInfo.drive.driveType', widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'manufacturerName', columnHeader: 'relatedInfo.drive.driveManufacturer', widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'categoryName', columnHeader: 'relatedInfo.drive.driveCategory', widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'driveModel', columnHeader: 'relatedInfo.drive.driveModel', widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'drivenMfg', columnHeader: 'relatedInfo.drive.drivenMfg', widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'drivenModel', columnHeader: 'relatedInfo.drive.drivenModel', widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'qcDate', columnHeader: 'relatedInfo.common.qcDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'releaseDate', columnHeader: 'relatedInfo.common.releaseDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 10});
        } else if(this.callerIsOfflineEvent){
            columnsInfo.push({ entityPropName: 'driveId', columnHeader: 'relatedInfo.drive.driveId', routerEntityName: EntityName.TURBINE, widthPercentage: 10}); 
            columnsInfo.push({ entityPropName: 'driveName', columnHeader: 'relatedInfo.drive.driveName', widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'unitName', columnHeader: 'relatedInfo.drive.unitName', widthPercentage: 16});
            columnsInfo.push({ entityPropName: 'driveType', columnHeader: 'relatedInfo.drive.driveType', widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'plantName', columnHeader: 'relatedInfo.drive.plantName', widthPercentage: 16});
            columnsInfo.push({ entityPropName: 'ownerName', columnHeader: 'relatedInfo.drive.ownerName', widthPercentage: 15});            
            columnsInfo.push({ entityPropName: 'qcDate', columnHeader: 'relatedInfo.common.qcDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'releaseDate', columnHeader: 'relatedInfo.common.releaseDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 10});
        } else {
            columnsInfo.push({ entityPropName: 'driveId', columnHeader: 'relatedInfo.drive.driveId', routerEntityName: EntityName.TURBINE, widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'driveName', columnHeader: 'relatedInfo.drive.driveName', widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'manufacturerName', columnHeader: 'relatedInfo.drive.manufacturerName', widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'unitName', columnHeader: 'relatedInfo.drive.unitName', widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'driveType', columnHeader: 'relatedInfo.drive.driveType', widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'driveModel', columnHeader: 'relatedInfo.drive.driveModel', widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'drivenMfg', columnHeader: 'relatedInfo.drive.drivenMfg', widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'drivenModel', columnHeader: 'relatedInfo.drive.drivenModel', widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'qcDate', columnHeader: 'relatedInfo.common.qcDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 12});
            columnsInfo.push({ entityPropName: 'releaseDate', columnHeader: 'relatedInfo.common.releaseDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 8});

        }

        return columnsInfo;
    }
}
