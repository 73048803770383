import { Bean } from "../../bean";
import { TurbinesFuel } from "./turbines-fuel";

export class Drives extends Bean {
    turbineId: number;
    name: string;
    type: number;
    unitId: number;
    manufacturerId: number;
    prevManufacturerId: number;
    model: string;
    genManufacturerId: number;
    series: string;
    genModel: string;
    mailPostalCode: string;
    classification: string;
    designFirmId: number;
    constructorId: number;
    startUpDate: string;
    shutDownDate: string;
    designElectricalOutput: number;
    designBtuConsumption: number;
    operatingElectricalOutput: number;
    operatingBtuConsumption: number;
    entryUser: string;
    entryCompany: string;
    entryDate: string;
    lastUser: string;
    lastCompany: string;
    lastDate: string;
    prevUser: string;
    prevCompany: string;
    prevDate: string;
    qcUser: string;
    qcDate: string;
    qcCompany: string;
    liveDate: string;
    releaseDate: string;
    releaseUser: string;
    turbineCount: number;
    amendmentDate: string;
    amendmentUser: string;
    startUpResolution: number;
    shutDownResolution: number;
    drivenEquipmentTypeId: number;
    driveCategoryId: number;
    recordStatus: string;
    driveStatus: string;

    // lists of beans - MVTs
    turbinesFuel: Array<TurbinesFuel>;

    protected constructor(entity?: any) {
        super(entity);
        this.turbineId = (entity && entity.turbineId) ?? null;
        this.name = (entity && entity.name) ?? null;
        this.type = (entity && entity.type) ?? null;
        this.unitId = (entity && entity.unitId) ?? null;
        this.manufacturerId = (entity && entity.manufacturerId) ?? null;
        this.prevManufacturerId = (entity && entity.prevManufacturerId) ?? null;
        this.model = (entity && entity.model) ?? null;
        this.genManufacturerId = (entity && entity.genManufacturerId) ?? null;
        this.series = (entity && entity.series) ?? null;
        this.genModel = (entity && entity.genModel) ?? null;
        this.mailPostalCode = (entity && entity.mailPostalCode) ?? null;
        this.classification = (entity && entity.classification) ?? null;
        this.designFirmId = (entity && entity.designFirmId) ?? null;
        this.constructorId = (entity && entity.constructorId) ?? null;
        this.startUpDate = (entity && entity.startUpDate) ?? null;
        this.shutDownDate = (entity && entity.shutDownDate) ?? null;
        this.designElectricalOutput = (entity && entity.designElectricalOutput) ?? null;
        this.designBtuConsumption = (entity && entity.designBtuConsumption) ?? null;
        this.operatingElectricalOutput = (entity && entity.operatingElectricalOutput) ?? null;
        this.operatingBtuConsumption = (entity && entity.operatingBtuConsumption) ?? null;
        this.entryUser = (entity && entity.entryUser) ?? null;
        this.entryCompany = (entity && entity.entryCompany) ?? null;
        this.entryDate = (entity && entity.entryDate) ?? null;
        this.lastUser = (entity && entity.lastUser) ?? null;
        this.lastCompany = (entity && entity.lastCompany) ?? null;
        this.lastDate = (entity && entity.lastDate) ?? null;
        this.prevUser = (entity && entity.prevUser) ?? null;
        this.prevCompany = (entity && entity.prevCompany) ?? null;
        this.prevDate = (entity && entity.prevDate) ?? null;
        this.qcUser = (entity && entity.qcUser) ?? null;
        this.qcDate = (entity && entity.qcDate) ?? null;
        this.qcCompany = (entity && entity.qcCompany) ?? null;
        this.liveDate = (entity && entity.liveDate) ?? null;
        this.releaseDate = (entity && entity.releaseDate) ?? null;
        this.releaseUser = (entity && entity.releaseUser) ?? null;
        this.turbineCount = (entity && entity.turbineCount) ?? null;
        this.amendmentDate = (entity && entity.amendmentDate) ?? null;
        this.amendmentUser = (entity && entity.amendmentUser) ?? null;
        this.startUpResolution = (entity && entity.startUpResolution) ?? null;
        this.shutDownResolution = (entity && entity.shutDownResolution) ?? null;
        this.drivenEquipmentTypeId = (entity && entity.drivenEquipmentTypeId) ?? null;
        this.driveCategoryId = (entity && entity.driveCategoryId) ?? null;
        this.recordStatus = (entity && entity.recordStatus) ?? null;
        this.driveStatus = (entity && entity.driveStatus) ?? null;
        
        this.turbinesFuel = (entity && entity.turbinesFuel) ?? [];
    }

}
