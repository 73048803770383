import { IContact } from "src/app/core/interfaces/icontacts";
import { Address} from "../../../address";
import { ContactOperation } from "../../../contact";
import { ContactsDisplay } from "../../../contact/display/contacts-display";
import { AssetsContactInfo } from "../save/assets-contact-info";

export class AssetsContactInfoDisplay extends AssetsContactInfo implements IContact {
    companyName: string;
    titleDesc: string;
    mailStateName: string;
    mailCountryName: string;
    qcUserEmailAddress: string;
    lastUserEmailAddress: string;
    mailCity: string;
    mailState: string;
    mailCountry: string;

    isDup: string;
    showDeleteImage: boolean
    showRollbackImage: boolean;
    functionalSlot: number;
    companyStatus: string;

    originalContactStatus: string;
    originalContactSaleFlag: string;
    originalContactOnSite: string;
    originalContactQcDate: string;
    originalContactQcUser: string;
    originalContactTitle: string;
    editableContactQcDate: Date;

    operationInPool: ContactOperation;

    iCopy: IContact;
    contactDisplay: ContactsDisplay;

    constructor(entity: any, currentDate: Date) {
        super(entity);

        this.companyName = entity && entity.companyName || null;
        this.titleDesc = entity && entity.titleDesc || null;
        this.mailStateName = entity && entity.mailStateName || null;
        this.mailCountryName = entity && entity.mailCountryName || null;
        this.qcUserEmailAddress = entity && entity.qcUserEmailAddress || null;
        this.lastUserEmailAddress = entity && entity.lastUserEmailAddress || null;
        this.mailCity = entity && entity.mailCity || null;
        this.mailState = entity && entity.mailState || null;
        this.mailCountry = entity && entity.mailCountry || null;

        this.originalContactStatus = this.contactStatus;
        this.originalContactSaleFlag = this.contactSaleFlag;
        this.originalContactOnSite = this.contactOnsite;
        this.originalContactQcDate = this.contactQcDate;
        this.originalContactQcUser = this.contactQcUser;
        this.originalContactTitle = this.contactTitle;
        this.editableContactQcDate = this.getContactQcDateFormat(currentDate);

        this.operationInPool = ContactOperation.NONE;
        this.contactDisplay = ContactsDisplay.CreateInstance({});
    }

    getContactQcDateFormat(currentDate: Date): Date{
        return this.contactQcDate !== '' && this.contactQcDate !== undefined  
                        && this.contactQcDate !== null  ? 
                new Date(Number(this.contactQcDate)) : currentDate;
    }

    static CreateInstance(entity: any, currentDate: Date): AssetsContactInfoDisplay {
        return new AssetsContactInfoDisplay(entity, currentDate);
    }

    static BuildFromList(entity: Array<any>, currentDate: Date): Array<AssetsContactInfoDisplay> {
        return entity.map(item => AssetsContactInfoDisplay.CreateInstance(item, currentDate));
    }

    set mainEntityId(value: number) {
        this.assetId = value;
    }
    get mainEntityId(): number {
        return this.assetId;
    }

    set companyId(value: number) {
        this.contactCompanies = value;
    }
    get companyId(): number {
        return this.contactCompanies;
    }

    get firstName(): string {
        return this.contactDisplay.firstName;
    }
    get lastName(): string {
        return this.contactDisplay.lastName;
    }

    set contactSaleFlagBool(value: boolean) {
        this.contactSaleFlag = (value ? '1' : '0');
    }
    get contactSaleFlagBool(): boolean {
        return (this.contactSaleFlag == '1');
    }

    set contactOnsiteBool(value: boolean) {
        this.contactOnsite = (value ? '1' : '0');
    }
    get contactOnsiteBool(): boolean {
        return (this.contactOnsite == '1');
    }

    set mailAddressVerifiedBool(value: boolean) {
        this.mailAddressVerified = (value ? '1' : '0');
    }
    get mailAddressVerifiedBool(): boolean {
        return (this.mailAddressVerified == '1');
    }

    set contactPhoneMobileSel(value: boolean) {
        this.contactPhoneMobile = value ? "1" : "0";
    }

    get contactPhoneMobileSel(): boolean {
        return (this.contactPhoneMobile == "1");
    }

    set contactAltPhoneMobileSel(value: boolean) {
        this.contactAltPhoneMobile = value ? "1" : "0";
    }

    get contactAltPhoneMobileSel(): boolean {
        return (this.contactAltPhoneMobile == "1");
    }

    set contactOptedOutBool(value: boolean) {
        this.contactDisplay.contactOptedOutBool = value;
    }
    get contactOptedOutBool(): boolean {
        return this.contactDisplay.contactOptedOutBool;
    }

    setContactAddress(address: Address): void {
      this.mailPostalCode = address.zipCode;
      this.mailAddressLine1 = address.address1;
      this.mailAddressLine2 = address.address2;
      this.mailCountryId = address.c_id;
      this.mailCountry = address.countryId;
      this.mailCountryName = address.countryName;
      this.mailStateId = address.s_id;
      this.mailState =address.stateAbbrev;
      this.mailStateName = address.stateName;
      this.mailCityId = address.ci_id;
      this.mailCity = address.cityName;
    }

    public static merge(to: IContact, from: IContact, mergeContactInfo: boolean = true, mergeEntityInfo: boolean = true): void {
        if (mergeContactInfo) {
            to.contactDisplay.merge(from.contactDisplay);
        }

        if (mergeEntityInfo) {
            to.mainEntityId = from.mainEntityId;
            to.companyName = from.companyName;
            to.contactFunction = from.contactFunction;
            to.contactId = from.contactId;
            to.companyId = from.companyId;
            to.contactLastCompany = from.contactLastCompany;
            to.contactLastDate = from.contactLastDate;
            to.contactLastUser = from.contactLastUser;
            to.contactPhoneCc = from.contactPhoneCc;
            to.contactPhoneNo = from.contactPhoneNo;
            to.contactPhoneExt = from.contactPhoneExt;
            to.contactQcDate = from.contactQcDate;
            to.contactQcUser = from.contactQcUser;
            to.qcUserEmailAddress = from.qcUserEmailAddress;
            to.lastUserEmailAddress = from.lastUserEmailAddress;
            to.contactSaleFlagBool = from.contactSaleFlagBool;
            to.contactStatus = from.contactStatus;
            to.contactTitleDesc = from.contactTitleDesc;
            to.titleDesc = from.titleDesc;
            to.contactTitle = from.contactTitle;
            to.contactOnsiteBool = from.contactOnsiteBool;
            to.mailAddressLine1 = from.mailAddressLine1;
            to.mailAddressLine2 = from.mailAddressLine2;
            to.mailCity = from.mailCity;
            to.mailState = from.mailState;
            to.mailCountry = from.mailCountry;
            to.mailCountryId = from.mailCountryId;
            to.mailStateId = from.mailStateId;
            to.mailCityId = from.mailCityId;
            to.mailPostalCode = from.mailPostalCode;
            to.mailCountryName = from.mailCountryName;
            to.mailStateName = from.mailStateName;
            to.mailAddressVerifiedBool = from.mailAddressVerifiedBool;
            to.mailAddressVerifiedDate = from.mailAddressVerifiedDate;
            to.contactFaxCc = from.contactFaxCc;
            to.contactFaxNo = from.contactFaxNo;
            to.contactFaxExt = from.contactFaxExt;
            to.contactAltPhoneCc = from.contactAltPhoneCc;
            to.contactAltPhoneNo = from.contactAltPhoneNo;
            to.contactAltPhoneExt = from.contactAltPhoneExt;
            to.contactAltPhoneMobileSel = from.contactAltPhoneMobileSel;
            to.contactPhoneMobileSel = from.contactPhoneMobileSel;
            to.contactAltPhoneMobile = from.contactAltPhoneMobile;
            to.contactPhoneMobile = from.contactPhoneMobile;
            to.mvOrder = from.mvOrder;
            to.mvId = from.mvId;
            to.changedFields = from.changedFields;

            to.operationInPool = from.operationInPool;
            to.setOperation(from.getOperation());
        }

    }

    iMerge(mergeContactInfo: boolean = true, mergeEntityInfo: boolean = true): void {
        AssetsContactInfoDisplay.merge(this, this.iCopy, mergeContactInfo, mergeEntityInfo);
    }

    initICopy(currentDate: Date): void {
        this.iCopy = new AssetsContactInfoDisplay({}, currentDate);
        AssetsContactInfoDisplay.merge(this.iCopy, this);
    }

    getMainDetails(entityBean: any): void {
    }
    /*******************************************
     * END declarations for Generic Interface
     *******************************************/
}
