import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Country, State } from 'src/app/core/models';
import { CitiesWithMessage, City, County } from 'src/app/core/models/common';
import { Constants } from 'src/app/core/models/constants';
import { EntityName } from 'src/app/core/models/enumerations/entity-name';
import { OffshoreArea, OffshoreField } from 'src/app/core/models/offshoreAddress';
import { TableUtils } from 'src/app/core/utils/table-utils';
import { AddressService } from '../../address-form/address.service';
import { MessageType } from '../../messages/message-handler/message-handler.component';
import { MessageHandlerService } from '../../messages/message-handler/message-handler.service';
import { OffshoreAddressService } from '../../offshore-address-form/offshore-address.service';

export interface LocationSearchParameters {
    type: string;
    countryId?: string;
    countryName?: string;
    stateAbbrev?: string;
    stateName?: string;
    cityName?: string;
    ciId?: string;
    waterBodyId?: number;
    waterBodyName?: string;
    fieldName?: string;
    fieldId?: number;
    areas?: OffshoreArea[];
    states?: State[];
    entityType?: string;
    dashAccess?: boolean;
}

export interface CountrySearchResult {
    cId: string;
    countryId: string;
    countryName: string;
}

export interface StateSearchResult {
    sId: string;
    stateAbbrev: string;
    stateName: string;
}

export interface CitySearchResult {
    ciId: string;
    cityName: string;
    stateAbbrev: string;
}

export interface CountySearchResult {
    coId: string;
    countyName: string;
}

export interface FieldSearchResult {
    fieldId: string;
    fieldName: string;
}

export interface AreaSearchResult {
    areaId: string;
    areaName: string;
}

export const LOCATION_COUNTRY = 'country';
export const LOCATION_STATE = 'state';
export const LOCATION_CITY = 'city';
export const LOCATION_COUNTY = 'county';
export const LOCATION_FIELD = 'field';
export const LOCATION_AREA = 'area';

@Component({
    selector: 'app-location-search',
    templateUrl: './location-search.component.html',
    styleUrls: ['./location-search.component.scss']
})
export class LocationSearchComponent implements OnInit {
    params: LocationSearchParameters;
    countries: Array<Country> = [];
    states: Array<State> = [];
    cities: Array<City> = [];
    counties: Array<County> = [];
    fields: Array<OffshoreField> = [];
    areas: Array<OffshoreArea> = [];
    showFilterSection: boolean = true;
    type: string = '';
    fullName: string = '';
    selection: any;
    @ViewChildren('tr') private rows: QueryList<ElementRef>

    constructor(
        private addressService: AddressService,
        private offshoreAddressService: OffshoreAddressService,
        public activeModal: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public messageHandlerService: MessageHandlerService,
        public translate: TranslateService
    ) { }

    ngOnInit(): void {
        this.params = this.config.data;
        this.type = this.params.type;
        this.showFilterSection = (this.type === LOCATION_COUNTRY);
        this.loadList(false);
    }

    public loadList(value?: boolean) {
        if (this.type == LOCATION_COUNTRY) {
            this.loadCountries(value);
        } else if (this.type == LOCATION_STATE) {
            this.loadStates();
        } else if (this.type == LOCATION_CITY) {
            this.loadCity();
        } else if (this.type == LOCATION_COUNTY) {
            this.loadCounty();
        } else if (this.type == LOCATION_FIELD) {
            this.loadFields();
        } else if (this.type == LOCATION_AREA) {
            this.loadAreas();
        }
    }

    private focusFirstElement() {
        setTimeout(() => {
            let first = this.rows.first;
            if (first) {
                first.nativeElement.focus();
                first.nativeElement.click();
            }
        }, 150);
    }

    private loadCountries(value: boolean) {
        this.params.countryId = this.params.countryId ? this.params.countryId : '';
        if (this.params.countryId !== '' || this.fullName !== '') {
            const applyCoverage =  !this.params.dashAccess && this.params.entityType !== EntityName.COMPANY;
            this.addressService.searchCountries(this.params.countryId.toUpperCase(), this.fullName.toUpperCase(), applyCoverage)
                .subscribe((countries: Country[]) => {
                    this.countries = countries;
                    if (countries.length === 0) {
                        this.messageHandlerService.showAlertAndReturnFocus(this.translate.instant('common.searchNotFound'));
                    } else {
                        this.focusFirstElement();
                    }
                });
        } else if (value) {
            this.messageHandlerService.showAlertAndReturnFocus(this.translate.instant('location.errorSearchCountry'));
        }
    }

    private loadStates() {
        this.states = this.params.states;
        this.focusFirstElement();
    }

    private loadCity() {
        this.addressService.getCityByCountryState(this.params.countryId, this.params.stateAbbrev, this.params.cityName || '', false)
            .subscribe((cWithMessage: CitiesWithMessage) => {
                this.cities = cWithMessage.cities;
                if (this.cities.length > 0) {
                    this.focusFirstElement();
                } else if (cWithMessage.message != null) {
                    if (cWithMessage.exception != Constants.EXCEPTION_SESSION_INVALID) {
                        const closeCallback = () => this.activeModal.close(null);
                        this.messageHandlerService.show(cWithMessage.message, MessageType.ERROR, closeCallback);
                    }
                }
            });
    }

    private loadCounty() {
        this.addressService.countyByCityId(this.params.ciId)
            .subscribe((counties: County[]) => {
                this.counties = counties;
                this.focusFirstElement();
            });
    }

    private loadFields() {
        this.offshoreAddressService.getOffshoreFields(this.params.waterBodyId, this.params.fieldName, false)
            .subscribe((fields: OffshoreField[]) => {
                this.fields = fields;
                this.focusFirstElement();
            });
    }

    private loadAreas() {
        this.areas = this.params.areas;
        this.focusFirstElement();
    }

    isCountryView() {
        return (this.type == LOCATION_COUNTRY);
    }

    isStateView() {
        return (this.type == LOCATION_STATE);
    }

    isCityView() {
        return (this.type == LOCATION_CITY);
    }

    isCountyView() {
        return (this.type == LOCATION_COUNTY);
    }

    isFieldView() {
        return (this.type == LOCATION_FIELD);
    }

    isAreaView() {
        return (this.type == LOCATION_AREA);
    }

    onRowDblclick(selRow: any) {
        if (this.isCountryView()) {
            this.returnCountry(selRow);
        }
        else if (this.isStateView()) {
            this.returnState(selRow);
        }
        else if (this.isCityView()) {
            this.returnCity(selRow);
        }
        else if (this.isCountyView()) {
            this.returnCounty(selRow);
        }
        else if (this.isFieldView()) {
            this.returnField(selRow);
        }
        else if (this.isAreaView()) {
            this.returnArea(selRow);
        }
    }

    private returnCountry(selRow: any) {
        let result: CountrySearchResult = { cId: selRow.cId, countryId: selRow.countryId, countryName: selRow.countryName };
        this.activeModal.close(result);
    }

    private returnState(selRow: any) {
        let result: StateSearchResult = { sId: selRow.sId, stateAbbrev: selRow.stateAbbrev, stateName: selRow.stateName };
        this.activeModal.close(result);
    }

    private returnCity(selRow: any) {
        let result: CitySearchResult = { ciId: selRow.ciId, cityName: selRow.cityName, stateAbbrev: selRow.stateAbbrev };
        this.activeModal.close(result);
    }

    private returnCounty(selRow: any) {
        let result: CountySearchResult = { coId: selRow.coId, countyName: selRow.countyName };
        this.activeModal.close(result);
    }

    private returnField(selRow: any) {
        let result: FieldSearchResult = { fieldId: selRow.fieldId, fieldName: selRow.fieldName };
        this.activeModal.close(result);
    }

    private returnArea(selRow: any) {
        let result: AreaSearchResult = { areaId: selRow.areaId, areaName: selRow.areaName };
        this.activeModal.close(result);
    }

    onRowSelect(event: any) {
        this.selection = event.data;
    }

    addRowSingleClick() {
        this.onRowDblclick(this.selection);
    }

    changeSelectionOnArrowKey(event: any, entityArray:any[], hasHeader:boolean = true) {
        TableUtils.changeSelectionOnArrowKey(this, "selection", event, entityArray, hasHeader);
    }

    closeModal() {
        this.activeModal.close()
    }

    clear() {
        this.countries = null;
        this.states = null;
        this.cities = null;
        this.counties = null;
        this.fields = null;
        this.areas = null;
        this.params.countryId = '';
        this.fullName = '';
    }

}
