import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageHandlerService } from './message-handler.service';

export enum MessageType {
    NONE = -1,
    ERROR = 0,
    CONFIRM = 1,
    CONFIRM_CANCEL = 2,
    INFO = 3,
    VALIDATION_ERROR = 4,
    RELEASE_ERROR = 5,
    SAVE_ERROR = 6,
    EXCEPTION = 7,
    CLOSE = 8,
    WARNING = 9,
    CAUTION = 10,
    MAP_IT_VALIDATION_ERROR = 11,
    WIDGET_REMOVE_CONFIRM = 12,
    CONFIRM_VALIDATION = 13,
    INFO_CONFIRM = 14,
    CONFIRM_OK_CANCEL = 15,
    PDF_SELECT_PREFERENCE = 16,
    CLOSE_CONFIRMATION = 17,
    CUSTOM_OK_CANCEL = 18,
    ALERT_TYPE_SYSTEM_MESSAGE = 19,
    MAP_IT_PREFERENCES = 20,
    CUSTOM_MESSAGE = 21,
    LOGOUT_CONFIRMATION = 22,
    INPUT = 23,
    SESSION_INVALID = 24,
    CUSTOM_INFO = 25
}

export interface MessageParameters {
    type: MessageType;
    title: string;
    text: string;
    actualInputValue: string;
    footer: string;
    actions: MessageAction[];
    disabled?: boolean;
    callback: (response: MessageResponse) => void;
}

export interface MessageAction {
    label: string;
    event: MessageEvent;
}

export enum MessageEvent {
    OK = 0,
    CANCEL = 1,
    YES = 2,
    NO = 3,
    REPORT = 4,
    ONLY_PLANT_CONTACTS = 5,
    ALL_ASSOCIATED_CONTACTS = 6,
}

export interface MessageResponse {
    event: MessageEvent;
    inputValue?: string;
}

export class MessageParametersBuilder {
    messageParameters: MessageParameters;

    constructor() {
        this.messageParameters = {
            type: MessageType.CUSTOM_MESSAGE,
            title: null,
            text: '',
            actualInputValue: '',
            footer: null,
            actions: [],
            callback: null
        };
    }

    type(type: MessageType): MessageParametersBuilder {
        this.messageParameters.type = type;
        return this;
    }

    title(title: string): MessageParametersBuilder {
        this.messageParameters.title = title;
        return this;
    }

    message(message: string): MessageParametersBuilder {
        this.messageParameters.text = message;
        return this;
    }

    footer(footer: string): MessageParametersBuilder {
        this.messageParameters.footer = footer;
        return this;
    }

    onClose(callback: (response: MessageResponse) => void): MessageParametersBuilder {
        this.messageParameters.callback = callback;
        return this;
    }

    addButton(action: MessageAction): MessageParametersBuilder {
        this.messageParameters.actions.push(action);
        return this;
    }

    build(): MessageParameters {
        return this.messageParameters;
    }

}

@Component({
    selector: 'app-handler-dialog',
    templateUrl: './message-handler.component.html',
    styleUrls: ['./message-handler.component.scss']
})
export class MessageHandlerComponent implements OnInit, AfterViewInit {
    message: MessageParameters;
    type: MessageType;
    title: string;
    text: string;
    actualInputValue: string;
    okLabel: string;
    cancelLabel: string;
    displayDialog: boolean = false;
    footer: string;
    actions: MessageAction[];
    disabled: boolean = false;
    onCloseDefaultFunction: (this: DynamicDialogRef<any>, ...args: MessageResponse[]) => void;
    @ViewChild('okButton') okButton: ElementRef;

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.okButton && this.okButton.nativeElement) {
                this.okButton.nativeElement.focus();
            }
        });
    }

    // This sentence allow use the MessageType enum values in the html template.
    MessageType = MessageType;

    constructor(private activeModal: DynamicDialogRef,
        private config: DynamicDialogConfig,
        private messageHandlerService: MessageHandlerService) { }

    ngOnInit(): void {
        this.message = this.config.data;
        this.type = this.message.type;
        this.title = this.message.title;
        this.text = this.message.text;
        this.actualInputValue = this.message.actualInputValue;
        this.disabled = this.message.disabled;
        this.text = this.replaceNewLine(this.text);
        this.footer = this.message.footer;
        this.actions = this.message.actions;
        this.onCloseDefaultFunction = this.activeModal.close;
        this.activeModal.close = this.interceptDialogClose.bind(this);
        
    }

    interceptDialogClose() {
        let resp: MessageResponse;
        // only yes-no buttons
        if(this.type === MessageType.CONFIRM ||
            this.type === MessageType.CLOSE ||
            this.type === MessageType.CAUTION ||
            this.type === MessageType.INFO_CONFIRM ||
            this.type === MessageType.PDF_SELECT_PREFERENCE) {

            resp =  {
                event: MessageEvent.NO
            };
        // only ok button
        } else if(this.type == MessageType.ERROR ||
                this.type == MessageType.INFO ||
                this.type == MessageType.SAVE_ERROR ||
                this.type == MessageType.WARNING ||
                this.type == MessageType.MAP_IT_VALIDATION_ERROR ||
                this.type == MessageType.ALERT_TYPE_SYSTEM_MESSAGE ||
                this.type == MessageType.RELEASE_ERROR ||
                this.type == MessageType.VALIDATION_ERROR) {
            resp =  { event: MessageEvent.OK }; 
        //for mapit options (just - system) do not execute any option     
        } else if(this.type == MessageType.MAP_IT_PREFERENCES) {
            resp =  { event: null }; 
        // for cancelable messages    
        } else {
            resp =  { event: MessageEvent.CANCEL };
        }        
        this.onCloseDefaultFunction.apply(this.activeModal, [resp]);
    }

    isInputEmpty(): boolean {
        return !this.actualInputValue || this.actualInputValue.trim() === '';
    }

    ok() {
        const resp: MessageResponse = {
            event: MessageEvent.OK
        }
        this.closeWithResponse(resp);
    }

    cancel() {
        const resp: MessageResponse = {
            event: MessageEvent.CANCEL
        }
        this.closeWithResponse(resp);
    }

    yes() {
        const resp: MessageResponse = {
            event: MessageEvent.YES
        }
        this.closeWithResponse(resp);
    }

    no() {
        const resp: MessageResponse = {
            event: MessageEvent.NO
        }
        this.closeWithResponse(resp);
    }

    execAction(event: MessageEvent) {
        const resp: MessageResponse = {
            event: event,
            inputValue: this.actualInputValue
        }
        this.closeWithResponse(resp);
    }

    closeWithResponse(resp: MessageResponse) {
        this.onCloseDefaultFunction.apply(this.activeModal, [resp]);
    }

    report() {
        this.messageHandlerService.reportException();
        const resp: MessageResponse = {
            event: MessageEvent.OK
        }
        this.closeWithResponse(resp);
    }

    replaceNewLine(text: string) {
        return text.replace(/\n/g, '<br>');
    }

}
