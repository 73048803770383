import { Bean } from "../../bean";

export class PlantsGreenhouseGasEmissions extends Bean {

    plantId: number;
    emissionsYear: string;
    co2Amount:string;
    comments:string;
    lastDate: Date;
    lastUser: string;
    mvOrder: number;
    mvId: string;

    constructor(entity?: any) {
        super(entity);

        this.plantId = entity && entity.plantId || null;
        this.emissionsYear = entity && entity.emissionsYear || null;
        this.co2Amount = entity && entity.co2Amount != null ? String(entity.co2Amount) : null;
        this.comments = entity && entity.comments || null;
        this.lastDate = entity && entity.lastDate || null;
        this.lastUser = entity && entity.lastUser || null;
        this.mvOrder = entity && entity.mvOrder || null;
        this.mvId = entity && entity.mvId || null;
    }
}



