import {Bean} from "../../bean";

export class ContactsComments extends Bean {
    mvId: number;
    contactId: number;
    comments: string;
    mvOrder: number;
    createUserId: number;
    createDate: string;

    protected constructor(entity?: any) {
        super(entity);

        this.mvId = entity && entity.mvId || null;
        this.contactId = entity && entity.contactId || null;
        this.comments = entity && entity.comments || null;
        this.mvOrder = entity && entity.mvOrder || null;
        this.createUserId = entity && entity.createUserId || null;
        this.createDate = entity && entity.createDate || null;
    }
}
