export enum EntityName {
  DASHBOARD = 'Dashboard',
  AREA = 'Area',
  ASSET = 'Asset',
  ASSET_PIPELINE = 'Pipeline',
  ASSET_TRANSMISSION_LINE = 'Transmission Line',
  BOILER = 'Boiler',
  COMPANY = 'Company',
  PLANT = 'Plant',
  OFFLINE_EVENT = 'Offline Event',
  TANK = 'Tank',
  MEQUIPMENT = 'Mining Equipment',
  OEQUIPMENT = 'Other Equipment',
  PROJECT = 'Project',
  PROJECT_CONFIRMED = 'Confirmed Project',
  PROJECT_UNCONFIRMED = 'Unconfirmed Project',
  PROJECT_UMBRELLA = 'Umbrella Project',
  UMBRELLA_PROJECT = 'Umbrella Project',
  PIPELINE_METER = 'Meter',
  SITE = 'Site',
  SUBSCRIPTION = 'Subscription',
  TURBINE = 'Drive',
  UNIT = 'Unit',
  UNIT_LTSA = 'Unit LTSA',
  PLANT_LTSA = 'Plant LTSA',
  PIPELINE_LTSA = 'Pipeline LTSA',
  GEOGRAPHY = 'Geography',
  GEOGRAPHY_OFFSHORE = 'GeographyOffshore',
  CODE_FILES = 'Code Files',
  REGION_ALERTING = 'Region Alerting',
  RELEASE = 'Release',
  ALERTS = 'Alert',
  COUNTRY = 'Country',
  STATE = 'State',
  COUNTY = 'County',
  CITY = 'City',
  PRODUCT = 'Product',
  SIC_CODE = 'SIC Code',
  COMPANY_TYPE = 'Company Type',
  PLANT_PRODUCT = 'Plant Product',
  PROJECT_PRODUCT = 'Project Product',
  UNIT_TYPE = 'Unit Type',
  DESIGN_FIRM = 'Design Firm',
  CONSTRUCTOR = 'Constructor',
  MANUFACTURER = 'Manufacturer',
  DRIVEN_EQUIPMENT_TYPE = 'Driven Equipment Type',
  EQUIPMENT_TYPE = 'Equipment Type',
  CRUDE_SLATE_TYPE = 'Crude Slate Type',
  SERV_CONTRACTOR = 'Service Contractor',
  PIPELINE_SYSTEM = 'Pipeline System',
  TRANSMISSION_SYSTEM = 'Transmission System',
  HS_PRODUCT = 'HS Product',
  WORLD_REGION = 'World Region',
  KV_RATING = 'kV Rating',
  AEC_FIRM = 'AEC Firm',
  PEC_ACTIVITY = 'PEC Activity',
  AUTHOR = 'Author',
  SIC_PRODUCT = 'SIC Product',
  FUEL_TYPE = 'Fuel Type',
  TITLE_QUERY = 'Title Query',
  NATIONALITY = 'Nationality',
  CONTACT = 'Contact',
  WATER_BODY = 'Water Body',//Geo
  FIELD_NAME = 'Field Name',//Geo
  FIELD = 'Field',//Geo
  UPA = 'UPA',
  UMB = 'UMB',
  PROD_FIELD_TYPE = 'Production Type',
  PREFERENCES = 'Preferences',
  NEWS = 'News',
  OPERATOR = "Operator",
  OWNER = "Owner",
  VENDOR = "Vendor"
}
