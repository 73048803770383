
<div class="grid flex mt-1">    
    <div class="col-4 xl:col-3">
        <label class="font-bold text-xs mt-1 flex justify-content-end">{{'project.pecZone.pecZoneID'| translate}}:</label>
        <label class="font-bold text-xs mt-3 flex justify-content-end">{{'project.pecZone.pecZoneDesc'| translate}}:</label>
    </div>
    <div class="col-4 -ml-2">
        <input #pecZoneId
            class="form__input-field-style w-full flex justify-content-end" 
            type="text"
            name="pecZoneId" 
            (keyup.enter)="loadList()" 
            [(ngModel)]="params.pecZoneId" 
            pInputText 
            autofocus appFocusElement #tabIndexSetted
        />
        <input 
            class="form__input-field-style w-full flex justify-content-end mt-2" 
            type="text"
            name="pecZoneName" 
            (keyup.enter)="loadList()" 
            [(ngModel)]="params.pecZoneName" 
            pInputText
            #tabIndexSetted
        />            
    </div>
    <div class="col-4 xl:col-5 mt-2 px-0">
        <div class="empty-div"></div>
        <div>
            <button (click)="loadList()" class="btn-row" #tabIndexSetted>{{'common.search'|translate}}</button>
        </div>
    </div>
</div>
<div class="-mt-3 pt-1">
    <div class="container-table-modal custom-scrollable-table-modal"> 
        <p-table 
        [value]="pecZones" 
        [style]="{'width':'100%'}" [reorderableColumns]="true" 
        [resizableColumns]="true" 
        selectionMode="single"
        [(selection)]="this.selection"
        (onRowSelect)="onRowSelect($event)">

        <ng-template pTemplate="body" let-item>
            <tr #tr [pSelectableRow]="item" (dblclick)="onRowDblclick(item)"
                    (keydown.enter)="onRowDblclick(item)"
                    (keydown)="changeSelectionOnArrowKey($event, pecZones, false)" >
                <td>{{item.pecZoneId}} - {{item.pecZoneName}}</td>
            </tr>
        </ng-template>
        </p-table>            
    </div>
        <div class="text-center mt-2">
            <button class="btn-row" (click)="addRowSingleClick()" #tabIndexSetted>{{'common.save'| translate}}</button>
            <button class="btn-row ml-2" (click)="closeModal()" #tabIndexSetted #lastElement>{{'common.cancel'| translate}}</button>
        </div>
</div>
