import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SortEvent } from "primeng/api";
import { Preference } from "src/app/core/models/common";
import { PreferencePrefix } from "src/app/core/models/constants";
import { EntityName } from "src/app/core/models/enumerations/entity-name";
import { SharedService } from "src/app/core/services/shared.service";
import { StringUtils } from "src/app/core/utils/string-utils";
import { ColumnEntityInfo, ColumnIconButton } from "src/app/shared/components/base/mvt-entity-associator/mvt-entity-associator.component";

export const RELATED_INFO_NEW_ENTITY_KEY = 'relatedInfoNewEntityKey';

@Component({
    template: ''
})

export class RelatedInfoBase {
    callerIsCompany: boolean = false;
    callerIsSite: boolean = false;
    callerIsPipeline: boolean = false;
    callerIsTransmissionLine: boolean = false;
    callerIsPlant: boolean = false;
    callerIsArea: boolean = false;
    callerIsProject: boolean = false;
    callerIsUnit: boolean = false;
    callerIsOEquipment: boolean = false;
    callerIsMiningEquipment: boolean = false;
    callerIsOfflineEvent: boolean = false;

    @Input() classToggled = false;
    cols: Array<any> = [];
    @Input() columnsInfo: ColumnEntityInfo[] = [];

    @Input() implementedComponent: any;
    @Input() caller: string;
    @Input() baseIndex?: number;
    @Input() rows: Array<any> = [];
    @Input() showHeader: boolean = true;
    @Input() callerEntityDisplay?: any;
    @Input() callerEntityIdPropName: string;
    @Input() addRecordDestinationPage: string;
    @Input() addRecordFunction:(implementedComponent: any, addRecordDestinationPage: string) => void;
    @Output() reloadFunction: EventEmitter<void> = new EventEmitter();
    @Input() preferences: Array<Preference>;
    @Input() tableKey: string;

    sharedService: SharedService;
    entityTableKey: string;

    constructor(
        sharedService: SharedService
    ) { this.sharedService = sharedService; }

    initBase() {
        this.callerIsSite = this.caller === EntityName.SITE;
        this.callerIsCompany = this.caller === EntityName.COMPANY;
        this.callerIsPipeline = this.caller === EntityName.ASSET_PIPELINE;
        this.callerIsTransmissionLine = this.caller === EntityName.ASSET_TRANSMISSION_LINE;
        this.callerIsPlant = this.caller === EntityName.PLANT;
        this.callerIsProject = this.caller === EntityName.PROJECT ||
                                this.caller === EntityName.PROJECT_CONFIRMED ||
                                    this.caller === EntityName.PROJECT_UNCONFIRMED;
        this.callerIsArea = this.caller === EntityName.AREA;
        this.callerIsUnit = this.caller === EntityName.UNIT;
        this.callerIsOEquipment = this.caller === EntityName.OEQUIPMENT;
        this.callerIsMiningEquipment =  this.caller === EntityName.MEQUIPMENT;
        this.callerIsOfflineEvent = this.caller === EntityName.OFFLINE_EVENT;
        if(this.entityTableKey) {
            this.tableKey = StringUtils.removeWhiteSpace(PreferencePrefix.RelatedInfo+this.entityTableKey);
        }
    }

    toggleField() {
        this.classToggled = !this.classToggled;
    }

    customSort(event: SortEvent) {
        this.sharedService.customSort(event);
    }

    reload() {
        if(!StringUtils.isEmpty(this.getCallerEntityId())) {
            this.reloadFunction.emit();
        }
    }

    getColumnTitleEntityNotAvailable?(): string {
        return 'common.entityNotAvailable';
    }

    createAttachmentColumn(entityPropName: string, widthPercentage: number, onClick?: (columnEntityInfo: ColumnEntityInfo, implementedComponent: any, rowData: any) => void): ColumnEntityInfo {
        let columnHeaderIcon = 'assets/icons/attached.png';
        let iconButtonArray = this.getAttachmentIconButton(onClick);
        return { entityPropName: entityPropName, columnHeader: '', columnHeaderIcon: columnHeaderIcon, iconButtonArray: iconButtonArray, widthPercentage: widthPercentage }
    }

    getAttachmentIconButton(onClick?: (columnEntityInfo: ColumnEntityInfo, implementedComponent: any, rowData: any) => void): ColumnIconButton[] {
        let iconButtonArray: ColumnIconButton[] = new Array();
        iconButtonArray.push({ getDynamicSrc: this.getDynamicSrcAttachmentIcon, onClick: onClick, tooltip: "Download attachment"});

        return iconButtonArray;
    }

    getDynamicSrcAttachmentIcon?(columnEntityInfo: ColumnEntityInfo, rowData: any) {
        if(rowData[columnEntityInfo.entityPropName] > 0) {
            return "assets/icons/attached_small.png";
        } else {
            return null;
        }
    }

    createDuplicateColumn(targetEntityIdPropName: string, routerEntityName: string, widthPercentage: number = 5): ColumnEntityInfo {
        let iconButtonArray = this.getDuplicateIconButton();
        return {
            entityPropName: '_' + targetEntityIdPropName,
            searchedEntityPropName: targetEntityIdPropName,
            columnHeader: '',
            routerEntityName: routerEntityName,
            iconButtonArray: iconButtonArray,
            widthPercentage: widthPercentage
        }
    }

    getDuplicateIconButton(): ColumnIconButton[] {
        let iconButtonArray: ColumnIconButton[] = new Array();
        iconButtonArray.push({ src: "assets/icons/duplicate.png", onClick: this.duplicateEntityFunction, tooltip: "Duplicate"});

        return iconButtonArray;
    }


    duplicateEntityFunction(columnEntityInfo: ColumnEntityInfo, implementedComponent: RelatedInfoBase, rowData: any): void {
        if(!StringUtils.isEmpty(columnEntityInfo.routerEntityName)) {
            if(rowData != null) {
                let selectedEntityId = rowData[columnEntityInfo.searchedEntityPropName];
                if(!StringUtils.isEmpty(selectedEntityId)) {
                    window.open(location.pathname+ `#${columnEntityInfo.getRouterLink(rowData) + "?" + columnEntityInfo.searchedEntityPropName}=${selectedEntityId}`, '_blank');
                }
            }
        }
    }



    public static saveEntityDisplayInLocalStorage(entityDisplay: any) {
        localStorage.setItem(RELATED_INFO_NEW_ENTITY_KEY, JSON.stringify(entityDisplay));
    }

    public static getSavedEntityDisplay(): any {
        const entityDisplay = localStorage.getItem(RELATED_INFO_NEW_ENTITY_KEY);
        if (entityDisplay) {
            localStorage.removeItem(RELATED_INFO_NEW_ENTITY_KEY);
            return JSON.parse(entityDisplay);
        }
        return null;
    }




    getCallerEntityId():number {
        let callerEntityId:string = null;
        if(this.callerEntityDisplay != null && !StringUtils.isEmpty(this.callerEntityIdPropName)) {
            callerEntityId = this.callerEntityDisplay[this.callerEntityIdPropName];
        }
        return Number(callerEntityId);
    }

    isAddButtonDisabled():boolean {

        return StringUtils.isEmpty(this.getCallerEntityId()) || this.addRecordDestinationPage === 'notyet';
    }

    addEntityFunction(implementedComponent: any, addRecordDestinationPage: string): void {
        if(!StringUtils.isEmpty(addRecordDestinationPage) && !StringUtils.isEmpty(this.getCallerEntityId())) {
            RelatedInfoBase.saveEntityDisplayInLocalStorage(this.callerEntityDisplay);
            window.open(location.pathname+ `#/ED/${addRecordDestinationPage + "?" + this.callerEntityIdPropName}=${this.getCallerEntityId()}`, '_blank');
        }
    }

}
