import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/core/services/shared.service';
import { EntityName } from 'src/app/core/models/enumerations/entity-name';
import { RelatedInfoBase } from '../related-info.base';
import { ColumnEntityInfo, ColumnEntityInfoEditableType } from 'src/app/shared/components/base/mvt-entity-associator/mvt-entity-associator.component';

@Component({
    selector: 'app-boiler',
    templateUrl: './boiler.component.html',
    styleUrls: ['./boiler.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RelatedInfoBoilerComponent extends RelatedInfoBase implements OnInit {
    entityTableKey =EntityName.BOILER;
    constructor(
        public sharedService: SharedService
    ) { super(sharedService); }

    ngOnInit(): void {
        this.initBase();
    }

    getColumnsInfo(): ColumnEntityInfo[] {
        let columnsInfo: ColumnEntityInfo[] = [];
        if(this.callerIsProject) {
            columnsInfo.push({ entityPropName: 'BoilerId', columnHeader: 'relatedInfo.boiler.boilerId',  routerEntityName: EntityName.BOILER, widthPercentage: 8});
            columnsInfo.push({ entityPropName: 'BoilerName', columnHeader: 'relatedInfo.boiler.boilerName', widthPercentage: 15});
            columnsInfo.push({ entityPropName: 'ManufacturerName', columnHeader: 'relatedInfo.boiler.manufacturerName', widthPercentage: 15});
            columnsInfo.push({ entityPropName: 'UnitId', columnHeader: 'relatedInfo.boiler.unitId', widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'UnitName', columnHeader: 'relatedInfo.boiler.unitName', widthPercentage: 16});
            columnsInfo.push({ entityPropName: 'BoilerTypeDesc', columnHeader: 'relatedInfo.boiler.boilerType', widthPercentage: 8});
            columnsInfo.push({ entityPropName: 'BoilerModel', columnHeader: 'relatedInfo.boiler.model', widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'QcDate', columnHeader: 'relatedInfo.common.qcDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 8});
            columnsInfo.push({ entityPropName: 'ReleaseDate', columnHeader: 'relatedInfo.common.releaseDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 10});

        } else if(this.callerIsArea){
            columnsInfo.push({ entityPropName: 'BoilerId', columnHeader: 'relatedInfo.boiler.boilerId', routerEntityName: EntityName.BOILER, widthPercentage: 17});
            columnsInfo.push({ entityPropName: 'BoilerName', columnHeader: 'relatedInfo.boiler.boilerName', widthPercentage: 17});
            columnsInfo.push({ entityPropName: 'ManufacturerName', columnHeader: 'relatedInfo.boiler.manufacturerName', widthPercentage: 17});
            columnsInfo.push({ entityPropName: 'UnitName', columnHeader: 'relatedInfo.boiler.unitName', widthPercentage: 16});
            columnsInfo.push({ entityPropName: 'BoilerTypeDesc', columnHeader: 'relatedInfo.boiler.boilerType', widthPercentage: 11});
            columnsInfo.push({ entityPropName: 'QcDate', columnHeader: 'relatedInfo.common.qcDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 11});
            columnsInfo.push({ entityPropName: 'ReleaseDate', columnHeader: 'relatedInfo.common.releaseDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 11});

        } else if(this.callerIsUnit){
            columnsInfo.push({ entityPropName: 'BoilerId', columnHeader: 'relatedInfo.boiler.boilerId', routerEntityName: EntityName.BOILER, widthPercentage: 15});
            columnsInfo.push({ entityPropName: 'BoilerName', columnHeader: 'relatedInfo.boiler.boilerName', widthPercentage: 15});
            columnsInfo.push({ entityPropName: 'BoilerTypeDesc', columnHeader: 'relatedInfo.boiler.boilerType', widthPercentage: 15});
            columnsInfo.push({ entityPropName: 'ManufacturerName', columnHeader: 'relatedInfo.boiler.boilerManufacturer', widthPercentage: 20});
            columnsInfo.push({ entityPropName: 'BoilerModel', columnHeader: 'relatedInfo.boiler.boilerInfo', widthPercentage: 15});
            columnsInfo.push({ entityPropName: 'QcDate', columnHeader: 'relatedInfo.common.qcDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'ReleaseDate', columnHeader: 'relatedInfo.common.releaseDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 10});
        } else if(this.callerIsOfflineEvent){
            columnsInfo.push({ entityPropName: 'BoilerId', columnHeader: 'relatedInfo.boiler.boilerId', routerEntityName: EntityName.BOILER, widthPercentage: 15});
            columnsInfo.push({ entityPropName: 'UnitName', columnHeader: 'relatedInfo.boiler.unitName', widthPercentage: 16});
            columnsInfo.push({ entityPropName: 'BoilerTypeDesc', columnHeader: 'relatedInfo.boiler.unitType', widthPercentage: 15});
            columnsInfo.push({ entityPropName: 'PlantName', columnHeader: 'relatedInfo.boiler.plantName', widthPercentage: 16});
            columnsInfo.push({ entityPropName: 'OwnerName', columnHeader: 'relatedInfo.boiler.ownerName', widthPercentage: 15});
            columnsInfo.push({ entityPropName: 'BoilerTypeDesc', columnHeader: 'relatedInfo.boiler.boilerType', widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'QcDate', columnHeader: 'relatedInfo.common.qcDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'ReleaseDate', columnHeader: 'relatedInfo.common.releaseDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 10});
        } else {
            columnsInfo.push({ entityPropName: 'BoilerId', columnHeader: 'relatedInfo.boiler.boilerId', routerEntityName: EntityName.BOILER, widthPercentage: 15});
            columnsInfo.push({ entityPropName: 'BoilerName', columnHeader: 'relatedInfo.boiler.boilerName', widthPercentage: 15});
            columnsInfo.push({ entityPropName: 'ManufacturerName', columnHeader: 'relatedInfo.boiler.manufacturerName', widthPercentage: 15});
            columnsInfo.push({ entityPropName: 'UnitName', columnHeader: 'relatedInfo.boiler.unitName', widthPercentage: 15});
            columnsInfo.push({ entityPropName: 'BoilerTypeDesc', columnHeader: 'relatedInfo.boiler.boilerType', widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'BoilerModel', columnHeader: 'relatedInfo.boiler.model', widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'QcDate', columnHeader: 'relatedInfo.common.qcDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'ReleaseDate', columnHeader: 'relatedInfo.common.releaseDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 10});

        }

        return columnsInfo;
    }

}
