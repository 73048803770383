import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
// Modal Dialog
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
// Service
import { SharedService } from 'src/app/core/services/shared.service';

// Models
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { SortEvent } from 'primeng/api';
import { OfflineEventSearchParams } from 'src/app/core/interfaces/search/ioffline-event-search-params';
import { Constants, EntityPreferenceSufix, RecordStatusValue } from 'src/app/core/models/constants';
import { OperationType } from 'src/app/core/models/enumerations/operation-type';
import { ProjectsOfflineEventsDisplay } from 'src/app/core/models/project/display/projects-offline-events-display';
import { ProjectsUnitIdsDisplay } from 'src/app/core/models/project/display/projects-unit-ids-display';
import { OfflineEventSearch } from 'src/app/core/models/search/offline-event-search';
import { SpinnerProcess } from 'src/app/core/models/spinner-process';
import { EntityCommonService } from 'src/app/core/services/entity-common.service';
import { StringUtils } from 'src/app/core/utils/string-utils';
import { TableUtils } from 'src/app/core/utils/table-utils';
import { OfflineEventSectionService } from 'src/app/offline-event-section/offline-event-section.service';
import { Industry, OfflineEvent, RecordStatus, SicCode, UnitTypes } from '../../../core/models/common';
import { LoadWheelService } from '../../load-wheel/load-wheel.service';
import { MessageHandlerService } from '../../messages/message-handler/message-handler.service';
import { BaseModalComponent } from '../base-modals.component';
import { PreferencesSectionService } from 'src/app/preferences-section/preferences-section.service';

@Component({
    selector: 'app-search-offline-event-modal',
    templateUrl: './search-offline-event-modal.component.html'
})

export class SearchModalOfflineEventComponent extends BaseModalComponent implements OnInit {
    @ViewChild('unitname') inputUnitName: ElementRef;
    tableKey = EntityPreferenceSufix.Entity;
    offlineEvents: Array<OfflineEventSearch> = [];
    elementsToAdd: Array<OfflineEventSearch> = [];
    entitiesArray: ProjectsOfflineEventsDisplay[];
    unitsIds: ProjectsUnitIdsDisplay[];
    unitTypes: Array<UnitTypes> = [];
    unitsSicCodes: Array<SicCode> = [];
    recordStatuses: Array<RecordStatus> = [];
    additionalInfo: OfflineEventSearch;
    industry: Array<Industry> = [];
    cols: any[] = [];
    isMainAccess: boolean = false;
    enabledNewRecordButton: boolean = false;

    constructor(
        private messageHandler: MessageHandlerService,
        public activeModal: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public sharedService: SharedService,
        public dialogService: DialogService,
        public translate: TranslateService,
        public offlineEventService: OfflineEventSectionService,
        public loadWheelService: LoadWheelService,
        private entityCommonService: EntityCommonService,
        public preferencesService: PreferencesSectionService,
        public datePipe: DatePipe) {
        super(activeModal, config, preferencesService);
    }

    searchParams: OfflineEventSearchParams = {
        offlineEventId: '',
        eventType: '',
        eventStatus: '',
        startDate: '',
        endDate: '',
        ownerName: '',
        unitId: '',
        unitName: '',
        plantId: '',
        plantName: '',
        industryCode: '',
        sicCode: '',
        unitType: '',
        areaName: '',
        operatorName: '',
        recordStatus: '',
        assetId: '',
        assetName: '',
        searchOfflineEventsIds: true,
        unitsIds: '',
        offlineEventsIds: '',
        recordedSearch: false
    }



    ngOnInit() {
        this.initPaginatorPreferences();
        this.isMainAccess = this.config.data?.isMainAccess !== undefined ? this.config.data?.isMainAccess : false;
        this.cols = [
            { field: 'eventID', header: this.translate.instant('project.searchOfflineEvent.eventId'), width: '12%' },
            { field: 'unitType', header: this.translate.instant('project.searchOfflineEvent.unitType'), width: '15%' },
            { field: 'eventStatusDesc', header: this.translate.instant('project.searchOfflineEvent.eventStatus'), width: '15%' },
            { field: this.isMainAccess ? 'largeEventName' : 'eventName', header: this.translate.instant('project.searchOfflineEvent.eventName'), width: '34%' },
            { field: 'eventStartDate', displayValueFunc: this.eventStartDisplay, header: this.translate.instant('project.searchOfflineEvent.eventStartDate'), width: '11%' },
            { field: 'eventDuration', header: this.translate.instant('project.searchOfflineEvent.duration'), width: '7%' }
        ];

        if (!this.isMainAccess) {
            this.cols.push({ field: 'checkbox', width: '6%' });
        }

        this.entitiesArray = this.config.data.entitiesArray;
        this.unitsIds = this.config.data.unitsIds;
        this.loadList();
        this.clear();
        this.searchOfflineEventByParamsRecording();
        if (this.searchParams.unitName != null && this.searchParams.unitName !== '') {
            setTimeout(() => this.inputUnitName.nativeElement.select(), 0);
        }
    }

    search(event?: any) {
        this.searchOfflineEvents(this.searchParams);
    }


    eventStartDisplay(implementedComponent: SearchModalOfflineEventComponent, rowData: OfflineEvent): string {
        return StringUtils.toStringNeverNull(implementedComponent.datePipe.transform(rowData.eventStartDate, 'dd-MMM-y'));
    }


    onPanelHide() {
        this.inputUnitName.nativeElement.focus()
    }

    private searchOfflineEventByParamsRecording() {

        this.searchParams = this.offlineEventService.searchParams;
        if (!this.isMainAccess) {
            this.searchParams.unitsIds = this.getUnitIds();
            this.searchParams.offlineEventsIds = this.getOfflineEvents();
            this.searchParams.recordedSearch = true;
            this.searchParams.searchOfflineEventsIds = true;
        }

        if (this.searchParams.recordedSearch) {
            if (!this.isEmptyObjectProperty(this.offlineEventService.searchParams)) {
                this.searchOfflineEvents(this.offlineEventService.searchParams);
            }
        }
    }

    private isEmptyObjectProperty(object: Object): boolean {
        return Object.values(object).every(x => (x === null || x === ''));
    }

    private searchOfflineEvents(params: OfflineEventSearchParams) {
        let wheel: SpinnerProcess = this.loadWheelService.showWheel(this.translate.instant('offlineEvent.searchingOfflineEvents'));
        this.offlineEventService.searchOfflineEvents(params, wheel)
            .subscribe({
                next: (offlineEvent: OfflineEventSearch[]) => {
                    if(offlineEvent === null) {
                        offlineEvent = [];
                        this.additionalInfo = null;
                        params.recordedSearch = false;
                        this.enabledNewRecordButton = false;
                    } else if (offlineEvent.length === 0) {
                        this.messageHandler.showAlertAndReturnFocus(this.translate.instant("common.searchNotFound"));
                        this.additionalInfo = null;
                        params.recordedSearch = false;
                        this.enabledNewRecordButton = true;
                    } else {
                        this.additionalInfo = offlineEvent[0];
                        this.focusFirstElement();
                        params.recordedSearch = true;
                        this.enabledNewRecordButton = true;
                    }
                    this.offlineEvents = offlineEvent;
                    this.loadWheelService.hideWheel(wheel);
                    this.resetTable();
                },
                error: (error) => {
                    this.loadWheelService.hideWheel(wheel);
                    params.recordedSearch = false;
                    if (error.message.endsWith(Constants.MAX_ROWS_LIMITATION_MESSAGE)) {
                        this.messageHandler.showAlertAndReturnFocus(error.message);
                        this.offlineEvents = [];
                        this.additionalInfo = null;
                        this.enabledNewRecordButton = false;
                    } else {
                        console.warn(error);
                    }
                }
            });
    }

    onRowSelect(event: any) {
        this.additionalInfo = event;
    }

    changeSelectionOnArrowKey(event: any, entityArray: any[], hasHeader: boolean = true) {
        TableUtils.changeSelectionOnArrowKey(this, "additionalInfo", event, entityArray, hasHeader);
    }

    onKeyDown(event: any, entityArray: any[], rowData: any, hasHeader: boolean = true): void {
        this.changeSelectionOnArrowKey(event, entityArray, hasHeader)
    }

    onKeyDownEnter(rowData: any): void {
        if (this.isMainAccess) {
            this.onRowDblclick(rowData);
        }
    }

    onRowDblclick(event: any) {
        if (this.isMainAccess) {
            this.activeModal.close(event);
        }
    }

    newRecord() {
        this.entityCommonService.sendNewRecordEvent();
        this.activeModal.close();
    }

    clear() {
        this.searchParams.unitName = '';
        this.searchParams.ownerName = '';
        this.searchParams.unitId = '';
        this.searchParams.plantName = '';
        this.searchParams.plantId = '';
        this.searchParams.assetId = '';
        this.searchParams.assetName = '';
        this.searchParams.unitType = '';
        this.searchParams.sicCode = '';
        this.searchParams.industryCode = '';
        this.searchParams.recordStatus = '';
        this.offlineEvents = [];
        this.elementsToAdd.length = 0;
        this.additionalInfo = null;
        this.searchParams.recordedSearch = false;
        this.searchParams.unitsIds = '';
        this.searchParams.offlineEventsIds = '';
        this.inputUnitName?.nativeElement.focus();
        this.enabledNewRecordButton = false;
    }

    private loadList() {
        this.loadUnitType();
        this.loadUnitSicCode();
        this.loadRecordStatus();
        this.loadIndustry();
    }

    private loadUnitType() {
        this.sharedService.getUnitType()
            .subscribe((unitTypes: UnitTypes[]) => this.unitTypes = [...unitTypes]);
    }

    private loadUnitSicCode() {
        this.sharedService.getUnitsSicCodes()
            .subscribe((sicCodes: SicCode[]) => this.unitsSicCodes = [...sicCodes]);
    }

    private loadRecordStatus() {
        this.sharedService.getRecordStatus()
            .subscribe((record: RecordStatus[]) => this.recordStatuses = [...record.filter(item => item.RecordStatusID !== RecordStatusValue.Pending)]);
    }

    private loadIndustry() {
        this.sharedService.getAllIndustryCodes()
            .subscribe((industry: Industry[]) => this.industry = [...industry]);
    }

    customSort(event: SortEvent) {
        this.sharedService.customSort(event);
    }

    changeElementsToAdd(event: any, rowData: OfflineEventSearch) {
        if (event.target.checked) {
            this.elementsToAdd.push(rowData);
        }
        else {
            this.elementsToAdd = this.elementsToAdd.filter(entity => entity.eventID !== rowData.eventID);
        }

    }

    addOfflineEvents() {
        this.activeModal.close(this.elementsToAdd);
        this.elementsToAdd.length = 0;
    }


    getUnitIds(): string {
        const filteredUnits = this.unitsIds.filter(unit => unit.getOperation() !== OperationType.DELETE);
        const unitsIdsCollected = filteredUnits.map(unit => unit.unitId).join(',');
        return unitsIdsCollected;
    }

    getOfflineEvents(): string {
        const filteredEntities = this.entitiesArray.filter(entity => entity.getOperation() !== OperationType.DELETE && entity.offlineEventId > 0);
        const offlineEventsIdsCollected = filteredEntities.map(entity => StringUtils.toStringNeverNull(entity.offlineEventId)).join(',');
        return offlineEventsIdsCollected;
    }

}
