import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/core/services/shared.service';
import { RelatedInfoBase } from '../related-info.base';
import { ColumnEntityInfo, ColumnEntityInfoEditableType } from 'src/app/shared/components/base/mvt-entity-associator/mvt-entity-associator.component';
import { EntityName } from 'src/app/core/models/enumerations/entity-name';

@Component({
    selector: 'app-unit-ltsa',
    templateUrl: './unit-ltsa.component.html',
    styleUrls: ['./unit-ltsa.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RelatedInfoUnitLtsaComponent extends RelatedInfoBase implements OnInit {
    entityTableKey =EntityName.UNIT_LTSA;
    constructor(
        public sharedService: SharedService) {
        super(sharedService);
    }

    ngOnInit(): void {
        this.initBase();
    }

    getColumnsInfo(): ColumnEntityInfo[] {
        let columnsInfo: ColumnEntityInfo[] = [];
        columnsInfo.push({ entityPropName: 'ltsaId', columnHeader: 'relatedInfo.unitLtsa.ltsaId', routerEntityName: EntityName.UNIT_LTSA, widthPercentage: 12});
        columnsInfo.push({ entityPropName: 'ltsa', columnHeader: 'relatedInfo.unitLtsa.ltsa', widthPercentage: 11});
        columnsInfo.push({ entityPropName: 'status', columnHeader: 'relatedInfo.unitLtsa.status', widthPercentage: 11});
        columnsInfo.push({ entityPropName: 'contractorName', columnHeader: 'relatedInfo.unitLtsa.contractor', widthPercentage: 11});
        columnsInfo.push({ entityPropName: 'contractLength', columnHeader: 'relatedInfo.unitLtsa.contractLength', widthPercentage: 11, headerAlign: 'right', bodyAlign: 'right'});
        columnsInfo.push({ entityPropName: 'ltsaType', columnHeader: 'relatedInfo.unitLtsa.ltsaType', widthPercentage: 11});
        columnsInfo.push({ entityPropName: 'expiration', columnHeader: 'relatedInfo.unitLtsa.expiration', widthPercentage: 11});
        columnsInfo.push({ entityPropName: 'qcDate', columnHeader: 'relatedInfo.common.qcDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 11});
        columnsInfo.push({ entityPropName: 'releaseDate', columnHeader: 'relatedInfo.common.releaseDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 11});

        return columnsInfo;
    }

}
