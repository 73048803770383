import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AttachmentService } from 'src/app/core/services/attachment.service';
import { SharedService } from 'src/app/core/services/shared.service';
import { RelatedInfoBase } from '../related-info.base';
import { ColumnEntityInfo, ColumnEntityInfoEditableType } from 'src/app/shared/components/base/mvt-entity-associator/mvt-entity-associator.component';
import { EntityName } from 'src/app/core/models/enumerations/entity-name';

@Component({
    selector: 'app-plant',
    templateUrl: './plant.component.html',
    styleUrls: ['./plant.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class PlantComponent extends RelatedInfoBase implements OnInit {
    entityTableKey =EntityName.PLANT;

    constructor(
        public router: Router,
        private attachmentService: AttachmentService,

        sharedService: SharedService
    ) { super(sharedService); }

    ngOnInit(): void {
        this.initBase();
    }

    getColumnsInfo(): ColumnEntityInfo[] {
        let columnsInfo: ColumnEntityInfo[] = [];
        if (this.callerIsCompany) {
            columnsInfo.push({ entityPropName: 'PlantID', columnHeader: 'relatedInfo.plant.plantId', routerEntityName: EntityName.PLANT, widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'PlantName', columnHeader: 'relatedInfo.plant.plantName', widthPercentage: 16});
            columnsInfo.push({ entityPropName: 'Owner', columnHeader: 'common.owner', widthPercentage: 13});
            columnsInfo.push({ entityPropName: 'Status', columnHeader: 'relatedInfo.plant.plantStatus', widthPercentage: 13});
            columnsInfo.push({ entityPropName: 'State', columnHeader: 'common.state', widthPercentage: 13});
            columnsInfo.push({ entityPropName: 'QcDate', columnHeader: 'relatedInfo.common.qcDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 13});
            columnsInfo.push({ entityPropName: 'ReleaseDate', columnHeader: 'relatedInfo.common.releaseDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 13});
            columnsInfo.push(this.createAttachmentColumn('CAttachment', 9, this.attachmentButtonOnClick));

        } else if (this.callerIsSite) {
            columnsInfo.push({ entityPropName: 'PlantID', columnHeader: 'relatedInfo.plant.plantId', routerEntityName: EntityName.PLANT, widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'PlantName', columnHeader: 'relatedInfo.plant.plantName', widthPercentage: 25});
            columnsInfo.push({ entityPropName: 'KoDate', columnHeader: 'relatedInfo.common.koDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 12});
            columnsInfo.push({ entityPropName: 'StartupDate', columnHeader: 'relatedInfo.common.startupDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 12});
            columnsInfo.push({ entityPropName: 'Status', columnHeader: 'relatedInfo.plant.plantStatus', widthPercentage: 15});
            columnsInfo.push({ entityPropName: 'QcDate', columnHeader: 'relatedInfo.common.qcDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 11});
            columnsInfo.push({ entityPropName: 'ReleaseDate', columnHeader: 'relatedInfo.common.releaseDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 10});
            columnsInfo.push(this.createAttachmentColumn('CAttachment', 5, this.attachmentButtonOnClick));

        } else if (this.callerIsPipeline || this.callerIsTransmissionLine) {
            columnsInfo.push({ entityPropName: 'PlantID', columnHeader: 'relatedInfo.plant.plantId', routerEntityName: EntityName.PLANT,  widthPercentage: 8});
            columnsInfo.push({ entityPropName: 'PlantName', columnHeader: 'relatedInfo.plant.plantName', widthPercentage: 14});
            columnsInfo.push({ entityPropName: 'PlantOwner', columnHeader: 'common.owner', widthPercentage: 14});
            columnsInfo.push({ entityPropName: 'PhysCity', columnHeader: 'relatedInfo.plant.physCity', widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'PhysState', columnHeader: 'relatedInfo.plant.physState', widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'PhysCountry', columnHeader: 'relatedInfo.plant.physCountry', widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'QcDate', columnHeader: 'relatedInfo.common.qcDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 9});
            columnsInfo.push({ entityPropName: 'ReleaseDate', columnHeader: 'relatedInfo.common.releaseDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'Association', columnHeader: 'relatedInfo.plant.association', widthPercentage: 10});
            columnsInfo.push(this.createAttachmentColumn('CountAttach', 5, this.attachmentButtonOnClick));

        } else if(this.callerIsProject || this.callerIsUnit || this.callerIsOEquipment || this.callerIsMiningEquipment) {
            columnsInfo.push({ entityPropName: 'PlantID', columnHeader: 'relatedInfo.plant.plantId', routerEntityName: EntityName.PLANT, widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'PlantName', columnHeader: 'relatedInfo.plant.plantName', widthPercentage: 15});
            columnsInfo.push({ entityPropName: 'Owner', columnHeader: 'relatedInfo.plant.companyName', widthPercentage: 13});
            columnsInfo.push({ entityPropName: 'Status', columnHeader: 'relatedInfo.plant.plantStatus', widthPercentage: 13});
            columnsInfo.push({ entityPropName: 'KoDate', columnHeader: 'relatedInfo.plant.kickoffDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'StartupDate', columnHeader: 'relatedInfo.common.startupDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'QcDate', columnHeader: 'relatedInfo.common.qcDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'ReleaseDate', columnHeader: 'relatedInfo.common.releaseDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 10});
            columnsInfo.push(this.createAttachmentColumn('CAttachment', 9, this.attachmentButtonOnClick));

        }
        else if(this.callerIsArea) {
            columnsInfo.push({ entityPropName: 'PlantID', columnHeader: 'relatedInfo.plant.plantId', routerEntityName: EntityName.PLANT,  widthPercentage: 8});
            columnsInfo.push({ entityPropName: 'PlantName', columnHeader: 'relatedInfo.plant.plantName', widthPercentage: 14});
            columnsInfo.push({ entityPropName: 'PlantOwner', columnHeader: 'common.owner', widthPercentage: 14});
            columnsInfo.push({ entityPropName: 'PhysCity', columnHeader: 'relatedInfo.plant.physCity', widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'PhysState', columnHeader: 'relatedInfo.plant.physState', widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'PhysCountry', columnHeader: 'relatedInfo.plant.physCountry', widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'Status', columnHeader: 'relatedInfo.plant.plantStatus', widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'QcDate', columnHeader: 'relatedInfo.common.qcDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 9});
            columnsInfo.push({ entityPropName: 'ReleaseDate', columnHeader: 'relatedInfo.common.releaseDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 10});
            columnsInfo.push(this.createAttachmentColumn('CAttachment', 5, this.attachmentButtonOnClick));
        }
        else if(this.callerIsOfflineEvent) {
            columnsInfo.push({ entityPropName: 'PlantID', columnHeader: 'relatedInfo.plant.plantId', routerEntityName: EntityName.PLANT,  widthPercentage: 8});
            columnsInfo.push({ entityPropName: 'PlantName', columnHeader: 'relatedInfo.plant.plantName', widthPercentage: 14});
            columnsInfo.push({ entityPropName: 'Owner', columnHeader: 'relatedInfo.plant.companyName', widthPercentage: 14});
            columnsInfo.push({ entityPropName: 'Status', columnHeader: 'relatedInfo.plant.plantStatus', widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'KoDate', columnHeader: 'relatedInfo.plant.kickoffDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 9});
            columnsInfo.push({ entityPropName: 'StartupDate', columnHeader: 'relatedInfo.plant.startupDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 9});
            columnsInfo.push({ entityPropName: 'QcDate', columnHeader: 'relatedInfo.common.qcDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 9});
            columnsInfo.push({ entityPropName: 'ReleaseDate', columnHeader: 'relatedInfo.common.releaseDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 10});
            columnsInfo.push(this.createAttachmentColumn('CAttachment', 5, this.attachmentButtonOnClick));

        }
        return columnsInfo;
    }

    attachmentButtonOnClick(columnEntityInfo: ColumnEntityInfo, implementedComponent: PlantComponent, rowData: any) {
        const plantId = rowData.PlantID;
        implementedComponent.attachmentService.plantIternalAttachmentById(plantId).subscribe(resp => {
            implementedComponent.attachmentService.handlerAttachmentsByCount(resp);
        });
    }

}
