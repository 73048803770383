import { Bean } from "../../bean";
import { ContactsComments } from "./contacts-comments";

export class Contacts extends Bean {
    contactId: number;
    firstName: string;
    middleName: string;
    lastName: string;
    nickName: string;
    jrSrIII: string;
    salutation: string;
    nationality: string;
    matronName: string;
    emailAddress: string;
    linkedInId: string;
    prevUser: string;
    prevDate: string;
    lastUser: string;
    lastDate: string;
    entryUser: string;
    entryDate: string;
    releaseDate: string;
    entryCompany: string;
    prevCompany: string;
    lastCompany: string;
    contactOptedOutBool: boolean;

    contactsComments: Array<ContactsComments>


    protected constructor(entity?: any) {
        super(entity);

        this.contactId = entity && entity.contactId || null;
        this.firstName = entity && entity.firstName || null;
        this.middleName = entity && entity.middleName || null;
        this.lastName = entity && entity.lastName || null;
        this.nickName = entity && entity.nickName || null;
        this.jrSrIII = entity && entity.jrSrIII || null;
        this.salutation = entity && entity.salutation || null;
        this.nationality = entity && entity.nationality || null;
        this.matronName = entity && entity.matronName || null;
        this.emailAddress = entity && entity.emailAddress || null;
        this.linkedInId = entity && entity.linkedInId || null;
        this.prevUser = entity && entity.prevUser || null;
        this.prevDate = entity && entity.prevDate || null;
        this.lastUser = entity && entity.lastUser || null;
        this.lastDate = entity && entity.lastDate || null;
        this.entryUser = entity && entity.entryUser || null;
        this.entryDate = entity && entity.entryDate || null;
        this.releaseDate = entity && entity.releaseDate || null;
        this.entryCompany = entity && entity.entryCompany || null;
        this.prevCompany = entity && entity.prevCompany || null;
        this.lastCompany = entity && entity.lastCompany || null;
        this.contactOptedOutBool = entity && entity.contactOptedOutBool || false;

        this.contactsComments = entity && entity.contactsComments || [];
    }
}
