import { Bean } from "../../../bean";
import { Assets } from "../../base/save/assets";
import { AssetsTLinesConnections } from "./assets-tlines-connections";

export class AssetsTLines extends Bean {
    assetId: number;
    assetTLinesId: number;
    parentId: number;
    nercRegion: string;
    controlAreaId: string;
    tLineLength: number;
    tLineLengthUom: string;
    kvRating: number;
    destinationAssetId: number;
    destinationAssetTypeId: number;
    overHead: string;
    belowGround: string;
    subsea: string;
    systemType: string;
    tLineStatus: string;
    transmissionSystemId: number;

    asset: Assets;

    assetsTLinesConnections: Array<AssetsTLinesConnections>;

    constructor(entity: any) {
        super(entity);

        this.assetId = entity && entity.assetId || null;
        this.assetTLinesId = entity && entity.assetTLinesId || null;
        this.parentId = entity && entity.parentId || null;
        this.nercRegion = entity && entity.nercRegion || null;
        this.controlAreaId = entity && entity.controlAreaId || null;
        this.tLineLength = (entity && entity.tLineLength) ?? null;
        this.tLineLengthUom = entity && entity.tLineLengthUom || null;
        this.kvRating = entity && entity.kvRating || null;
        this.destinationAssetId = entity && entity.destinationAssetId || null;
        this.destinationAssetTypeId = entity && entity.destinationAssetTypeId || null;
        this.overHead = entity && entity.overHead || null;
        this.belowGround = entity && entity.belowGround || null;
        this.subsea = entity && entity.subsea || null;
        this.systemType = entity && entity.systemType || null;
        this.tLineStatus = entity && entity.tLineStatus || null;
        this.transmissionSystemId = entity && entity.transmissionSystemId || null;

        this.asset = entity && entity.asset || null;

        this.assetsTLinesConnections = entity && entity.assetsTLinesConnections || [];
    }
}
