import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MessagesService } from '../messages.service';

@Component({
    selector: 'app-message-dialog',
    templateUrl: './message-dialog.component.html',
    styleUrls: ['./message-dialog.component.scss']
})

export class MessageDialogComponent implements OnInit, OnDestroy {
    private messageChanged: Subscription;
    displayDialog: boolean = false;
    title: string;
    message: string;

    constructor(
        private messageService: MessagesService
    ) { }

    ngOnInit(): void {
        this.messageChanged = this.messageService.messageChanged$.subscribe(messageData => {
            this.displayDialog = messageData?.display;
            this.title = messageData?.title;
            this.message = messageData?.message;
        });
    }

    ngOnDestroy() {
        this.messageChanged?.unsubscribe();
    }

    close() {
        this.displayDialog = false;
    }

}
