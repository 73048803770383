import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, QueryList, Renderer2, OnInit, AfterViewChecked } from "@angular/core";
import { EditableColumn } from "primeng/table";
import { Subject, Subscription } from "rxjs";
import { ColumnEntityInfo, ColumnEntityInfoEditableType } from "src/app/shared/components/base/mvt-entity-associator/mvt-entity-associator.component";
import { HTMLType, TabIndexService } from "../services/tabindex.service";
import { StringUtils } from "../utils/string-utils";
import { FocusableEvent, FocusableEventType, SiblingDirection } from "./focusable-table.directive";

@Directive({
    selector: '[focusableMVT]',
})
export class FocusableTableMVTDirective implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
    @Input() focusManager: Subject<FocusableEvent>;
    @Input() columns: Array<ColumnEntityInfo>;

    private editableColumns: QueryList<EditableColumn>
    private focusinListener: any;
    private mouseUpListener: any;
    private mouseDownListener: any;
    private focusManagerSubscription: Subscription;
    private tabs: string[];
    private ecListeners: any[] = [];
    private pgListeners: any[] = [];
    private ignoreLastTabEvent: boolean = false;
    private lastTargetNextSibling: any;
    private lastTargetPreviousSibling: any;
    private mousePressed: boolean = false;
    private orderByRowIndex = (a, b) => a.rowIndex - b.rowIndex;
    private lastHashCode: number = 0;

    private currentFocusedElement;

    constructor(
        private renderer: Renderer2,
        private el: ElementRef,
        private tabIndexService: TabIndexService) {}

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        const table = this.el.nativeElement;

        this.focusinListener = this.renderer.listen(table, 'focusin', (event) => {
            this.onFocusIn(event);
        });

        this.mouseDownListener = this.renderer.listen(table, 'mousedown', () => this.mousePressed = true);
        this.mouseUpListener = this.renderer.listen(table, 'mouseup', () => this.mousePressed = false);

        this.focusManagerSubscription = this.focusManager.subscribe((event: FocusableEvent) => {
            this.focusableEventHandler(event);
        });

        this.loadTabs(this.columns);
        this.disableHeaderColumns()
    }

    ngAfterViewChecked(): void {
        let currentHashCode = 0;
        if (this.editableColumns && this.editableColumns.length > 0) {
            this.editableColumns.forEach((editableColumn) => {
                const ecTmp = {
                    field: editableColumn.field,
                    rowIndex: editableColumn.rowIndex,
                    data: editableColumn.data
                }
                const ecHashCode = StringUtils.hashCode(JSON.stringify(ecTmp));
                currentHashCode = (currentHashCode << 5) - currentHashCode + ecHashCode;
                currentHashCode = currentHashCode & currentHashCode;
            });
        }

        if (this.lastHashCode !== currentHashCode && currentHashCode !== 0) {
            this.tabIndexService.disableAllFrom(this.el.nativeElement, this.renderer)
        }

        this.lastHashCode = currentHashCode;
    }

    private disableHeaderColumns(): void {
        const tableElements = this.el.nativeElement.querySelectorAll('*');
        for (let i = 0; i < tableElements.length; i++) {
            const element = tableElements[i];
            const elementTagName = element.tagName.toLowerCase();

            if (elementTagName === HTMLType.th || elementTagName === HTMLType.button) {
                this.renderer.setAttribute(element, 'tabindex', '-1');
            }
        }
    }

    ngOnDestroy(): void {
        this.focusManagerSubscription.unsubscribe();
        this.resetFocusListeners();
        if (this.focusinListener) {
            this.focusinListener();
        }
        if (this.mouseUpListener) {
            this.mouseUpListener();
        }
        if (this.mouseDownListener) {
            this.mouseDownListener();
        }
    }

    focusToLastRow(): void {
        this.focusTo(-1);
    }

    focusToByIndex(index: number): void {
        this.focusTo(index);
    }

    focusToByIndexAndField(index: number, field: string): void {
        this.focusTo(index, field);
    }

    tabNext(fieldName: string, rowIndex: number, pgElement: HTMLElement = null): void {
        const siblingDirection: SiblingDirection = SiblingDirection.NEXT;
        if (pgElement) {
            const targetPgElement = this.pgElementSelector(pgElement, siblingDirection);
            if (targetPgElement) {
                this.pgFocusTo(targetPgElement);
            } else {
                this.setFocusToNextElement();
            }
            return;
        }

        if (this.lastTargetNextSibling
            && !this.lastTargetNextSibling.classList.contains('p-datepicker-trigger')
            && this.isLastTargetNextDropdown()) {
            this.setFocusToSibling(fieldName, rowIndex, siblingDirection);
            return;
        }

        if (this.isLastCell(rowIndex, fieldName)) {
            this.setFocusToNextElement(false);
            return;
        }

        if(this.setFocusToIconButtonSiblings(fieldName, rowIndex, siblingDirection)) {
            return;
        }

        let targetTab = this.tabs[this.tabs.indexOf(fieldName) + 1];
        if (!targetTab) {
            targetTab = this.tabs[0];
            rowIndex++;
        }

        this.tabToEditableColumn(targetTab, rowIndex, siblingDirection, pgElement);
    }

    isLastTargetNextDropdown() {
        return this.lastTargetNextSibling.parentElement != null && this.lastTargetNextSibling.parentElement.classList != null 
            && !this.lastTargetNextSibling.parentElement.classList.contains('p-dropdown');
    }

    tabPrevious(fieldName: string, rowIndex: number, pgElement: HTMLElement = null): void {
        const siblingDirection: SiblingDirection = SiblingDirection.PREVIOUS;
        if (pgElement) {
            const targetPgElement = this.pgElementSelector(pgElement, siblingDirection);
            if (targetPgElement) {
                this.pgFocusTo(targetPgElement);
            } else {
                const rowsSize = this.editableColumns.length / this.columns.length;
                this.tabPrevious(null, rowsSize, targetPgElement);
            }
            return;
        }

        if (this.lastTargetPreviousSibling && !this.lastTargetPreviousSibling.classList.contains('calendar-inputtext')) {
            this.setFocusToSibling(fieldName, rowIndex, siblingDirection);
            return;
        }

        if (this.isFirstCell(rowIndex, fieldName)) {
            const lastFocusableElement = this.getLastFocusableElement();
            if (lastFocusableElement) {
                this.tabIndexService.setTabindexRecursively(this.el.nativeElement, this.renderer);
                lastFocusableElement.focus();
            }
            return;
        }

        if(this.setFocusToIconButtonSiblings(fieldName, rowIndex, siblingDirection)) {
            return;
        }

        let targetTab = this.tabs[this.tabs.indexOf(fieldName) - 1];
        if (!targetTab) {
            targetTab = this.tabs[this.tabs.length - 1];
            rowIndex--;
        }

        this.tabToEditableColumn(targetTab, rowIndex, siblingDirection, pgElement);
    }

    private setFocusToIconButtonSiblings(fieldName: string, rowIndex: number, siblingDirection: SiblingDirection): boolean {
        let targetTab = this.tabs[this.tabs.indexOf(fieldName)];
        const cell: EditableColumn = this.getEditableColumn(targetTab, rowIndex);
        const columnEntityInfo: ColumnEntityInfo = this.columns.find(column => column.entityPropName === fieldName);
        if(columnEntityInfo != null && columnEntityInfo.iconButtonArray != null) {
            const buttons = cell.el.nativeElement.querySelectorAll('button');
            for (let i = 0; i < buttons.length; i++) {
                if(buttons[i] === this.currentFocusedElement) {
                    let targetButton = null;
                    if(siblingDirection === SiblingDirection.NEXT) {
                        const nextButton = i < buttons.length - 1 ? buttons[i + 1] : null;
                        targetButton = nextButton;
                    }
                    if(siblingDirection === SiblingDirection.PREVIOUS) {
                        const previousButton = i > 0 ? buttons[i - 1] : null;
                        targetButton = previousButton;
                    }
                    if(targetButton != null) {
                        targetButton.focus();
                        return true;
                    }
                }
            }
        }
        return false;
    }

    tabToEditableColumn(targetTab: string, rowIndex: number, siblingDirection: SiblingDirection, pgElement: HTMLElement = null) {
        const tabElement: EditableColumn = this.getEditableColumn(targetTab, rowIndex);
        if (tabElement) {
            if(this.isCellFocusable(tabElement, rowIndex)) {
                this.openCellOrSetFocus(tabElement, targetTab, rowIndex, siblingDirection);
            } else {
                if(siblingDirection === SiblingDirection.NEXT) {
                    this.tabNext(tabElement.field, rowIndex, pgElement);
                } else if(siblingDirection === SiblingDirection.PREVIOUS) {
                    this.tabPrevious(tabElement.field, rowIndex, pgElement);
                }
            }
        }
    }

    getEditableColumn(targetTab: string, rowIndex: number): EditableColumn {
        const columnRows: EditableColumn[] = this.editableColumns.filter(x => x.el.nativeElement.classList.contains(targetTab)).sort(this.orderByRowIndex);
        return columnRows.find(x => x.rowIndex === rowIndex);
    }

    isCellFocusable(editableColumn: EditableColumn, rowIndex: number): boolean {
        let isEditable: boolean = false;
        const columnEntityInfo: ColumnEntityInfo = this.columns.find(column => column.entityPropName === editableColumn.field);
        if(columnEntityInfo != null) {
            isEditable = columnEntityInfo.isFocusableCell(editableColumn.data, rowIndex);
        }
        return isEditable;
    }

    private setFocusToNextElement(ignorePaginator: boolean = true): void {
        const nextFocusableElement = this.getNextFocusableElement(ignorePaginator);
        if (nextFocusableElement) {
            this.tabIndexService.setTabindexRecursively(this.el.nativeElement, this.renderer);
            nextFocusableElement.focus();
        }
    }

    private openCellOrSetFocus(cell: EditableColumn, fieldName: string, rowIndex: number, direction: SiblingDirection): void {
        const cellElement = cell.el.nativeElement;
        const buttonElements = cell.el.nativeElement.querySelectorAll('button');
        const aElement = cell.el.nativeElement.querySelector('a');
        const inputElement = cell.el.nativeElement.querySelector('input');

        if (buttonElements != null && buttonElements.length > 0) {
            const columnEntityInfo: ColumnEntityInfo = this.columns.find(column => column.entityPropName === fieldName);
            if(columnEntityInfo.iconButtonArray != null) {
                if (direction === SiblingDirection.NEXT) {
                    buttonElements[0].focus();
                } else if (direction === SiblingDirection.PREVIOUS) {
                    buttonElements[buttonElements.length - 1].focus();
                }
            } else {
                buttonElements[0].focus();
            }
            return;
        }

        if (aElement) {
            aElement.focus();
            return;
        }

        if (inputElement && inputElement.type === 'checkbox') {
            if (inputElement.disabled && direction === SiblingDirection.NEXT) {
                this.tabNext(fieldName, rowIndex);
            } else if (inputElement.disabled && direction === SiblingDirection.PREVIOUS) {
                this.tabPrevious(fieldName, rowIndex);
            } else {
                inputElement.focus();
            }

            return;
        }

        cell.openCell();
    }

    private setFocusToSibling(fieldName: string, rowIndex: number, direction: SiblingDirection = SiblingDirection.NEXT): void {
        const columnRows = this.editableColumns.filter(x => x.el.nativeElement.classList.contains(fieldName)).sort(this.orderByRowIndex);
        const tabElement = columnRows.find(x => x.rowIndex === rowIndex);
        if (tabElement) {
            tabElement.openCell();

            let setFocusTo = () => {
                this.lastTargetNextSibling?.focus();
                this.lastTargetNextSibling = null;
            };
            if (direction === SiblingDirection.PREVIOUS) {
                setFocusTo = () => {
                    this.lastTargetPreviousSibling?.focus();
                    this.lastTargetPreviousSibling = null;
                };
            }

            setTimeout(() => {
                setFocusTo();
            }, 50);
        }
    }

    private isFirstCell(rowIndex: number, field: string): boolean {
        const firstRowIndex = this.findFirstRowIndex();
        if(rowIndex >= firstRowIndex) {
            rowIndex = rowIndex - firstRowIndex;
        }

        return rowIndex === 0 && this.tabs.indexOf(field) === 0;
    }

    private findFirstRowIndex(): number {
        let firstRowIndex = 0;
        this.editableColumns.forEach((column, index) => {
            if (index === 0) {
                firstRowIndex = column.rowIndex;
            } else if (column.rowIndex < firstRowIndex) {
                firstRowIndex = column.rowIndex;
            }
        });
        return firstRowIndex;
    }

    private isLastCell(rowIndex: number, field: string): boolean {
        const rowsSize = this.editableColumns.length / this.columns.length;
        const firstRowIndex = this.findFirstRowIndex();
        if(rowIndex >= firstRowIndex) {
            rowIndex = rowIndex - firstRowIndex;
        }
        return rowIndex === rowsSize - 1 && this.tabs.indexOf(field) === this.tabs.length - 1;
    }

    private getLastFocusableElement(): HTMLElement {
        let lastFocusableElement: HTMLElement = null;
        let tableTabIndex = Number(this.el.nativeElement.tabIndex);
        const minElements = 0;

        if (tableTabIndex > 0) {
            while (!this.isFocusable(lastFocusableElement) && tableTabIndex > minElements) {
                tableTabIndex--;
                lastFocusableElement = document.querySelector('[tabindex="' + tableTabIndex + '"]') as HTMLElement;
            }
        }

        return lastFocusableElement;
    }

    private maxTabIndex(): number {
        const tbElements = Array.from(document.querySelectorAll('[tabindex]'));

        return tbElements.reduce((max, el) => {
            const tabIndex = Number(el.getAttribute('tabindex'));
            return tabIndex > max ? tabIndex : max;
        }, 0);
    }

    private getNextFocusableElement(ignorePaginator: boolean = true): HTMLElement {
        const pgElements = this.pgEnabledElements();
        if (!ignorePaginator && pgElements.length > 0) {
            return pgElements[0];
        }

        let nextFocusableElement: HTMLElement = null;
        let tableTabIndex = Number(this.el.nativeElement.tabIndex);
        if (tableTabIndex > 0) {
            while (!this.isFocusable(nextFocusableElement) && tableTabIndex < this.maxTabIndex()) {
                tableTabIndex++;
                nextFocusableElement = document.querySelector('[tabindex="' + tableTabIndex + '"]') as HTMLElement;
            }
        }

        const tagName = nextFocusableElement.tagName.toLowerCase();
        if (tagName === HTMLType.scrolltop) {
            nextFocusableElement = nextFocusableElement.querySelector('button') as HTMLElement;
        }

        return nextFocusableElement;
    }

    private isFocusable(element: HTMLElement): boolean {
        if (!element) return false;
        return element.tabIndex >= 0
            && !element.hidden
            && element.getAttribute('disabled') == null
            && element.getAttribute('role') !== 'treeitem'
            && element.getAttribute('role') !== 'columnheader'
            && (element.tagName.toLowerCase() !== 'a' || (element as HTMLAnchorElement).href !== null);
    }

    private focusableEventHandler(event: FocusableEvent): void {
        if (event.type === FocusableEventType.FOCUS_TO_LAST_ROW) {
            this.focusToLastRow();
        } else if (event.type === FocusableEventType.FOCUS_TO_BY_INDEX) {
            this.focusToByIndex(event.payload);
        } else if (event.type === FocusableEventType.FOCUS_TO_BY_INDEX_AND_FIELD) {
            this.focusToByIndexAndField(event.payload.index, event.payload.field);
        } else if (event.type === FocusableEventType.RESET_FOCUS_LISTENERS) {
            this.resetFocusListeners();
        } else if (event.type === FocusableEventType.ADD_EDITABLE_COLUMNS) {
            this.editableColumns = event.payload;
        } else if (event.type === FocusableEventType.ADD_CUSTOM_COLUMNS) {
            this.loadTabs(event.payload);
        } else if (event.type === FocusableEventType.IGNORE_LAST_TAB_EVENT) {
            this.ignoreLastTabEvent = true;
        }
    }

    private loadTabs(columns: ColumnEntityInfo[]): void {
        this.tabs = columns.filter((col: ColumnEntityInfo) => col.isTabFocusableColumn()).map((c: ColumnEntityInfo) => c.entityPropName);
    }

    private resetFocusListeners(): void {
        this.ecListeners.forEach((ecListener) => {
            const {listener} = ecListener;
            listener();
        });
        this.ecListeners = [];

        this.pgListeners.forEach((pgListener) => {
            const {listener} = pgListener;
            listener();
        });
        this.pgListeners = [];
    }

    private getParentElement(target: any): boolean {
        while (target) {
            const tagName = target?.tagName?.toLowerCase();
            if (tagName === 'p-table') {
                break;
            }
            target = target.parentNode;
        }
        return target;
    }

    private getRelatedTarget(event: any): any {
        if (event.target === this.el.nativeElement) {
            const relatedTarget = event.relatedTarget;
            if (relatedTarget && relatedTarget.getAttribute('tabindex') > 0) {
                return relatedTarget;
            } else {
                const parentElement = this.getParentElement(relatedTarget);
                if (parentElement) {
                    return parentElement;
                } else {
                    return this.getLastFocusableElement();
                }
            }
        } else {
            return this.el.nativeElement;
        }
    }

    private onFocusIn(event: any): void {
        event.preventDefault();
        if (this.mousePressed) {
            return;
        }

        const table = this.el.nativeElement;
        const relatedTarget = this.getRelatedTarget(event);

        this.currentFocusedElement = event.target;

        if (relatedTarget) {
            const focusFromTop = table.tabIndex > relatedTarget.tabIndex;
            const focusFromBottom = table.tabIndex < relatedTarget.tabIndex;
            const rowsSize = this.editableColumns.length / this.columns.length;

            if (this.tabs.length > 0) {
                if (focusFromTop) {
                    if (rowsSize === 0) {
                        this.getNextFocusableElement().focus();
                        return;
                    }
                    this.focusTo(0);
                }

                if (focusFromBottom) {
                    if (rowsSize === 0) {
                        this.getLastFocusableElement().focus();
                        return;
                    }

                    const pgElements = this.pgEnabledElements();
                    if(pgElements.length > 0) {
                        this.pgFocusTo(pgElements[pgElements.length - 1]);
                    } else {
                        this.focusTo(rowsSize - 1, this.tabs[this.tabs.length - 1])
                    }
                }
            } else {
                table.focus();
            }
        }

        this.lastTargetNextSibling = null;
        this.lastTargetPreviousSibling = null;
        if (event.target) {
            this.lastTargetNextSibling = event.target.nextElementSibling;
            this.lastTargetPreviousSibling = event.target.previousElementSibling;

            if (event.target.type === 'text') {
                event.target.select();
            }
        }

        this.editableColumns.forEach((editableColumn) => {
            if (editableColumn.field && this.tabs.includes(editableColumn.field)) {
                const currentEcl = this.ecListeners.find((ecl) => ecl.ec.field === editableColumn.field && ecl.ec.rowIndex === editableColumn.rowIndex);
                if (!currentEcl) {
                    const ecListener = this.addKeydownTabListener(editableColumn);
                    this.ecListeners.push({ec: editableColumn, listener: ecListener});
                } else {
                    if (currentEcl.ec !== editableColumn) {
                        currentEcl.listener();
                        currentEcl.ec = editableColumn;
                        currentEcl.listener = this.addKeydownTabListener(editableColumn);
                    }
                }
            }
        });

        const pgElements = this.pgEnabledElements();
        pgElements.forEach((element) => {
            const pgListener = this.pgListeners.find((pl) => pl.element === element);
            if (!pgListener) {
                const pgNewListener = this.addKeydownTabListener(null, element);
                this.pgListeners.push({element, listener: pgNewListener});
            }
        });
    }

    private addKeydownTabListener(editableColumn: EditableColumn, pgElement: HTMLElement = null): any {
        const baseElement = pgElement ? pgElement : editableColumn.el.nativeElement;
        const ecListener = this.renderer.listen(baseElement, 'keydown', (event) => {
            if (event.key === 'Tab') {
                event.stopPropagation();
                event.preventDefault();

                const fieldName = editableColumn ? editableColumn.field : null;
                const rowIndex = editableColumn ? editableColumn.rowIndex : null;

                if (this.ignoreLastTabEvent) {
                    this.ignoreLastTabEvent = false;
                } else {
                    if (event.shiftKey) {
                        this.tabPrevious(fieldName, rowIndex, pgElement);
                    } else {
                        this.tabNext(fieldName, rowIndex, pgElement);
                    }
                }
            }
        });
        return ecListener;
    }

    private focusTo(index: number, fieldname: string = null): void {
        setTimeout(() => {
            try {
                let focusableElement: EditableColumn = null;

                if (fieldname === null) {
                    fieldname = this.tabs[0];
                }

                const focusableColumn: EditableColumn[] = this.editableColumns.filter(x => x.el.nativeElement.classList.contains(fieldname)).sort(this.orderByRowIndex);
                if (index === -1) {
                    focusableElement = focusableColumn.reverse()[0];
                } else {
                    focusableElement = focusableColumn[index];
                }

                if (focusableElement) {
                    this.openCellOrSetFocus(focusableElement, fieldname, index, SiblingDirection.NONE);
                }
            } catch (ex) {}
        }, 5);
    }

    private pgElementSelector(pgElement: HTMLElement, direction: SiblingDirection): HTMLElement {
        const pgElements = this.pgEnabledElements();
        const pgElementIndex = pgElements.indexOf(pgElement);

        let prevPgElement = null;
        let nextPgElement = null;
        if (pgElementIndex > -1) {
            if (pgElementIndex > 0) {
                prevPgElement = pgElements[pgElementIndex - 1];
            }

            if (pgElementIndex < pgElements.length) {
                nextPgElement = pgElements[pgElementIndex + 1];
            }
        }

        return direction === SiblingDirection.NEXT ? nextPgElement : prevPgElement;
    }

    private pgFocusTo(pgElement: HTMLElement): void {
        const tagName = pgElement.tagName.toLowerCase();
        if (tagName === HTMLType.pdropdown) {
            const innerInput = pgElement.querySelector('span');
            if (innerInput) {
                setTimeout(() => {
                    innerInput.focus();
                }, 100);
            }
        } else {
            pgElement.focus();
        }
    }

    private pgEnabledElements(): HTMLElement[] {
        const paginator = this.el.nativeElement.querySelector('p-paginator');
        if (!paginator) {
            return [];
        }
        return [...paginator.querySelectorAll('button, p-dropdown')].filter(x => !x.hasAttribute('disabled'));
    }

}

