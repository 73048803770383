import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
// Rxjs
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// Environment
import { environment } from 'src/environments/environment';
// Model// Interface
import { Preference } from '../core/models/common';
import { PreferencePrefix, PreferenceSufix } from '../core/models/constants';
import { StringUtils } from '../core/utils/string-utils';
import { BaseComponent } from '../base.component';

@Injectable({
    providedIn: 'root'
})
export class PreferencesSectionService {

    commonPreferences: Array<Preference> = [];
    
    constructor(
        private http: HttpClient,
    ) {
    }

    deletePaginatorPreferences(): Observable<any> {
        const params = new HttpParams()
        return this.http.post<any>(`${environment.apiUrl}preferences/deletePaginatorPreferences`, params);
    }

    updatePreference(resourceName: string, value: string): Observable<any> {
        const params = new HttpParams()
            .set('resourceName', resourceName)
            .set('value', value);

        return this.http.post<any>(`${environment.apiUrl}preferences/updatePreference`, params);
    }

    updatePreferences(preferences: Array<Preference>): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}preferences/updatePreferences`, preferences);
    }

    getAllPreferences(): Observable<Preference[]> {
        return this.http.get<Preference>(`${environment.apiUrl}preferences/getAllPreferences`)
            .pipe(
                map((data: any) => Preference.BuildPreferences(data.response))
            );
    }

    getPaginatorPreferencesCount(): Observable<number> {
        return this.http.get<number>(`${environment.apiUrl}preferences/getPaginatorPreferencesCount`)
            .pipe(
                map((data: any) => data.response)
            );
    }

    getPreferencesByPrefix(prefix: string): Observable<Preference[]> {
        const params = new HttpParams().set('prefix', prefix);
        return this.http.get<Preference>(`${environment.apiUrl}preferences/getPreferencesByPrefix`, { params })
            .pipe(
                map((data: any) => Preference.BuildPreferences(data.response))
            );
    }

    getPreferencesByPrefixes(prefixes: Array<string>): Observable<Preference[]> {
        const params = new HttpParams().set('prefixes', prefixes.join(','));
        return this.http.get<Preference>(`${environment.apiUrl}preferences/getPreferencesByPrefixes`, { params })
            .pipe(
                map((data: any) => Preference.BuildPreferences(data.response))
            );
    }

    setCommonPreferences(preferences: Array<Preference>): void {
        this.commonPreferences = [];
        if(preferences && preferences.length > 0) {
            let preference = preferences.find(pref => pref.resourceName === PreferencePrefix.Preferences+PreferenceSufix.DefaultGeneralResults);
            if(preference) {
                this.commonPreferences.push(preference);
            }
            let searchPreferences = preferences.filter(pref => pref.resourceName.startsWith(PreferencePrefix.Search));
            if(searchPreferences && searchPreferences.length > 0) {
                this.commonPreferences.push(...searchPreferences);
            }
        }
    }

    updateCommonPreferences(resourceName: string, value: string): void {
        resourceName = StringUtils.removeWhiteSpace(resourceName);
        if(!this.commonPreferences) {
            this.commonPreferences = [];
        }
        let preference = this.commonPreferences.find(pref => pref.resourceName === resourceName);
        if(preference) {
            preference.value = value;
        } else {
            this.commonPreferences.push(Preference.BuildPreference(resourceName, value));
        }
        this.updatePreference(resourceName, value).subscribe();
    }

    getCommonRowsPerPage(resourceName: string): number {
        resourceName = StringUtils.removeWhiteSpace(resourceName);
        let rowsPerPage: number = 5;
        if(this.commonPreferences && this.commonPreferences.length > 0) {
            let preference = this.commonPreferences.find(pref => pref.resourceName === resourceName);
            if(preference) {
                rowsPerPage = Number(preference.value);
            } else {
                preference = this.commonPreferences.find(pref => pref.resourceName === PreferencePrefix.Preferences+PreferenceSufix.DefaultGeneralResults);
                if(preference) {
                    rowsPerPage = Number(preference.value);
                }
            }
        }
        return rowsPerPage;
    }

    getPaginatorPreference(preferences: Array<Preference>, resourceName: string, defaultPaginator: number): number{
        if(preferences && preferences.length > 0) {
            let preference = preferences.find(pref => pref.resourceName === resourceName);
            if(!preference) {
                preference = preferences.find(pref => pref.resourceName === PreferencePrefix.Preferences+PreferenceSufix.DefaultGeneralResults);
            }
            return preference ? Number(preference.value) : defaultPaginator;
        }
        return defaultPaginator;
        
    }

    loadPaginatorPreferences<T extends BaseComponent>(prefixes: Array<string>, implementedComponent: T, callDoAfterLoadPreferences: boolean = true) {
        prefixes.push(PreferencePrefix.Preferences+PreferenceSufix.DefaultGeneralResults);
        prefixes.push(PreferencePrefix.Search);
        this.getPreferencesByPrefixes(prefixes)
            .subscribe({
                next: (preferences: Array<Preference>) => {
                    this.setCommonPreferences(preferences);
                    if(implementedComponent) {
                        if(implementedComponent.preferences) {
                            implementedComponent.preferences = preferences;
                        }
                        if(callDoAfterLoadPreferences && implementedComponent.doAfterLoadPreferences) {
                            implementedComponent.doAfterLoadPreferences();
                        }
                    }
                    
                }
        });
    }

    getPreferenceResourceName(prefix: string, sufix: string): string {
        return StringUtils.removeWhiteSpace(prefix+'_'+sufix);
    }

    updatePreferenceOnPageChange(resourceName: string, value: string): void {
        this.updatePreference(resourceName, value).subscribe();
    }

}
