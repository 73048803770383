import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { EntityName } from 'src/app/core/models/enumerations/entity-name';
import { SharedService } from 'src/app/core/services/shared.service';
import { RelatedInfoBase } from '../related-info.base';
import { ColumnEntityInfo, ColumnEntityInfoEditableType } from 'src/app/shared/components/base/mvt-entity-associator/mvt-entity-associator.component';

@Component({
    selector: 'app-operator',
    templateUrl: './operator.component.html',
    styleUrls: ['./operator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class OperatorComponent extends RelatedInfoBase implements OnInit {
    entityType = EntityName.COMPANY;
    entityTableKey =EntityName.OPERATOR;
    constructor(

        public sharedService: SharedService
    ) { super(sharedService); }

    ngOnInit(): void {
        this.initBase();
    }

    getColumnsInfo(): ColumnEntityInfo[] {
        let columnsInfo: ColumnEntityInfo[] = [];
        columnsInfo.push({ entityPropName: 'operatorId', columnHeader: 'relatedInfo.company.companyId', routerEntityName: EntityName.COMPANY, widthPercentage: 15});
        columnsInfo.push({ entityPropName: 'companyName', columnHeader: 'relatedInfo.company.companyName', widthPercentage: 50});
        columnsInfo.push({ entityPropName: 'qcDate', columnHeader: 'relatedInfo.common.qcDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 15});
        columnsInfo.push({ entityPropName: 'releaseDate', columnHeader: 'relatedInfo.common.releaseDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 20});

        return columnsInfo;
    }

}
