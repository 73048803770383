import { ContactsDisplay } from "../../../contact/display/contacts-display";
import { EntityName } from "../../../enumerations/entity-name";
import { Assets } from "../save/assets";
import { AssetsCompanyInfoDisplay } from "./assets-company-info-display";
import { AssetsContactInfoDisplay } from "./assets-contact-info-display";
import { AssetsInternalAttachmentsDisplay } from "./assets-internal-attachments-display";
import { AssetsMaintenanceBudgetDisplay } from "./assets-maintenance-budget-display";
import { AssetsNotesDisplay } from "./assets-notes-display";
import { AssetsProductsDisplay } from "./assets-products-display";
import { AssetsProjectsDisplay } from "./assets-projects-display";

export class AssetsDisplay extends Assets {
    operatorName: string;
    originPhysCountryAbrev: string;
    originPhysCountryName: string;
    originPhysStateAbrev: string;
    originPhysStateName: string;
    originPhysCityName: string;
    originPhysCountyName: string;
    originAssetName: string;
    origOffshoreCtryDesigName: string;
    origOffshoreStateDesigName: string;
    originOffshoreWaterbodyName: string;
    originOffshoreAreaName: string;
    systemName: string;

    assetsContactInfoDisplay: Array<AssetsContactInfoDisplay>;
    assetsCompanyInfoDisplay: Array<AssetsCompanyInfoDisplay>;
    assetsProductsDisplay: Array<AssetsProductsDisplay>;
    assetsNotesDisplay: Array<AssetsNotesDisplay>;
    assetsInternalAttachmentsDisplay: Array<AssetsInternalAttachmentsDisplay>;
    assetsProjectsDisplay: Array<AssetsProjectsDisplay>;
    assetsMaintenanceBudgetDisplay: Array<AssetsMaintenanceBudgetDisplay>;

    contactsDisplays: Array<ContactsDisplay>;

    constructor(entity: any) {
        super(entity);

        this.operatorName = entity && entity.operatorName || null;
        this.originPhysCountryAbrev = entity && entity.originPhysCountryAbrev || null;
        this.originPhysCountryName = entity && entity.originPhysCountryName || null;
        this.originPhysStateAbrev = entity && entity.originPhysStateAbrev || null;
        this.originPhysStateName = entity && entity.originPhysStateName || null;
        this.originPhysCityName = entity && entity.originPhysCityName || null;
        this.originPhysCountyName = entity && entity.originPhysCountyName || null;
        this.originAssetName = entity && entity.originAssetName || null;
        this.origOffshoreCtryDesigName = entity && entity.origOffshoreCtryDesigName || null;
        this.origOffshoreStateDesigName = entity && entity.origOffshoreStateDesigName || null;
        this.originOffshoreWaterbodyName = entity && entity.originOffshoreWaterbodyName || null;
        this.originOffshoreFieldName = entity && entity.originOffshoreFieldName || null;
        this.originOffshoreAreaName = entity && entity.originOffshoreAreaName || null;
        this.systemName = entity && entity.systemName || null;

        this.assetsContactInfoDisplay = entity && entity.assetsContactInfoDisplay || [];
        this.assetsCompanyInfoDisplay = entity && entity.assetsCompanyInfoDisplay || [];
        this.assetsProductsDisplay = entity && entity.assetsProductsDisplay || [];
        this.assetsNotesDisplay = entity && entity.assetsNotesDisplay || [];
        this.assetsInternalAttachmentsDisplay = entity && entity.assetsInternalAttachmentsDisplay || [];
        this.assetsProjectsDisplay = entity && entity.assetsProjectsDisplay || [];
        this.assetsMaintenanceBudgetDisplay = entity && entity.assetsMaintenanceBudgetDisplay || [];

        this.contactsDisplays = entity && entity.contactsDisplays || [];
    }

    static BuildAssetsDisplay(entity: any): AssetsDisplay {
      return new AssetsDisplay(entity);
    }

    prepareSaveCollection() {
        this.assetsContactInfo = this.assetsContactInfoDisplay;
        this.assetsCompanyInfo = this.assetsCompanyInfoDisplay;
        this.assetsProducts = this.assetsProductsDisplay;
        this.assetsNotes = this.assetsNotesDisplay;
        this.assetsInternalAttachments = this.assetsInternalAttachmentsDisplay;
        this.assetsProjects = this.assetsProjectsDisplay;
        this.assetsMaintenanceBudget = this.assetsMaintenanceBudgetDisplay;
        this.contactsDisplays.forEach(contact =>{
            contact.prepareSaveCollection();
        });
        this.contacts = this.contactsDisplays;
    }

    get originAssetTypeDesc(): string {
        return this.getAssetTypeDescriptionById(this.originAssetTypeId);
    }

    public getAssetTypeDescriptionById(typeId: number): string 
    {
        if(typeId === 1)
        {
            return EntityName.ASSET_TRANSMISSION_LINE;
        }
        else if(typeId === 3)
        {
            return EntityName.ASSET_PIPELINE;
        }
        else if(typeId === 4)
        {
            return EntityName.SITE; 
        }
        else if(typeId == 5)
        {
            return EntityName.PLANT;
        }
        
        return null;
    }

}
