import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
// Modal Dialog
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
// Service
import { AddressService } from '../../address-form/address.service';
// Models
import { TranslateService } from '@ngx-translate/core';
import { SortEvent } from 'primeng/api';
import { Dropdown } from 'primeng/dropdown';
import { CompanySectionService } from 'src/app/company-section/company-section.service';
import { CompanySearchParams } from 'src/app/core/interfaces/search/icompany-search-params';
import { CompanySearch } from 'src/app/core/models';
import { CompanyClassificationValue, CompanyStatusValue, Constants, EntityPreferenceSufix, HexaColor, RecordStatusValue } from 'src/app/core/models/constants';
import { SpinnerProcess } from 'src/app/core/models/spinner-process';
import { EntityCommonService } from 'src/app/core/services/entity-common.service';
import { SharedService } from 'src/app/core/services/shared.service';
import { TableUtils } from 'src/app/core/utils/table-utils';
import {
    CompanyClasses, CompanyStatus, Country, Industry, RecordStatus, State, StockChanges
} from '../../../core/models/common';
import { LoadWheelService } from '../../load-wheel/load-wheel.service';
import { MessageType } from '../../messages/message-handler/message-handler.component';
import { MessageHandlerService } from '../../messages/message-handler/message-handler.service';
import { BaseModalComponent } from '../base-modals.component';
import { PreferencesSectionService } from 'src/app/preferences-section/preferences-section.service';

@Component({
    selector: 'app-search-modal',
    templateUrl: './search-modal.component.html'
})

export class SearchModalComponent extends BaseModalComponent implements OnInit {
    @ViewChild('companyname') inputCompanyName: ElementRef;
    @ViewChild('companyStatus') inputCompanyStatus: ElementRef;
    @ViewChild('industryCodeDropdown') industryCodeDropdown: Dropdown;
    @ViewChild('companyClassesDropdown') companyClassesDropdown: Dropdown;
    @ViewChild('physCountriesDropdown') physCountriesDropdown: Dropdown;
    @ViewChild('physStatesDropdown') physStatesDropdown: Dropdown;
    @ViewChild('recordStatusDropdown') recordStatusDropdown: Dropdown;
    tableKey = EntityPreferenceSufix.Entity;
    companies: Array<CompanySearch> = [];
    status: Array<CompanyStatus> = [];
    companyClasses: Array<CompanyClasses> = [];
    stockChages: Array<StockChanges> = [];
    recordStatuses: Array<RecordStatus> = [];
    additionalInfo: CompanySearch;
    industry: Array<Industry> = [];
    physCountries: Array<Country> = [];
    physStates: Array<State> = [];
    enabledNewRecordButton: boolean = false;
    selectCompanyStatus: any[] = [];
    disabledClassification = false;
    cols: any[] = [];
    isMainAccess: boolean = false;
    filterParent: boolean = false;
    ownerList: Array<number> = [];

    constructor(private companySectionService: CompanySectionService,
        private entityCommonService: EntityCommonService,
        private addressService: AddressService,
        private messageHandler: MessageHandlerService,
        public activeModal: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public sharedService: SharedService,
        public dialogService: DialogService,
        public translate: TranslateService,
        public preferencesService: PreferencesSectionService,
        public loadWheelService: LoadWheelService) {
        super(activeModal, config, preferencesService);
    }

    ngOnInit() {
        this.initPaginatorPreferences();
        this.cols = [
            { field: 'companyId', header: 'Company ID', width: '9%' },
            { field: 'completeName', header: 'Company Name/Secondary Name', width: '28%' },
            { field: 'classification', header: 'CL/S', width: '8%' },
            { field: 'recordStatusDesc', header: 'Record Status', width: '10%' },
            { field: 'companyStatusDesc', header: 'Company Status', width: '10%' },
            { field: 'physAddressV1', header: 'Phys Address', width: '11%' },
            { field: 'physCity', header: 'Phys City', width: '9%' },
            { field: 'mailStateDesc', header: 'Phys State', width: '10%' },
        ];
        this.loadList();
        this.clear();
        this.searchCompanyByParamsRecording();
        this.isMainAccess = this.config.data?.isMainAccess !== undefined ? this.config.data?.isMainAccess : false;
        this.initParentSearch();
        this.initOwnerSearch();
        if (this.companySearch.company_name != null && this.companySearch.company_name !== '') {
            setTimeout(() => this.inputCompanyName.nativeElement.select(), 0);
        }
    }

    initParentSearch(): void {
        this.filterParent = this.config.data?.filterParent !== undefined ? this.config.data?.filterParent : false;
        if (this.filterParent) {
            this.ownerList = this.config.data?.ownerList;
        }

    }

    initOwnerSearch(): void {
        if (this.config.data?.fromCompanyScreen &&
            this.config.data?.parentCompanyForm?.get('classification')?.value === CompanyClassificationValue.Parent) {
            this.companySearch.company_classification = CompanyClassificationValue.Holding;
            this.disabledClassification = true;
        }
    }

    search(event?: any) {
        this.hideDropdownsIfEnterEventOccurs(event);

        const okFunctionModal = (): void => {
            setTimeout(() => this.inputCompanyName.nativeElement.focus(), Constants.DELAY_BUTTON_FOCUS_MS);
        }

        if (!this.isEmptyObjectProperty(this.companySearch)) {
            if (this.companySearch.company_status && this.companySearch.company_status.length > 0) {
                this.searchCompany(this.companySearch);
            } else {
                this.messageHandler.show(Constants.ERROR_VALID_COMPANY_STATUS, MessageType.ERROR, okFunctionModal);
                this.companies = [];
                this.additionalInfo = null;
            }
        }
    }

    hideDropdownsIfEnterEventOccurs(event: any) {
        if (event?.type === 'keyup' && event?.key === 'Enter' && event?.target instanceof HTMLInputElement) {
            this.industryCodeDropdown?.hide();
            this.companyClassesDropdown?.hide();
            this.physCountriesDropdown?.hide();
            this.physStatesDropdown?.hide();
            this.recordStatusDropdown?.hide();
        }
    }

    private searchCompanyByParamsRecording() {
        if (this.config.data?.section) {
            this.companySectionService.SearchParams.company_classification = this.companySearch.company_classification;
        }
        this.companySearch = this.companySectionService.SearchParams;
        if (this.companySearch.recordedSearch) {
            const ignoreSearchParams = ['company_status', 'recordedSearch'];
            if (!this.isEmptyObjectProperty(this.companySectionService.SearchParams, ignoreSearchParams)) {
                this.searchCompany(this.companySectionService.SearchParams);
            }
        } else {
            this.companySearch.company_status = [CompanyStatusValue.Active];
        }
        if (this.companySearch.phys_country !== null && this.companySearch.phys_country !== '') {
            this.loadStates(this.companySearch.phys_country);
        }
    }

    private isEmptyObjectProperty(sp: Object, ignore: Array<string> = []): boolean {
        return Object.keys(sp).filter(k => !ignore.includes(k)).every(x => sp[x] === '' || sp[x] === null);
    }

    private searchCompany(params: CompanySearchParams) {
        let wheel: SpinnerProcess = this.loadWheelService.showWheel(this.translate.instant("searching.companies"));
        this.companySectionService.searchCompany(params, wheel)
            .subscribe({
                next: (company: CompanySearch[]) => {
                    if(company === null) {
                        company = [];
                        this.additionalInfo = null;
                        params.recordedSearch = false;
                        this.enabledNewRecordButton = false;
                    } else if (company.length === 0) {
                        this.messageHandler.showAlertAndReturnFocus(
                            this.translate.instant("common.searchNotFound"),
                            undefined,
                            this.inputCompanyName,
                            true
                        );
                        this.additionalInfo = null;
                        params.recordedSearch = false;
                        this.enabledNewRecordButton = true;
                    } else {
                        this.additionalInfo = company[0];
                        this.focusFirstElement();
                        params.recordedSearch = true;
                        this.enabledNewRecordButton = true;
                    }
                    this.companies = company;
                    this.companies.map((company) => { company.completeName = `${company.companyName} ${company.secondaryName !== null ? ' / ' + company.secondaryName : ''}` })
                    this.loadWheelService.hideWheel(wheel);
                    this.resetTable();
                },
                error: (error) => {
                    this.loadWheelService.hideWheel(wheel);
                    params.recordedSearch = false;
                    if (error.message.endsWith(Constants.MAX_ROWS_LIMITATION_MESSAGE)) {
                        this.messageHandler.showAlertAndReturnFocus(
                            error.message,
                            undefined,
                            this.inputCompanyName,
                            true
                        );
                        this.companies = [];
                        this.additionalInfo = null;
                        this.enabledNewRecordButton = false;
                    } else {
                        console.warn(error);
                    }
                }
            });
    }

    onRowSelect(event: any) {
        this.additionalInfo = event;
    }

    onRowDblclick(rowData: any) {
        if (this.canAssociate(rowData)) {
            if (this.canAssociateParent(rowData)) {
                if (this.filterParent) {
                    this.companySectionService.companyInTree(this.ownerList, rowData, this);
                } else {
                    this.activeModal.close(rowData);
                }
            }
        } else {
            this.messageHandler.show(this.translate.instant('company.searchModal.cannotAssociate'), MessageType.INFO);
        }
    }

    parentDataOk(companyData: CompanySearch) {
        this.activeModal.close(companyData);
    }

    parentDataFail(companyData: CompanySearch) {
        //nothing
    }

    changeSelectionOnArrowKey(event: any, entityArray: any[], hasHeader: boolean = true) {
        TableUtils.changeSelectionOnArrowKey(this, "additionalInfo", event, entityArray, hasHeader);
    }

    onChangePhysCountry() {
        if (this.companySearch.phys_country !== '' && this.companySearch.phys_country !== null) {
            this.loadStates(this.companySearch.phys_country);
        } else {
            this.physStates = [];
        }
        this.companySearch.phys_state = null;

    }

    clear() {
        this.companySearch.company_name = '';
        this.companySearch.previus_name = '';
        this.companySearch.industry_code = null;
        this.companySearch.company_status = [CompanyStatusValue.Active];
        this.companySearch.company_classification = null;
        this.companySearch.phys_country = null;
        this.companySearch.phys_state = null;
        this.physStates = [];
        this.companySearch.phys_city = '';
        this.companySearch.phys_county = '';
        this.companySearch.record_status = null;
        this.companies = [];
        this.enabledNewRecordButton = false;
        this.additionalInfo = null;
        this.companySearch.recordedSearch = false;
        setTimeout(() => this.inputCompanyName.nativeElement.focus(), 1);
        this.initOwnerSearch();
    }

    private loadList() {
        this.loadCompanyStatus();
        this.loadCompanyClasses();
        this.loadRecordStatus();
        this.loadIndustry();
        this.loadCountries();
    }

    private loadCompanyStatus() {
        this.sharedService.getStatus()
            .subscribe((status: CompanyStatus[]) => this.status = status);
    }

    private loadCompanyClasses() {
        this.sharedService.getCompanyClasses()
            .subscribe((classes: CompanyClasses[]) => this.companyClasses = [...classes]);
    }

    private loadRecordStatus() {
        this.sharedService.getRecordStatus()
            .subscribe((record: RecordStatus[]) => this.recordStatuses = [...record.filter(item => item.RecordStatusID !== 'P')]);
    }

    private loadIndustry() {
        this.sharedService.getAllIndustryCodes()
            .subscribe((industry: Industry[]) => this.industry = [...industry]);
    }

    private loadCountries() {
        this.addressService.getAllCountries()
            .subscribe((countries: Country[]) => this.physCountries = [...countries]);
    }

    private loadStates(countryId: string) {
        this.addressService.getStateByCountry(countryId, '', true)
            .subscribe((states: State[]) => this.physStates = [...states]);
    }

    newRecord() {
        this.entityCommonService.sendNewRecordEvent();
        this.activeModal.close();
    }

    customSort(event: SortEvent) {
        this.sharedService.customSort(event);
    }

    canAssociate(company: CompanySearch): boolean {
        return this.isMainAccess ||
            (this.companySectionService.canAssociateCompany(company));
    }

    canAssociateParent(company: CompanySearch): boolean {
        return !this.filterParent ||
            this.companySectionService.canAssociateParentCompany(company);
    }

    getInvalidStatusMessage(company: CompanySearch): string {
        return (company.recordStatus &&
            company.recordStatus !== RecordStatusValue.Enhanced &&
            company.recordStatus !== RecordStatusValue.Archived &&
            company.recordStatus !== RecordStatusValue.Unconfirmed) ?
            company.recordStatusDesc : company.companyStatusDesc;
    }

    getRowStyle(company: CompanySearch): any {
        return { 'color': this.canAssociate(company) && this.canAssociateParent(company) ? '' : HexaColor.lightGray }
    }

    getRowTooltip(company: CompanySearch): string {
        let tooltip: string = '';
        if (!this.canAssociate(company)) {
            tooltip =
                (company.recordStatus === RecordStatusValue.Trash
                    || company.companyStatus == CompanyStatusValue.Closed
                    || company.companyStatus == CompanyStatusValue.SuspendedUnresolved) ?
                    this.translate.instant('company.searchModal.invalidAStatus', { status: this.getInvalidStatusMessage(company) }) :
                    this.translate.instant('company.searchModal.invalidAnStatus', { status: this.getInvalidStatusMessage(company) });
        }
        return tooltip;
    }

}
