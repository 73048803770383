import { Component, Input } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { IAuditEntityData } from 'src/app/core/interfaces/iaudit-entity-data';
import { UserModalComponent } from '../modals/user-modal/user-modal.component';

@Component({
    selector: 'app-audit-entity-data',
    templateUrl: './audit-entity-data.component.html',
    styleUrls: ['./audit-entity-data.component.css']
})
export class AuditEntityData {
    @Input() auditEntityBean: IAuditEntityData;

    constructor(
        private dialogService: DialogService
        ) { }

    displayLink(str: string): boolean {
        if (str !== '' && str !== null) {
            return true;
        } else {
            return false;
        }
    }

    openUserModal(userModal: string) {
        const modalRef = this.dialogService.open(UserModalComponent, {
            header: `User Details for ${userModal}`,
            width: '30rem',
            data: {
                user: userModal,
                entityName: this.auditEntityBean.entityName,
                entityId: this.auditEntityBean.entityId
            },
            dismissableMask: true,
            draggable: true,
            keepInViewport: true
        });
    }
    
}
