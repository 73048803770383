import { Table } from "primeng/table";

export class TableUtils {

    public static getCellValue(event: any): any {
        return event.data[event.field];
    }

    public static changeSelectionOnArrowKey(implementedComponent: any, propNameSelectedRowData: string, event: any, rows: any[], hasHeader: boolean = true): void {
        const itemsPerPage = 5;
        const { key, shiftKey, target } = event;

        if (key === "Tab" || key === "ArrowDown" || key === "ArrowUp") {
            let rowIndex = target.rowIndex + (key === "ArrowDown" || (key === "Tab" && !shiftKey) ? 1 : -1);

            if (hasHeader) {
                rowIndex -= 1;
            }

            const currentPage = Math.floor(rowIndex / itemsPerPage);
            rowIndex = (currentPage * itemsPerPage) + (rowIndex % itemsPerPage);

            if (rowIndex % itemsPerPage === 0) {
                rowIndex -= 1;
            }

            rowIndex = Math.max(0, Math.min(rowIndex, rows.length - 1));
            implementedComponent[propNameSelectedRowData] = rows[rowIndex];
        }
    }

    public static getRowsByPage(table: Table): Array<any> {
        if(!table){
            return [];
        }
        let rows = table.value;
        let minIndex = table.first;
        let maxIndex = minIndex + table.rows;
        maxIndex = rows.length <= maxIndex ? rows.length : maxIndex; 
        return rows.slice(minIndex, maxIndex);
    }

    public static restrictArrowKeyNavigation(event: KeyboardEvent) {
        const arrowKeys = ['ArrowDown', 'Down', 'ArrowUp', 'Up', 'ArrowLeft', 'Left', 'ArrowRight', 'Right'];
        
        if (arrowKeys.includes(event.key)) {
            event.stopPropagation();
        }
    }

}
