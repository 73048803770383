import { IAuditEntityData } from "src/app/core/interfaces/iaudit-entity-data";
import {Address} from "../../address";
import {ContactsDisplay} from "../../contact/display/contacts-display";
import {OperationType} from "../../enumerations/operation-type";
import { OffshoreAddress } from "../../offshoreAddress";
import {Plants} from "../save/plants";
import {PlantsActivityInfoDisplay} from "./plants-activity-info-display";
import {PlantsBasinsDisplay} from "./plants-basins-display";
import {PlantsCompanyInfoDisplay} from "./plants-company-info-display";
import {PlantsConnectionsDisplay} from "./plants-connections-display";
import {PlantsContactInfoDisplay} from "./plants-contact-info-display";
import {PlantsElectricalBusRatingDisplay} from "./plants-electrical-busrating-display";
import {PlantsFaxInfoDisplay} from "./plants-fax-info-display";
import {PlantsFeedInfoDisplay} from "./plants-feed-info-display";
import {PlantsFuelTypesDisplay} from "./plants-fuel-types-display";
import {PlantsInternalAttachmentsDisplay} from "./plants-internal-attachments-display";
import {PlantsNotesDisplay} from "./plants-notes-display";
import {PlantsOutputInfoDisplay} from "./plants-output-info-display";
import {PlantsPhoneInfoDisplay} from "./plants-phone-info-display";
import {PlantsPipelineMetersDisplay} from "./plants-pipeline-meters-display";
import {PlantsGreenhouseGasEmissionsDisplay} from "./plants-greenhouse-gas-emissions-display";
import { PlantsGreenhouseGasFacilitiesDisplay } from './plants-greenhouse-gas-facilities-display';
import {PlantsProductInfoDisplay} from "./plants-product-info-display";
import {PlantsShalePlaysDisplay} from "./plants-shale-plays-display";
import {PlantsSicCodesDisplay} from "./plants-sic-codes-display";
import {PlantsSicProductDisplay} from "./plants-sic-product-display";
import {PlantsSic7CodesDisplay} from "./plants-sic7-codes-display";
import { MVTOperations } from "src/app/core/mvt-operations";
import { EntityName } from "../../enumerations/entity-name";

export class PlantsDisplay extends Plants {
    siteName: string;
    ownerName: string;
    ownerStatus: string;
    physCountryName: string;
    physStateName: string;
    physCountyName: string;
    mailCountryName: string;
    mailStateName: string;
    ultimateParentName: string;
    ultimateParentPhysAddress: string;
    ultimateParentPhysCountryName: string;
    ultimateParentClassification: string;
    ultimateParentQcDate: string;
    operatorName: string;
    operatorPhysAddress: string;
    operatorPhysCountryName: string;
    operatorQcDate: string;
    electConnName: string;
    ngConnName: string;
    defCountryCode: string;
    physCountry: string;
    physState: string;
    physCity: string;
    mailCountry: string;
    mailState: string;
    mailCity: string;

    paddRegion: string;
    strElectricConId: string;
    strNaturalConId: string;

    lastUserEmailAddress: string;
    entryUserEmailAddress: string;
    previousUserEmailAddress: string;
    qcUserEmailAddress: string;
    releaseUserEmailAddress: string;
    amendmentUserEmailAddress: string;
    railCompanyName: string;

    hasNgConsumptionActual: boolean;
    hasNgConsumptionLevel: boolean;
    hasCompanyRelationshipTree: boolean;

    // mapped from an MVT
    phoneCc: string;
    phoneNo: string;
    phoneExt: string;
    phoneMobile: string;

    gasgenRegion: string;
    plantAlertType: string;
    alertSubject: string;
    plantAlertDelay: string;
    plantAlertComments: string;
    paddRegionDesc: string;

    offshoreWaterbodyName: string;
    offshoreFieldName: string;
    offshoreAreaName: string;
    offshoreCountryDesigName: string;
    offshoreStateDesigName: string;

    acPlantAlertSubjects: Array<any>;
    acPlantAlerts: Array<any>;
    acPlantUnitsAlerts: Array<any>;
    acUnitOfflineEventsAlerts: Array<any>;
    acPlantBasins: Array<any>;

    // derived fields
    energyProducerDesc: string;
    automotiveDesc: string;

    // Offshore or Onshore Site.
    offshore: boolean;
    vnFaxSelected: boolean;

    // lists of beans - MVTs
    plantsCompanyInfoDisplay: Array<PlantsCompanyInfoDisplay>;
    plantsContactInfoDisplay: Array<PlantsContactInfoDisplay>;
    plantsElectricalBusRatingDisplay: Array<PlantsElectricalBusRatingDisplay>;
    plantsNotesDisplay: Array<PlantsNotesDisplay>;
    plantsFaxInfoDisplay: Array<PlantsFaxInfoDisplay>;
    plantsFeedInfoDisplay: Array<PlantsFeedInfoDisplay>;
    plantsFuelTypesDisplay: Array<PlantsFuelTypesDisplay>;
    plantsShalePlaysDisplay: Array<PlantsShalePlaysDisplay>;
    plantsBasinsDisplay: Array<PlantsBasinsDisplay>;
    plantsOutputInfoDisplay: Array<PlantsOutputInfoDisplay>;
    plantsPhoneInfoDisplay: Array<PlantsPhoneInfoDisplay>;
    plantsProductInfoDisplay: Array<PlantsProductInfoDisplay>;
    plantsSic7CodesDisplay: Array<PlantsSic7CodesDisplay>;
    plantsSicCodesDisplay: Array<PlantsSicCodesDisplay>;
    plantsSicProductDisplay: Array<PlantsSicProductDisplay>;
    plantsPipelineMetersDisplay: Array<PlantsPipelineMetersDisplay>;
    plantsGreenhouseGasEmissionsDisplay: Array<PlantsGreenhouseGasEmissionsDisplay>;
    plantsGreenhouseGasFacilitiesDisplay: Array<PlantsGreenhouseGasFacilitiesDisplay>;
    plantsConnectionsDisplay: Array<PlantsConnectionsDisplay>;
    plantsInternalAttachmentsDisplay: Array<PlantsInternalAttachmentsDisplay>;
    plantsActivityInfoDisplay: Array<PlantsActivityInfoDisplay>;

    plantsOffshoreConnectionsDisplay: Array<any>;
    plantsOnshoreConnectionsDisplay: Array<any>;
    plantsOriginalInternalAttachmentsDisplay: Array<any>;

    contactsDisplays: Array<ContactsDisplay>;

    noLtsaActivityVerified: boolean;
    noLtsaActivityLastDate: string;
    noLtsaActivityLastUser: string;

    noProjActivityVerified: boolean;
    noProjActivityLastDate: string;
    noProjActivityLastUser: string;
    noProjActivityPrevDate: string;
    noProjActivityPrevUser: string;

    noEnergyProducerVerified: boolean;
    noEnergyProducerLastDate: string;
    noEnergyProducerLastUser: string;
    noEnergyProducerPrevDate: string;
    noEnergyProducerPrevUser: string;

    existingSqFtLastDate: string;
    existingSqFtLastUser: string;
    existingSqFtPrevDate: string;
    existingSqFtPrevUser: string;
    existingSqFtWasChanged: boolean;

    noPlantFaxVerifiedLastDate: string;
    noPlantFaxVerifiedLastUser: string;
    noPlantFaxVerifiedPrevDate: string;
    noPlantFaxVerifiedPrevUser: string;

    readonly physicalAddress: Address;
    readonly offshoreAddress: OffshoreAddress;
    readonly physicalEmail: Address;

    private constructor(entity?: any) {
        super(entity);

        this.siteName = entity && entity.siteName || null;
        this.ownerName = entity && entity.ownerName || null;
        this.physCountryName = entity && entity.physCountryName || null;
        this.physStateName = entity && entity.physStateName || null;
        this.physCountyName = entity && entity.physCountyName || null;
        this.mailCountryName = entity && entity.mailCountryName || null;
        this.mailStateName = entity && entity.mailStateName || null;
        this.ultimateParentName = entity && entity.ultimateParentName || null;
        this.ultimateParentPhysAddress = entity && entity.ultimateParentPhysAddress || null;
        this.ultimateParentPhysCountryName = entity && entity.ultimateParentPhysCountryName || null;
        this.ultimateParentClassification = entity && entity.ultimateParentClassification || null;
        this.ultimateParentQcDate = entity && entity.ultimateParentQcDate || null;
        this.operatorName = entity && entity.operatorName || null;
        this.operatorPhysAddress = entity && entity.operatorPhysAddress || null;
        this.operatorPhysCountryName = entity && entity.operatorPhysCountryName || null;
        this.operatorQcDate = entity && entity.operatorQcDate || null;
        this.electConnName = entity && entity.electConnName || null;
        this.ngConnName = entity && entity.ngConnName || null;
        this.defCountryCode = entity && entity.defCountryCode || null;
        this.physCountry = entity && entity.physCountry || null;
        this.physState = entity && entity.physState || null;
        this.physCity = entity && entity.physCity || null;
        this.mailCountry = entity && entity.mailCountry || null;
        this.mailState = entity && entity.mailState || null;
        this.mailCity = entity && entity.mailCity || null;
        this.phoneMobile = entity && entity.phoneMobile || null;

        this.paddRegion = entity && entity.paddRegion || null;
        this.strElectricConId = entity && entity.strElectricConId || null;
        this.strNaturalConId = entity && entity.strNaturalConId || null;

        this.lastUserEmailAddress = entity && entity.lastUserEmailAddress || null;
        this.entryUserEmailAddress = entity && entity.entryUserEmailAddress || null;
        this.previousUserEmailAddress = entity && entity.previousUserEmailAddress || null;
        this.qcUserEmailAddress = entity && entity.qcUserEmailAddress || null;
        this.releaseUserEmailAddress = entity && entity.releaseUserEmailAddress || null;
        this.amendmentUserEmailAddress = entity && entity.amendmentUserEmailAddress || null;
        this.railCompanyName = entity && entity.railCompanyName || null;

        this.plantAlertType = entity && entity.plantAlertType || null;
        this.plantAlertDelay = entity && entity.plantAlertDelay || null;
        this.plantAlertComments = entity && entity.plantAlertComments || null;
        this.paddRegionDesc = entity && entity.paddRegionDesc || null;

        this.offshore = entity && entity.offshore || false;
        this.offshoreWaterbodyName = entity && entity.offshoreWaterbodyName || null;
        this.offshoreFieldName = entity && entity.offshoreFieldName || null;
        this.offshoreAreaName = entity && entity.offshoreAreaName || null;
        this.offshoreCountryDesigName = entity && entity.offshoreCountryDesigName || null;
        this.offshoreStateDesigName = entity && entity.offshoreStateDesigName || null;

        this.acPlantAlertSubjects = entity && entity.acPlantAlertSubjects || null;
        this.acPlantAlerts = entity && entity.acPlantAlerts || null;
        this.acPlantUnitsAlerts = entity && entity.acPlantUnitsAlerts || null;
        this.acPlantBasins = entity && entity.acPlantBasins || null;

        this.energyProducerDesc = entity && entity.energyProducerDesc || null;
        this.automotiveDesc = entity && entity.automotiveDesc || null;

        this.plantsCompanyInfoDisplay = entity && entity.plantsCompanyInfoDisplay || [];
        this.ownerStatus = this.plantsCompanyInfoDisplay && this.plantsCompanyInfoDisplay[0] && this.plantsCompanyInfoDisplay[0].companyStatus || null;
        this.plantsContactInfoDisplay = entity && entity.plantsContactInfoDisplay || [];
        this.plantsElectricalBusRatingDisplay = entity && entity.plantsElectricalBusRatingDisplay || [];
        this.plantsNotesDisplay = entity && entity.plantsNotesDisplay || [];
        this.plantsFaxInfoDisplay = entity && entity.plantsFaxInfoDisplay || [];
        this.plantsFeedInfoDisplay = entity && entity.plantsFeedInfoDisplay || [];
        this.plantsFuelTypesDisplay = entity && entity.plantsFuelTypesDisplay || [];
        this.plantsShalePlaysDisplay = entity && entity.plantsShalePlaysDisplay || [];
        this.plantsBasinsDisplay = entity && entity.plantsBasinsDisplay || [];
        this.plantsOutputInfoDisplay = entity && entity.plantsOutputInfoDisplay || [];
        this.plantsPhoneInfoDisplay = entity && entity.plantsPhoneInfoDisplay || [];
        this.plantsProductInfoDisplay = entity && entity.plantsProductInfoDisplay || [];
        this.plantsSic7CodesDisplay = entity && entity.plantsSic7CodesDisplay || [];
        this.plantsSicCodesDisplay = entity && entity.plantsSicCodesDisplay || [];
        this.plantsSicProductDisplay = entity && entity.plantsSicProductDisplay || [];
        this.plantsPipelineMetersDisplay = entity && entity.plantsPipelineMetersDisplay || [];
        this.plantsGreenhouseGasFacilitiesDisplay = entity && entity.plantsGreenhouseGasFacilitiesDisplay || [];
        this.plantsGreenhouseGasEmissionsDisplay = entity && entity.plantsGreenhouseGasEmissionsDisplay || [];
        this.plantsConnectionsDisplay = entity && entity.plantsConnectionsDisplay || [];
        this.plantsInternalAttachmentsDisplay = entity && entity.plantsInternalAttachmentsDisplay || [];
        this.plantsActivityInfoDisplay = entity && entity.plantsActivityInfoDisplay || [];

        this.contactsDisplays = entity && entity.contactsDisplays || [];

        this.noProjActivityVerified = entity && entity.noProjActivityVerified || false;
        this.noProjActivityLastDate = entity && entity.noProjActivityLastDate || null;
        this.noProjActivityLastUser = entity && entity.noProjActivityLastUser || null;
        this.noProjActivityPrevDate = entity && entity.noProjActivityPrevDate || null;
        this.noProjActivityPrevUser = entity && entity.noProjActivityPrevUser || null;

        this.noLtsaActivityVerified = entity && entity.noLtsaActivityVerified || false;
        this.noLtsaActivityLastUser = entity && entity.noLtsaActivityLastUser || null;
        this.noLtsaActivityLastDate = entity && entity.noLtsaActivityLastDate || null;

        this.noEnergyProducerVerified = entity && entity.noEnergyProducerVerified || false;
        this.noEnergyProducerLastUser = entity && entity.noEnergyProducerLastUser || null;
        this.noEnergyProducerLastDate = entity && entity.noEnergyProducerLastDate || null;
        this.noEnergyProducerPrevUser = entity && entity.noEnergyProducerPrevUser || null;
        this.noEnergyProducerPrevDate = entity && entity.noEnergyProducerPrevDate || null;

        this.existingSqFtWasChanged = entity && entity.existingSqFtWasChanged || false;
        this.existingSqFtLastUser = entity && entity.existingSqFtLastUser || null;
        this.existingSqFtLastDate = entity && entity.existingSqFtLastDate || null;
        this.existingSqFtPrevUser = entity && entity.existingSqFtPrevUser || null;
        this.existingSqFtPrevDate = entity && entity.existingSqFtPrevDate || null;
        this.vnFaxSelected = entity && entity.vnFaxSelected || false;
        this.noPlantFaxVerifiedPrevUser = entity && entity.noPlantFaxVerifiedPrevUser || null;
        this.noPlantFaxVerifiedPrevDate = entity && entity.noPlantFaxVerifiedPrevDate || null;
        this.noPlantFaxVerifiedLastUser = entity && entity.noPlantFaxVerifiedLastUser || null;
        this.noPlantFaxVerifiedLastDate = entity && entity.noPlantFaxVerifiedLastDate || null;

        this.physicalAddress = Address.BuildPhysicalAddress(entity);
        this.physicalEmail = Address.BuildMailingAddress(entity);
        this.offshoreAddress = OffshoreAddress.BuildOffshoreAddress(entity);
    }

    static BuildPlantDisplay(entity: any): PlantsDisplay {
        return new PlantsDisplay(entity);
    }

    static BuildNewPlant(response: any): PlantsDisplay {
        return new PlantsDisplay({
            plantId: response[0].PLANTS_ID,
            operation: OperationType.INSERT,
            intDataDepValState: OperationType.INSERT
        });
    }

    prepareSaveCollection() {
        this.plantsCompanyInfo = this.plantsCompanyInfoDisplay;
        this.plantsContactInfo = this.plantsContactInfoDisplay;
        this.plantsElectricalBusRating = this.plantsElectricalBusRatingDisplay;
        this.plantsNotes = this.plantsNotesDisplay;
        this.plantsFaxInfo = this.plantsFaxInfoDisplay;
        this.plantsFeedInfo = this.plantsFeedInfoDisplay;
        this.plantsFuelTypes = this.plantsFuelTypesDisplay;
        this.plantsShalePlays = this.plantsShalePlaysDisplay;
        this.plantsBasins = this.plantsBasinsDisplay;
        this.plantsOutputInfo = this.plantsOutputInfoDisplay;
        this.plantsPhoneInfo = this.plantsPhoneInfoDisplay;
        this.plantsProductInfo = this.plantsProductInfoDisplay;
        this.plantsSic7Codes = this.plantsSic7CodesDisplay;
        this.plantsSicCodes = this.plantsSicCodesDisplay;
        this.plantsSicProduct = this.plantsSicProductDisplay;
        this.plantsPipelineMeters = this.plantsPipelineMetersDisplay;
        this.plantsGreeenhouseGasEmissions = this.plantsGreenhouseGasEmissionsDisplay;
        this.plantsGreeenhouseGasFacilities = this.plantsGreenhouseGasFacilitiesDisplay;
        this.plantsConnections = this.plantsConnectionsDisplay;
        this.plantsInternalAttachments = this.plantsInternalAttachmentsDisplay;
        this.plantsActivityInfo = this.plantsActivityInfoDisplay;

        this.contacts = this.contactsDisplays;
    }

    setPhysicalAddress(address: Address): void {
        this.physPostalCode = address.zipCode;
        this.physAddressV1 = address.address1;
        this.physAddressV2 = address.address2;
        this.pecZoneSimplex = address.pecZoneId;
        this.physCountryId = address.c_id;
        this.physStateId = address.s_id;
        this.physCityId = address.ci_id;
        this.physCountyId = address.co_id;
    }

    setMailingAddress(address: Address): void {
        this.mailPostalCode = address.zipCode;
        this.mailAddressV1 = address.address1;
        this.mailAddressV2 = address.address2;
        this.mailCountryId = address.c_id;
        this.mailStateId = address.s_id;
        this.mailCityId = address.ci_id;
    }

    setOffshoreAddress(offshoreAddress: OffshoreAddress): void {
        this.offshoreBlockV1 = offshoreAddress.originOffshoreBlockV1;
        this.offshoreBlockV2 = offshoreAddress.originOffshoreBlockV2;
        this.offshoreWaterbodyId= String(offshoreAddress.originOffshoreWaterbodyId);
        this.offshoreFieldId = String(offshoreAddress.originOffshoreFieldId);
        this.offshoreAreaId = String(offshoreAddress.originOffshoreAreaId);
        this.offshoreStateDesignation = String(offshoreAddress.origOffshoreStateDesignation);
    }

    get auditEntityBean(): IAuditEntityData {
        return {
            lastUser: this.lastUser,
            entryUser: this.entryUser,
            prevUser: this.prevUser,
            qcUser: this.qcUser,
            lastDate: this.lastDate,
            entryDate: this.entryDate,
            prevDate: this.prevDate,
            qcDate: this.qcDate,
            releaseUser: this.releaseUser,
            releaseDate: this.releaseDate,
            amendmentUser: this.amendmentUser,
            amendmentDate: this.amendmentDate,
            lastUserEmailAddress: this.lastUserEmailAddress,
            entryUserEmailAddress: this.entryUserEmailAddress,
            previousUserEmailAddress: this.previousUserEmailAddress,
            qcUserEmailAddress: this.qcUserEmailAddress,
            releaseUserEmailAddress: this.releaseUserEmailAddress,
            amendmentUserEmailAddress: this.amendmentUserEmailAddress,
            lastUpdateStatus: '',
            entityName: EntityName.PLANT,
            entityId: this.plantId
        };
    }

    set steamProdBool(value:boolean)
    {
        this.steamProd = (value ? '1' : '0');
    }

    get steamProdBool():boolean
    {
        return (this.steamProd=='1');
    }

    set wastewaterBool(value:boolean)
    {
        this.wastewater = (value ? '1' : '0');
    }

    get wastewaterBool():boolean
    {
        return (this.wastewater == '1');
    }

    initEnergyProducer(): void {
        let has4911 = false;
        let has4931 = false;
        let hasSicCodes = false;
        this.energyProducerType = null;
        this.energyProducerDesc = 'No';
        let plantsSicCodesDisplay = MVTOperations.filterByDeleted(this.plantsSicCodesDisplay);
        if(this.industryCode === '01') {
            has4911 = plantsSicCodesDisplay.some((item: PlantsSicCodesDisplay) => { return item.sicCode === '4911'; });

            if(has4911) {
                let plantsSicProductDisplay = MVTOperations.filterByDeleted(this.plantsSicProductDisplay);
                MVTOperations.arrangeMvOrder(plantsSicProductDisplay);
                has4911 = plantsSicProductDisplay.length !== 0;
                plantsSicProductDisplay.every(item => {
                    if (item.sicProduct === '4911*0006') {
                        this.energyProducerType = 'Co-Operative';
                        return false;
                    } else if (item.sicProduct === '4911*0007') {
                       this.energyProducerType = 'Federal Owned';
                       return false;
                    } else if (item.sicProduct === '4911*0008') {
                        this.energyProducerType = 'Investor Owned Utility';
                        return false;
                    } else if (item.sicProduct === '4911*0009') {
                        this.energyProducerType = 'Municipal';
                        return false;
                    } else if (item.sicProduct === '4911*0010') {
                        this.energyProducerType = 'State Owned';
                        return false;
                    } else {
                        has4911 = false;
                        return true;
                    }
                });
            }

            if(!has4911) {
                has4931 = plantsSicCodesDisplay.some((item: PlantsSicCodesDisplay) => { return item.sicCode === '4931'; });

                if(has4931) {
                    this.energyProducerType = 'Non-Utility';
                }
            }

        } else if(this.industryCode != '') {
            hasSicCodes = plantsSicCodesDisplay.some((item: PlantsSicCodesDisplay) => {
                return item.sicCode === '4911' || item.sicCode === '4931';
            });

            if(hasSicCodes) {
                this.energyProducerType = 'Industrial Owned (IEP)';
            }
        }

        if(this.energyProducerType != null) {
            this.energyProducerDesc = 'Yes (' + this.energyProducerType + ')';
            this.noEnergyProducerVerified = false;
        } else if(this.noEnergyProducerVerified) {
            this.energyProducerDesc = 'No';
        } else {
            this.energyProducerDesc = 'Unknown';
        }
    }
}
