<div class="container-modal">
    <p-messages></p-messages>
    <div *ngIf="isMainAccess" class="">
        <div class="d-flex justify-content-start pl-4 mt-1">
            <button (click)="clear()" class="btn-custom" tabindex="12">{{'clear'| translate}}</button>
        </div>
        <div class="mt-3">
            <form>
                <div class="grid">
                    <div class="col-12 lg:col-6 xl:col-6 lg:mt-2 xl:mt-2">
                        <!-- Unit Name -->
                        <div class="flex -mt-3 pt-1 align-items-center">
                            <label
                                class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'project.searchOfflineEvent.unitName'|
                                translate}}:</label>
                            <div class="col-6 flex px-1">
                                <input
                                    #unitname
                                    appFocusElement
                                    class="form__input-field-style w-full"
                                    type="text"
                                    name="unitName"
                                    (keydown.enter)="search()"
                                    [(ngModel)]="searchParams.unitName"
                                    pInputText
                                    maxlength="80"
                                    autofocus
                                    tabindex="1" />
                            </div>
                            <div class="col-3 px-0"></div>
                        </div>
                        <!-- Owner Name -->
                        <div class="flex -mt-3 pt-1 align-items-center">
                            <label
                                class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'project.searchOfflineEvent.ownerName'|
                                translate}}:</label>
                            <div class="col-6 flex px-1">
                                <input
                                    #ownerName
                                    class="form__input-field-style w-full"
                                    type="text"
                                    name="ownerName"
                                    (keydown.enter)="search()"
                                    [(ngModel)]="searchParams.ownerName"
                                    pInputText
                                    maxlength="80"
                                    tabindex="2"/>
                            </div>
                            <div class="col-3 px-0"></div>
                        </div>
                        <!-- Unit ID -->
                        <div class="flex -mt-3 pt-1 align-items-center">
                            <label
                                class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'project.searchOfflineEvent.unitId'|
                                translate}}:</label>
                            <div class="col-6 flex px-1">
                                <input
                                    #unitId
                                    class="form__input-field-style w-full"
                                    type="text"
                                    name="unitId"
                                    (keydown.enter)="search()"
                                    [(ngModel)]="searchParams.unitId"
                                    pInputText
                                    appOnlyNumbers
                                    maxlength="80"
                                    tabindex="3" />
                            </div>
                            <div class="col-3 px-0"></div>
                        </div>
                        <!-- Plant Name -->
                        <div class="flex -mt-3 pt-1 align-items-center">
                            <label
                                class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'project.searchOfflineEvent.plantName'|
                                translate}}:</label>
                            <div class="col-6 flex px-1">
                                <input
                                    #plantName
                                    class="form__input-field-style w-full"
                                    type="text"
                                    name="plantName"
                                    (keydown.enter)="search()"
                                    [(ngModel)]="searchParams.plantName"
                                    pInputText
                                    maxlength="80"
                                    tabindex="4" />
                            </div>
                            <div class="col-3 px-0"></div>
                        </div>
                        <!-- Asset ID -->
                        <div class="flex -mt-3 pt-1 align-items-center">
                            <label
                                class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'project.searchOfflineEvent.assetId'|
                                translate}}:</label>
                            <div class="col-6 flex px-1">
                                <input
                                    #assetId
                                    class="form__input-field-style w-full"
                                    type="text"
                                    name="assetId"
                                    appOnlyNumbers
                                    (keydown.enter)="search()"
                                    [(ngModel)]="searchParams.assetId"
                                    pInputText
                                    maxlength="80"
                                    tabindex="5" />
                            </div>
                            <div class="col-3 px-0"></div>
                        </div>
                        <!-- Asset Name -->
                        <div class="flex -mt-3 pt-1 align-items-center">
                            <label
                                class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'project.searchOfflineEvent.assetName'|
                                translate}}:</label>
                            <div class="col-6 flex px-1">
                                <input
                                    #assetName
                                    class="form__input-field-style w-full"
                                    type="text"
                                    name="assetName"
                                    (keydown.enter)="search()"
                                    [(ngModel)]="searchParams.assetName"
                                    pInputText
                                    maxlength="80"
                                    tabindex="6" />
                            </div>
                            <div class="col-3 px-0"></div>
                        </div>
                    </div>
                    <div class="col-12 lg:col-6 xl:col-6 -mt-3 sm:-mt-3 lg:mt-2 xl:mt-2">
                        <!-- Unit Type -->
                        <div class="flex -mt-3 pt-1 align-items-center">
                            <label
                                class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'project.searchOfflineEvent.unitType'|
                                translate}}:</label>
                            <div class="col-6 flex px-1">
                                <p-dropdown
                                    class="form__container-fields-layout--selects w-full"
                                    [options]="unitTypes"
                                    [filter]="true"
                                    [placeholder]="'common.selectOne' | translate"
                                    showClear="true"
                                    filterBy="unitTypeId,unitTypeDesc"
                                    resetFilterOnHide="false"
                                    autofocusFilter="true"
                                    (keyup.enter)="search($event)"
                                    name="unitType"
                                    [(ngModel)]="searchParams.unitType"
                                    optionLabel="unitTypeDesc"
                                    optionValue="unitTypeId" [tabindex]="7">
                                </p-dropdown>
                            </div>
                            <div class="col-3 px-0"></div>
                        </div>
                        <!-- SIC Code -->
                        <div class="flex -mt-3 pt-1 align-items-center">
                            <label
                                class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'project.searchOfflineEvent.sicCode'|
                                translate}}:</label>
                            <div class="col-6 flex px-1">
                                <p-dropdown
                                    class="form__container-fields-layout--selects w-full"
                                    [options]="unitsSicCodes"
                                    [filter]="true"
                                    [placeholder]="'common.selectOne' | translate"
                                    showClear="true"
                                    filterBy="sicCode,sicCodeDescription"
                                    resetFilterOnHide="true"
                                    autofocusFilter="false"
                                    (keyup.enter)="search($event)"
                                    name="sicCode"
                                    [(ngModel)]="searchParams.sicCode"
                                    optionLabel="sicCodeDescription"
                                    optionValue="sicCode"
                                    [tabindex]="8">
                                </p-dropdown>
                            </div>
                            <div class="col-3 px-0"></div>
                        </div>
                        <!-- Industry Code -->
                        <div class="flex -mt-3 pt-1 align-items-center">
                            <label
                                class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'project.searchOfflineEvent.industryCode'|
                                translate}}:</label>
                            <div class="col-6 flex px-1">
                                <p-dropdown
                                    class="form__container-fields-layout--selects w-full"
                                    [options]="industry"
                                    [filter]="true"
                                    [placeholder]="'common.selectOne' | translate"
                                    showClear="true"
                                    filterBy="industryCode,industryDescription"
                                    resetFilterOnHide="true"
                                    autofocusFilter="true"
                                    (keyup.enter)="search($event)"
                                    name="industry"
                                    [(ngModel)]="searchParams.industryCode"
                                    optionLabel="industryDescription"
                                    optionValue="industryCode"
                                    [tabindex]="9">
                                </p-dropdown>
                            </div>
                            <div class="col-3 px-0"></div>
                        </div>
                        <!-- Plant ID -->
                        <div class="flex -mt-3 pt-1 align-items-center">
                            <label
                                class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'project.searchOfflineEvent.plantId'|
                                translate}}:</label>
                            <div class="col-6 flex px-1">
                                <input
                                    class="form__input-field-style w-full"
                                    type="text"
                                    (keyup.enter)="search()"
                                    name="plantId"
                                    [(ngModel)]="searchParams.plantId"
                                    pInputText
                                    appOnlyNumbers
                                    maxlength="40"
                                    tabindex="10"/>
                            </div>
                        </div>
                        <!-- Record Status -->
                        <div class="flex -mt-3 pt-1 align-items-center">
                            <label
                                class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'project.searchOfflineEvent.recordStatus'|
                                translate}}:</label>
                            <div class="col-6 flex px-1">
                                <p-dropdown
                                    class="form__container-fields-layout--selects w-full"
                                    [options]="recordStatuses"
                                    [filter]="true"
                                    [placeholder]="'common.selectOne' | translate"
                                    showClear="true"
                                    filterBy="RecordStatusID,RecordStatusDescription"
                                    resetFilterOnHide="true"
                                    autofocusFilter="true"
                                    (keyup.enter)="search($event)"
                                    name="recordStatus"
                                    [(ngModel)]="searchParams.recordStatus"
                                    optionLabel="RecordStatusDescription"
                                    optionValue="RecordStatusID"
                                    [tabindex]="11">
                                </p-dropdown>
                            </div>
                            <div class="col-3 px-0"></div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <hr *ngIf="unitsIds == null" class="hr-modal">
    <div class="container-info-modal">
        <div class="grid main-content">
            <div class="col-6 text-xs" *ngIf="isMainAccess">
                <img src="assets/icons/icon_info.png" width="15px" height="15px" alt="info">
                <span><strong>{{'common.mouseUsers'| translate}}:</strong> {{'common.searchMouseUsers'|
                    translate}}</span>
            </div>
            <div class="col-6 text-xs" *ngIf="isMainAccess">
                <img src="assets/icons/icon_info.png" width="15px" height="15px" alt="info">
                <span><strong>{{'common.keyboardUsers' | translate}}:</strong> {{'common.searchKeyboardUsers'|
                    translate}}</span>
            </div>
            <div class="col-12" *ngIf="!isMainAccess">
                <span class="text-xs">{{'offlineEvent.offlineEventSubtitle'| translate}}</span>
            </div>
        </div>
    </div>
    <div class="container-table-modal">
        <div class="company-table">
            <p-table
                #searchTable
                [value]="offlineEvents"
                selectionMode="single"
                [rows]="rowsPerPage"
                (onPage)="onPageChange($event)"
                [showCurrentPageReport]="true"
                [columns]="cols"
                [resizableColumns]="true"
                styleClass="p-datatable-gridlines"
                [reorderableColumns]="true"
                (sortFunction)="customSort($event)"
                [customSort]="true"
                [rowsPerPageOptions]="rowsPerPageOptions"
                [paginator]="true"
                [(selection)]="this.additionalInfo"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" [style.width]="col.width" pReorderableColumn pResizableColumn
                            [pSortableColumn]="col.field"
                            [ngStyle]="{'text-align': col.field !== 'eventDuration' ? 'left' : 'right'}">
                            {{col.header}}
                            <p-sortIcon *ngIf="col.field != 'checkbox'" [field]="col.field"></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="colgroup" let-columns>
                    <colgroup>
                        <col *ngFor="let col of columns" [style]="col.style" tabindex="-1">
                    </colgroup>
                </ng-template>
                <ng-template pTemplate="body" let-columns="columns" let-index="rowIndex" let-rowData>
                    <tr #tr [pSelectableRow]="rowData" [pReorderableRow]="index"
                        (keydown)="changeSelectionOnArrowKey($event, offlineEvents)"
                        (keydown.enter)="onKeyDownEnter(rowData)"
                        (click)="onRowSelect(rowData)"
                        (dblclick)="onRowDblclick(rowData)">
                        <td *ngFor="let col of columns" class="{{col.field}} px-2"
                            [style.white-space]="col.wSpace || 'normal'">
                            <div *ngIf="!col.editable && col.field != 'checkbox' && col.field.indexOf('Date') < 0"
                                [ngStyle]="{'text-align': col.field !== 'eventDuration' ? 'left' : 'right'}">
                                {{rowData[col.field]}}
                            </div>
                            <div *ngIf="!col.editable && col.field == 'checkbox'" style="text-align: center;" >
                                <input *ngIf="!isMainAccess" type="checkbox"
                                    (change)="changeElementsToAdd($event, rowData)">
                            </div>
                            <div *ngIf="!col.editable && col.field.indexOf('Date') >= 0">
                                {{ rowData[col.field] | date: 'd-MMM-y' }}
                            </div>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-columns>
                    <tr>
                        <td [attr.colspan]="columns.length">
                            <span class="flex justify-content-center w-full">{{'common.noDataTable'| translate}}</span>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
    <div class="container-info-modal main-content">
        <div class="grid card-modal mt-2">
            <!-- Additional info -->
            <div class="col-12 header-info">
                <span>{{'additional_info'| translate}}:</span>
            </div>
            <!-- First block -->
            <div class="col-12 lg:col-6 xl:col-6 text-xs">
                <div class="content-card">
                    <label class="custom-label-card-right-short">{{'project.searchOfflineEvent.eventName' |
                        translate}}:</label>
                    <label for="" class="custom-label-card-info">{{ this.isMainAccess ? additionalInfo?.largeEventName : additionalInfo?.eventName  }}</label>
                </div>
                <div class="content-card">
                    <label class="custom-label-card-right-short">{{'project.searchOfflineEvent.ownerName'|
                        translate}}:</label>
                    <label for="" class="custom-label-card-info">{{ additionalInfo?.ownerName }}</label>
                </div>
                <div class="content-card">
                    <label class="custom-label-card-right-short">{{'project.searchOfflineEvent.operatorName'|
                        translate}}:</label>
                    <label for="" class="custom-label-card-info">{{ additionalInfo?.operatorName }}</label>
                </div>
                <div class="content-card">
                    <label class="custom-label-card-right-short">{{'project.searchOfflineEvent.eventStartDate'|
                        translate}}:</label>
                    <label for="" class="custom-label-card-info">{{ additionalInfo?.eventStartDate | date: 'd-MMM-y'
                        }}</label>
                </div>
                <div class="content-card">
                    <label class="custom-label-card-right-short">{{'project.searchOfflineEvent.recordStatus'|
                        translate}}:</label>
                    <label for="" class="custom-label-card-info">{{ additionalInfo?.recordStatus}}</label>
                </div>
                <div class="content-card">
                    <label class="custom-label-card-right-short">{{'project.searchOfflineEvent.eventComments'|
                        translate}}:</label>
                    <label for="" class="custom-label-card-info">{{ additionalInfo?.eventComments}}</label>
                </div>
            </div>
            <!-- Second block -->
            <div class="col-12 lg:col-6 xl:col-6 text-xs">
                <div class="content-card">
                    <label class="custom-label-card-right-short">{{'project.searchOfflineEvent.sicCode'|
                        translate}}:</label>
                    <label for="" class="custom-label-card-info">{{ additionalInfo?.sicCodeCompositeName() }}</label>
                </div>
                <div class="content-card">
                    <label class="custom-label-card-right-short">{{'project.searchOfflineEvent.primaryFuel'|
                        translate}}:</label>
                    <label for="" class="custom-label-card-info">{{ additionalInfo?.primaryFuel }}</label>
                </div>
                <div class="content-card">
                    <label class="custom-label-card-right-short">{{'project.searchOfflineEvent.powerUsage'|
                        translate}}:</label>
                    <label for="" class="custom-label-card-info">{{ additionalInfo?.powerUsage }}</label>
                </div>
                <div class="content-card">
                    <label class="custom-label-card-right-short">{{'project.searchOfflineEvent.eventEndDate'|
                        translate}}:</label>
                    <label for="" class="custom-label-card-info">{{additionalInfo?.eventEndDate | date: 'd-MMM-y'}}
                    </label>
                </div>
                <div class="content-card">
                    <label class="custom-label-card-right-short">{{'project.searchOfflineEvent.qcDate'|
                        translate}}:</label>
                    <label for="" class="custom-label-card-info">{{ additionalInfo?.qcDate | date: 'd-MMM-y' }}</label>
                </div>
                <div class="content-card">
                    <label class="custom-label-card-right-short"></label>
                    <label for="" class="custom-label-card-info"></label>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!isMainAccess">
        <div class="grid mt-2">
            <div class="col-6 text-left">
                <label class="text-sm">{{'common.records'| translate}}: {{offlineEvents.length}}</label>
            </div>
            <div class="col-6 text-right">
                <button (click)="addOfflineEvents()" [disabled]="elementsToAdd.length==0" class="btn-row"
                    style="width: auto;">{{'offlineEvent.addItems'| translate}}</button>
            </div>
        </div>
    </div>

    <div *ngIf="isMainAccess" class="grid pt-3">
        <div class="col-12 lg:col-11 xl:col-11 flex justify-content-end">
            <label class="custom-label mt-2 lg:mr-2 xl:-mr-1">{{'project.searchOfflineEvent.infoSearchOfflineEvent'| translate}}
                <strong> {{'project.searchOfflineEvent.notDuplicate'| translate}}</strong>
            </label>
        </div>
        <div class="col-12 lg:col-1 xl:col-1 flex justify-content-end">
            <button [disabled]="!enabledNewRecordButton" [ngStyle]="{'color':!enabledNewRecordButton ? 'grey' : 'black'}"
                class="col-4 btn-custom h-2rem" label="Create New Record"
                (click)="newRecord()">{{'project.searchOfflineEvent.newRecordButton'| translate}}
            </button>
        </div>
    </div>
