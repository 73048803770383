<div class="px-4 h-21rem">
    <div class="row container-search-country" *ngIf="showFilterSection">
        <div class="grid mt-1">
            <div class="col-9">
                <!-- Abbreviation ID -->
                <div class="grid ml-1 pl-0 xl:ml-0 lg:ml-0 md:ml-0 sm:ml-0">
                    <div class="col-3 flex justify-content-end mt-1 ml-2 xl:-ml-2 lg:-ml-2 md:-ml-2 sm:-ml-2">
                        <label class="text-xs">{{'location_filter_abb'| translate}}:</label>
                    </div>
                    <div class="col-9 -ml-2">
                        <input type="text" pInputText [(ngModel)]="params.countryId" class="p-inputtext-sm"
                            (keyup.enter)="loadList(true)" appFocusElement tabindex="1">
                    </div>
                </div>
                <!-- Country -->
                <div class="grid ml-1 pl-0 xl:ml-0 lg:ml-0 md:ml-0 sm:ml-0">
                    <div class="col-3 flex justify-content-end mt-1 ml-2 xl:-ml-2 lg:-ml-2 md:-ml-2 sm:-ml-2">
                        <label class="text-xs">{{'location_filter_full'| translate}}:</label>
                    </div>
                    <div class="col-9 -ml-2">
                        <input type="text" pInputText [(ngModel)]="fullName" class="p-inputtext-sm" (keyup.enter)="loadList(true)" tabindex="2">
                    </div>
                </div>
            </div>
            <div class="col-3 px-0 xl:-ml-3 lg:-ml-3 md:-ml-3 sm:-ml-3">
                <button class="btn-row" (click)="loadList(true)">{{'search'| translate}}</button>
                <button class="btn-row" style="margin-top: 12px !important;" (click)="clear()">{{'clear'| translate}}</button>
            </div>
        </div>
    </div>
    <!-- Country readOnly -->
    <div class="grid mt-2 pt-3 pl-2 pb-4" *ngIf="params.countryName">
        <div class="col-2 flex justify-content-end mt-1 ml-2 xl:-ml-2 lg:-ml-2 md:-ml-2 sm:-ml-2">
            <label class="text-xs">{{'common.country'| translate}}:</label>
        </div>
        <div class="col-9 -ml-2">
            <input type="text" pInputText class="p-inputtext-sm border-1 surface-300" readonly [(ngModel)]="params.countryName" tabindex="3">
        </div>
    </div>
    <!-- Water Body -->
    <div class="grid mt-2 pt-1 pl-2 pb-3" *ngIf="params.waterBodyName">
        <div class="col-2 flex justify-content-end mt-1 ml-2 xl:-ml-2 lg:-ml-2 md:-ml-2 sm:-ml-2">
            <label class="text-xs">{{'common.waterBody'| translate}}:</label>
        </div>
        <div class="col-9 -ml-2">
            <input type="text" pInputText class="p-inputtext-sm border-1 surface-300" readonly [(ngModel)]="params.waterBodyName" tabindex="4">
        </div>
    </div>
    <!-- Table -->
    <div class="border-1 border-500">
        <div class="col-12 custom-scrollable-table-modal">
            <p-table #countryTable *ngIf="isCountryView()" [value]="countries"
                [style]="{'width':'100%'}" [reorderableColumns]="true" [resizableColumns]="true" selectionMode="single"
                (onRowSelect)="onRowSelect($event)" [globalFilterFields]="['countryId','countryName']"
                [(selection)]="this.selection">
                <ng-template pTemplate="body" let-item tabIndex="5">
                    <tr #tr
                    [pSelectableRow]="item"
                    (dblclick)="onRowDblclick(item)"
                    (keyup.enter)="onRowDblclick(item)"
                    (keydown)="changeSelectionOnArrowKey($event, countries, false)">
                        <td>{{item.countryId}}</td>
                        <td>{{item.countryName}}</td>
                    </tr>
                </ng-template>
            </p-table>

            <p-table #stateTable *ngIf="isStateView()" [value]="states"
                [style]="{'width':'100%'}" [reorderableColumns]="true" [resizableColumns]="true" selectionMode="single"
                (onRowSelect)="onRowSelect($event)" [globalFilterFields]="['stateAbbrev','stateName']"
                [(selection)]="this.selection">
                <ng-template pTemplate="header" dataKey="id">
                    <tr>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item tabIndex="6">
                    <tr #tr
                    [pSelectableRow]="item"
                    (dblclick)="onRowDblclick(item)"
                    (keyup.enter)="onRowDblclick(item)"
                    (keydown)="changeSelectionOnArrowKey($event, states)">
                        <td>{{item.stateAbbrev}}</td>
                        <td>{{item.stateName}}</td>
                    </tr>
                </ng-template>
            </p-table>

            <p-table #cityTable *ngIf="isCityView()" [value]="cities"
                [style]="{'width':'100%'}" [reorderableColumns]="true" [resizableColumns]="true" selectionMode="single"
                (onRowSelect)="onRowSelect($event)" [globalFilterFields]="['stateName','cityName']"
                [(selection)]="this.selection">
                <ng-template pTemplate="header" dataKey="id">
                    <tr>
                        <th pSortableColumn="stateName">{{'location_state'| translate}}<p-sortIcon field="stateName">
                            </p-sortIcon>
                        </th>
                        <th pSortableColumn="cityName">{{'location_full'| translate}}<p-sortIcon field="cityName">
                            </p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item tabIndex="7">
                    <tr #tr
                    [pSelectableRow]="item"
                    (dblclick)="onRowDblclick(item)"
                    (keyup.enter)="onRowDblclick(item)"
                    (keydown)="changeSelectionOnArrowKey($event, cities)">
                        <td>{{item.stateName}}</td>
                        <td>{{item.cityName}}</td>
                    </tr>
                </ng-template>
            </p-table>

            <p-table #countyTable *ngIf="isCountyView()" [value]="counties"
                [style]="{'width':'100%'}" [reorderableColumns]="true" [resizableColumns]="true" selectionMode="single"
                (onRowSelect)="onRowSelect($event)" [globalFilterFields]="['countyName']"
                [(selection)]="this.selection">
                <ng-template pTemplate="header" dataKey="id">
                    <tr>
                        <th pSortableColumn="countyName">{{'location_full'| translate}}<p-sortIcon field="countyName">
                            </p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item tabIndex="8">
                    <tr #tr
                    [pSelectableRow]="item"
                    (dblclick)="onRowDblclick(item)"
                    (keyup.enter)="onRowDblclick(item)"
                    (keydown)="changeSelectionOnArrowKey($event, counties)">
                        <td>{{item.countyName}}</td>
                    </tr>
                </ng-template>
            </p-table>

            <p-table #fieldTable *ngIf="isFieldView()" [value]="fields"
                [style]="{'width':'100%'}" [reorderableColumns]="true" [resizableColumns]="true" selectionMode="single"
                (onRowSelect)="onRowSelect($event)" [globalFilterFields]="['fieldName']"
                [(selection)]="this.selection">
                <ng-template pTemplate="header" dataKey="id">
                    <tr>
                        <th pSortableColumn="fieldName">{{'location_full'| translate}}<p-sortIcon field="fieldName">
                            </p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item tabIndex="9">
                    <tr #tr
                    [pSelectableRow]="item"
                    (dblclick)="onRowDblclick(item)"
                    (keyup.enter)="onRowDblclick(item)"
                    (keydown)="changeSelectionOnArrowKey($event, fields)">
                        <td>{{item.fieldName}}</td>
                    </tr>
                </ng-template>
            </p-table>

            <p-table #areaTable *ngIf="isAreaView()" [value]="areas"
                [style]="{'width':'100%'}" [reorderableColumns]="true" [resizableColumns]="true" selectionMode="single"
                (onRowSelect)="onRowSelect($event)" [globalFilterFields]="['areaName']"
                [(selection)]="this.selection">
                <ng-template pTemplate="header" dataKey="id">
                    <tr>
                        <th pSortableColumn="areaName">{{'location_full'| translate}}<p-sortIcon field="areaName">
                            </p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item tabIndex="10">
                    <tr #tr
                    [pSelectableRow]="item"
                    (dblclick)="onRowDblclick(item)"
                    (keyup.enter)="onRowDblclick(item)"
                    (keydown)="changeSelectionOnArrowKey($event, areas)">
                        <td>{{item.areaName}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
    <div class="text-center mt-2">
        <button class="btn-row" (click)="addRowSingleClick()" [disabled]="this.selection === null" tabindex="11">{{'save'| translate}}</button>
        <button class="btn-row ml-2" (click)="closeModal()" tabindex="12">{{'cancel'| translate}}</button>
    </div>
</div>
