<div [ngClass]="showHeader ? 'main-section' : ''" id="mainBaseEntityAssociator">
    <div class="header" *ngIf="showHeader">
        <h3>{{ tKeyComponentTitle | translate }}</h3>
    </div>
    <div class="cont-details-button text-left">
        <span *ngIf="componentDescription !== null && componentDescription !== ''">{{ componentDescription | translate }} </span>
        <span *ngIf="componentDescription === null">{{ 'mvtEntityAssociatorComponent.componentDescription' | translate:{entityName: entityName} }} </span>
        <button *ngIf="showAddRowButton" type="button" class="btn-row mt-1 mb-3" [disabled]="isDisabledButton()" (click)="addRow($event)" [tabindex]="baseIndex">{{ "common.addRow" | translate }}</button>
    </div>
    <div class="-mt-3 pt-1 grid" [ngClass]="(!showPaginator) ? 'custom-scrollable-table' : ''">
        <div class="col-12">
            <p-table
                #dataTable
                [value]="entitiesArrayFiltered()"
                focusableMVT
                [focusManager]="focusManager"
                [columns]="cols"
                [resizableColumns]="true"
                [reorderableColumns]="true"
                (onEditInit)="onEditInit(cols, $event)"
                (onEditComplete)="onEditComplete(cols, $event)"
                styleClass="p-datatable-gridlines"
                (onRowReorder)="onRowReorder($event)"
                selectionMode="single"
                [(selection)]="selectedRow"
                [paginator]="showPaginator" [rowsPerPageOptions]="rowsPerPageOptions" [rows]="defaultRowsPerPage"
                [showCurrentPageReport]="showPaginator"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [tabindex]="baseIndex > 0 ? baseIndex + 1 : 0"
                (onPage)="onPageChange($event)"
                [sortMode]="sortingMode" [customSort]="true" (sortFunction)="customSort($event)">

                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns"
                            [class]="col.columnHeaderIcon != null ? 'cursor-pointer' : ''"
                            [style.flex-basis]="col.width()"
                            [style.text-align]="col.headerAlign"
                            [style.margin-left]="'10px'"
                            pReorderableColumn pResizableColumn
                            [pSortableColumnDisabled]="!col.isSortableColumn()"
                            pSortableColumn="{{col.getSortableColumn()}}">
                            {{col.columnHeader | translate}}
                            <img *ngIf="col.columnHeaderIcon != null" [src]="col.columnHeaderIcon" width="10px">
                            <p-sortIcon *ngIf="sortingMode != null && col.isSortableColumn()" field="{{col.entityPropName}}"></p-sortIcon>
                            <input
                                *ngIf="this.selectionMode === 'multiple' && col.isSelectionModeColumn()"
                                tabindex="-1"
                                [disabled]="isHeaderCheckboxDisabled()"
                                type="checkbox"
                                (change)="onSelectionModeColumnEdited($event)"
                                [(ngModel)]="selectionModeColumnSelected"
                                [title]="'common.checkUncheckAll' | translate">
                        </th>
                    </tr>
                </ng-template>

            <ng-template pTemplate="colgroup" let-columns>
                <colgroup>
                    <col *ngFor="let col of columns" [style.flex-basis]="col.width()">
                </colgroup>
            </ng-template>

            <ng-template pTemplate="body" let-columns="columns" let-index="rowIndex" let-rowData>
                <tr [pReorderableRow]="hasRowReorder() ? index : null" (dblclick)="dgRowDoubleClick(rowData)"	
                    [pSelectableRow]="this.highlightSeleccion ? rowData : null" (keydown)="changeSelectionOnArrowKey($event)"
                    [ngStyle]="{'cursor': this.onRowDoubleClick != null ? 'pointer':''}" tabindex="0">
                    <td *ngFor="let col of columns" [pEditableColumn]="rowData" [pEditableColumnField]="col.entityPropName" [pEditableColumnRowIndex]="index" class="{{col.entityPropName}}"
                        [style.flex-basis]="col.width()" [style.text-align]="col.bodyAlign" [style.white-space]="col.wSpace || 'normal'"
                        [ngStyle]="{'background': col.columnBackgroundColor(rowData, index), 'color': col.columnTextColor(rowData, index), 'border': col.cellBorderColor(rowData, index)}" pTooltip="{{col.columnTooltip(rowData, index) | translate}}" title="{{col.columnTitle(rowData, index) | translate}}">
                        <div *ngIf="col.draggable && tableEditEnabled" class="draggable-col">
                            <span class="pi pi-bars" pReorderableRowHandle title="Reorder Row"></span>
                        </div>
                        <div *ngIf="!col.isEditable(rowData, index) || !tableEditEnabled || col.hasDirectEdition(rowData, index)">
                            <ng-container *ngIf="!col.isIconArrayColumn(); then textCell; else iconButtonCell">
                            </ng-container>

                            <ng-template #textCell>
                                <div *ngIf="col.editableType !== columnEntityInfoEditableType.checkboxField"
                                    class="pl-2 pr-2">
                                    <div *ngIf="col.getRouterEntityName(this.implementedComponent, rowData) == null && !col.hasUrlLink(rowData)">{{ col.getCellDisplayValue(rowData) }}</div>
                                    <a *ngIf="col.getRouterEntityName(this.implementedComponent, rowData) != null"
                                        (keydown.enter)="onLinkEnter($event)"
                                        (click)="col.onRouterLinkClick(rowData, rowData[col.entityPropName])"
                                        (keydown)="this.disableNavigation($event)"
                                        href="javascript:void(0)">
                                        {{col.getCellDisplayValue(rowData)}}
                                    </a>
                                    <a *ngIf="col.hasUrlLink(rowData)"
                                        (keydown.enter)="onLinkEnter($event)"
                                        [href]="col.getUrlLink(this.implementedComponent, rowData)"
                                        [target]="(col.getUrlLink(this.implementedComponent, rowData) != null) ? col.getUrlLink(this.implementedComponent, rowData) : '_blank'">
                                        {{col.getCellDisplayValue(rowData)}}
                                    </a>
                                </div>
                                <div *ngIf="col.editableType === columnEntityInfoEditableType.checkboxField && tableEditEnabled"
                                    style="text-align: center;">
                                    <input
                                        tabindex="0"
                                        type="checkbox"
                                        [disabled]="!col.isEditable(rowData, index) && !col.hasDirectEdition(rowData, index)"
                                        (change)="onCheckBoxEdited(col, rowData, index)"
                                        [(ngModel)]="rowData[col.entityPropName]">
                                </div>
                            </ng-template>
                            <ng-template #iconButtonCell>
                                <div class="flex flex-row justify-content-center">
                                    <div *ngFor="let iconButton of col.iconButtonArray" class="justify-content-center mr-1">
                                        <button *ngIf="!iconButton.hasClassImg() && iconButton.hasImage(rowData, index, implementedComponent)"
                                                [pTooltip]="iconButton.getToolTipText(rowData, implementedComponent)" 
                                                [tooltipPosition]="'left'" (click)="iconButton.onIconClick(implementedComponent, rowData, index)"
                                                pButton pRipple type="button"
                                                class="p-button-text icon-container p-button-icon-only">
                                            <img [src]="iconButton.getSrcIcon(rowData, implementedComponent)" width="14px" class="cursor-pointer">
                                        </button>
                                        <button *ngIf="iconButton.hasClassImg() && iconButton.hasImage(rowData, index, implementedComponent)"
                                            (click)="iconButton.onIconClick(implementedComponent, rowData, index)"
                                            pButton pRipple type="button"
                                            class="p-button-text icon-container p-button-icon-only">
                                            <i [class]="iconButton.getClassIcon(rowData, implementedComponent)"></i>
                                        </button>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                        <p-cellEditor *ngIf="col.isEditable(rowData, index) && tableEditEnabled && !col.hasDirectEdition(rowData, index)" class="flex flex-row"
                            [ngClass]="(col.bodyAlign === 'right') ? 'justify-content-end' : ((col.bodyAlign === 'center') ? 'justify-content-center': '')">
                            <ng-template pTemplate="input">
                                <input
                                    *ngIf="col.editableType == columnEntityInfoEditableType.numberField && !col.formatThousands && !col.formatThousandsDecimals"
                                    type="text"
                                    style="width: 80%;"
                                    (keydown)="this.disableNavigation($event)"
                                    [maxwhole]="col.maxLength"
                                    appOnlyNumbers [maxdecimal]="col.maxDecimals"
                                    (keydown.enter)="onEnter(col, rowData, index)"
                                    [(ngModel)]="rowData[col?.entityPropName]">
                                <input
                                    *ngIf="col.editableType == columnEntityInfoEditableType.numberField && col.formatThousands && !col.formatThousandsDecimals"
                                    type="text"
                                    style="width: 80%;"
                                    [maxlength]="col.maxLength"
                                    (keydown)="this.disableNavigation($event)"
                                    [mask]="col.getThousandsMask()"
                                    (keydown.enter)="onEnter(col, rowData, index)"
                                    [(ngModel)]="rowData[col.entityPropName]">
                                <input
                                    *ngIf="col.editableType == columnEntityInfoEditableType.numberField && !col.formatThousands && col.formatThousandsDecimals"
                                    type="text"
                                    style="width: 80%;"
                                    [mask]="col.getThousandsMask()"
                                    [separatorLimit]="col.getSeparatorLimit()"
                                    (keydown.enter)="onEnter(col, rowData, index)"
                                    (keydown)="this.disableNavigation($event)"
                                    [(ngModel)]="rowData[col.entityPropName]">
                                <input
                                    *ngIf="col.editableType == columnEntityInfoEditableType.compositeIdField"
                                    type="text"
                                    style="width: 80%;"
                                    [maxlength]="col.maxLength"
                                    compositeId
                                    (keydown.enter)="onEnter(col, rowData, index)"
                                    (keydown)="this.disableNavigation($event)"
                                    [(ngModel)]="rowData[col.entityPropName]">
                                <input
                                    *ngIf="col.editableType == columnEntityInfoEditableType.textField"
                                    type="text"
                                    style="width: 80%;"
                                    [maxlength]="col.maxLength"
                                    (keydown)="this.disableNavigation($event)"
                                    stringUtilsDirective [regexAccepted]="col.regexAccepted" [toUpperCase]="col.toUpperCase"
                                    (keydown.enter)="onEnter(col, rowData, index)"
                                    [(ngModel)]="rowData[col.entityPropName]">
                                <p-calendar
                                    *ngIf="col.editableType == columnEntityInfoEditableType.calendarField"
                                    dateFormat="dd-M-yy"
                                    showButtonBar="true"
                                    [appendTo]="appendTo"
                                    inputStyleClass="calendar-inputtext"
                                    [maxDate]="col.maxValue"
                                    [showIcon]="true"
                                    (onFocus)="initializeDateOnFocus(rowData, col.entityPropName)"
                                    (ngModelChange)="changeDate(col, rowData, $event)"
                                    [(ngModel)]="rowData[col.entityPropName]">
                                </p-calendar>
                                <p-dropdown
                                    *ngIf="col.editableType == columnEntityInfoEditableType.dropdownField"
                                    [options]="col.dropdownOptionsArray(this.implementedComponent, rowData)"
                                    [optionValue]="col.dropdownValue"
                                    [optionLabel]="col.dropdownLabel"
                                    [appendTo]="appendTo"
                                    [autofocus]="true"
                                    showClear="true"
                                    [filter]="true"
                                    [placeholder]="'common.selectOne' | translate"
                                    [style]="{'max-width':'fit-content', 'display':'flex', 'align-items':'center'}"
                                    [disabled]="col.isDropDownEnabled ? !col.isDropDownEnabled(rowData, index) : false"
                                    [(ngModel)]="rowData[col.dropDownFieldIdPropName]">
                                </p-dropdown>

                                <button pButton pRipple
                                        *ngIf="col.openSearchModal !== null"
                                        type="button"
                                        icon="pi pi-search"
                                        class="ml-1 p-button-text icon-container"
                                        style="margin-left: 3%;"
                                        (click)="col.openSearchModal(this, rowData, index)">
                                </button>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <div *ngIf="col.editableType !== columnEntityInfoEditableType.checkboxField"
                                    class="pl-2 pr-2">
                                    <div *ngIf="col.getRouterEntityName(this.implementedComponent, rowData) == null && !col.hasUrlLink(rowData)">{{ col.getCellDisplayValue(rowData) }}</div>
                                    <a *ngIf="col.getRouterEntityName(this.implementedComponent, rowData) != null"
                                        [routerLink]="[col.getRouterLink(rowData), rowData[col.entityPropName]]"
                                        [target]="(col.getRouterEntityName(this.implementedComponent, rowData) != null) ? col.getRouterEntityName(this.implementedComponent, rowData) + rowData[col.entityPropName] : '_blank'">
                                        {{col.getCellDisplayValue(rowData)}}
                                    </a>
                                    <a *ngIf="col.hasUrlLink(rowData)"
                                        [href]="col.getUrlLink(this.implementedComponent, rowData)"
                                        [target]="(col.getUrlLink(this.implementedComponent, rowData) != null) ? col.getUrlLink(this.implementedComponent, rowData) : '_blank'">
                                        {{col.getCellDisplayValue(rowData)}}
                                    </a>
                                </div>
                            </ng-template>
                        </p-cellEditor>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td [attr.colspan]="15">
                        <div *ngIf="!future" style="text-align: center;" class="not-result-msg">
                            <span>{{ emptyMessage }}</span>
                        </div>
                        <div *ngIf="future" style="text-align: center;" class="not-result-msg">
                            <span>{{ "unit.future.addRow"  | translate }}</span>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <hr class="mt-1" *ngIf="internalAdditionalInfoFields">
        <div class="flex" *ngIf="internalAdditionalInfoFields">
            <div class="col-12">
                <div class="grid">
                    <div class="flex col-4 py-0" *ngFor="let additionalInfoField of internalAdditionalInfoFields">
                        <div class="col-4 sm:col-4 md:col-4 lg:col-4 xl:col-4 text-right px-0 py-0">
                            <label class="text-xs font-bold">{{additionalInfoField.columnHeader | translate}}:</label>
                        </div>
                        <div class="col-8 sm:col-8 md:col-8 lg:col-8 xl:col-8 py-0">
                            <label for="" class="text-xs ml-1">{{ additionalInfoField.getCellDisplayValue(selectedRow) }}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>     
    </div>
</div>
