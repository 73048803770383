import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { EntityName } from 'src/app/core/models/enumerations/entity-name';
import { SharedService } from 'src/app/core/services/shared.service';
import { RelatedInfoBase } from '../related-info.base';
import { ColumnEntityInfo, ColumnEntityInfoEditableType } from 'src/app/shared/components/base/mvt-entity-associator/mvt-entity-associator.component';

@Component({
    selector: 'app-transmission-line',
    templateUrl: './transmission-line.component.html',
    styleUrls: ['./transmission-line.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class RelatedInfoTransmissionLineComponent extends RelatedInfoBase implements OnInit {
    entityType = EntityName.ASSET_TRANSMISSION_LINE;
    entityTableKey =EntityName.ASSET_TRANSMISSION_LINE;
    constructor(

        sharedService: SharedService
    ) { super(sharedService); }

    ngOnInit(): void {
        this.initBase();
    }

    getColumnsInfo(): ColumnEntityInfo[] {
        let columnsInfo: ColumnEntityInfo[] = [];
        if (this.callerIsCompany) {
            columnsInfo.push({ entityPropName: 'AssetID', columnHeader: 'asset.id', routerEntityName: EntityName.ASSET_TRANSMISSION_LINE, widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'AssetName', columnHeader: 'asset.name', widthPercentage: 20});
            columnsInfo.push({ entityPropName: 'Owner', columnHeader: 'owner', widthPercentage: 15});
            columnsInfo.push({ entityPropName: 'TLineStatusDesc', columnHeader: 'tline-status', widthPercentage: 15});
            columnsInfo.push({ entityPropName: 'KVRatingDesc', columnHeader: 'kv_rating', widthPercentage: 15, headerAlign: 'right', bodyAlign: 'right'});
            columnsInfo.push({ entityPropName: 'SystemType', columnHeader: 'tline-type', widthPercentage: 15});
            columnsInfo.push({ entityPropName: 'QCDate', columnHeader: 'common.qcDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 15});
            columnsInfo.push({ entityPropName: 'ReleaseDate', columnHeader: 'relatedInfo.common.releaseDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 15});

        } else if(this.callerIsPlant || this.callerIsProject || this.callerIsOfflineEvent) {
            columnsInfo.push({ entityPropName: 'AssetID', columnHeader: 'asset.id', routerEntityName: EntityName.ASSET_TRANSMISSION_LINE, widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'AssetName', columnHeader: 'asset.name', widthPercentage: 20});
            columnsInfo.push({ entityPropName: 'Owner', columnHeader: 'owner', widthPercentage: 15});
            columnsInfo.push({ entityPropName: 'TLineStatusDesc', columnHeader: 'tline-status', widthPercentage: 15});
            columnsInfo.push({ entityPropName: 'KVRatingDesc', columnHeader: 'kv_rating', widthPercentage: 15, headerAlign: 'right', bodyAlign: 'right'});
            columnsInfo.push({ entityPropName: 'SystemTypeDesc', columnHeader: 'tline-type', widthPercentage: 15});
            columnsInfo.push({ entityPropName: 'QCDate', columnHeader: 'common.qcDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 15});
            columnsInfo.push({ entityPropName: 'ReleaseDate', columnHeader: 'relatedInfo.common.releaseDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 15});

        } else if (this.callerIsTransmissionLine) {
            columnsInfo.push({ entityPropName: 'AssetID', columnHeader: 'relatedInfo.tLine.assetId', routerEntityName: EntityName.ASSET_TRANSMISSION_LINE, widthPercentage: 8});
            columnsInfo.push({ entityPropName: 'AssetName', columnHeader: 'relatedInfo.tLine.assetName', widthPercentage: 20});
            columnsInfo.push({ entityPropName: 'Owner', columnHeader: 'relatedInfo.tLine.owner', widthPercentage: 14});
            columnsInfo.push({ entityPropName: 'TLineStatusDesc', columnHeader: 'relatedInfo.tLine.status', widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'KVRatingDesc', columnHeader: 'relatedInfo.tLine.kvRating', widthPercentage: 10, headerAlign:' right', bodyAlign: 'right'});
            columnsInfo.push({ entityPropName: 'SystemType', columnHeader: 'relatedInfo.tLine.type', widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'QCDate', columnHeader: 'relatedInfo.common.qcDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'ReleaseDate', columnHeader: 'relatedInfo.common.releaseDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 10});

        }

        return columnsInfo;
    }

}
