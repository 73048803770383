import { HttpClient } from "@angular/common/http";
import { ComponentRef, ElementRef, Injectable } from "@angular/core";
import { DialogService, DynamicDialogComponent, DynamicDialogRef } from "primeng/dynamicdialog";
import { map } from "rxjs/operators";
import { SessionService } from 'src/app/core/services/session.service';
import { ApiEndpoint, Constants } from "src/app/core/models/constants";
import { RegistrationService } from "src/app/core/services/registration.service";
import { environment } from "src/environments/environment";
import { MessageAction, MessageEvent, MessageHandlerComponent, MessageParameters, MessageResponse, MessageType } from "./message-handler.component";
import { UserDetail } from "src/app/core/models/user-detail";
import { TelemetryService } from "src/app/core/services/telemetry.service";
import { VersionService } from "src/app/core/services/version.service";

export type MessageHandlerFunction = (resp: MessageResponse) => void;

const MsgMetadata = [
    { title: 'Error' },
    { title: 'Confirmation' },
    { title: 'Confirmation' },
    { title: 'Information' },
    { title: 'Warning - Record Failed Validation!' },
    { title: 'Warning - Record Failed Release!' },
    { title: 'Error - Record Failed to Save!' },
    { title: 'Exception' },
    { title: 'Close Confirmation' },
    { title: 'Warning' },
    { title: 'Caution' },
    { title: 'Warning - Map It Failed' },
    { title: 'Remove Widget' },
    { title: 'Warning - Record Failed Validation!' },
    { title: 'Information' },
    { title: 'Confirmation' },
    { title: 'Confirmation' },
    { title: 'Close Confirmation' },
    { title: 'Confirmation' },
    { title: 'System Message' },
    { title: 'Confirmation' },
    { title: 'Confirmation' },
    { title: 'Logout Confirmation' },
    { title: '' },
    { title: '' },
    { title: 'Information' },
];

@Injectable({
    providedIn: 'root'
})
export class MessageHandlerService {
    private static readonly SUCCESS_SENDING_EMAIL = "An e-mail about this error has been sent to a system administrator";
    private static readonly ERROR_SENDING_EMAIL = "An e-mail about this issue could not be sent due to a system error. Please contact the Support Services department.";

    trackingCode: string;
    exceptionClass: string;
    exceptionMessage: string;
    exceptionStack: string;
    showingSystemMessage: boolean = false;

    constructor(
        private dialogService: DialogService,
        private session: SessionService,
        private telemetry: TelemetryService,
        private registrationService: RegistrationService,
        private http: HttpClient
    ) { }

    public destroyAllDialogs() {
        for (let dRef of this.dialogService.dialogComponentRefMap.keys()) {
            dRef.destroy();
        }
    }

    open(messageParameters: MessageParameters) {
        const message = messageParameters.text;
        const type = messageParameters.type;
        const title = messageParameters.title;
        const footer = messageParameters.footer;
        const actions = messageParameters.actions;
        const callback = messageParameters.callback;
        this.create(type, title, message, footer, actions, callback);
    }

    create(
        type: MessageType = MessageType.INFO,
        title: string,
        message: string,
        footer: string,
        actions: MessageAction[],
        callback: MessageHandlerFunction = null,
        disabled: boolean = false,
        actualInputValue: string = '') {
           
        const isSystemMessage = type === MessageType.ALERT_TYPE_SYSTEM_MESSAGE;
        if (!isSystemMessage || !this.showingSystemMessage) {

            const messageParams: MessageParameters = {
                type: type,
                title: title || MsgMetadata[type].title,
                text: message,
                actualInputValue: actualInputValue,
                footer: footer,
                actions: actions,
                disabled: disabled,
                callback: callback
            }

            const modalRef = this.dialogService.open(MessageHandlerComponent, {
                focusOnShow: true,
                closable: false,
                showHeader: false,
                width: '200px;',
                contentStyle: {
                    "background-color": "black",
                    "padding": "0",
                    "border-radius": "4px"
                },
                baseZIndex: 10000,
                data: messageParams,
                draggable: true,
                keepInViewport: true,
                duplicate: isSystemMessage
            });
            
            if(modalRef === null) {
                console.warn(`Dialog NOT SHOWN for: ${message}`);
            } else {
                this.showingSystemMessage = isSystemMessage;
                modalRef.onClose.subscribe((response: any) => {
                    this.showingSystemMessage = false;
                    modalRef.destroy();
                    if (callback !== null) {
                        callback(response);
                    }
                });
            }
            
            if(type === MessageType.EXCEPTION) {
                this.telemetry.uiError(this.exceptionClass);
            }
        }
    }

    show(message: string,
        type: MessageType = MessageType.INFO,
        callback: MessageHandlerFunction = null,
        okLabel: string = null,
        cancelLabel: string = null) {

        const actions: MessageAction[] = [];

        if (okLabel !== null && cancelLabel !== null) {
            actions.push({ label: okLabel, event: MessageEvent.OK });
            actions.push({ label: cancelLabel, event: MessageEvent.CANCEL });
        }

        this.create(type, null, message, null, actions, callback);
    }

    showInputDialog(title: string,
        message: string,
        actualInputValue: string,
        callback: MessageHandlerFunction = null,
        disabled: boolean = false,
        okLabel: string = "Save",
        cancelLabel: string = "Cancel") {

        // Don't show the dialog if the comment is empty and disabled.
        if(disabled && actualInputValue == '') {
            return;
        }

        const actions: MessageAction[] = [];

        if (okLabel !== null && cancelLabel !== null) {
            actions.push({ label: okLabel, event: MessageEvent.OK });
            actions.push({ label: cancelLabel, event: MessageEvent.CANCEL });
        }

        this.create(MessageType.INPUT, title, message, null, actions, callback, disabled, actualInputValue);
    }

    showAlertAndReturnFocus(msgModal: string, inputEventTarget?: EventTarget,
        inputElementRef?: ElementRef, forceLastFocused?:boolean) {
        if((inputEventTarget === undefined && inputElementRef === undefined) || forceLastFocused) {
            inputEventTarget = this.getLastFocusedInput();
        }
        const selectOpt = (resp: MessageResponse): void => {
            if (resp.event == MessageEvent.OK) {
                setTimeout(() => {
                    let input: any = (inputElementRef != null  &&
                        (!forceLastFocused || (forceLastFocused && inputEventTarget == null))) ?
                         inputElementRef.nativeElement : inputEventTarget;
                    if(input != null) {
                        input.focus();
                    }
                }, 100);
            }
        }
        this.show(msgModal, MessageType.INFO, selectOpt);
    }

    getLastFocusedInput(): HTMLElement {
        const activeElement = document.activeElement as HTMLElement;

        if (activeElement.tagName.toLowerCase() === 'input') {
            return activeElement;
        }

        return null;
    }

    setExceptionData(data: any) {
        this.trackingCode = data.errorTrackingCode,
        this.exceptionClass = data.exception,
        this.exceptionMessage = data.message,
        this.exceptionStack = data.stack
    }

    public static errorMessage(resp: any, defaultMessage: string = null): string {
        let msg = '';
        if (resp.exception !== null) {
            msg = Constants.SYSTEM_ERROR_SERVER_ISSUE;
            if(resp.errorTrackingCode !== undefined)
            {
               msg += Constants.NEWLINE + Constants.replace(Constants.SYSTEM_ERROR_TRACKING_CODE, [resp.errorTrackingCode]);
            }
        } else if (resp.message !== null) {
            msg = resp.message;
        } else if (defaultMessage !== null) {
            msg = defaultMessage;
        }
        return msg;
    }

    public static errorType(resp: any): MessageType {
        if (resp.exception) {
            if(resp.exception === Constants.EXCEPTION_SESSION_INVALID)
            {
               return MessageType.SESSION_INVALID;
            }
            return resp.errorTrackingCode ? MessageType.EXCEPTION : MessageType.ERROR;
        } else if (resp.message?.length > 0) {
            return MessageType.ERROR;
        } else if (resp && Object.keys(resp).length > 0) {
            return MessageType.INFO;
        } else {
            return MessageType.NONE;
        }
    }

    reportException(): void {
        this.registrationService.getUserDetailInfo(this.session.getUserName()).subscribe((userDetail: UserDetail) => {
           let mailMessage = '';
           mailMessage += "Tracking Code: " + this.trackingCode + Constants.DOUBLE_NEWLINE;
           mailMessage += "NED Version: " + VersionService.getClientVersion() + Constants.NEWLINE;
           mailMessage += "IP Address: [clientIP]" + Constants.NEWLINE;
           mailMessage += "First Name: " + ((userDetail.firstName == null) ? (" " + Constants.NEWLINE) : (userDetail.firstName + Constants.NEWLINE));
           mailMessage += "Last Name: " + ((userDetail.lastName == null) ? (" " + Constants.NEWLINE) : (userDetail.lastName + Constants.NEWLINE));
           mailMessage += "Job Title: " + ((userDetail.title == null) ? (" " + Constants.NEWLINE) : (userDetail.title + Constants.NEWLINE));
           mailMessage += "E-Mail: " + ((userDetail.emailAddress == null) ? (" " + Constants.NEWLINE) : (userDetail.emailAddress + Constants.NEWLINE));
           mailMessage += "Phone Number: " + ((userDetail.phoneNumber == null) ? (" " + Constants.NEWLINE) : (userDetail.phoneNumber + Constants.NEWLINE));
           mailMessage += "State: " + ((userDetail.stateName == null) ? (" " + Constants.NEWLINE) : (userDetail.stateName + Constants.NEWLINE));
           mailMessage += "Country: " + ((userDetail.countryName == null) ? (" " + Constants.NEWLINE) : (userDetail.countryName + Constants.NEWLINE));
           mailMessage += "Instant Messenger Tool: " + ((userDetail.imTool == null) ? (" " + Constants.NEWLINE) : (userDetail.imTool + Constants.NEWLINE));
           mailMessage += "Instant Messenger ID: " + ((userDetail.imUsername == null) ? (" " + Constants.DOUBLE_NEWLINE) : (userDetail.imUsername + Constants.DOUBLE_NEWLINE));
           mailMessage += "Exception Class: " + this.exceptionClass + Constants.NEWLINE;
           mailMessage += "Exception Message: " + this.exceptionMessage + Constants.DOUBLE_NEWLINE;
           mailMessage += "Exception Stack: " + Constants.NEWLINE + this.exceptionStack + Constants.DOUBLE_NEWLINE;

           const data = { "mailSubject": 'NED Application Error', "mailMessage": mailMessage, "htmlFlag": false }
           this.http.post(environment.apiUrl + ApiEndpoint.SendEmail, data)
               .pipe(
                   map((data: any) => {
                       if (data === null) {
                           this.show(MessageHandlerService.SUCCESS_SENDING_EMAIL, MessageType.INFO);
                       } else {
                           this.show(MessageHandlerService.ERROR_SENDING_EMAIL, MessageType.INFO);
                       }
                   }))
               .subscribe();

        });
    }

}
