import { Bean } from "../../bean";
import { Contacts } from "../../contact/save/contacts";
import { ProjectsBidder } from "./projects-bidder";
import { ProjectsCapacity } from "./projects-capacity";
import { ProjectsCompany } from "./projects-company";
import { ProjectsContractingNeeds } from "./projects-contracting-needs";
import { ProjectsDefinitiveKeyNeeds } from "./projects-definitive-keyneeds";
import { ProjectsEngineeringNeeds } from "./projects-engineering-needs";
import { ProjectsFaxInfo } from "./projects-fax-info";
import { ProjectsIndustryFlag } from "./projects-industry-flag";
import { ProjectsInternalAttachments } from "./projects-internal-attachments";
import { ProjectsKeyNeeds } from "./projects-key-needs";
import { ProjectsMarketTrends } from "./projects-market-trends";
import { ProjectsMatterStates } from "./projects-matter-states";
import { ProjectsNotes } from "./projects-notes";
import { ProjectsOfflineEvents } from "./projects-offline-events";
import { ProjectsPhoneInfo } from "./projects-phone-info";
import { ProjectsPipesInstallation } from "./projects-pipes-installation";
import { ProjectsUnitIds } from "./projects-unit-ids";

export class Projects extends Bean {
    projectId: number;
    projectName: string;
    plantId: number;
    umbrellaId: number;
    plantsName: string;

    physPostalCode: string;
    ownerProject: string;
    ownerSicCode: string;
    projectTiv: number;
    lastCompany: string;
    lastUser: string;
    lastDate: string;
    prevCompany: string;
    prevUser: string;
    prevDate: string;
    entryCompany: string;
    entryUser: string;
    entryDate: string;
    pecActivity: string;
    reviewDate: string;
    duration: number;
    afeDate: number;
    rfqDate: number;
    bidDocDate: number;
    kickoffDate: number;
    completionDate: string;
    longLeadDate: number;
    completionDateResolution: string;
    industryCode: string;
    status: string;
    lastTime: string;
    qcDate: string;
    qcUser: string;
    qcTime: string;
    envAir: string;
    envLand: string;
    envWater: string;
    entryTime: string;
    buyElect: number;
    buyFuel: number;
    buySteam: number;
    sellElect: number;
    sellFuel: number;
    sellSteam: number;
    hsProductId: string;
    outputCapacity: number;
    outputCapacityUnit: string;
    timeMeasure: string;
    storageCapacity: string;
    storageCapacityUnit: string;
    laborUnion: string;
    laborMerit: number;
    scopeText: string;
    scheduleText: string;
    buildingNewSqft: number;
    buildingRetRenSqft: number;
    projectProductSicCode: string;
    releaseDate: string;
    releaseUser: string;
    liveDate: string;
    author: number;
    contributingAuthor: number;
    unitName: string;
    projectTivCurrency: string;
    projectRecordStatus: string;
    projectStatusReason: string;
    amendmentDate: string;
    amendmentUser: string;
    tivQcDate: string;
    scopeQcDate: string;
    scheduleQcDate: string;
    tivQcUser: string;
    scopeQcUser: string;
    scheduleQcUser: string;
    projectFuelType: string;
    projectType: string;
    initiallyUnconfirmed: number;
    confirmedDate: string;
    restart: number;
    restartDate: string;
    projectSource: string;

    physCountryId: number;
    physStateId: number;
    physCityId: number;
    physCountyId: number;

    estimatedKickoffDate: number;
    peakWorkers: number;
    peakWorkersDate: number;
    projectProbabilityId: string;
    shiftsDay: number;
    shiftLength: number;

    projectsNotes: Array<ProjectsNotes>
    projectsKeyNeeds: Array<ProjectsKeyNeeds>
    projectsPhone: Array<ProjectsPhoneInfo>;
    projectsCompany: Array<ProjectsCompany>;
    projectsContractingNeeds: Array<ProjectsContractingNeeds>;
    projectsDefinitiveKeyNeeds: Array<ProjectsDefinitiveKeyNeeds>;
    projectsEngineeringNeeds: Array<ProjectsEngineeringNeeds>;
    projectsFax: Array<ProjectsFaxInfo>;
    projectsIndustryFlag: Array<ProjectsIndustryFlag>;
    projectsMatterStates: Array<ProjectsMatterStates>;
    projectsUnitIds: Array<ProjectsUnitIds>;
    projectsInternalAttachments: Array<ProjectsInternalAttachments>;
    projectsOfflineEvents: Array<ProjectsOfflineEvents>;
    projectsBidder: Array<ProjectsBidder>;
    projectsCapacity: Array<ProjectsCapacity>;
    projectsPipesInstallation: Array<ProjectsPipesInstallation>;

    contacts: Array<Contacts>;
    projectsMarketTrends: Array<ProjectsMarketTrends>;


    protected constructor(entity?: any) {
        super(entity);

        this.projectId = entity && entity.projectId || null;
        this.projectName = entity && entity.projectName || null;
        this.plantId = entity && entity.plantId || null;
        this.umbrellaId = entity && entity.umbrellaId || null;
        this.plantsName = entity && entity.plantsName || null;

        this.physPostalCode = entity && entity.physPostalCode || null;
        this.ownerProject = entity && entity.ownerProject || null;
        this.ownerSicCode = entity && entity.ownerSicCode || null;
        this.projectTiv = (entity && entity.projectTiv) ?? null;
        this.lastCompany = entity && entity.lastCompany || null;
        this.lastUser = entity && entity.lastUser || null;
        this.lastDate = entity && entity.lastDate || null;
        this.prevCompany = entity && entity.prevCompany || null;
        this.prevUser = entity && entity.prevUser || null;
        this.prevDate = entity && entity.prevDate || null;
        this.entryCompany = entity && entity.entryCompany || null;
        this.entryUser = entity && entity.entryUser || null;
        this.entryDate = entity && entity.entryDate || null;
        this.pecActivity = entity && entity.pecActivity || null;
        this.reviewDate = entity && entity.reviewDate || null;
        this.duration = entity && entity.duration || null;
        this.afeDate = entity && entity.afeDate || null;
        this.rfqDate = entity && entity.rfqDate || null;
        this.bidDocDate = entity && entity.bidDocDate || null;
        this.kickoffDate = entity && entity.kickoffDate || null;
        this.completionDate = entity && entity.completionDate || null;
        this.longLeadDate = entity && entity.longLeadDate || null;
        this.completionDateResolution = entity && entity.completionDateResolution || null; 
        this.industryCode = entity && entity.industryCode || null;
        this.status = entity && entity.status || null;
        this.lastTime = entity && entity.lastTime || null;
        this.qcDate = entity && entity.qcDate || null;
        this.qcUser = entity && entity.qcUser || null;
        this.qcTime = entity && entity.qcTime || null;
        this.envAir = entity && entity.envAir || null;
        this.envLand = entity && entity.envLand || null;
        this.envWater = entity && entity.envWater || null;
        this.entryTime = entity && entity.entryTime || null;
        this.buyElect = entity && entity.buyElect || null;
        this.buyFuel = entity && entity.buyFuel || null;
        this.buySteam = entity && entity.buySteam || null;
        this.sellElect = entity && entity.sellElect || null;
        this.sellFuel = entity && entity.sellFuel || null;
        this.sellSteam = entity && entity.sellSteam || null;
        this.hsProductId = entity && entity.hsProductId || null;
        this.outputCapacity = entity && entity.outputCapacity || null;
        this.outputCapacityUnit = entity && entity.outputCapacityUnit || null;
        this.timeMeasure = entity && entity.timeMeasure || null;
        this.storageCapacity = entity && entity.storageCapacity || null;
        this.storageCapacityUnit = entity && entity.storageCapacityUnit || null;
        this.laborUnion = entity && (entity.laborUnion || entity.laborUnion === 0) ? entity.laborUnion : null;
        this.laborMerit = entity && entity.laborMerit || null;
        this.scopeText = entity && entity.scopeText || null;
        this.scheduleText = entity && entity.scheduleText || null;
        this.buildingNewSqft = (entity && entity.buildingNewSqft) ?? null;
        this.buildingRetRenSqft = (entity && entity.buildingRetRenSqft) ?? null;
        this.projectProductSicCode = entity && entity.projectProductSicCode || '';
        this.releaseDate = entity && entity.releaseDate || null;
        this.releaseUser = entity && entity.releaseUser || null;
        this.liveDate = entity && entity.liveDate || null;
        this.author = entity && (entity.author || entity.author === 0) ? entity.author : null;
        this.contributingAuthor = entity && entity.contributingAuthor || null;
        this.unitName = entity && entity.unitName || null;
        this.projectTivCurrency = entity && entity.projectTivCurrency || null;
        this.projectRecordStatus = entity && entity.projectRecordStatus && String(entity.projectRecordStatus) || null;
        this.projectStatusReason = entity && entity.projectStatusReason || null;
        this.amendmentDate = entity && entity.amendmentDate || null;
        this.amendmentUser = entity && entity.amendmentUser || null;
        this.tivQcDate = entity && entity.tivQcDate || null;
        this.scopeQcDate = entity && entity.scopeQcDate || null;
        this.scheduleQcDate = entity && entity.scheduleQcDate || null;
        this.tivQcUser = entity && entity.tivQcUser || null;
        this.scopeQcUser = entity && entity.scopeQcUser || null;
        this.scheduleQcUser = entity && entity.scheduleQcUser || null;
        this.projectFuelType = entity && entity.projectFuelType || null;
        this.projectType = entity && entity.projectType && String(entity.projectType) || null;
        this.initiallyUnconfirmed = entity && entity.initiallyUnconfirmed || null;
        this.confirmedDate = entity && entity.confirmedDate || null;
        this.restart = entity && entity.restart || 0;
        this.restartDate = entity && entity.restartDate || null;
        this.projectSource = entity && entity.projectSource && String(entity.projectSource) || null;

        this.physCountryId = entity && entity.physCountryId || null;
        this.physStateId = entity && entity.physStateId || null;
        this.physCityId = entity && entity.physCityId || null;
        this.physCountyId = entity && entity.physCountyId || null;

        this.estimatedKickoffDate = entity && entity.estimatedKickoffDate || null;
        this.peakWorkers = (entity && entity.peakWorkers) ?? null;
        this.peakWorkersDate = entity && entity.peakWorkersDate || null;
        this.projectProbabilityId = entity && entity.projectProbabilityId || null;
        this.shiftsDay = entity && entity.shiftsDay || null;
        this.shiftLength = entity && entity.shiftLength || null;
    }
}
