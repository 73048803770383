import { IPlantProduct } from 'src/app/core/interfaces/iplant-product';
import { PlantsProductInfo } from '../save/plants-product-info';

export class PlantsProductInfoDisplay extends PlantsProductInfo implements IPlantProduct{
    productDesc: string;
    hsUnit: string;

    protected constructor(entity: any) {
        super(entity);

        this.productDesc = entity && entity.productDesc || null;
        this.hsUnit = entity && entity.hsUnit || null;
        this.initialData = entity;
    }
    set plantProductId(value: string) {
        this.hsProduct = value;
    }

    get plantProductId(): string {
        return this.hsProduct;
    }

    static CreateInstance(entity: any): PlantsProductInfoDisplay {
        return new PlantsProductInfoDisplay(entity);
    }

    static BuildFromList(entity: Array<any>): Array<PlantsProductInfoDisplay> {
        return entity.map(item => PlantsProductInfoDisplay.CreateInstance(item));
    }

}
