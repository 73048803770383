import { Bean } from "../../bean";

export class PlantsProductInfo extends Bean {
    mvId: number;
    plantId: number;
    hsProduct: string;
    productStatus: string;
    productType: string;
    mvOrder: number;
    intdatadepamendstate: string;
    isInsert: boolean;
    lastDate: string;

    protected constructor(entity?: any) {
        super(entity);

        this.mvId = entity && entity.mvId || null;
        this.plantId = entity && entity.plantId || null;
        this.hsProduct = entity && entity.hsProduct || null;
        this.productStatus = entity && entity.productStatus || null;
        this.productType = entity && entity.productType || null;
        this.mvOrder = entity && entity.mvOrder || null;
        this.intdatadepamendstate = entity && entity.intdatadepamendstate || null;
        this.isInsert = entity && entity.isInsert || null;
        this.lastDate = entity && entity.lastDate || null;
    }

}
