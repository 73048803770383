import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/core/services/shared.service';
import { RelatedInfoBase } from '../related-info.base';
import { ColumnEntityInfo, ColumnEntityInfoEditableType } from 'src/app/shared/components/base/mvt-entity-associator/mvt-entity-associator.component';
import { EntityName } from 'src/app/core/models/enumerations/entity-name';

@Component({
    selector: 'app-unit',
    templateUrl: './unit.component.html',
    styleUrls: ['./unit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RelatedInfoUnitComponent extends RelatedInfoBase implements OnInit {
    entityTableKey =EntityName.UNIT;
    constructor(
        public sharedService: SharedService) {
        super(sharedService);
    }

    ngOnInit(): void {
        this.initBase();
        if(this.callerIsPlant || this.callerIsArea) {
            this.addRecordDestinationPage = 'unit';
        }
    }

    getColumnsInfo(): ColumnEntityInfo[] {
        let columnsInfo: ColumnEntityInfo[] = [];
        columnsInfo.push({ entityPropName: 'unitId', columnHeader: 'relatedInfo.unit.unitId', routerEntityName: EntityName.UNIT, widthPercentage: 10 });
        columnsInfo.push({ entityPropName: 'unitName', columnHeader: 'relatedInfo.unit.unitName', widthPercentage: 10 });
        if(this.callerIsOEquipment || this.callerIsMiningEquipment){
            columnsInfo.push({ entityPropName: 'unitStatusId', columnHeader: 'relatedInfo.unit.unitStatus', widthPercentage: 10 });
        }else{
            columnsInfo.push({ entityPropName: 'unitStatus', columnHeader: 'relatedInfo.unit.unitStatus', widthPercentage: 10 });
        }
        columnsInfo.push({ entityPropName: 'unitTypeName', columnHeader: 'relatedInfo.unit.unitTypeName', widthPercentage: 15});
        if(!this.callerIsArea) {
            columnsInfo.push({ entityPropName: 'areaName', columnHeader: 'relatedInfo.unit.areaName', widthPercentage: 15});
        }
        columnsInfo.push({ entityPropName: 'kickoffDate', columnHeader: 'relatedInfo.unit.kickoffDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 10});
        columnsInfo.push({ entityPropName: 'startupDate', columnHeader: 'relatedInfo.unit.startupDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 10});
        columnsInfo.push({ entityPropName: 'qcDate', columnHeader: 'relatedInfo.common.qcDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 10});
        columnsInfo.push({ entityPropName: 'releaseDate', columnHeader: 'relatedInfo.common.releaseDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 10});
        if(this.callerIsArea) {
            columnsInfo.push(this.createDuplicateColumn('unitId', EntityName.UNIT, 5));
        }

        return columnsInfo;
    }

}
