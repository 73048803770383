import { AssetsPipesConnectionsDisplay } from "./asset/pipeline/display/assets-pipes-connections-display";
import { AssetsTLinesConnectionsDisplay } from "./asset/transmissionline/display/assets-tlines-connections-display";
import { Bean } from "./bean";
import { Evergreen } from "./constants";
import { AssetsType } from "./enumerations/assets-type";
import { EntityName } from "./enumerations/entity-name";

export class CompanyStatus {
    public StatusDescription: string;
    public StatusID: string;

    private constructor(
        statusDescription: string,
        statusID: string
    ) {
        this.StatusDescription = statusDescription;
        this.StatusID = statusID;
    }

    static BuildCompanyStatus(entity: Array<any>): Array<CompanyStatus> {
        return entity.map(item => CompanyStatus.CreateInstance(item));
    }

    private static CreateInstance(status: any): CompanyStatus {
        return new CompanyStatus(`${status.STATUS_ID} - ${status.STATUS_DESC}`, status.STATUS_ID);
    }

}

export class Currency {
    code: string;
    description: string;
    counter: number;

    private constructor(
        code: string,
        description: string,
        counter: number
    ) {
        this.code = code;
        this.description = description;
        this.counter = counter;
    }

    static BuildCurrency(entity: Array<any>): Array<Currency> {
        return entity.map(item => Currency.CreateInstance(item));
    }

    private static CreateInstance(record: any): Currency {
        return new Currency(record.CURRENCY_CODE,
            `${record.CURRENCY_CODE} - ${record.CURRENCY_DESC}`,
            Number(record.COUNTER_CURRENCY));
    }
}

export class DateResolution {
    id: string;
    letterId: string;
    description: string;

    constructor(id: string, letterId: string, desc: string) {
        this.id = id;
        this.letterId = letterId;
        this.description = desc;
    }

    static BuildDateResolution(entity: Array<any>): Array<DateResolution> {
        return entity.map(item => DateResolution.CreateInstance(item));
    }

    private static CreateInstance(record: any): DateResolution {
        const firstCharacter = record.RES_DESC.charAt(0);
        return new DateResolution(record.RES_ID, firstCharacter, `${firstCharacter} - ${record.RES_DESC}`);
    }
}

export class ProductStatus {
    StatusId: string;
    StatusDesc: string;

    private constructor(
        statusDescription: string,
        statusID: string
    ) {
        this.StatusId = statusID;
        this.StatusDesc = statusDescription;
    }

    private static CreateInstance(status: any): ProductStatus {
        return new ProductStatus(`${status.STATUS_ID} - ${status.STATUS_DESC}`, status.STATUS_ID);
    }

    static BuildProductStatus(entity: Array<any>): Array<ProductStatus> {
        return entity.map(item => ProductStatus.CreateInstance(item));
    }
}
export class ProductType {
    typeId: string;
    typeDesc: string;

    private constructor(
        typeDescription: string,
        typeID: string
    ) {
        this.typeId = typeID;
        this.typeDesc = typeDescription;
    }

    private static CreateInstance(status: any): ProductType {
        return new ProductType(`${status.TYPE_ID} - ${status.TYPE_DESC}`, status.TYPE_ID);
    }

    static BuildProductType(entity: Array<any>): Array<ProductType> {
        return entity.map(item => ProductType.CreateInstance(item));
    }

    
}

export class ControlArea {
    ControlAreaId: string;
    ControlAreaDesc: string;

    private constructor(
        controlAreaDescription: string,
        controlAreaID: string
    ) {
        this.ControlAreaId = controlAreaID;
        this.ControlAreaDesc = controlAreaDescription;
    }

    private static CreateInstance(controlArea: any): ControlArea {
        return new ControlArea(`${controlArea.CONTROL_AREA_ID} - ${controlArea.CONTROL_AREA_DESC}`, controlArea.CONTROL_AREA_ID);
    }

    static BuildControlArea(entity: Array<any>): Array<ControlArea> {
        return entity.map(item => ControlArea.CreateInstance(item));
    }
}

export class KvRating {
    KvRatingId: string;
    KvRatingDesc: string;

    private constructor(
        kvRatingDescription: string,
        kvRatingID: string
    ) {
        this.KvRatingId = kvRatingID;
        this.KvRatingDesc = kvRatingDescription;
    }

    private static CreateInstance(kvRating: any): KvRating {
        return new KvRating(kvRating.KV_RATING_DESC, kvRating.KV_RATING_ID);
    }

    static BuildKvRating(entity: Array<any>): Array<KvRating> {
        return entity.map(item => KvRating.CreateInstance(item));
    }
}

export class NercRegion {
    NercRegionId: string;
    NercRegionDesc: string;

    private constructor(
        nercRegionDescription: string,
        nercRegionID: string
    ) {
        this.NercRegionId = nercRegionID;
        this.NercRegionDesc = nercRegionDescription;
    }

    private static CreateInstance(nercRegion: any): NercRegion {
        return new NercRegion(`${nercRegion.NERC_REGIONS_ID} - ${nercRegion.NERC_REGION_NAME}`, nercRegion.NERC_REGIONS_ID);
    }

    static BuildNercRegion(entity: Array<any>): Array<NercRegion> {
        return entity.map(item => NercRegion.CreateInstance(item));
    }
}

export class NercSubRegion {
    NercSubRegionId: string;
    NercSubRegionDesc: string;
    NercRegion: string
    Inactive: boolean;

    private constructor(
        nercSubRegionDescription: string,
        nercRegion: string,
        nercSubRegionID: string
    ) {
        this.NercRegion = nercRegion;
        this.NercSubRegionId = nercSubRegionID;
        this.NercSubRegionDesc = nercSubRegionDescription;
        this.Inactive = true;
    }

    private static CreateInstance(nercSubRegion: any): NercSubRegion {
        const ids = nercSubRegion.NERC_SUBREGIONS_ID?.split('*');
        return new NercSubRegion(`${ids[1]} - ${nercSubRegion.SUBREGION_NAME}`, ids[0], ids[1]);
    }

    static BuildNercSubRegion(entity: Array<any>): Array<NercSubRegion> {
        return entity.map(item => NercSubRegion.CreateInstance(item));
    }
}

export class OilGasBasin {
    OilGasBasinId: string;
    OilGasBasinDesc: string;

    private constructor(
        basinID: string, basinDescription: string
    ) {
        this.OilGasBasinId = basinID;
        this.OilGasBasinDesc = basinDescription;
    }

    private static CreateInstance(oilGasBasin: any): OilGasBasin {
        return new OilGasBasin(oilGasBasin.OIL_GAS_BASIN_ID, oilGasBasin.OIL_GAS_BASIN_DESC);
    }

    static BuildOilGasBasins(entity: Array<any>): Array<OilGasBasin> {
        return entity.map(item => OilGasBasin.CreateInstance(item));
    }
}

export class ShalePlay {
    ShalePlayId: string;
    ShalePlayDesc: string;

    private constructor(
        shalePlayID: string,
        shalePlayDescription: string
    ) {
        this.ShalePlayId = shalePlayID;
        this.ShalePlayDesc = shalePlayDescription;
    }

    private static CreateInstance(shalePlay: any): ShalePlay {
        return new ShalePlay(shalePlay.SHALE_PLAY_ID, shalePlay.SHALE_PLAY);
    }

    static BuildShalePlays(entity: Array<any>): Array<ShalePlay> {
        return entity.map(item => ShalePlay.CreateInstance(item));
    }
}

export class RecordStatus {
    public RecordStatusDescription: string;
    public RecordStatusID: string;
    public inactive: boolean;

    private constructor(
        recordStatusDescription: string,
        recordStatusID: string
    ) {
        this.RecordStatusDescription = recordStatusDescription;
        this.RecordStatusID = recordStatusID;
    }

    static BuildRecordStatus(entity: Array<any>): Array<RecordStatus> {
        return entity.map(item => RecordStatus.CreateInstance(item));
    }

    private static CreateInstance(record: any): RecordStatus {
        return new RecordStatus(`${record.RECORD_STATUS_ID || record.STATUS_ID} - ${record.STATUS_DESC}`, record.RECORD_STATUS_ID || record.STATUS_ID);
    }
}

export class FirmType {
    FirmTypeID: string;
    FirmTypeDesc: string;
    Description: string;
    vendor: number;

    private constructor(
        firmTypeID: string,
        firmTypeDesc: string,
        description: string,
        vendor:number
    ) {
        this.FirmTypeID = firmTypeID;
        this.FirmTypeDesc = firmTypeDesc;
        this.Description = description;
        this.vendor = vendor;
    }

    static BuildFirmTypes(entity: Array<any>): Array<FirmType> {
        return entity.map(item => FirmType.CreateInstance(item));
    }

    static CreateInstance(record: any): FirmType {
        return new FirmType(record.FIRM_TYPES_ID, record.FIRM_TYPES_DESC, `${record.FIRM_TYPES_ID} - ${record.FIRM_TYPES_DESC}`, record.VENDOR);
    }

}

export class PlantStatus {
    StatusID: string;
    Description: string;

    private constructor(
        statusID: string,
        description: string
    ) {
        this.StatusID = statusID;
        this.Description = description;
    }

    static BuildPlantStatus(entity: Array<any>): Array<PlantStatus> {
        return entity.map(item => PlantStatus.CreateInstance(item));
    }

    private static CreateInstance(record: any): PlantStatus {
        return new PlantStatus(record.PLANT_STATUS_ID, `${record.PLANT_STATUS_ID} - ${record.STATUS_DESC}`);
    }
}

export class LTSAStatus {
    StatusID: string;
    Description: string;

    private constructor(
        statusID: string,
        description: string
    ) {
        this.StatusID = statusID;
        this.Description = description;
    }

    static BuildLTSAStatus(entity: Array<any>): Array<LTSAStatus> {
        return entity.map(item => LTSAStatus.CreateInstance(item));
    }

    private static CreateInstance(record: any): LTSAStatus {
        return new LTSAStatus(record.LTSA_STATUS_ID, `${record.LTSA_STATUS_ID} - ${record.LTSA_STATUS_DESC}`);
    }
}

export class LTSATypes {
    id: string;
    description: string;

    private constructor(
        id: string,
        description: string
    ) {
        this.id = id;
        this.description = description;
    }

    static BuildLTSATypes(entity: Array<any>): Array<LTSATypes> {
        return entity.map(item => LTSATypes.CreateInstance(item));
    }

    private static CreateInstance(record: any): LTSATypes {
        return new LTSATypes(record.UNIT_LTSA_TYPE_ID, `${record.UNIT_LTSA_TYPE_ID} - ${record.UNIT_LTSA_TYPE_DESC}`);
    }
}

export class JobFunction {
    private functionId: number;
    private functionDesc: string;
    private functionConcat: string;

    private constructor(
        functionId: number, functionDesc: string
    ) {
        this.functionId = functionId;
        this.functionDesc = functionDesc;
        this.functionConcat = functionDesc;
        if (functionId != null) {
            this.functionConcat = this.functionId + ' - ' + this.functionDesc;
        }
    }

    static BuildJobFunctions(entity: Array<any>): Array<JobFunction> {
        return entity.map(item => JobFunction.CreateInstance(item));
    }

    private static CreateInstance(record: any): JobFunction {
        return new JobFunction(record.FUNCTION_ID, record.FUNCTION_DESC);
    }
}

export class FunctionalTitle {
    public titleId: string;
    public rank: number;
    public titleDesc: string;

    private constructor(
        titleId: string,
        rank: number,
        titleDesc: string
    ) {
        this.titleId = titleId;
        this.rank = rank;
        this.titleDesc = titleDesc;
    }

    static BuildFunctionalTitles(entity: Array<any>): Array<FunctionalTitle> {
        let functionalTitles = entity.map(item => FunctionalTitle.CreateInstance(item));
        functionalTitles.sort((a, b) => a.rank - b.rank);
        return functionalTitles;
    }

    private static CreateInstance(record: any): FunctionalTitle {
        return new FunctionalTitle(String(record.TITLE_ID), record.RANK, record.TITLE_DESC);
    }
}

export class CompanyClasses {
    public ClassificationDescription: string;
    public ClassificationID: string;
    public inactive: boolean;

    private constructor(
        classificationDescription: string,
        classificationID: string
    ) {
        this.ClassificationDescription = classificationDescription;
        this.ClassificationID = classificationID;
    }

    static BuildCompanyClasses(entity: Array<any>): Array<CompanyClasses> {
        return entity.map(item => CompanyClasses.CreateInstance(item));
    }

    private static CreateInstance(companyClasses: any): CompanyClasses {
        return new CompanyClasses(`${companyClasses.CLASSIFICATION_ID} - ${companyClasses.CLASSIFICATION_DESC}`, companyClasses.CLASSIFICATION_ID);
    }
}

export class UnitTypes {

    constructor(
        public unitTypeId: string,
        public unitTypeDesc: string
    ) { }

    static BuildUnitTypes(entity: Array<any>): Array<UnitTypes> {
        return entity.map(item => UnitTypes.CreateInstance(item));
    }

    private static CreateInstance(unitTypes: any): UnitTypes {
        const { UNIT_TYPES_ID, TYPE_NAME } = unitTypes;
        return new UnitTypes(UNIT_TYPES_ID, `${UNIT_TYPES_ID} - ${TYPE_NAME}`);
    }

}

export class UnitCapacity {

    constructor(
        public capacity: string,
        public hsProduct: string,
        public uom: string,
        public outageCapacity: string
    ) { }

    static BuildUnitCapacity(entity: Array<any>): Array<UnitCapacity> {
        return entity.map(item => UnitCapacity.CreateInstance(item));
    }

    private static CreateInstance(unitCapacity: any): UnitCapacity {
        const { CAPACITY, HS_PRODUCT, UOM, OUTAGE_CAPACITY } = unitCapacity;
        return new UnitCapacity(CAPACITY, HS_PRODUCT,UOM, OUTAGE_CAPACITY);
    }

}

export class StockChanges {
    private StockChangeDescription: string;
    private StockChangeID: string;
    private StockChangeSymbol: string;
    private DisplayOrder: number;

    private constructor(
        stockChangeDescription: string,
        stockChangeID: string,
        stockChangeSymbol: string,
        displayOrder: number
    ) {
        this.StockChangeDescription = stockChangeDescription;
        this.StockChangeID = stockChangeID;
        this.StockChangeSymbol = stockChangeSymbol;
        this.DisplayOrder = displayOrder;
    }

    static BuildStockChange(entity: Array<any>): Array<StockChanges> {
        return entity.map(item => StockChanges.CreateInstance(item));
    }

    private static CreateInstance(stock: any): StockChanges {
        return new StockChanges(`${stock.STOCK_EXCHANGE_SYMBOL} - ${stock.STOCK_EXCHANGE_DESC}`,
            stock.STOCK_EXCHANGE_ID,
            stock.STOCK_EXCHANGE_SYMBOL,
            stock.DISPLAY_ORDER);
    }
}

export class Industry {

    constructor(
        public industryCode: string,
        public industryDescription: string
    ) { }

    static BuildIndustry(entity: Array<any>): Array<Industry> {
        return entity.map(item => Industry.CreateInstance(item));
    }

    private static CreateInstance(industry: any): Industry {
        const { INDUSTRY_CODE, INDUSTRY_DESC } = industry;
        return new Industry(INDUSTRY_CODE, `${INDUSTRY_CODE} - ${INDUSTRY_DESC}`);
    }

}

export class ReleaseIndustry {

    constructor(
        public industryCode: string,
        public industryDescription: string,
        public selected: boolean
    ) { }

    static BuildEntity(entities: Array<Industry>): Array<ReleaseIndustry> {
        return entities.map(item => ReleaseIndustry.CreateInstance(item));
    }

    private static CreateInstance(industry: Industry): ReleaseIndustry {
        return new ReleaseIndustry(industry.industryCode, industry.industryDescription, false);
    }

}

export class SicCode {
    public sicCode: string;
    public sicDescription: string;
    public sicCodeDescription: string;

    constructor(
        sicCode: string,
        sicDescription: string,
        sicCodeDescription: string
    ) {
        this.sicCode = sicCode;
        this.sicDescription = sicDescription
        this.sicCodeDescription = sicCodeDescription
    }

    static BuildSicCode(entity: Array<any>): Array<SicCode> {
        return entity.map(item => SicCode.CreateInstance(item));
    }

    private static CreateInstance(sic: any): SicCode {
        const { SIC_CODE, SIC_CODE_ID, SIC_CODE_DESC, SIC_CODE_NAME } = sic;
        return new SicCode(SIC_CODE_ID || SIC_CODE, `${SIC_CODE_DESC || SIC_CODE_NAME}`,
            `${SIC_CODE_ID || SIC_CODE} - ${SIC_CODE_DESC || SIC_CODE_NAME}`);
    }

}

export class SicProduct {
    public sicProduct: string;
    public sicDescription: string;
    public sicProductDescription: string;

    constructor(
        sicProduct: string,
        sicDescription: string,
        sicProductDescription: string
    ) {
        this.sicProduct = sicProduct;
        this.sicDescription = sicDescription;
        this.sicProductDescription = sicProductDescription;
    }

    static BuildSicProduct(entity: Array<any>): Array<SicProduct> {
        return entity.map(item => SicProduct.CreateInstance(item));
    }

    private static CreateInstance(sic: any): SicProduct {
        const { SIC_PRODUCT_ID, PRODUCT_DESC } = sic;
        return new SicProduct(SIC_PRODUCT_ID, `${PRODUCT_DESC}`,
            `${SIC_PRODUCT_ID} - ${PRODUCT_DESC}`);
    }

}

export class EmailStatus {
    public status: string;
    public overrideStatus: string;
    public overridePerson: string;
    public overrideDate: string;

    constructor(
        status: string,
        overrideStatus: string,
        overridePerson: string,
        overrideDate: string
    ) {
        this.status = status;
        this.overrideStatus = overrideStatus;
        this.overridePerson = overridePerson;
        this.overrideDate = overrideDate;
    }

    static BuildEmailStatus(entity: Array<any>): EmailStatus {
        if (entity && entity.length > 0) {
            return EmailStatus.CreateInstance(entity[0]);
        }
        return null;
    }

    private static CreateInstance(resp: any): EmailStatus {
        const { EMAIL_STATUS, EMAIL_OVERRIDE_STATUS, OVERRIDE_PERSON, OVERRIDE_SET_DATE } = resp;
        return new EmailStatus(EMAIL_STATUS, EMAIL_OVERRIDE_STATUS, OVERRIDE_PERSON, OVERRIDE_SET_DATE);
    }
}

export class Country {
    public cId: number;
    public countryId: string;
    public countryName: string;
    public telephoneCC: number;
    public countryNameConcat: string;

    constructor(
        cId: number,
        countryId: string,
        countryName: string,
        telephoneCC: number,
        countryNameConcat: string
    ) {
        this.cId = cId;
        this.countryId = countryId;
        this.countryName = countryName;
        this.telephoneCC = telephoneCC;
        this.countryNameConcat = countryNameConcat;
    }

    static BuildCountry(entity: Array<any>): Array<Country> {
        return entity.map(item => Country.CreateInstance(item));
    }

    private static CreateInstance(country: any): Country {
        const { C_ID, COUNTRY_ID, COUNTRY_NAME, TELEPHONE_CC } = country;
        return new Country(C_ID, COUNTRY_ID, COUNTRY_NAME, TELEPHONE_CC, `${COUNTRY_ID} - ${COUNTRY_NAME}`);
    }

}

export class State {
    private rn: number;
    public sId: string;
    public stateName: string;
    private stateSubDivision: string;
    private stateType: string;
    private fipsCode: string;
    private marketRegion: string;
    private paddRegion: string;
    private gasgenRegion: string;
    public stateAbbrev: string;
    private stateConcat: string;

    constructor(
        rn: number,
        sId: string,
        stateName: string,
        stateSubDivision: string,
        stateType: string,
        fipsCode: string,
        marketRegion: string,
        paddRegion: string,
        gasgenRegion: string,
        stateAbbrev: string,
        stateConcat: string
    ) {
        this.rn = rn;
        this.sId = sId;
        this.stateName = stateName;
        this.stateSubDivision = stateSubDivision;
        this.stateType = stateType;
        this.fipsCode = fipsCode;
        this.marketRegion = marketRegion;
        this.paddRegion = paddRegion;
        this.gasgenRegion = gasgenRegion;
        this.stateAbbrev = stateAbbrev;
        this.stateConcat = stateConcat;
    }

    static BuildState(entity: Array<any>): Array<State> {
        return entity.map(item => State.CreateInstance(item));
    }

    private static CreateInstance(state: any): State {
        const { RN, S_ID, STATE_NAME, STATE_SUBDIVISION,
            STATE_ABBREV, STATE_TYPE, FIPS_CODE,
            MARKET_REGION, PADD_REGION, GASGEN_REGION } = state;
        return new State(RN,
            S_ID,
            STATE_NAME,
            STATE_SUBDIVISION,
            STATE_TYPE,
            FIPS_CODE,
            MARKET_REGION,
            PADD_REGION,
            GASGEN_REGION,
            STATE_ABBREV,
            `${STATE_ABBREV} - ${STATE_NAME}`);
    }

}

export class City {
    private rn: number;
    public ciId: number;
    public cityName: string;
    public latitude: string;
    public longitude: string;
    public stateAbbrev: string;
    public stateName: string;

    constructor(
        rn: number,
        ciId: number,
        cityName: string,
        latitude: string,
        longitude: string,
        stateAbbrev: string,
        stateName: string
    ) {
        this.rn = rn;
        this.ciId = ciId;
        this.cityName = cityName;
        this.latitude = latitude;
        this.longitude = longitude;
        this.stateAbbrev = stateAbbrev;
        this.stateName = stateName;
    }

    static BuildCity(entity: Array<any>): Array<City> {
        return entity.map(item => City.CreateInstance(item));
    }

    private static CreateInstance(city: any): City {
        const { RN, CI_ID, CITY_NAME, LATITUDE,
            LONGITUDE, STATE_ABBREV, STATE_NAME } = city;
        return new City(RN,
            CI_ID,
            CITY_NAME,
            LATITUDE,
            LONGITUDE,
            STATE_ABBREV,
            STATE_NAME);
    }

}

export class CitiesWithMessage {
    public cities: City[];
    public message: string;
    public exception: string;

    constructor(
        cities: City[],
        message: string,
        exception: string
    ) {
        this.cities = cities;
        this.message = message;
        this.exception = exception;
    }
}

export class County {
    private city: City;
    public coId: number;
    public countyName: string;

    constructor(
        city: City,
        coId: number,
        countyName: string
    ) {
        this.city = city;
        this.coId = coId;
        this.countyName = countyName;
    }

    static BuildCounty(entity: Array<any>): Array<County> {
        return entity.map(item => County.CreateInstance(item));
    }

    private static CreateInstance(county: any): County {
        const { CO_ID, COUNTY_NAME } = county;
        return new County(undefined, CO_ID, COUNTY_NAME);
    }

}

export class PecZone {
    public pecZoneId: string;
    public pecZoneName: string;

    constructor(
        pecZoneId: string,
        pecZoneName: string
    ) {
        this.pecZoneId = pecZoneId;
        this.pecZoneName = pecZoneName;
    }

    static BuildPecZone(entity: Array<any>): Array<PecZone> {
        return entity.map(item => PecZone.CreateInstance(item));
    }

    private static CreateInstance(pecZone: any): PecZone {
        const { PEC_ZONES_ID, PEC_ZONE_NAME } = pecZone;
        return new PecZone(PEC_ZONES_ID, PEC_ZONE_NAME);
    }

}

export class TransmissionLine {
    private AssetID: number;
    private AssetName: string;
    private Owner: string;
    private TLineStatus: string;
    private TLineStatusDesc: string;
    private KVRating: number;
    private KVRatingDesc: string;
    private SystemType: string;
    private QCDate: number;
    private ReleaseDate: number;
    private SystemTypeDesc: string;

    private constructor(
        assetID: number,
        assetName: string,
        owner: string,
        tLineStatus: string,
        tLineStatusDesc: string,
        kvRating: number,
        kvRatingDesc: string,
        systemType: string,
        qcDate: number,
        releaseDate: number,
        systemTypeDesc: string
    ) {
        this.AssetID = assetID;
        this.AssetName = assetName;
        this.KVRating = kvRating;
        this.KVRatingDesc = kvRatingDesc;
        this.Owner = owner;
        this.TLineStatus = tLineStatus;
        this.TLineStatusDesc = tLineStatusDesc;
        this.SystemType = systemType;
        this.QCDate = qcDate;
        this.ReleaseDate = releaseDate;
        this.SystemTypeDesc = systemTypeDesc;
    }

    static BuildTransmissionLine(entity: Array<any>): Array<TransmissionLine> {
        return entity.map(item => TransmissionLine.CreateInstance(item));
    }

    private static CreateInstance(tLine: any): TransmissionLine {
        const {
            ASSET_ID,
            ASSET_NAME,
            OWNER_DESC,
            TLINE_STATUS,
            SYSTEM_STATUS_DESC,
            KV_RATING,
            KV_RATING_DESC,
            SYSTEM_TYPE,
            QC_DATE,
            RELEASE_DATE,
            SYSTEM_TYPE_DESC
        } = tLine;

        return new TransmissionLine(
            ASSET_ID,
            ASSET_NAME,
            OWNER_DESC,
            TLINE_STATUS,
            SYSTEM_STATUS_DESC,
            KV_RATING,
            KV_RATING_DESC,
            SYSTEM_TYPE,
            QC_DATE,
            RELEASE_DATE,
            SYSTEM_TYPE_DESC
        );
    }
}

export class Site {
    private SiteID: number;
    private SiteName: string;
    private State: string;
    private Country: string;
    private QcDate: number;
    private ReleaseDate: number;

    private constructor(
        siteID: number,
        siteName: string,
        state: string,
        country: string,
        qcDate: number,
        releaseDate: number
    ) {
        this.SiteID = siteID;
        this.SiteName = siteName;
        this.State = state;
        this.Country = country;
        this.QcDate = qcDate;
        this.ReleaseDate = releaseDate;
    }

    static CreateInstance(site: any): Site {
        const {
            SITE_ID,
            SITE_NAME,
            STATE_NAME,
            COUNTRY_NAME,
            QC_DATE,
            RELEASE_DATE
        } = site;

        return new Site(
            SITE_ID,
            SITE_NAME,
            STATE_NAME,
            COUNTRY_NAME,
            QC_DATE,
            RELEASE_DATE
        );
    }

    static BuildSite(entity: Array<any>): Array<Site> {
        return entity.map(item => Site.CreateInstance(item));
    }
}

export class Pipeline {
    private AssetID: number;
    private AssetName: string;
    private Status: string;
    private Type: string;
    private StartUpDate: number;
    private CompDate: number;
    private QcDate: number;
    private CAttachment: number;
    private ReleaseDate: number;
    private Association: string;

    constructor(
        assetID: number,
        assetName: string,
        status: string,
        type: string,
        startUpDate: number,
        compDate: number,
        qcDate: number,
        cAttachment: number,
        releaseDate: number,
        association: string
    ) {
        this.AssetID = assetID;
        this.AssetName = assetName;
        this.Status = status;
        this.Type = type;
        this.StartUpDate = startUpDate;
        this.CompDate = compDate;
        this.QcDate = qcDate;
        this.ReleaseDate = releaseDate;
        this.CAttachment = cAttachment;
        this.Association = association;
    }

    get lateralAssetId(): number {
        return this.AssetID;
    }

    get lateralAssetName(): string {
        return this.AssetName;
    }

    get lateralName(): string {
        return `${this.AssetID} - ${this.AssetName} - ${this.Association}`;
    }

    get lateralType(): string {
        return this.Type;
    }

    static BuildPipeline(entity: Array<any>): Array<Pipeline> {
        return entity.map(item => Pipeline.CreateInstance(item));
    }

    private static CreateInstance(pipeline: any): Pipeline {
        const {
            ASSET_ID,
            ASSET_NAME,
            STATUS_DESC,
            PIPELINE_TYPE,
            STARTUP_DATE,
            COMPLETION_DATE,
            QC_DATE,
            COUNT_ATTACH,
            RELEASE_DATE,
            ASSOCIATION
        } = pipeline;

        return new Pipeline(
            ASSET_ID,
            ASSET_NAME,
            STATUS_DESC,
            PIPELINE_TYPE,
            STARTUP_DATE,
            COMPLETION_DATE,
            QC_DATE,
            COUNT_ATTACH,
            RELEASE_DATE,
            ASSOCIATION
        );
    }
}

export class PipelineLtsa {
    private LtsaID: number;
    private CompanyName: string;
    private ContractLength: string;
    private Status: string;
    private ExpirationDate: number;
    private QcDate: number;
    private ReleaseDate: number;

    constructor(
        ltsaId: number,
        companyName: string,
        contractLength: string,
        status: string,
        expirationDate: number,
        qcDate: number,
        releaseDate: number
    ) {
        this.LtsaID = ltsaId;
        this.CompanyName = companyName;
        this.ContractLength = contractLength;
        this.Status = status;
        this.ExpirationDate = expirationDate;
        this.QcDate = qcDate;
        this.ReleaseDate = releaseDate;
    }

    static BuildPipelineLtsa(entity: Array<any>): Array<PipelineLtsa> {
        return entity.map(item => PipelineLtsa.CreateInstance(item));
    }

    private static CreateInstance(pipelineLtsa: any): PipelineLtsa {
        const {
            PIPE_LTSA_ID,
            COMPANY_NAME,
            CONTRACT_LENGTH,
            LTSA_STATUS_DESC,
            CONTRACT_EXPIRATION,
            QC_DATE,
            RELEASE_DATE
        } = pipelineLtsa;

        return new PipelineLtsa(
            PIPE_LTSA_ID,
            COMPANY_NAME,
            CONTRACT_LENGTH === Evergreen.EvergreenCode ? Evergreen.EvergreenLetter : CONTRACT_LENGTH,
            LTSA_STATUS_DESC,
            CONTRACT_EXPIRATION,
            QC_DATE,
            RELEASE_DATE
        );
    }
}

export class News {
    Id: number;
    CategoryName: string;
    ArticleSubject: string;
    ReleaseDate: number;

    constructor(
        id: number,
        categoryName: string,
        articleSubject: string,
        releaseDate: number
    ) {
        this.Id = id;
        this.CategoryName = categoryName;
        this.ArticleSubject = articleSubject;
        this.ReleaseDate = releaseDate;
    }

    static BuildNew(entity: Array<any>): Array<News> {
        return entity.map(item => News.CreateInstance(item));
    }

    private static CreateInstance(news: any): News {
        const {
            ID,
            CAT_NAME,
            ARTICLESUBJECT,
            RELEASE_DATE
        } = news;

        return new News(
            ID,
            CAT_NAME,
            ARTICLESUBJECT,
            RELEASE_DATE
        );
    }
}

export class Areas {
    private AreaId: number;
    private AreaName: string;
    private AreaStatus: string;
    private State: string;
    private Country: string;
    private QcDate: number;
    private ReleaseDate: number;

    constructor(
        areaId: number,
        areaName: string,
        areaStatus: string,
        state: string,
        country: string,
        qcDate: number,
        releaseDate: number
    ) {
        this.AreaId = areaId;
        this.AreaName = areaName;
        this.AreaStatus = areaStatus;
        this.State = state;
        this.Country = country;
        this.QcDate = qcDate;
        this.ReleaseDate = releaseDate;
    }

    get areaId():number{
        return this.AreaId;
    }

    static BuildAreas(entity: Array<any>): Array<Areas> {
        return entity.map(item => Areas.CreateInstance(item));
    }

    private static CreateInstance(areas: any): Areas {
        const {
            AREA_ID,
            AREA_NAME,
            AREA_STATUS,
            PHYS_STATE,
            PHYS_COUNTRY,
            QC_DATE,
            RELEASE_DATE,
            COUNTRY_NAME,
            STATE_NAME
        } = areas;

        return new Areas(
            AREA_ID,
            AREA_NAME,
            AREA_STATUS,
            PHYS_STATE || STATE_NAME,
            PHYS_COUNTRY || COUNTRY_NAME,
            QC_DATE,
            RELEASE_DATE
        );
    }
}

export class Units {
    unitId: number;
    unitName: string;
    unitStatus: string;
    unitStatusId: string;
    unitTypeName: string;
    areaName: string;
    kickoffDate: number;
    startupDate: number;
    qcDate: number;
    releaseDate: number;
    selected: boolean;

    constructor(
        unitId: number,
        unitName: string,
        unitStatus: string,
        unitStatusId: string,
        unitTypeName: string,
        areaName: string,
        kickoffDate: number,
        startupDate: number,
        qcDate: number,
        releaseDate: number
    ) {
        this.unitId = unitId;
        this.unitName = unitName;
        this.unitStatus = unitStatus;
        this.unitStatusId = unitStatusId;
        this.unitTypeName = unitTypeName;
        this.areaName = areaName;
        this.kickoffDate = kickoffDate;
        this.startupDate = startupDate;
        this.qcDate = qcDate;
        this.releaseDate = releaseDate;
        this.selected = false;
    }

    static BuildUnits(entity: Array<any>): Array<Units> {
        return entity.map(item => Units.CreateInstance(item));
    }

    private static CreateInstance(units: any): Units {
        const {
            UNIT_ID,
            UNIT_NAME,
            STATUS_DESC,
            UNIT_STATUS,
            TYPE_NAME,
            AREA_NAME,
            KO_DATE,
            STARTUP_DATE,
            QC_DATE,
            RELEASE_DATE
        } = units;

        return new Units(
            UNIT_ID,
            UNIT_NAME,
            STATUS_DESC,
            UNIT_STATUS,
            TYPE_NAME,
            AREA_NAME,
            KO_DATE,
            STARTUP_DATE,
            QC_DATE,
            RELEASE_DATE
        );
    }

    get koDate(){
        return this.kickoffDate;
    }
}

export class Boilers {
    private BoilerId: number;
    private BoilerName: string;
    private ManufacturerName: string;
    private UnitId: string;
    private UnitName: string;
    private PlantName: string;
    private OwnerName: string;
    private BoilerType: string;
    private BoilerTypeDesc: string;
    private Status: string;
    private QcDate: number;
    private ReleaseDate: number;
    private BoilerModel: string;
    private BoilerInfo: string;

    constructor(
        boilerId: number,
        boilerName: string,
        manufacturerName: string,
        unitId: string,
        unitName: string,
        plantName: string,
        ownerName: string,
        boilerType: string,
        boilerTypeDesc: string,
        status: string,
        qcDate: number,
        releaseDate: number,
        model: string,
        boilerInfo: string
    ) {
        this.BoilerId = boilerId;
        this.BoilerName = boilerName;
        this.ManufacturerName = manufacturerName;
        this.UnitId = unitId;
        this.UnitName = unitName;
        this.PlantName = plantName;
        this.OwnerName = ownerName;
        this.BoilerType = boilerType;
        this.BoilerTypeDesc = boilerTypeDesc;
        this.Status = status;
        this.QcDate = qcDate;
        this.ReleaseDate = releaseDate;
        this.BoilerModel = model;
        this.BoilerInfo = boilerInfo;
    }

    static BuildBoilers(entity: Array<any>): Array<Boilers> {
        return entity.map(item => Boilers.CreateInstance(item));
    }

    private static CreateInstance(boilers: any): Boilers {
        const {
            BOILER_ID,
            NAME, BOILER_NAME,
            MANUFACTURER_NAME,
            UNIT_ID,
            UNIT_NAME,
            PLANT_NAME,
            COMPANY_NAME,
            TYPE, BOILER_TYPE,
            TYPE_DESCRIPTION, BOILER_TYPE_DESC,
            BOILER_STATUS_DESC,
            QC_DATE,
            RELEASE_DATE,
            MODEL,
            BOILER_INFO
        } = boilers;

        return new Boilers(
            BOILER_ID,
            NAME || BOILER_NAME,
            MANUFACTURER_NAME,
            UNIT_ID,
            UNIT_NAME || NAME,
            PLANT_NAME,
            COMPANY_NAME,
            TYPE || BOILER_TYPE,
            TYPE_DESCRIPTION || BOILER_TYPE_DESC,
            BOILER_STATUS_DESC,
            QC_DATE,
            RELEASE_DATE,
            MODEL,
            BOILER_INFO
        );
    }
}

export class Drives {
    driveId: number;
    driveName: string;
    manufacturerName: string;
    unitId: string;
    unitName: string;
    plantName: string;
    ownerName: string;
    driveType: string;
    driveModel: string;
    drivenMfg: string;
    drivenModel: string;
    qcDate: number;
    releaseDate: number;
    categoryId: number;
    categoryName: string;
    driveManufacturer: string;

    constructor(
        driveId: number,
        driveName: string,
        manufacturerName: string,
        unitId: string,
        unitName: string,
        plantName: string,
        ownerName: string,
        driveType: string,
        driveModel: string,
        drivenMfg: string,
        drivenModel: string,
        qcDate: number,
        releaseDate: number,
        categoryId: number,
        categoryName: string,
        driveManufacturer: string
    ) {
        this.driveId = driveId;
        this.driveName = driveName;
        this.manufacturerName = manufacturerName;
        this.unitId = unitId;
        this.unitName = unitName;
        this.plantName = plantName;
        this.ownerName = ownerName;
        this.driveType = driveType;
        this.driveModel = driveModel;
        this.drivenMfg = drivenMfg;
        this.drivenModel = drivenModel;
        this.qcDate = qcDate;
        this.releaseDate = releaseDate;
        this.categoryId = categoryId;
        this.categoryName = categoryName;
        this.driveManufacturer = driveManufacturer;
    }

    static BuildDrives(entity: Array<any>): Array<Drives> {
        return entity.map(item => Drives.CreateInstance(item));
    }

    private static CreateInstance(drives: any): Drives {
        const {
            TURBINE_ID,
            NAME,
            MANUFACTURER_NAME,
            UNIT_ID,
            UNIT_NAME,
            PLANT_NAME,
            COMPANY_NAME,
            TYPE_DESCRIPTION,
            MODEL,
            DRIVEN_MFG,
            GEN_MODEL,
            QC_DATE,
            RELEASE_DATE,
            TURBINE_NAME,
            TURBINE_TYPE_DESC,
            CATEGORY_ID,
            CATEGORY_DESC,
            TURBINE_MANUFACTURER
            
        } = drives;

        return new Drives(
            TURBINE_ID,
            NAME || TURBINE_NAME,
            MANUFACTURER_NAME,
            UNIT_ID,
            UNIT_NAME,
            PLANT_NAME,
            COMPANY_NAME,
            TYPE_DESCRIPTION || TURBINE_TYPE_DESC,
            MODEL,
            DRIVEN_MFG,
            GEN_MODEL,
            QC_DATE,
            RELEASE_DATE,
            CATEGORY_ID,
            CATEGORY_DESC,
            TURBINE_MANUFACTURER
        );
    }
}

export class MiningEquipments {
    private MiningEquipId: number;
    private MiningEquipName: string;
    private MiningEquipType: string;
    private VendorName: string;
    private StartupDate: number;
    private MiningEquipStatus: string;
    private QcDate: number;
    private ReleaseDate: number;

    constructor(
        equipmentId: number,
        equipmentName: string,
        equipmentType: string,
        vendorName: string,
        startupDate: number,
        equipmentStatus: string,
        qcDate: number,
        releaseDate: number
    ) {
        this.MiningEquipId = equipmentId;
        this.MiningEquipName = equipmentName;
        this.MiningEquipType = equipmentType;
        this.VendorName = vendorName;
        this.StartupDate = startupDate;
        this.MiningEquipStatus = equipmentStatus;
        this.QcDate = qcDate;
        this.ReleaseDate = releaseDate;
    }

    static BuildMiningEquipment(entity: Array<any>): Array<MiningEquipments> {
        return entity.map(item => MiningEquipments.CreateInstance(item));
    }

    private static CreateInstance(miningEquipments: any): MiningEquipments {
        const {
            MINING_EQUIPMENT_ID,
            MINING_EQUIPMENT_NAME,
            MINING_EQUIPMENT_TYPE_DESC,
            VENDOR_NAME,
            STARTUP_DATE,
            MINING_EQUIPMENT_STATUS_DESC,
            QC_DATE,
            RELEASE_DATE
        } = miningEquipments;

        return new MiningEquipments(
            MINING_EQUIPMENT_ID,
            MINING_EQUIPMENT_NAME,
            MINING_EQUIPMENT_TYPE_DESC,
            VENDOR_NAME,
            STARTUP_DATE,
            MINING_EQUIPMENT_STATUS_DESC,
            QC_DATE,
            RELEASE_DATE
        );
    }

}

export class PlantsLTSA {
    private LtsaId: number;
    private Contractor: string;
    private ContractLength: string;
    private Status: string;
    private ExpirationDate: number;
    private UnitName: string;
    private ReleaseDate: number;
    private QcDate: number;
    private CapitalOrMaintenanceTypes: number;
    private CapitalOrMaintenanceTypesBool: boolean;

    constructor(
        ltsaId: number,
        contractor: string,
        contractLength: string,
        status: string,
        expirationDate: number,
        unitName: string,
        releaseDate: number,
        qcDate: number,
        capitalOrMaintenanceTypes: number,
        capitalOrMaintenanceTypesBool: boolean
    ) {
        this.LtsaId = ltsaId;
        this.Contractor = contractor;
        this.ContractLength = contractLength;
        this.Status = status;
        this.ExpirationDate = expirationDate;
        this.UnitName = unitName;
        this.ReleaseDate = releaseDate;
        this.QcDate = qcDate;
        this.CapitalOrMaintenanceTypes = capitalOrMaintenanceTypes;
        this.CapitalOrMaintenanceTypesBool = capitalOrMaintenanceTypesBool;
    }

    static BuildPlantsLTSA(entity: Array<any>): Array<PlantsLTSA> {
        return entity.map(item => PlantsLTSA.CreateInstance(item));
    }

    private static CreateInstance(plantsLTSA: any): PlantsLTSA {
        const {
            PLANT_LTSA_ID,
            COMPANY_NAME,
            CONTRACT_LENGTH,
            LTSA_STATUS_DESC,
            CONTRACT_EXPIRATION,
            PLANT_NAME,
            RELEASE_DATE,
            QC_DATE,
            CAPITAL_OR_MAINTENANCE_TYPES
        } = plantsLTSA;

        return new PlantsLTSA(
            PLANT_LTSA_ID,
            COMPANY_NAME,
            CONTRACT_LENGTH === Evergreen.EvergreenCode ? Evergreen.EvergreenLetter : CONTRACT_LENGTH,
            LTSA_STATUS_DESC,
            CONTRACT_EXPIRATION,
            PLANT_NAME,
            RELEASE_DATE,
            QC_DATE,
            CAPITAL_OR_MAINTENANCE_TYPES,
            CAPITAL_OR_MAINTENANCE_TYPES === 1
        );
    }
}

export class Tank {
    private TankId: number;
    private TankName: string;
    private TankType: string;
    private Capacity: number;
    private StartupDate: number;
    private TankStatus: string;
    private QcDate: number;
    private ReleaseDate: number;
    private UnitHS: string;
    private TankStatusId: string;
    private TankInfo: string;

    constructor(
        public tankId: number,
        public tankName: string,
        public tankType: string,
        public capacity: number,
        public startupDate: number,
        public tankStatus: string,
        public qcDate: number,
        public releaseDate: number,
        public unitHS: string,
        public tankStatusId: string,
        public tankInfo: string
    ) {
        this.TankId = tankId;
        this.TankName = tankName;
        this.TankType = tankType;
        this.Capacity = capacity;
        this.StartupDate = startupDate;
        this.TankStatus = tankStatus;
        this.QcDate = qcDate;
        this.ReleaseDate = releaseDate;
        this.UnitHS = unitHS;
        this.TankStatusId = tankStatusId;
        this.TankInfo = tankInfo;
    }

    static BuildTank(entity: Array<any>): Array<Tank> {
        return entity.map(item => Tank.CreateInstance(item));
    }

    private static CreateInstance(tank: any): Tank {
        const {
            TANK_ID,
            TANK_NAME,
            TANK_TYPE_DESC,
            DESIGN_CAPACITY,
            STARTUP_DATE,
            TANK_STATUS_DESC,
            QC_DATE,
            RELEASE_DATE,
            HS_UNIT,
            TANK_STATUS,
            TANK_INFO
        } = tank;

        return new Tank(
            TANK_ID,
            TANK_NAME,
            TANK_TYPE_DESC,
            DESIGN_CAPACITY,
            STARTUP_DATE,
            TANK_STATUS_DESC,
            QC_DATE,
            RELEASE_DATE,
            HS_UNIT,
            TANK_STATUS,
            TANK_INFO
        );
    }
}

export class Equipment {
    private EquipmentId: number;
    private EquipmentName: string;
    private EquipmentType: string;
    private UnitType: string;
    private StartupDate: number;
    private EquipmentStatus: string;
    private QcDate: number;
    private ReleaseDate: number;

    constructor(
        equipmentId: number,
        equipmentName: string,
        equipmentType: string,
        unitType: string,
        startupDate: number,
        equipmentStatus: string,
        qcDate: number,
        releaseDate: number
    ) {
        this.EquipmentId = equipmentId;
        this.EquipmentName = equipmentName;
        this.EquipmentType = equipmentType;
        this.UnitType = unitType;
        this.StartupDate = startupDate;
        this.EquipmentStatus = equipmentStatus;
        this.QcDate = qcDate;
        this.ReleaseDate = releaseDate;
    }

    static BuildEquipment(entity: Array<any>): Array<Equipment> {
        return entity.map(item => Equipment.CreateInstance(item));
    }

    private static CreateInstance(equipment: any): Equipment {
        const {
            EQUIPMENT_ID,
            EQUIPMENT_NAME,
            TYPE_NAME,
            EQUIPMENT_STATUS_DESC,
            EQUIPMENT_TYPE_DESC,
            STARTUP_DATE,
            QC_DATE,
            RELEASE_DATE
        } = equipment;

        return new Equipment(
            EQUIPMENT_ID,
            EQUIPMENT_NAME,
            EQUIPMENT_TYPE_DESC,
            TYPE_NAME,
            STARTUP_DATE,
            EQUIPMENT_STATUS_DESC,
            QC_DATE,
            RELEASE_DATE
        );
    }
}

export class OfflineEvent {
    public eventID: number;
    public eventType: string;
    public eventTypeDesc: string;
    public eventStatus: string;
    public eventStatusDesc: string;
    public eventStartDate: Date;
    public eventEndDate: Date;
    public eventDuration: number;
    public unitId: number;
    public unitName: string;
    public eventQCDate: number;
    public eventReleaseDate: number;
    public unitExtraNames: string;


    constructor(
        eventID: number,
        eventType: string,
        eventTypeDesc: string,
        eventStatus: string,
        eventStatusDesc: string,
        eventStartDate: Date,
        eventEndDate: Date,
        eventDuration: number,
        unitId: number,
        unitName: string,
        eventQCDate: number,
        eventReleaseDate: number,
        unitExtraNames: string,
    ) {
        this.eventID = eventID;
        this.eventType = eventType;
        this.eventTypeDesc = eventTypeDesc;
        this.eventStatus = eventStatus;
        this.eventStatusDesc = eventStatusDesc;
        this.eventStartDate = eventStartDate;
        this.eventEndDate = eventEndDate;
        this.eventDuration = eventDuration;
        this.unitId = unitId;
        this.unitName = unitName;
        this.eventQCDate = eventQCDate;
        this.eventReleaseDate = eventReleaseDate;
        this.unitExtraNames = unitExtraNames;
    }

    static BuildOfflineEvents(entity: Array<any>): Array<OfflineEvent> {
        return entity.map(item => OfflineEvent.CreateInstance(item));
    }

    private static CreateInstance(offlineEvent: any): OfflineEvent {
        return new OfflineEvent(
            offlineEvent.OFFLINE_EVENT_ID,
            offlineEvent.OFFLINE_EVENT_TYPE,
            offlineEvent.OUTAGE_TYPE_DESC ?? offlineEvent.OUTAGE_TYPE,
            offlineEvent.OFFLINE_EVENT_STATUS,
            offlineEvent.OUTAGE_STATUS_DESC ?? offlineEvent.OUTAGE_STATUS,
            offlineEvent.START_DATE,
            offlineEvent.END_DATE,
            offlineEvent.DURATION,
            offlineEvent.UNIT_ID,
            offlineEvent.UNIT_NAME,
            offlineEvent.QC_DATE,
            offlineEvent.RELEASE_DATE,
            offlineEvent.EXTRA_UNITS_NAMES
        );
    }
}

export class Projects {
    constructor(
        public statusId: string,
        public status: string,
        public projectId: number,
        public projectName: string,
        public projectTiv: number,
        public kickoffDate: number,
        public pecTiming: string,
        public completionDate: number,
        public qcDate: number,
        public cAttachment: number,
        public releaseDate: number,
        public probability: string
    ) { }

    static BuildProjects(entity: Array<any>): Array<Projects> {
        return entity.map(item => Projects.CreateInstance(item));
    }

    private static CreateInstance(projects: any): Projects {
        const {
            STATUS_ID,
            PROJECT_STATUS_DESC,
            PROJECT_ID,
            PROJECT_NAME,
            PROJECT_TIV,
            KICKOFF_DATE,
            PEC_TIMING,
            COMPLETION_DATE,
            QC_DATE,
            RELEASE_DATE,
            COUNT_ATTACH,
            PROJECT_PROBABILITY_DESC
        } = projects;

        return new Projects(
            STATUS_ID,
            PROJECT_STATUS_DESC || projects.PROJECT_STATUS,
            PROJECT_ID,
            PROJECT_NAME,
            PROJECT_TIV,
            KICKOFF_DATE,
            PEC_TIMING,
            COMPLETION_DATE,
            QC_DATE,
            COUNT_ATTACH,
            RELEASE_DATE,
            PROJECT_PROBABILITY_DESC
        );
    }
}

export class InternalNote {

    constructor(
        public createUser: string,
        public createDate: string,
        public comments: string
    ) { }

    public static CreateInstance(queryObject: any): InternalNote {
        const {
            USERNAME,
            CREATE_DATE,
            COMMENTS,
        } = queryObject;
        return new InternalNote(USERNAME, CREATE_DATE, COMMENTS);
    }
}

export class MarketTrend {
    constructor(
        public marketTrendId: number,
        public marketTrendDescription: string,
        public marketTrendIdDescription: string
    ) { }

    static BuildMarketTrend(entity: Array<any>): Array<MarketTrend> {
        return entity.map(item => MarketTrend.CreateInstance(item));
    }

    private static CreateInstance(marketTrend: any): MarketTrend {
        const {
            MARKET_TREND_ID,
            MARKET_TREND_DESC
        } = marketTrend;

        return new MarketTrend(
            MARKET_TREND_ID,
            MARKET_TREND_DESC,
            `${MARKET_TREND_ID} - ${MARKET_TREND_DESC}`
        );
    }
}

export class ShiftsPerDay {
    public constructor(
        public id: number,
        public desc: string
    ) { }



    static getGenericList(): Array<ShiftsPerDay> {
        return [
            { id: 1, desc: '1' },
            { id: 2, desc: '2' },
            { id: 3, desc: '3' },
            { id: 4, desc: '4' }
        ];
    }
}

export class HoursPerShift {
    public constructor(
        public id: number,
        public desc: string
    ) { }


    static getGenericList(): Array<HoursPerShift> {
        return [
            { id: 8, desc: '8' },
            { id: 10, desc: '10' },
            { id: 12, desc: '12' },
            { id: 14, desc: '14' },
            { id: 16, desc: '16' }
        ];
    }
}

export class UnionFacility {
    public constructor(
        public id: string,
        public desc: string
    ) { }

    static getGenericList(): Array<UnionFacility> {
        return [
            { id: null, desc: 'Unconfirmed' },
            { id: '1', desc: 'Union' },
            { id: '0', desc: 'Non Union' },
            { id: '2', desc: 'Both' }
        ];
    }
}
export class CompanyOperators {
    private operatorId: number;
    private companyName: string;
    private qcDate: string;
    private releaseDate: string;

    constructor(
        operatorId: number,
        companyName: string,
        qcDate: string,
        releaseDate: string
    ) {
        this.operatorId = operatorId;
        this.companyName = companyName;
        this.qcDate = qcDate;
        this.releaseDate = releaseDate;
    }

    static BuildOperators(entity: Array<any>): Array<CompanyOperators> {
        return entity.map(item => CompanyOperators.CreateInstance(item));
    }

    private static CreateInstance(cOperators: any): CompanyOperators {
        const {
            OPERATOR_ID,
            COMPANY_NAME,
            QC_DATE,
            RELEASE_DATE,
        } = cOperators;
        return new CompanyOperators(Number(OPERATOR_ID), COMPANY_NAME, QC_DATE, RELEASE_DATE);
    }
}

export class CompanyOwners {
    private CompanyId: number;
    private CompanyName: string;
    private Classification: string;
    private ClassificationDesc: string;
    private OwnerId: number;
    private QcDate: number;
    private ReleaseDate: number;
    private OwnerPercentage: number;

    constructor(
        companyId: number,
        companyName: string,
        classification: string,
        classificationDesc: string,
        ownerId: number,
        qcDate: number,
        releaseDate: number,
        ownerPercentage: number
    ) {
        this.CompanyId = companyId;
        this.CompanyName = companyName;
        this.Classification = classification;
        this.ClassificationDesc = classificationDesc;
        this.OwnerId = ownerId;
        this.QcDate = qcDate;
        this.ReleaseDate = releaseDate;
        this.OwnerPercentage = ownerPercentage;
    }

    static BuildCompOwners(entity: Array<any>): Array<CompanyOwners> {
        return entity.map(item => CompanyOwners.CreateInstance(item));
    }

    private static CreateInstance(cOwners: any): CompanyOwners {
        const {
            COMPANY_ID,
            COMPANY_NAME,
            CLASSIFICATION,
            CLASSIFICATION_DESC,
            OWNER_ID,
            QC_DATE,
            RELEASE_DATE,
            OWNER_PCT
        } = cOwners;
        return new CompanyOwners(
            COMPANY_ID,
            COMPANY_NAME,
            CLASSIFICATION,
            CLASSIFICATION_DESC,
            OWNER_ID,
            QC_DATE,
            RELEASE_DATE,
            OWNER_PCT
        );
    }
}

export class Status {
    public StatusDescription: string;
    public StatusID: string;

    private constructor(statusDescription: string,
        statusID: string) {
        this.StatusDescription = statusDescription;
        this.StatusID = statusID;
    }

    static BuildStatus(entity: Array<any>): Array<Status> {
        return entity.map(item => Status.CreateInstance(item));
    }

    private static CreateInstance(status: any): Status {
        return new Status(`${status.STATUS_ID} - ${status.STATUS_DESC}`, status.STATUS_ID);
    }

}

export class Product {
    public productID: string;
    public productDesc: string;
    public unitId: string;
    public lblproductId: string;
    public hasFeedFutureCapacity: boolean;
    public hasOutputFutureCapacity: boolean;
    public productStatus: string;
    public productType: string;



    private constructor(productID: string,
        productDesc: string, unitId: string,
        lblproductId: string, hasFeedFutureCapacity: boolean,
        hasOutputFutureCapacity: boolean,
        productStatus: string, productType: string) {
        this.productID = productID;
        this.productDesc = productDesc;
        this.unitId = unitId;
        this.lblproductId = lblproductId;
        this.hasFeedFutureCapacity = hasFeedFutureCapacity;
        this.hasOutputFutureCapacity = hasOutputFutureCapacity;
        this.productStatus = productStatus;
        this.productType = productType;
    }

    static BuildProduct(entity: Array<any>): Array<Product> {
        return entity.map(item => Product.CreateInstance(item));
    }


    private static CreateInstance(product: any): Product {
        return new Product(
            product.HS_PRODUCTS_ID || product.HS_PRODUCT, 
            product.PRODUCT_DESC || product.PRODUCT_NAME, 
            product.HS_UNIT,
            product.HS_PRODUCTS_ID, 
            product.COUNT_FEED_FUTURE === '1', 
            product.COUNT_OUTPUT_FUTURE === '1',
            product.PRODUCT_STATUS,
            product.PRODUCT_TYPE);
    }

}

export class System {
    public systemID: string;
    public systemName: string;

    private constructor(systemID: string, systemName: string) {
        this.systemID = systemID;
        this.systemName = systemName;
    }

    static BuildSystemList(entity: Array<any>): Array<System> {
        return entity.map(item => System.CreateInstance(item));
    }

    static BuildSystem(entity: any): System {
        return System.CreateInstance(entity);
    }

    private static CreateInstance(system: any): System {
        return new System(
            system.PIPELINE_SYSTEM_ID || system.TRANSMISSION_SYSTEM_ID,
            system.PIPELINE_SYSTEM_NAME || system.TRANSMISSION_SYSTEM_NAME);
    }

}

export class Uom {
    public constructor(
        public uomID: string,
        public uomshortName: string,
        public uomExtendedName: string
    ) { }

    static getGenericListInCm(): Array<Uom> {
        return [
        { uomID: "Inches (in)", uomExtendedName: 'Inches (in)', uomshortName: "IN" },
        { uomID: "Centimeters (cm)", uomExtendedName: 'Centimeters (cm)', uomshortName: "CM" }
        ];
    }

    static getGenericListMiKm(): Array<Uom> {
        return [
        { uomID: "Miles (mi)", uomExtendedName: 'Miles (mi)', uomshortName: "MI" },
        { uomID: "Kilometers (km)", uomExtendedName: 'Kilometers (km)', uomshortName: "KM" }
        ];
    }

    static getGenericListDiameter(): Array<Uom> {
        return [
            { uomID: "Feet", uomExtendedName: 'Feet', uomshortName: "Feet" },
            { uomID: "Meter", uomExtendedName: 'Meter', uomshortName: "Meter" }
        ];
    }

    static getGenericListMawp(): Array<Uom> {
        return [
            { uomID: "Bar", uomExtendedName: 'Bar', uomshortName: "Bar" },
            { uomID: "PSIG", uomExtendedName: 'PSIG', uomshortName: "PSIG" }
        ];
    }
}

export class BidderStatus {
    public constructor(
        public bidderStatusId: string,
        public bidderStatusName: string
    ) { }

    static getGenericList(): Array<BidderStatus> {
        return [
        { bidderStatusId: "Future", bidderStatusName: 'Future'},
        { bidderStatusId: "Bid in Progress", bidderStatusName: 'Bid in Progress'},
        { bidderStatusId: "Shortlisted", bidderStatusName: 'Shortlisted'},
        { bidderStatusId: "Awarded", bidderStatusName: 'Awarded'},
        { bidderStatusId: "Not Awarded", bidderStatusName: 'Not Awarded'},
        { bidderStatusId: "Withdrawn", bidderStatusName: 'Withdrawn'},
        { bidderStatusId: "Revoked/Replaced", bidderStatusName: 'Revoked/Replaced'}
        ];
    }
}

export class PipelinePoint {
    public constructor(
        public pointID: string,
        public pointName: string
    ) { }

    static getGenericList(): Array<PipelinePoint> {
        return [
        { pointID: "Delivery", pointName: "Delivery" },
        { pointID: "Receipt", pointName: "Receipt" },
        { pointID: "Both", pointName: "Both" }
        ];
    }

}

export class OfflineEventAssetTypes {
    public constructor(
        public assetTypeId: number,
        public assetTypeName: string
    ) { }

    static getGenericList(): Array<OfflineEventAssetTypes> {
        return [
        { assetTypeId: AssetsType.TRANSMISSION_LINE, assetTypeName: EntityName.ASSET_TRANSMISSION_LINE },
        { assetTypeId: AssetsType.PIPELINE, assetTypeName: EntityName.ASSET_PIPELINE }
        ];
    }
}


export class PipelineMeter {
    public meterNo: string;
    public meterName: string;
    public pipeline: string;
    public category: string;
    public locProp: string;
    public locQti: string;
    public admin1: string;
    public admin2: string;
    public lastUser: string;
    public lastDate: number;
    public entryUser: string;
    public entryDate: number;

    public operatorName: string;
    public tspName: string;
    public tspNumber: string;
    public dnrNumber: string;
    public pipelineType: string;
    public flowIndicator: string;
    public marketPoint: string;
    public plantId: string;

    private constructor(
        meterNo: string,
        meterName: string,
        pipeline: string,
        category: string,
        locProp: string,
        locQti: string,
        admin1: string,
        admin2: string,
        lastUser: string,
        lastDate: number,
        entryUser: string,
        entryDate: number,
        operatorName: string,
        tspName: string,
        tspNumber: string,
        dnrNumber: string,
        pipelineType: string,
        flowIndicator: string,
        marketPoint: string
    ) {
        this.meterNo = meterNo;
        this.meterName = meterName;
        this.pipeline = pipeline;
        this.category = category;
        this.locProp = locProp;
        this.locQti = locQti;
        this.admin1 = admin1;
        this.admin2 = admin2;
        this.lastUser = lastUser;
        this.lastDate = lastDate;
        this.entryUser = entryUser;
        this.entryDate = entryDate;
        this.operatorName = operatorName;
        this.tspName = tspName;
        this.tspNumber = tspNumber;
        this.dnrNumber = dnrNumber;
        this.pipelineType = pipelineType;
        this.flowIndicator = flowIndicator;
        this.marketPoint = marketPoint;
    }

    static BuildPipelineMeterList(entity: Array<any>): Array<PipelineMeter> {
        return entity.map(item => PipelineMeter.CreateInstance(item));
    }

    static BuildPipelineMeter(entity: any): PipelineMeter {
        return PipelineMeter.CreateInstance(entity);
    }

    private static CreateInstance(pipelineMeter: any): PipelineMeter {
        return new PipelineMeter(
            pipelineMeter.METER_NO,
            pipelineMeter.METERNAME,
            pipelineMeter.PIPELINE,
            pipelineMeter.CATEGORY,
            pipelineMeter.LOCPROP,
            pipelineMeter.LOCQTI,
            pipelineMeter.ADMIN1,
            pipelineMeter.ADMIN2,
            pipelineMeter.LAST_USER,
            pipelineMeter.LAST_DATE,
            pipelineMeter.ENTRY_USER,
            pipelineMeter.ENTRY_DATE,
            pipelineMeter.OPERATOR,
            pipelineMeter.TSPNAME,
            pipelineMeter.TSPNUMBER,
            pipelineMeter.DRN,
            pipelineMeter.PIPETYPE,
            pipelineMeter.FLOWINDDESC,
            pipelineMeter.MARKETPOINT);
    }
}

export class Category {
    public categoryID: string;
    public categoryDesc: string;

    private constructor(
        categoryID: string,
        categoryDesc: string
    ) {
        this.categoryID = categoryID;
        this.categoryDesc = categoryDesc;
    }

    static BuildCategoryList(entity: Array<any>): Array<Category> {
        return entity.map(item => Category.CreateInstance(item));
    }

    static BuildCategory(entity: any): Category {
        return Category.CreateInstance(entity);
    }

    private static CreateInstance(category: any): Category {
        return new Category(
            category.CATID,
            category.CATEGORY);
    }

}

export class LocationQti {
    public locID: string;
    public locDesc: string;
    private locDescConcat: string;

    private constructor(
        locID: string,
        locDesc: string,
        locDescConcat: string
    ) {
        this.locID = locID;
        this.locDesc = locDesc;
        this.locDescConcat = locDescConcat;
    }

    static BuildLocationQtiList(entity: Array<any>): Array<LocationQti> {
        return entity.map(item => LocationQti.CreateInstance(item));
    }

    static BuildLocationQti(entity: any): LocationQti {
        return LocationQti.CreateInstance(entity);
    }

    private static CreateInstance(loc: any): LocationQti {
        const { LOCQTI, LOCQTIDESC } = loc;
        return new LocationQti(
            loc.LOCQTI,
            loc.LOCQTIDESC,
            `${LOCQTI} - ${LOCQTIDESC}`);
    }

}

export class FlowIndicator {
    public flowID: string;
    public flowDesc: string;
    private flowDescConcat: string;

    private constructor(
        flowID: string,
        flowDesc: string,
        flowDescConcat: string
    ) {
        this.flowID = flowID;
        this.flowDesc = flowDesc;
        this.flowDescConcat = flowDescConcat;
    }

    static BuildFlowIndicatorList(entity: Array<any>): Array<FlowIndicator> {
        return entity.map(item => FlowIndicator.CreateInstance(item));
    }

    static BuildFlowIndicator(entity: any): FlowIndicator {
        return FlowIndicator.CreateInstance(entity);
    }

    private static CreateInstance(flow: any): FlowIndicator {
        const { FLOWIND, FLOWINDDESC } = flow;
        return new FlowIndicator(
            flow.FLOWIND,
            flow.FLOWINDDESC,
            `${FLOWIND} - ${FLOWINDDESC}`);
    }
}

export class MapitEntity {
    public constructor(
        public oriType: string,
        public oriId: string,
        public oriName: string,
        public oriLat: string,
        public oriLong: string,
        public desType: string,
        public desId: string,
        public desName: string,
        public desLat: string,
        public desLong: string,
        public connections: Array<any>,
        public entityId: string,
        public entityName: string,
        public latitude: string,
        public longitude: string
    ) { }

};

export class MapitConnection {
    public constructor(
        public id: string,
        public connType: string,
        public connName: string,
        public latitude: string,
        public longitude: string,
        public industryCode: string
    ) { }

    static BuildFromTline(entity: Array<AssetsTLinesConnectionsDisplay>): Array<MapitConnection> {
        return entity.map(item => MapitConnection.CreateFromTline(item));
    }

    private static CreateFromTline(conn: AssetsTLinesConnectionsDisplay): MapitConnection {
        return new MapitConnection(String(conn.plantId), "5", conn.plantDesc,
            String(conn.latitude), String(conn.longitude), conn.industryCode);
    }

    static BuildFromPipe(entity: Array<AssetsPipesConnectionsDisplay>): Array<MapitConnection> {
        return entity.map(item => MapitConnection.CreateFromPipeline(item));
    }

    private static CreateFromPipeline(conn: AssetsPipesConnectionsDisplay): MapitConnection {
        return new MapitConnection(String(conn.connectionId), conn.connectionAssetType,
            conn.assetName, String(conn.latitude), String(conn.longitude),
            conn.industryCode);
    }

};

export class InstallationType {
    typeId: string;
    typeDesc: string;

    private constructor(
        typeDesc: string,
        typeId: string
    ) {
        this.typeId = typeId;
        this.typeDesc = typeDesc;
    }

    private static CreateInstance(status: any): InstallationType {
        return new InstallationType(`${status.TYPE_DESC}`, status.TYPE_ID);
    }

    static BuildInstallationType(entity: Array<any>): Array<InstallationType> {
        return entity.map(item => InstallationType.CreateInstance(item));
    }
}

export class GreenhouseGasEmission extends Bean{
    public reportingYear: string;
    public totalCo2Amount: string;

    private constructor(
        reportingYear: string,
        totalCo2Amount: string
    ) {
        super();
        this.reportingYear = reportingYear;
        this.totalCo2Amount = totalCo2Amount;
    }

    static BuildEntitiesList(entity: Array<any>): Array<GreenhouseGasEmission> {
        return entity.map(item => GreenhouseGasEmission.CreateInstance(item));
    }

    static BuildEntity(entity: any): GreenhouseGasEmission {
        return GreenhouseGasEmission.CreateInstance(entity);
    }

    private static CreateInstance(entity: any): GreenhouseGasEmission {
        return new GreenhouseGasEmission(
            entity.REPORTING_YEAR,
            entity.TOTAL_CO2_AMOUNT);
    }

}

export class UnitsLTSA {
    private ltsaId: number;
    private unitId: number;
    private ltsa: string;
    private status: string;
    private contractorName: string;
    private contractLength: string;
    private unitName: string;
    private ltsaType: string;
    private expiration: string;
    private qcDate: string;
    private releaseDate: string;

    constructor(
        ltsaId: number,
        unitId: number,
        ltsa: string,
        status: string,
        contractorName: string,
        contractLength: string,
        ltsaType: string,
        expiration: string,
        qcDate: string,
        releaseDate: string
    ) {
        this.ltsaId = ltsaId;
        this.unitId = unitId;
        this.ltsa = ltsa == "1" ? "Y" : "N";
        this.status = status;
        this.contractorName = contractorName;
        this.contractLength = contractLength;
        this.ltsaType = ltsaType;
        this.expiration = expiration;
        this.qcDate = qcDate;
        this.releaseDate = releaseDate;        
    }

    static BuildUnitsLTSA(entity: Array<any>): Array<UnitsLTSA> {
        return entity.map(item => UnitsLTSA.CreateInstance(item));
    }

    private static CreateInstance(unitLTSA: any): UnitsLTSA {
        const {
            UNIT_LTSA_ID,
            UNIT_ID,
            HAS_LTSA,
            LTSA_STATUS_DESC,
            SERVICE_CONTRACTOR_NAME,
            CONTRACT_LENGTH,
            UNIT_LTSA_TYPE_DESC,
            CONTRACT_EXPIRATION,
            QC_DATE,
            RELEASE_DATE
        } = unitLTSA;

        return new UnitsLTSA(
            UNIT_LTSA_ID,
            UNIT_ID,
            HAS_LTSA,
            LTSA_STATUS_DESC,
            SERVICE_CONTRACTOR_NAME,
            CONTRACT_LENGTH === Evergreen.EvergreenCode ? Evergreen.EvergreenLetter : CONTRACT_LENGTH,
            UNIT_LTSA_TYPE_DESC,
            CONTRACT_EXPIRATION,
            QC_DATE,
            RELEASE_DATE
        );
    }

    formatHasLtsa():void{

    }
}

export class AreaOwners {
    private CompanyId: number;
    private CompanyName: string;
    private OwnerPercentage: number;
    private QcDate: string;
    private ReleaseDate: string;
   
    constructor(
        companyId: number,
        companyName: string,
        ownerPercentage: number,
        qcDate: string,
        releaseDate: string
    ) {
        this.CompanyId = companyId;
        this.CompanyName = companyName;
        this.OwnerPercentage = ownerPercentage;
        this.QcDate = qcDate;
        this.ReleaseDate = releaseDate;
    }

    static BuildAreaOwners(entity: Array<any>): Array<AreaOwners> {
        return entity.map(item => AreaOwners.CreateInstance(item));
    }

    private static CreateInstance(aOwners: any): AreaOwners {
        const {
            OWNER_ID,
            COMPANY_NAME,
            OWNER_PCT,
            QC_DATE,
            RELEASE_DATE           
        } = aOwners;
        return new AreaOwners(
            OWNER_ID,
            COMPANY_NAME,
            OWNER_PCT,
            QC_DATE,
            RELEASE_DATE  
        );
    }
}

export class AreaType {
    areaTypeId: string;
    areaTypeDesc: string;
    areaTypeConcat: string;

    private constructor(
        areaTypeId: string,
        areaTypeDesc: string,
        areaTypeConcat: string
    ) {
        this.areaTypeId = areaTypeId;
        this.areaTypeDesc = areaTypeDesc;
        this.areaTypeConcat = areaTypeConcat;
    }

    static BuildAreaType(entity: Array<any>): Array<AreaType> {
        return entity.map(item => AreaType.CreateInstance(item));
    }

    static CreateInstance(record: any): AreaType {
        return new AreaType(record.AREA_TYPES_ID, record.TYPE_DESC, `${record.AREA_TYPES_ID} - ${record.TYPE_DESC}`);
    }

}

export class AreaStatus {
    areaStatusId: string;
    areaStatusDesc: string;
    areaStatusConcat: string;

    private constructor(
        areaStatusId: string,
        areaStatusDesc: string,
        areaStatusConcat: string,
    ) {
        this.areaStatusId = areaStatusId;
        this.areaStatusDesc = areaStatusDesc;
        this.areaStatusConcat = areaStatusConcat;
    }

    static BuildAreaStatuses(entity: Array<any>): Array<AreaStatus> {
        return entity.map(item => AreaStatus.CreateInstance(item));
    }

    static CreateInstance(record: any): AreaStatus {
        return new AreaStatus(record.AREA_STATUS_ID, record.STATUS_DESC, `${record.AREA_STATUS_ID} - ${record.STATUS_DESC}`);
    }

}

export class BoilerStatus {
    boilerStatusId: string;
    boilerStatusDesc: string;
    boilerStatusConcat: string;

    private constructor(
        boilerStatusId: string,
        boilerStatusDesc: string,
        boilerStatusConcat: string,
    ) {
        this.boilerStatusId = boilerStatusId;
        this.boilerStatusDesc = boilerStatusDesc;
        this.boilerStatusConcat = boilerStatusConcat;
    }

    static BuildBoilerStatuses(entity: Array<any>): Array<BoilerStatus> {
        return entity.map(item => BoilerStatus.CreateInstance(item));
    }

    static CreateInstance(record: any): BoilerStatus {
        return new BoilerStatus(record.BOILER_STATUS_ID, record.STATUS_DESC, `${record.BOILER_STATUS_ID} - ${record.STATUS_DESC}`);
    }

}

export class BoilerType {
    boilerTypeId: string;
    boilerTypeDesc: string;
    boilerTypeConcat: string;

    private constructor(
        boilerTypeId: string,
        boilerTypeDesc: string,
        boilerTypeConcat: string
    ) {
        this.boilerTypeId = boilerTypeId;
        this.boilerTypeDesc = boilerTypeDesc;
        this.boilerTypeConcat = boilerTypeConcat;
    }

    static BuildBoilerType(entity: Array<any>): Array<BoilerType> {
        return entity.map(item => BoilerType.CreateInstance(item));
    }

    static CreateInstance(record: any): BoilerType {
        return new BoilerType(record.TYPE_ID, record.TYPE_DESCRIPTION, `${record.TYPE_ID} - ${record.TYPE_DESCRIPTION}`);
    }

}

export class EquipmentStatus {
    equipmentStatusId: string;
    equipmentStatusDesc: string;
    equipmentStatusConcat: string;

    private constructor(
        equipmentStatusId: string,
        equipmentStatusDesc: string,
        equipmentStatusConcat: string,
    ) {
        this.equipmentStatusId = equipmentStatusId;
        this.equipmentStatusDesc = equipmentStatusDesc;
        this.equipmentStatusConcat = equipmentStatusConcat;
    }

    static BuildEquipmentStatuses(entity: Array<any>): Array<EquipmentStatus> {
        return entity.map(item => EquipmentStatus.CreateInstance(item));
    }

    static CreateInstance(status: any): EquipmentStatus {
        return new EquipmentStatus(status.EQUIPMENT_STATUS_ID, status.STATUS_DESC, `${status.EQUIPMENT_STATUS_ID} - ${status.STATUS_DESC}`);
    }
}

export class EquipmentType {
    equipmentTypeId: string;
    equipmentTypeDesc: string;

    private constructor(
        equipmentTypeId: string,
        equipmentTypeDesc: string,
    ) {
        this.equipmentTypeId = equipmentTypeId;
        this.equipmentTypeDesc = equipmentTypeDesc;
    }

    static BuildEquipmentType(entity: Array<any>): Array<EquipmentType> {
        return entity.map(item => EquipmentType.CreateInstance(item));
    }

    static CreateInstance(record: any): EquipmentType {
        return new EquipmentType(record.EQUIPMENT_TYPES_ID, record.TYPE_DESC);
    }
}

export class Approval {
    public constructor(
        public approvalId: number,
        public approvalName: string
    ) { }

    static getGenericList(): Array<Approval> {
        return [
            { approvalId: 0, approvalName: 'None'},
            { approvalId: 1, approvalName: 'Technical'},
            { approvalId: 2, approvalName: 'Editor'},
            { approvalId: -1, approvalName: 'No Preference'}
        ];
    }
}

export class SelectView {
    public constructor(
        public selectViewId: string,
        public selectViewName: string
    ) { }

    static getGenericList(): Array<SelectView> {
        return [
            { selectViewId: 'CO', selectViewName: 'Changes Only'},
            { selectViewId: 'EF', selectViewName: 'Editorial Fields'},
            { selectViewId: 'FR', selectViewName: 'Full Record'}
        ];
    }
}

export class AlertStatusDisplay {
    alertStatusName: string;
    alertStatus: string;

    static getGenericList(): Array<AlertStatusDisplay> {
        return [
            { alertStatusName: "All Statuses", alertStatus: "S,Q,P,C,E" },
            { alertStatusName: "Scheduled/Queued", alertStatus: "S,Q" },
            { alertStatusName: "Processing", alertStatus: "P" },
            { alertStatusName: "Completed", alertStatus: "C" },
            { alertStatusName: "Error", alertStatus: "E" }
        ];
    }    
}

export class AlertTimeSince {
    timeSinceName: string;
    timeSince: string;

    static getGenericList(): Array<AlertTimeSince> {
        return [
                {timeSinceName:"6 Hours",  timeSince:"6"},
				{timeSinceName:"24 Hours", timeSince:"24"},
				{timeSinceName:"3 Days",   timeSince:"72"},
				{timeSinceName:"1 Week",   timeSince:"168"},
				{timeSinceName:"1 Month",  timeSince:"720"}
            ];
    }    
}
export class OfficeLocation {
    public officeLocationId: string;
    public officeLocationName: string;

    constructor(
        officeLocationId: string,
        officeLocationName: string
    ) {
        this.officeLocationId = officeLocationId;
        this.officeLocationName = officeLocationName;
    }

    static BuildEntity(entity: Array<any>): Array<OfficeLocation> {
        return entity.map(item => OfficeLocation.CreateInstance(item));
    }

    private static CreateInstance(officeLocation: any): OfficeLocation {
        const { C_ID, COUNTRY_NAME } = officeLocation;
        return new OfficeLocation(C_ID, COUNTRY_NAME);
    }

}
export class PipelineMeterBlocked {
    public meterNo: string;
    public plantId: string;

    private constructor(
        meterNo: string,
        plantId: string
    ) {
        this.meterNo = meterNo;
        this.plantId = plantId;
    }

    static BuildPipelineMeterList(entity: Array<any>): Array<PipelineMeterBlocked> {
        return entity.map(item => PipelineMeterBlocked.CreateInstance(item));
    }

    static BuildPipelineMeter(entity: any): PipelineMeterBlocked {
        return PipelineMeterBlocked.CreateInstance(entity);
    }

    private static CreateInstance(pipelineMeter: any): PipelineMeterBlocked {
        return new PipelineMeterBlocked(
            pipelineMeter.METER_NO,
            pipelineMeter.PLANT_ID)
    }
}

export class OfflineEventStatus {
    offlineEventStatusId: string;
    offlineEventStatusDesc: string;
    offlineEventStatusConcat: string;

    private constructor(
        offlineEventStatusId: string,
        offlineEventStatusDesc: string,
        offlineEventStatusConcat: string
    ) {
        this.offlineEventStatusId = offlineEventStatusId;
        this.offlineEventStatusDesc = offlineEventStatusDesc;
        this.offlineEventStatusConcat = offlineEventStatusConcat;
    }

    static BuildOfflineEventStatus(entity: Array<any>): Array<OfflineEventStatus> {
        return entity.map(item => OfflineEventStatus.CreateInstance(item));
    }

    static CreateInstance(record: any): OfflineEventStatus {
        return new OfflineEventStatus(record.OUTAGE_STATUS_ID, record.OUTAGE_STATUS_DESC, `${record.OUTAGE_STATUS_ID} - ${record.OUTAGE_STATUS_DESC}`);
    }
}

export class OfflineEventType {
    offlineEventTypeId: string;
    offlineEventTypeDesc: string;
    offlineEventTypeConcat: string;

    private constructor(
        offlineEventTypeId: string,
        offlineEventTypeDesc: string,
        offlineEventTypeConcat: string
    ) {
        this.offlineEventTypeId = offlineEventTypeId;
        this.offlineEventTypeDesc = offlineEventTypeDesc;
        this.offlineEventTypeConcat = offlineEventTypeConcat;
    }

    static BuildOfflineEventType(entity: Array<any>): Array<OfflineEventType> {
        return entity.map(item => OfflineEventType.CreateInstance(item));
    }

    static CreateInstance(record: any): OfflineEventType {
        return new OfflineEventType(record.OUTAGE_TYPES_ID, record.OUTAGE_TYPE_DESC, `${record.OUTAGE_TYPES_ID} - ${record.OUTAGE_TYPE_DESC}`);
    }
}

export class OfflineEventCause {
    offlineEventCauseId: string;
    offlineEventCauseDesc: string;
    offlineEventCauseConcat: string;
    inactive: boolean;

    private constructor(
        offlineEventCauseId: string,
        offlineEventCauseDesc: string,
        offlineEventCauseConcat: string
    ) {
        this.offlineEventCauseId = offlineEventCauseId;
        this.offlineEventCauseDesc = offlineEventCauseDesc;
        this.offlineEventCauseConcat = offlineEventCauseConcat;
    }

    static BuildOfflineEventCause(entity: Array<any>): Array<OfflineEventCause> {
        return entity.map(item => OfflineEventCause.CreateInstance(item));
    }

    static CreateInstance(record: any): OfflineEventCause {
        return new OfflineEventCause(record.OUTAGE_CAUSE_ID, record.OUTAGE_CAUSE_DESC, `${record.OUTAGE_CAUSE_ABBR} - ${record.OUTAGE_CAUSE_DESC}`);
    }
}

export class EquipmentCategory {
    categoryId: string;
    categoryName: string;
    categoryDesc: string;

    private constructor(
        categoryId: string,
        categoryName: string,
        categoryDesc: string
    ) {
        this.categoryId = categoryId;
        this.categoryName = categoryName;
        this.categoryDesc = categoryDesc;
    }

    static BuildEquipmentCategory(entity: Array<any>): Array<EquipmentCategory> {
        return entity.map(item => EquipmentCategory.CreateInstance(item));
    }


    private static CreateInstance(record: any): EquipmentCategory {
        
        return new EquipmentCategory(
            record.OUTAGE_CATEGORY_ID, 
            record.OUTAGE_CATEGORY_DESC,
            `${record.OUTAGE_CATEGORY_ID} - ${record.OUTAGE_CATEGORY_DESC}`
            );
    }
}

export class ServiceContractor {
    serviceContractorId: string;
    serviceContractorName: string;
    usable: string;
    inactive: boolean;

    private constructor(
        serviceContractorId: string,
        serviceContractorName: string,
        usable: string
    ) {
        this.serviceContractorId = serviceContractorId;
        this.serviceContractorName = serviceContractorName;
        this.usable = usable;
    }

    static BuildServiceContractorList(entity: Array<any>): Array<ServiceContractor> {
        return entity.map(item => ServiceContractor.CreateInstance(item));
    }

    static BuildActiveServiceContractorList(entity: Array<any>): Array<ServiceContractor> {
        return entity.map(item => ServiceContractor.CreateInstance(item))
            .filter(contractor => contractor.usable === '1');
    }


    private static CreateInstance(record: any): ServiceContractor {
        
        return new ServiceContractor(
            record.SERVICE_CONTRACTOR_ID, 
            record.SERVICE_CONTRACTOR_NAME,
            record.USABLE
            );
    }
}

export class Preference {
    public resourceName: string;
    public value: string;

    private constructor(
        resourceName: string,
        value: string
    ) {
        this.resourceName = resourceName;
        this.value = value;
    }

    static BuildPreferences(entity: Array<any>): Array<Preference> {
        return entity.map(item => Preference.CreateInstance(item));
    }

    static BuildPreference(resourceName: string, selectedValue: string): Preference {
        return new Preference(resourceName, selectedValue);
    }

    private static CreateInstance(pref: any): Preference {
        return new Preference(pref.RESOURCE_NAME, pref.SELECTED_VALUE);
    }
}