import { IAuditEntityData } from "src/app/core/interfaces/iaudit-entity-data";
import { Address } from "../../../address";
import { AssetsPath } from "../../../assets-path";
import { AssetsPathType } from "../../../enumerations/assets-path-type";
import { AssetsType } from "../../../enumerations/assets-type";
import { EntityName } from "../../../enumerations/entity-name";
import { OperationType } from "../../../enumerations/operation-type";
import { OffshoreAddress } from "../../../offshoreAddress";
import { AssetsDisplay } from "../../base/display/assets-display";
import { AssetsTLines } from "../save/assets-tlines";
import { AssetsTLinesConnectionsDisplay } from "./assets-tlines-connections-display";

export class AssetsTLinesDisplay extends AssetsTLines {
    parentDesc: string;
    parentClassification: string;
    nercRegionDesc: string;
    controlAreaDesc: string;
    kvRatingDesc: string;
    systemTypeDesc: string;
    tLineStatusDesc: string;
    transmissionSystemName: string;
    lastUserEmailAddress: string;
    entryUserEmailAddress: string;
    previousUserEmailAddress: string;
    qcUserEmailAddress: string;
    releaseUserEmailAddress: string;
    amendmentUserEmailAddress: string;
    destinationAssetName: string;

    destinationPhysCountryAbrev: string;
    destinationPhysCountryName: string;
    destinationPhysStateAbrev: string;
    destinationPhysStateName: string;
    destinationPhysCityName: string;
    destinationPhysCountyName: string;
    destOffshoreStateDesignationName: string;
    destOffshoreCtryDesignationName: string;
    destinationOffshoreWaterbodyName: string;
    destinationOffshoreFieldName: string;
    destinationOffshoreAreaName: string;

    destinationOffshore: boolean;
    destinationLongitude: number;
    destinationLatitude: number;
    destinationPhysCountry: number;
    destinationPhysAddressV1: string;
    destinationPhysAddressV2: string;
    destinationPhysPostalCode: string;
    destinationPhysState: number;
    destinationPhysCity: number;
    destinationPhysCounty: number;

    destOffshoreStateDesignation: string;
    destinationOffshoreBlockV1: string;
    destinationOffshoreBlockV2: string;
    destinationOffshoreWaterbody: string;
    destinationOffshoreFieldId: string;
    destinationOffshoreAreaId: string;

    assetDisplay: AssetsDisplay;

    origination: AssetsPath;
    destination: AssetsPath;

    assetsTLinesConnectionsDisplay: Array<AssetsTLinesConnectionsDisplay>;

    constructor(entity: any) {
        super(entity);

        this.parentDesc = entity && entity.parentDesc || null;
        this.parentClassification = entity && entity.parentClassification || null;
        this.nercRegionDesc = entity && entity.nercRegionDesc || null;
        this.controlAreaDesc = entity && entity.controlAreaDesc || null;
        this.kvRatingDesc = entity && entity.kvRatingDesc || null;
        this.systemTypeDesc = entity && entity.systemTypeDesc || null;
        this.tLineStatusDesc = entity && entity.tLineStatusDesc || null;
        this.transmissionSystemName = entity && entity.transmissionSystemName || null;
        this.lastUserEmailAddress = entity && entity.lastUserEmailAddress || null;
        this.entryUserEmailAddress = entity && entity.entryUserEmailAddress || null;
        this.previousUserEmailAddress = entity && entity.previousUserEmailAddress || null;
        this.qcUserEmailAddress = entity && entity.qcUserEmailAddress || null;
        this.releaseUserEmailAddress = entity?.releaseUserEmailAddress || null;
        this.amendmentUserEmailAddress = entity?.amendmentUserEmailAddress || null;
        this.destinationAssetName = entity && entity.destinationAssetName || null;

        this.destinationPhysCountryAbrev = entity && entity.destinationPhysCountryAbrev || null;
        this.destinationPhysCountryName = entity && entity.destinationPhysCountryName || null;
        this.destinationPhysStateAbrev = entity && entity.destinationPhysStateAbrev || null;
        this.destinationPhysStateName = entity && entity.destinationPhysStateName || null;
        this.destinationPhysCityName = entity && entity.destinationPhysCityName || null;
        this.destinationPhysCountyName = entity && entity.destinationPhysCountyName || null;
        this.destOffshoreStateDesignationName = entity && entity.destOffshoreStateDesignationName || null;
        this.destOffshoreCtryDesignationName = entity && entity.destOffshoreCtryDesignationName || null;
        this.destinationOffshoreWaterbodyName = entity && entity.destinationOffshoreWaterbodyName || null;
        this.destinationOffshoreFieldName = entity && entity.destinationOffshoreFieldName || null;
        this.destinationOffshoreAreaName = entity && entity.destinationOffshoreAreaName || null;

        this.destinationOffshore = entity && entity.destinationOffshore || null;
        this.destinationLongitude = entity && entity.destinationLongitude || null;
        this.destinationLatitude = entity && entity.destinationLatitude || null;
        this.destinationPhysCountry = entity && entity.destinationPhysCountry || null;
        this.destinationPhysAddressV1 = entity && entity.destinationPhysAddressV1 || null;
        this.destinationPhysAddressV2 = entity && entity.destinationPhysAddressV2 || null;
        this.destinationPhysPostalCode = entity && entity.destinationPhysPostalCode || null;
        this.destinationPhysState = entity && entity.destinationPhysState || null;
        this.destinationPhysCity = entity && entity.destinationPhysCity || null;
        this.destinationPhysCounty = entity && entity.destinationPhysCounty || null;

        this.destOffshoreStateDesignation = entity && entity.destOffshoreStateDesignation || null;
        this.destinationOffshoreBlockV1 = entity && entity.destinationOffshoreBlockV1 || null;
        this.destinationOffshoreBlockV2 = entity && entity.destinationOffshoreBlockV2 || null;
        this.destinationOffshoreWaterbody = entity && entity.destinationOffshoreWaterbody || null;
        this.destinationOffshoreFieldId = entity && entity.destinationOffshoreFieldId || null;
        this.destinationOffshoreAreaId = entity && entity.destinationOffshoreAreaId || null;

        this.assetDisplay = AssetsDisplay.BuildAssetsDisplay(entity.assetDisplay);
        this.assetDisplay.assetTypeId = AssetsType.TRANSMISSION_LINE;
        this.asset = this.assetDisplay;

        this.loadOriginationPath();
        this.loadDestinationPath();

        this.assetsTLinesConnectionsDisplay = entity && entity.assetsTLinesConnectionsDisplay || [];
    }

    static BuildTLineDisplay(entity: any): AssetsTLinesDisplay {
      return new AssetsTLinesDisplay(entity);
    }

    static BuildNewTLine(response: any): AssetsTLinesDisplay {
        return new AssetsTLinesDisplay({
        assetId: response[0].ASSET_ID,
        assetTLinesId: response[0].ASSET_TLINES_ID,
        operation: OperationType.INSERT,
        intDataDepValState: OperationType.INSERT
      });
    }

    get overHeadBoolean(): boolean {
        return this.overHead === '1';
    }

    set overHeadBoolean(value: boolean) {
        this.overHead = value ? '1' : '0';
    }

    get belowGroundBoolean(): boolean {
        return this.belowGround === '1';
    }

    set belowGroundBoolean(value: boolean) {
        this.belowGround = value ? '1' : '0';
    }

    get subseaBoolean(): boolean {
        return this.subsea === '1';
    }

    set subseaBoolean(value: boolean) {
        this.subsea = value ? '1' : '0';
    }

    get auditEntityBean(): IAuditEntityData {
        return {
            lastUser: this.assetDisplay?.lastUser,
            entryUser: this.assetDisplay?.entryUser,
            prevUser: this.assetDisplay?.prevUser,
            qcUser: this.assetDisplay?.qcUser,
            lastDate: this.assetDisplay?.lastDate,
            entryDate: this.assetDisplay?.entryDate,
            prevDate: this.assetDisplay?.prevDate,
            qcDate: this.assetDisplay?.qcDate,
            releaseUser: this.assetDisplay?.releaseUser,
            releaseDate: this.assetDisplay?.releaseDate,
            amendmentUser: this.assetDisplay?.amendmentUser,
            amendmentDate: this.assetDisplay?.amendmentDate,
            lastUserEmailAddress: this.lastUserEmailAddress,
            entryUserEmailAddress: this.entryUserEmailAddress,
            previousUserEmailAddress: this.previousUserEmailAddress,
            qcUserEmailAddress: this.qcUserEmailAddress,
            releaseUserEmailAddress: this.releaseUserEmailAddress,
            amendmentUserEmailAddress: this.amendmentUserEmailAddress,
            lastUpdateStatus: '',
            entityName: EntityName.ASSET_TRANSMISSION_LINE,
            entityId: this.assetId
        };
    }

    setPhysicalAddress(physicalAddress: Address) {
    }

    setMailingAddress(mailingAddress: Address) {
    }

    private loadOriginationPath(): void {
        this.origination = new AssetsPath(
            AssetsPathType.ORIGINATION,
            this.assetDisplay.originAssetId,
            this.assetDisplay.originAssetName,
            this.assetDisplay.originOffshore,
            Address.BuildOriginAddress(this),
            OffshoreAddress.BuildOriginOffshoreAddress(this),
            this.assetDisplay.originLatitude,
            this.assetDisplay.originLongitude,
            null //this.diameterOut
        );
    }

    setOriginationPath(path: AssetsPath): void {
        this.assetDisplay.originAssetId = path.plantId;
        this.assetDisplay.originAssetName = path.plantName;
        this.assetDisplay.originLatitude = path.latitude;
        this.assetDisplay.originLongitude = path.longitude;
        this.assetDisplay.originAssetTypeId = AssetsType.PLANT;
        //this.diameterOut = path.diameter;

        if(path.onshoreAddress !== null) {
            this.setPhysicalAddress(path.onshoreAddress);
        }
    }

    private loadDestinationPath(): void {
        this.destination = new AssetsPath(
            AssetsPathType.DESTINATION,
            this.destinationAssetId,
            this.destinationAssetName,
            this.destinationOffshore,
            Address.BuildDestinationAddress(this),
            OffshoreAddress.BuildDestinationOffshoreAddress(this),
            this.destinationLatitude,
            this.destinationLongitude,
            null //this.diameterOut
        );
    }

    setDestinationPath(path: AssetsPath): void {
        this.destinationAssetId = path.plantId;
        this.destinationAssetName = path.plantName;
        this.destinationLatitude = path.latitude;
        this.destinationLongitude = path.longitude;
        this.destinationAssetTypeId = AssetsType.PLANT;
        //this.diameterIn = path.diameter;

        if(path.onshoreAddress !== null) {
            this.setMailingAddress(path.onshoreAddress);
        }
    }

    applyChangedFields(changeObj: any) {
        this.changedFields = {};
        this.assetDisplay.changedFields = {};

        for(const field in changeObj) {
            let auxObj = {};
            auxObj[field] = 0;
            if(this.hasOwnProperty(field)) {
                this.changedFields[field] = 0;
            } else if(this.assetDisplay.hasOwnProperty(field)) {
                this.assetDisplay.changedFields[field] = 0;
            }
        }
    }

    prepareSaveCollection() {
        this.assetDisplay.prepareSaveCollection();
        this.assetDisplay.assetId = this.assetId;
        this.asset = this.assetDisplay;
    }

    get destinationAssetTypeDesc(): string {
        return this.assetDisplay.getAssetTypeDescriptionById(this.destinationAssetTypeId);
    }

}
