<div class="projects-section">
    <div class="header">
        <h3>{{ titleKey | translate }}</h3>
    </div>
    <!-- Message Dialog Component for display Info -->
    <app-message-dialog></app-message-dialog>
    <div class="mt-2">
        <button class="btn-row" type="button" [disabled]="isDisabledButton()" (click)="addRow()" [tabIndex]="baseIndex">{{ "common.addRow" | translate }}</button>
    </div>
    <div class="projects-table custom-scrollable-table mt-3">

        <p-table [value]="projectsFiltered()" [columns]="cols" [resizableColumns]="true"
            focusable [focusManager]="projectsFocusManager"
            styleClass="p-datatable-gridlines"
            (onEditInit)="onEditInit($event)"
            (onEditComplete)="onEditComplete($event)"
            (onRowReorder)="onRowReorder($event)" [reorderableColumns]="true"
            [tabIndex]="baseIndex + 1">

            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns" [style.text-align]="col.isAmount ? 'right':'left'" [style.flex-basis]="col.width" pReorderableColumn pResizableColumn>
                        {{col.header}}
                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="colgroup" let-columns>
                <colgroup>
                    <col *ngFor="let col of columns" [style.flex-basis]="col.width">
                </colgroup>
            </ng-template>

            <ng-template pTemplate="body" let-columns="columns" let-index="rowIndex" let-rowData>
                <tr [pReorderableRow]="index">
                    <td *ngFor="let col of columns" [pEditableColumn]="rowData" [style.flex-basis]="col.width"
                        [pEditableColumnField]="col.field" [pEditableColumnRowIndex]="index" [style.white-space]="col.wSpace || 'normal'" class="{{col.field}} px-2">
                        <div *ngIf="col.draggable && tableEditEnabled" class="draggable-col">
                            <span class="pi pi-bars" pReorderableRowHandle title="Reorder Row"></span>
                        </div>
                        <div *ngIf="!col.editable || !tableEditEnabled">
                            <div *ngIf="!col.isDate && !col.isAmount">
                                {{ rowData[col.field]}}
                            </div>
                            <div *ngIf="col.isDate">
                                {{ rowData[col.field] | date: 'dd-MMM-y' }}
                            </div>

                            <div *ngIf="col.isAmount" class="project-amount">
                                {{ rowData[col.field] | amount}}
                            </div>
                        </div>
                        <p-cellEditor *ngIf="col.editable && tableEditEnabled">
                            <ng-template pTemplate="input">
                                <div *ngIf="col.field == 'projectId'">
                                    <input type="text" class="large"
                                        [(ngModel)]="rowData[col.field]" (keydown.enter)="onEnter(col, rowData, index)"
                                        (keydown)="this.restrictArrowKeyNavigation($event)"
                                        (focusout)="onFocusOut(col, rowData, index)" maxlength="12" appOnlyNumbers>
                                    <button pButton pRipple
                                            type="button"
                                            icon="pi pi-search"
                                            class="ml-1 p-button-text icon-container"
                                            (click)="openProjectModal(rowData, index)">
                                    </button>
                                </div>
                            </ng-template>
                            <ng-template *ngIf="col.field != 'projectCompletionDate'" pTemplate="output">
                                {{rowData[col.field]}}
                            </ng-template>
                        </p-cellEditor>
                        <div class="text-center" *ngIf="col.delete && tableEditEnabled">
                            <button pButton pRipple type="button" icon="pi pi-trash" class="p-button-text icon-container" (click)="deleteProject(rowData)" (keydown.enter)="deleteProject(rowData)" tabindex="-1"></button>
                        </div>
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td [attr.colspan]="columns.length + 1">
                        <div class="not-result-msg">
                            <span>{{ "asset.projects.notFound" | translate }}</span>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>

    </div>
</div>
