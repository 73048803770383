import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { EntityName } from 'src/app/core/models/enumerations/entity-name';
import { AttachmentService } from 'src/app/core/services/attachment.service';
import { SharedService } from 'src/app/core/services/shared.service';
import { RelatedInfoBase } from '../related-info.base';
import { ColumnEntityInfo, ColumnEntityInfoEditableType } from 'src/app/shared/components/base/mvt-entity-associator/mvt-entity-associator.component';

@Component({
    selector: 'app-pipeline',
    templateUrl: './pipeline.component.html',
    styleUrls: ['./pipeline.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class RelatedInfoPipelineComponent extends RelatedInfoBase implements OnInit {
    entityType = EntityName.ASSET_PIPELINE;
    entityTableKey =EntityName.ASSET_PIPELINE;
    constructor(
        private attachmentService: AttachmentService,

        sharedService: SharedService
    ) { super(sharedService); }

    ngOnInit(): void {
        this.initBase();
    }

    getColumnsInfo(): ColumnEntityInfo[] {
        let columnsInfo: ColumnEntityInfo[] = [];
        if (this.callerIsCompany) {
            columnsInfo.push({ entityPropName: 'AssetID', columnHeader: 'relatedInfo.pipeline.assetId', routerEntityName: EntityName.ASSET_PIPELINE, widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'AssetName', columnHeader: 'relatedInfo.pipeline.assetName', widthPercentage: 20});
            columnsInfo.push({ entityPropName: 'StartUpDate', columnHeader: 'relatedInfo.common.startupDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 15});
            columnsInfo.push({ entityPropName: 'CompDate', columnHeader: 'relatedInfo.pipeline.completionDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 15});
            columnsInfo.push({ entityPropName: 'QcDate', columnHeader: 'relatedInfo.common.qcDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 15});
            columnsInfo.push({ entityPropName: 'ReleaseDate', columnHeader: 'relatedInfo.common.releaseDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 15});
            columnsInfo.push(this.createAttachmentColumn('CAttachment', 10, this.attachmentButtonOnClick));

        } else if (this.callerIsPipeline || this.callerIsPlant || this.callerIsProject || this.callerIsOfflineEvent) {
            columnsInfo.push({ entityPropName: 'AssetID', columnHeader: 'relatedInfo.pipeline.assetId', routerEntityName: EntityName.ASSET_PIPELINE, widthPercentage: 8});
            columnsInfo.push({ entityPropName: 'AssetName', columnHeader: 'relatedInfo.pipeline.assetName', widthPercentage: 19});
            columnsInfo.push({ entityPropName: 'Status', columnHeader: 'relatedInfo.pipeline.status', widthPercentage: 14});
            columnsInfo.push({ entityPropName: 'Type', columnHeader: 'relatedInfo.pipeline.type', widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'StartUpDate', columnHeader: 'relatedInfo.common.startupDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 11});
            columnsInfo.push({ entityPropName: 'CompDate', columnHeader: 'relatedInfo.pipeline.completionDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 11});
            columnsInfo.push({ entityPropName: 'QcDate', columnHeader: 'relatedInfo.common.qcDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 11});
            columnsInfo.push({ entityPropName: 'ReleaseDate', columnHeader: 'relatedInfo.common.releaseDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 11});
            columnsInfo.push(this.createAttachmentColumn('CAttachment', 5, this.attachmentButtonOnClick));
        }

        return columnsInfo;
    }

    attachmentButtonOnClick(columnEntityInfo: ColumnEntityInfo, implementedComponent: RelatedInfoPipelineComponent, rowData: any) {
        const assetId = rowData.AssetID;
        implementedComponent.attachmentService.assetIternalAttachmentById(assetId).subscribe(resp => {
            implementedComponent.attachmentService.handlerAttachmentsByCount(resp);
        });
    }

}
