import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { RegistrationService } from 'src/app/core/services/registration.service';
import { HeaderService } from '../../header/header.service';
import { RouterUrl } from 'src/app/core/models/constants';
import { EntityName } from 'src/app/core/models/enumerations/entity-name';
import { StringUtils } from 'src/app/core/utils/string-utils';

@Component({
    selector: 'app-nav-brand',
    templateUrl: './nav-brand.component.html',
    styleUrls: ['./nav-brand.component.scss']
})
export class NavBrandComponent implements OnInit, OnDestroy {

    title: string = "ED";
    subtitle: string;
    private headerChanged: Subscription;

    constructor(public router: Router,
        public headerService: HeaderService,
        private registrationService: RegistrationService) { }

    ngOnInit(): void {
        this.headerChanged = this.headerService.headerChanged$.subscribe (headerData => {
            this.subtitle = headerData?.entityType;
        });

        this.registrationService.getOperatingMode().subscribe((mode) => {
            if (mode !== "live" && mode.length > 1) {
                this.title += mode.charAt(0).toUpperCase() + mode.slice(1);
            }
        });
    }

    ngOnDestroy() {
        this.headerChanged?.unsubscribe();
    }

    goDashboard() {
        window.open(location.pathname + '#' + RouterUrl.Dashboard, StringUtils.safeWindowName(EntityName.DASHBOARD));
    }
}
