/* Application wide constants that share common purposes, grouped as enums to avoid use of out-of-scope values */

export const enum RouterUrl {
   Dashboard = '/ED/dashboard',
   Login = '/auth/login',
   Logout = '/auth/logout',
}

export const enum ApiEndpoint {
   AttachmentDownload = 'attachment/download',
   Login = 'auth/login',
   Logout = 'auth/logout',
   Notifications = 'shared/notificationList',
   SendEmail = 'shared/sendMail',
   ServerDate = 'shared/serverDate',
   ServerVersion = 'shared/serverVersion',
   TelemetryReport = 'telemetry/report',
   TokenVersion = 'auth/version',
   UserDetails = 'registration/user/details',
   VerifySession = 'auth/verify',
}

export const enum WidgetType {
   Wizard = 'nedWidget_wizard',
   PendingRelease = 'nedWidget_pendingRelease',
   QuickAccess = 'nedWidget_quickAccess',
   RecentlyOpened = 'nedWidget_recentlyOpened',
   WorkInProgress = 'nedWidget_workInProgress'
}

export const enum WidgetVisibility {
   Expanded = 'E',
   Collapsed = 'C',
   Hidden = 'H'
}

export const enum WidgetOtherOptions {
   HEIGHT = 'HEIGHT',
   WIDTH = 'WIDTH',
   ENTITIES_FILTER = 'ENTITIES_FILTER'
}


/* Value Constants (groups of constants that correlate with values used outside of the front end) */
/* Not a requirement, but generally the values are defined in alphabetical order for readability (or numeric order for ones with numeric values) */

export const enum SubscriptionStatusValue {
   Inactive = 0,
   Active = 1,
   Unpaid = 2,
}

export const enum CurrencyCodeValue {
   USD = 'USD',
}

export const enum CompanyStatusValue {
   Active = 'C',
   Closed = 'B',
   SuspendedUnresolved = 'SU',
};

export const enum ReleaseStatusValue {
   Error = 'E',
   Migrated = 'M',
   Processing = 'P',
   Warn = 'W',
};

export const enum RecordStatusValue {
   Archived = 'S',
   Enhanced = 'E',
   Incomplete = 'I',
   Pending = 'P',
   Trash = 'T',
   Unconfirmed = 'U',
};

export const enum ProjectRecordStatusValue {
   Active = 'A',
   PendingReverification = 'P',
   Sensitive = 'S',
   Trash = 'T',
};

export const enum ContactStatusValue {
   Current = 'CURRENT',
   Dead = 'DEAD',
   Moved = 'MOVED',
   Retired = 'RETIRED',
   Trash = 'TRASH',
};

export const enum CompanyClassificationValue {
   Holding = 'H',
   Parent = 'P',
}

export const enum CompanyClassificationDescValue {
   Holding = 'Holding',
   Parent = 'Parent',
}

export const enum PlantStatusValue {
   Cancelled = 'K',
   Closed = 'C',
   Engineered = 'E',
   Lead = 'L',
   Mothballed = 'M',
   OnHold = 'H',
   Operational = 'O',
   Planned = 'P',
   Removed = 'R',
   Shuttered = 'S',
   UnderConstruction = 'UC',
   Unresolved = 'U',
}

export const enum PlantProbabilityValue {
   NotLikely = '1',
   Likely = '2',
   MFApproved = '3',
   MFConstruction = '4',
   Completed = '5',
}

export const enum ProjectStatusValue {
   Active = 'A',
   Cancelled = 'C',
   Done = 'D',
   OnHold = 'H',
   Suspended = 'S',
   UnableToConfirm = 'UI',
   Unconfirmed = 'U',
}

export const enum ProjectProbabilityValue {
   NotLikely = 1,
   Likely = 2,
   MFApproved = 3,
   MFConstruction = 4,
   Completed = 5,
}

export const enum PecTimingValue {
   P1 = 'P1',
   P2 = 'P2',
   E1 = 'E1',
   E2 = 'E2',
   C1 = 'C1',
   C2 = 'C2',
}

export const enum PecActivityValue {
   CompletionStartup = '41',
   MaintenancePrograms = '42',
   ScheduledMaintenance = '45',
}

export const enum ProjectTypeValue {
   Grassroot = '1',
   Brownfield = '2',
   UnitAddition = '6',
   MaintenanceProgram = '29',
   MaintenanceEvent = '30',
}

export const enum ProjectTypeClassificationValue {
   Capital = 'Capital',
   Maintenance = 'Maintenance',
}

export const enum ProjectBidderTypeValue {
   Contractor = 14,
   Estimating = 20,
   RealEstateDeveloper = 22,
   Subcontractor = 23,
   Consultant = 26,
   ProjectFinancing = 27,
}

export const enum ProjectBidderStatusValue {
   Awarded = 'Awarded',
   BidInProgress = 'Bid in Progress',
   Future = 'Future',
   NotAwarded = 'Not Awarded',
   RevokedReplaced = 'Revoked/Replaced',
   Shortlisted = 'Shortlisted',
   Withdrawn = 'Withdrawn',
}

export const enum UnitStatusValue {
   Cancelled = 'K',
   Closed = 'C',
   Engineered = 'E',
   Mothballed = 'M',
   Operational = 'O',
   Planned = 'P',
   Removed = 'R',
   Shuttered = 'S',
   UnderConstruction = 'UC',
}

export const enum AlertKindValue {
   OfflineEvent = 'OFFLINE_EVENT',
   PlantInfo = 'PLANT_INFO',
}

export enum AlertStatusValue {
   Completed = 'Completed',
   Error = 'Error',
   Processing = 'Processing',
   Queued = 'Queued',
   Scheduled = 'Scheduled',
   Unscheduled = 'Unscheduled',
}

export enum AlertStatusId {
   Completed = 'C',
   Error = 'E',
   Processing = 'P',
   Queued = 'Q',
   Scheduled = 'S',
   Unscheduled = 'U',
}

export const enum TankStatusValue {
   Cancelled = 'K',
   Closed = 'C',
   Engineered = 'E',
   Lead = 'L',
   Mothballed = 'M',
   OnHold = 'H',
   Operational = 'O',
   Planned = 'P',
   Removed = 'R',
   Shuttered = 'S',
   UnderConstruction = 'UC',
   Unresolved = 'U',
}

export const enum HsMeasureValue {
   CubicMeters = 'CUBIC METERS',
   BBL = 'BBL',
   MMSCF = 'MMSCF'
}

export const enum UnitDynamicSectionValue {
   Equipment = 'Equipment',
   CrudeSlate = 'Crude Slate'
}

export const enum UnitTypesValue {
   AtmosphericDistillation = '00001',
   Vacuum = '00106',
   WindTurbine = '00117'
}

export const enum UnitFeedProductNameValue {
   CrudePetroleum = 'Crude Petroleum'
}

export const enum  UnitEquipmentFieldGroup {
   Drums = '1',
   HeaterFurnaces = '2',
   HeatExchangers = '3',
   CyclonesReactor = '4',
   CyclonesRegen = '5',
   Reactors = '6',
   CatalystManufacturer = '7',
   PSI = '8',
   Tanks = '9'
}

export const enum DriveCategoryValue {
   Generator = 1,
   Compressor = 2,
   Pump = 3
}

export const enum DriveTypeValue {
   AeroderivativeCombustionTurbine = 1,
   STSteamTurbine = 2,
   HTHydraulicTurbine = 3,
   ICInternalCombustionEngine = 4,
   HeavyFrameCombustionTurbine = 5,
   WTWindTurbine = 6,
   EMElectricMotor = 7,
   TurboExpander = 8
}

export const enum ElectricalMetricValue {
   HorsePower = 'HP',
   MegaWatts = 'MW'
}

export const enum ConfirmationValues {
   YES = 'Yes',
   NO = 'No',
};

export const enum ElectricalMetricConversionValue {
   HorsePower = 1341.02,
}

export const enum ElectricalMetricLimit {
   HorsePower = 9999998,
   MegaWatts= 7457.009
}

export const enum ReleaseStatuses {
   Amendment = 'Amendment',
   WIP = 'WIP',
   Idle = 'Idle',
   Update = 'Update',
   Minor = 'Minor',
   Major = 'Major',
   New = 'New',
   Unknown = 'Unknown',
}
export enum MailingAddressesStatus {
   Current = 'CURRENT',
   SuspendedUnresolved = "SUSPENDED / UNRESOLVED",
   Closed = "CLOSED",
   Engineered = "ENGINEERED",
   Cancelled = "CANCELLED",
   Planned = "PLANNED",
   OnHold = "ON HOLD",
   Removed = "REMOVED",
   UnResolved = "UNRESOLVED",
   Dismantled = "DISMANTLED",
   NonManufacturing = "NON-MANUFACTURING",
   ClosedForSale = "CLOSED FOR SALE",
   ClosedSite = "CLOSED SITE",
   Killed = "KILLED",
   Lead = "LEAD",
 }

 export const enum Evergreen {
   EvergreenCode = 99,
   EvergreenLetter = 'E'
}

export const enum AssetsSvtName {
   Sites = 'ASSET_SITES_ID',
   Pipes = 'ASSET_PIPES_ID',
   TLines = 'ASSET_TLINES_ID'
}

export const enum ReleaseDatabaseValues {
   Inflow = 'INFLOW',
   Live = 'LIVE'
}

export const enum ProjectReleaseValue {
   ProjectCapacity = 'PROJECTS_CAPACITY',
   PlannedOutputCapacity = 'PLANNED_OUTPUT_CAPACITY',
   InstalledOutputCapacity = 'INSTALLED_OUTPUT_CAPACITY',
   ProjectPecView = "PEC_VIEW",
   ProjectProjects = "PROJECTS|"
}

export const enum ReleaseActivityPlantValues {
  PlantsActivityInfo = 'PLANTS_ACTIVITY_INFO|',
  CapitalActivity  = 'CAPITAL_ACTIVITY',
  MroActivity = 'MRO_ACTIVITY',
  TotalActivity  = 'TOTAL_ACTIVITY'
}

export const enum CurrencySymbols {
   USD = '$',
}

export const enum PipelineTypes {
   Gathering = 'Gathering',
   Lateral = 'Lateral',
   MainTrunk = 'Main/Trunk'
}

export const enum OfflineEventStatusValue {
   Future = 'F',
   Ongoing = 'O',
   Cancelled = 'C',
   Past = 'P'
}

export const enum OfflineEventTypeValue {
   Planned = 'P',
   Unplanned = 'U'
}

export const enum SicCodeValue {
   PetroleumRefining = '2911',
   ElectricPower = '4939'
}

export const enum OfflineEventCauseValue {
   Weather = 3,
   Electrical = 4
}

export const enum OfflineMvtCommand {
   ReloadEquipments = 1,
   FilterEventCauses = 2,
   ValidateOfflineStatus = 3,
   ChangeOfflineStatus = 4,
   ChangeOEDateData = 5,
   updateUnitCapacity = 6
}

export const enum SelectedView {
  changeOnly = 'CO',
  fullRecord = 'FR',
  editorialFields = 'EF'
};

export const enum ReleaseButtonLabel {
  ApproveTechnically = 'Approve Technically',
  EditorApproveAndRelease = 'Editor Approve and Release',
  Release = 'Release'
}

export const enum SelectedViewProjectChanges {
  changeOnly = 'CO',
  fullRecord = 'FR',
  editorialFields = 'EF'
}

export const enum ReleaseApproval{
  none = '0',
  technical = '1',
  editor = '2',
  noPreference = '-1'
}

export const enum HexaColor {
   white = '#FFFFFF',
   black = '#000000',
   gray = '#808080',
   silver = '#CCCCCC',
   red = '#FF0000',
   yellow = '#FFFF00',
   lightGreen = '#C0FFC0',
   lightGray = '#B9B9B9'
}

export const enum LTSAEvergreen {
   Code = 99, 
   Letter = 'E'
}

export const enum LTSAStatusValues {
   Cancelled = 2, 
   Completed = 3
}

export const enum LTSAStatusDescValues {
   Active = 'Active',
}

export const enum ServiceAgreementTypes {
   Maintenance = 1, 
   Capital = 2, 
   Operations = 3, 
   Rentals = 4
}

export const enum ActiveEntityLabels {
   YES = 'Yes',
   NO = 'No',
   UNKNOWN = 'Unknown'
};

export const enum ResolutionIdValues {
   Weak = 'W',
};

export const enum PreferencePrefix{
   Preferences = 'Preferences_',
   RelatedInfo = "RelatedInfo_",
   Search = "SearchModal_"
}

export const enum PreferenceSufix{
   DefaultGeneralResults = 'DefaultGeneralResults',
   DefaultEntity = 'DefaultEntity',
   DefaultIndustryCodes = 'DefaultIndustryCodes',
   DefaultOfficeLocations = 'DefaultOfficeLocations'
}

export const enum EntityPreferenceSufix{
   Entity = 'Entity',
   PoolContact = 'PoolContact',
   SlottedContact = 'SlottedContact',
   Contact = 'Contact',
   Alerts = 'Alerts',
   OEAssets = 'Assets',
   OEUnits = 'Units',
   OEPlants = 'Plants'
}


/**
 * General Use Constants
 *
 * NOTE 1: Everything in here is generally public and should also be static & readonly
 * NOTE 2: Place values first, and functions at the bottom to avoid some odd syntax issues
 */

export class Constants {

    public static readonly NEWLINE = "\n";
    public static readonly DOUBLE_NEWLINE = "\n\n";

    public static readonly APP_TITLE = 'IIR ED Application';

    public static readonly SYSTEM_ERROR_COMMUNICATION = "Unable to communicate with server.";
    public static readonly SYSTEM_ERROR_SERVER_ISSUE = 'An error has occurred on the server.';
    public static readonly SYSTEM_ERROR_TRACKING_CODE = 'Your tracking code is \n<b>{0}</b>.\n\nIf you continue to experience this problem, click on the "Report to System Administrator" button to notify us.';


    public static readonly LINKEDIN_BASE_URL = 'https://www.linkedin.com/in/';
    public static readonly OVERRIDE_BAD_EMAIL_JSP = '/emailOverride/overrideBadEmail.jsp';

    public static readonly COUNTRY_ID_USA = "US";
    public static readonly COUNTRY_ID_CANADA = "CA";

    // Plant Industrial Info
    public static readonly SIC_CODE_ELECTRIC_POWER = "4939";

    // Minimum dates
    public static readonly MIN_YEAR = 1901;

    public static readonly MIN_DATE = '1500-01-01';
    public static readonly MIN_YEAR_MONTH = 198001;
    public static readonly MIN_KICKOFF_DATE = '2003-01-01';

    // PEC Activity
    public static readonly PEC_ACTIVITY_COMPLETION_DESC = 'Completion/Start-Up';

    // Messages
    public static readonly WARNING_RECORD_FAILED_VALIDATION = 'Warning - Record Failed Validation!';
    public static readonly WARNING_RECORD_FAILED_RELEASE = 'Warning - Record Failed Release!';
    public static readonly WARNING_COMPANY_ASSOCIATED = 'Company cannot be associated. \n Please use a company with Company Status - Active and Record Status - Enhanced, Standard or Unconfirmed.';

    public static readonly MESSAGE_EMAIL_INVALID = 'The Email format is incorrect.<br>';
    public static readonly MESSAGE_CONTAC_ALREADY_OPEN = 'You currently have a Contact Associations window opened for this contact.';
    public static readonly MESSAGE_GENERIC_NOT_EXISTS = 'No results were found';
    public static readonly MESSAGE_ON_SITE_MAILING_ADDRESSES_NOT_EXISTS = 'No On-Site mailing addresses have been found for this contact record.';
    public static readonly MESSAGE_COMPANY_CANNOT_OWN_ITSELF = 'A company cannot own itself';
    public static readonly MESSAGE_ENTITY_NOT_EXISTS = '{0} does not exist or is not within your coverage. <br> Please enter a valid ID.';
    public static readonly MESSAGE_MARKETING_EMAIL_STATUS_BAD = 'It has been determined by marketing that this e-mail address is invalid. Click here to update status.';
    public static readonly MESSAGE_MARKETING_EMAIL_STATUS_FILTERED = 'The e-mail address is valid, but has filters in place to block bulk e-mail messaging';
    public static readonly MESSAGE_MARKETING_EMAIL_OVERRIDE_STATUS_GOOD = 'This e-mail address was overridden as valid on {0} by {1}.\nClick here if you want to clear status override.';
    public static readonly MESSAGE_LINKEDIN_PROFILE_URL_INVALID_FORMAT = "The LinkedIn Profile URL format is incorrect. Enter only the portion after 'www.linkedin.com/in/'. Refer to the ED Guide for a more detailed explanation.";
    public static readonly MESSAGE_LINKEDIN_PROFILE_URL_INVALID_CHARACTERS = 'The LinkedIn Profile URL contains unsuported characters. Please check that it is entered correctly. Refer to the ED Guide for a more detailed explanation.';

    public static readonly ERROR_SELECT_COUNTRY_FIRST = 'Please select a Country first';
    public static readonly ERROR_SELECT_WATERBODY_FIRST = 'Please select a Water Body first';
    public static readonly ERROR_FIELD_IS_REQUIRED = '{0} is required.<br>';

    public static readonly CONFIRM_SAVE_RECORD = 'Do you want to save the record?';
    public static readonly CONFIRM_RELEASE_AMENDMENT = 'Do you want to release the Amendment?';
    public static readonly CONFIRM_RELEASE_UPDATE = 'Do you want to release the Update?';
    public static readonly CONFIRM_RELEASE_ENTITY = 'Do you want to release the {0}?';
    public static readonly CONFIRM_SELL_NOTE_CHANGE = 'Marking this comment as \'\'Sell\'\' will make the comment visible on PECWeb to clients and unselect the previous comment marked as \'\'Sell\'\'.\n Do you want to proceed?';
    public static readonly CONFIRM_SELL_NOTE_NEW = 'Marking this comment as \'\'Sell\'\' will make the comment visible on PECWeb to clients.\n Do you want to proceed?';
    public static readonly CONFIRM_SELL_NOTE_CHANGE_BLNAK = 'This comment was previously marked as \'\'Sell\'\'. Deleting the text will remove it from PECWeb.\n Do you want to proceed?';
    public static readonly CONFIRM_CLEAR_LOCKS = 'You have the following records locked in another session:<br>{0}<br>Do you want to clear these locks?';
    public static readonly CONFIRM_SAVE_RECORD_EDITED = "The record has been edited.\n Do you want to save the changes?";
    public static readonly CONFIRM_ADD_CONTACT = 'You are about to add a {0} contact to this record. Do you wish to continue?';
    public static readonly CONFIRM_CHANGE_STOCK_EXCHANGE = 'Changing the Stock Exchange to Select One will clear the Stock Symbol Field.\n Do you wish to continue?';
    public static readonly CONFIRM_CHANGE_OFFSHORE_SITES_STATUS = 'Changing the Offshore status of this site will clear the Physical Address fields. Do you wish to continue?';
    public static readonly CONFIRM_CANCEL_SENDING_ALERT = "Are you sure you want to cancel sending this alert?";
    public static readonly CONFIRM_RETRY_SENDING_ALERT = "Are you sure you want to retry sending this alert?";



    // Validation error
    public static readonly COMPANY_ID_REQUIRED = 'Company ID is required. <br>';
    public static readonly COMPANY_NAME_REQUIRED = 'Company Name is required. <br>';
    public static readonly COMPANY_STATUS_REQUIRED = 'Company Status is required. <br>';
    public static readonly COMPANY_CLASSIFICATION_REQUIRED = 'Classification is required. <br>';
    public static readonly COMPANY_PHYSICAL_COUNTRY_REQUIRED = 'Physical Country is required. <br>';
    public static readonly COMPANY_PHYSICAL_POSTAL_REQUIRED = 'Physical Postal Code is required. <br>';
    public static readonly COMPANY_PHYSICAL_ADDRESS_REQUIRED = 'Physical Address is required. <br>';
    public static readonly COMPANY_PHYSICAL_CITY_REQUIRED = 'Physical City is required. <br>';
    public static readonly COMPANY_PHYSICAL_STATE_REQUIRED = 'Physical State is required. <br>';
    public static readonly COMPANY_MAIL_COUNTY_REQUIRED = 'Mail Country is required. <br>';
    public static readonly COMPANY_MAIL_POSTAL_CODE_REQUIRED = 'Mail Postal Code is required. <br>';
    public static readonly COMPANY_RECORD_STATUS_REQUIRED = 'Record Status is required. <br>';
    public static readonly COMPANY_MAIL_ADDRESS_REQUIRED = 'Mail Address is required. <br>';
    public static readonly COMPANY_MAIL_STATE_REQUIRED = 'Mail State is required. <br>';
    public static readonly COMPANY_MAIL_CITY_REQUIRED = 'Mail City is required. <br>';
    public static readonly COMPANY_STOCK_SYMBOL_REQUIRED = 'If a Stock Exchange is selected the Stock Symbol is required. <br>';
    public static readonly COMPANY_SIC_CODES_REQUIRED = 'SIC Code Description is required. <br>';
    public static readonly COMPANY_TYPE_DESCRIPTION_REQUIRED = 'Type Description is required. <br>';
    public static readonly ERROR_INVALID_DUNS_NUMBER = "The DUNS ID format should be NN-NNN-NNNN";
    public static readonly CONTACT_NAME_REQUIRED = 'The First Name and Last Name are required <br>';
    public static readonly ERROR_VALID_COMPANY_STATUS = 'You must specify at least one Company Status.';
    public static readonly COMPANY_TYPE_REQUIRED = 'Company Type is required. <br>';
    public static readonly COMPANY_MAIL_PHONE_CC_REQUIRED = 'Telephone Country is required. <br>';
    public static readonly COMPANY_MAIL_PHONE_NO_REQUIRED = 'Telephone Number is required. <br>';
    public static readonly ERROR_GEOGRAPHY_NOT_AVAILABLE = 'Geography not available in your coverage';
    public static readonly ASSETS_SITES_PRODUCTS_REQUIRED = 'Product Description is required. <br>';

    // Information
    public static readonly ON_SITE_SELECTION_REMOVED = "The On-Site selection was removed for this Contact. \n Please view the On-Site mailing addresses and import the appropriate address for this contact.";
    public static readonly DUNS_FORMAT_ERROR = "The DUNS Number format should be entered in\nNN-NNN-NNNN.";
    public static readonly ERROR_INVALID_YEAR_ESTABLISHED = "The Year Established must be between 1500 and the current year.";
    public static readonly ERROR_WRONG_LATITUDE = "The Latitude must be between -90 and 90.";
    public static readonly ERROR_WRONG_LONGITUDE = "The Longitude must be between -180 and 180.";

    public static readonly EXCEPTION_SESSION_INVALID = "com.IIR.exception.SessionInvalidException";
    public static readonly EXCEPTION_REGISTRATION = "com.IIR.genericquery.exception.RegistrationException";

    public static readonly MAX_ROWS_LIMITATION_MESSAGE = 'results, please refine your search';

    // TODO: Get rid of this -- see: NED-333
    public static readonly REGEXP_EMAIL_VALIDATION_RFC2822 = /^([a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)$/i;

    public static readonly DELAY_BUTTON_FOCUS_MS = 3000;

    public static readonly STANDARD_DATE_FORMAT = 'dd-MMM-y';

    public static readonly NGX_MASK_CONFIG = {thousandSeparator: ','};

    public static readonly PIPELINE_SYSTEM = 'isPipeSystem';
    public static readonly TLINE_SYSTEM = 'isTSystem';

    // TODO: Get rid of this -- see: NED-332
    public static ENTITIES_VIEWS_DATA: any = {
        COMPANY: ["COMPANY_ID", "COMPANY_MV"],
        PLANT: ["PLANT_ID", "PLANT_VIEW_MV"],
        PLANT_LTSA: ["PLANT_LTSA_ID", "PLANT_LTSA_MV"],
        UNIT_LTSA: ["UNIT_LTSA_ID", "UNIT_LTSA_MV"],
        ASSET_PIPELINE: ["PIPELINE_ID", "ASSETS_PIPES_MV"],
        ASSET_TRANSMISSION_LINE: ["TLINE_ID", "ASSETS_TLINES_MV"],
        UNIT: ["UNIT_ID", "UNIT_MV"],
        TURBINE: ["TURBINE_ID", "TURBINEPROFILE_VIEW"],
        BOILER: ["BOILER_ID", "BOILERPROFILE_VIEW"],
        PROJECT_CONFIRMED: ["PROJECT_ID", "PEC_VIEW"],
        PROJECT_UNCONFIRMED: ["PROJECT_ID", "PEC_VIEW"],
        MEQUIPMENT: ["MINING_EQUIPMENT_ID", "MINING_EQUIPMENT_MV"],
        OEQUIPMENT: ["EQUIPMENT_ID", "EQUIPMENT_MV"],
        OFFLINE_EVENT: ["OFFLINE_EVENT_ID", "OFFLINE_EVENT_VIEW"]
    };

    public static ROWS_PER_PAGE_OPTIONS = [5, 10, 25, 50, 100, 1000];


    public static readonly REGEX_VALID_LINKEDIN_ID = Constants.buildLinkedInIdRegex();




    /**
     * Replace a parameterized constant with runtime values.  Such as:  What goes {0} must come {1}
     */
    public static replace(tmp: string, args: string[]): string {
        for (let i = 0;i < args.length; i++) {
            tmp = tmp.replaceAll(`{${i}}`, args[i]);
        }
        return tmp;
    }

    private static buildLinkedInIdRegex() {
       // This defines a bunch of charcters & character ranges that we will allow in linkedin id values
       // They will become a single large regex character class
       // It is defined this way for easy self-documentation, human readability, and ease of maintenance
       const pieces = [
          "!\u{0022}$%",          // exclamation, double quote, dollar, percent
          "'-*",                  // single quote, parenthesis, asterisk
          ",-.",                  // comma, hyphen, period
          "0->",                  // numbers, colon, semicolon, less, equal, greater
          "@-~",                  // at, letters, brackets, backslash, caret, underscore, backquote, braces, pipe, tilde
          "\u{0080}-\u{00FE}",    // accented characters and other printable extended ASCII symbols
          "\\p{L}\\p{N}\\p{S}",   // Unicode categories for all latters, numbers, and symbols
       ];

       return new RegExp("^[" + pieces.join("") + "]+$", "u");
    }

}

