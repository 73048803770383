import {Bean} from "../../bean";

export class CompaniesContactInfo extends Bean {
    companyId: number;
    contactFunction: string;
    contactId: number;
    contactLastCompany: string;
    contactLastDate: string;
    contactLastUser: string;
    contactPhoneCc: number;
    contactPhoneNo: string;
    contactPhoneExt: string;
    contactPhoneMobile: string;
    contactQcDate: string;
    contactQcUser: string;
    contactSaleFlag: string;
    contactStatus: string;
    contactTitleDesc: string;
    contactTitle: string;
    contactOnsite: string;
    mailAddressLine1: string;
    mailAddressLine2: string;
    mailPostalCode: string;
    mvOrder: number;
    mvId: number;
    contactFaxCc: number;
    contactFaxNo: string;
    contactFaxExt: string;
    mailAddressVerified: string;
    mailAddressVerifiedDate: string;
    contactAltPhoneCc: number;
    contactAltPhoneNo: string;
    contactAltPhoneExt: string;
    contactAltPhoneMobile: string;

    mailCountryId: number;
    mailStateId: number;
    mailCityId: number;

    protected constructor(entity?: any) {
        super(entity);

        this.companyId = entity && entity.companyId || null;
        this.contactFunction = entity && entity.contactFunction || null;
        this.contactId = entity && entity.contactId || null;
        this.contactLastCompany = entity && entity.contactLastCompany || null;
        this.contactLastDate = entity && entity.contactLastDate || null;
        this.contactLastUser = entity && entity.contactLastUser || null;
        this.contactPhoneCc = entity && entity.contactPhoneCc || null;
        this.contactPhoneNo = entity && entity.contactPhoneNo || null;
        this.contactPhoneExt = entity && entity.contactPhoneExt || null;
        this.contactPhoneMobile = entity && entity.contactPhoneMobile || null;
        this.contactQcDate = entity && entity.contactQcDate || null;
        this.contactQcUser = entity && entity.contactQcUser || null;
        this.contactSaleFlag = entity && entity.contactSaleFlag || null;
        this.contactStatus = entity && entity.contactStatus || null;
        this.contactTitleDesc = entity && entity.contactTitleDesc || null;
        this.contactTitle = entity && entity.contactTitle || null;
        this.contactOnsite = entity && entity.contactOnsite || null;
        this.mailAddressLine1 = entity && entity.mailAddressLine1 || null;
        this.mailAddressLine2 = entity && entity.mailAddressLine2 || null;
        this.mailPostalCode = entity && entity.mailPostalCode || null;
        this.mvOrder = entity && entity.mvOrder || null;
        this.mvId = entity && entity.mvId || null;
        this.contactFaxCc = entity && entity.contactFaxCc || null;
        this.contactFaxNo = entity && entity.contactFaxNo || null;
        this.contactFaxExt = entity && entity.contactFaxExt || null;
        this.mailAddressVerified = entity && entity.mailAddressVerified || '0';
        this.mailAddressVerifiedDate = entity && entity.mailAddressVerifiedDate || null;
        this.contactAltPhoneCc = entity && entity.contactAltPhoneCc || null;
        this.contactAltPhoneNo = entity && entity.contactAltPhoneNo || null;
        this.contactAltPhoneExt = entity && entity.contactAltPhoneExt || null;
        this.contactAltPhoneMobile = entity && entity.contactAltPhoneMobile || null;
        this.mailCountryId = entity && entity.mailCountryId || null;
        this.mailStateId = entity && entity.mailStateId || null;
        this.mailCityId = entity && entity.mailCityId || null;
    }
}
