import { Bean } from "../../../bean";
import { Contacts } from "../../../contact/save/contacts";
import { AssetsCompanyInfo } from "./assets-company-info";
import { AssetsContactInfo } from "./assets-contact-info";
import { AssetsInternalAttachments } from "./assets-internal-attachments";
import { AssetsMaintenanceBudget } from "./assets-maintenance-budget";
import { AssetsNotes } from "./assets-notes";
import { AssetsProducts } from "./assets-products";
import { AssetsProjects } from "./assets-projects";

export abstract class Assets extends Bean {
    assetId: number;
    assetTypeId: number;
    assetName: string;
    operatorId: number;
    originPhysAddressV1: string;
    originPhysAddressV2: string;
    originPhysCity: number;
    originPhysState: number;
    originPhysPostalCode: string;
    originPhysCounty: number;
    originPhysCountry: number;
    originLatitude: number;
    originLongitude: number;
    originLatLonVerified: string;
    origOffshoreStateDesignation: number;
    originAssetId: number;
    originAssetTypeId: number;
    originOffshoreBlockV1: string;
    originOffshoreBlockV2: string;
    originOffshoreFieldName: string;
    originOffshoreWaterbodyId: number;
    originOffshoreFieldId: number;
    originOffshoreAreaId: number;
    originOffshore: boolean;
    recordStatus: string;
    startupDate: string;
    startupDateResolution: number;
    shutdownDate: string;
    shutdownDateResolution: number;
    operationalDate: string;
    operationalDateResolution: number;
    completionDate: string;
    completionDateResolution: number;
    phoneCc: number;
    phoneNo: string;
    phoneExt: string;
    phoneMobile: string;
    faxCc: number;
    faxNo: string;
    faxExt: string;
    entryUser: string;
    entryCompany: string;
    entryDate: string;
    lastUser: string;
    lastCompany: string;
    lastDate: string;
    prevUser: string;
    prevCompany: string;
    prevDate: string;
    qcUser: string;
    qcDate: string;
    liveDate: string;
    releaseDate: string;
    releaseUser: string;
    amendmentDate: string;
    amendmentUser: string;
    initialCapitalInvestment: number;
    initialCapitalCurrency: string;

    assetsContactInfo: Array<AssetsContactInfo>;
    assetsCompanyInfo: Array<AssetsCompanyInfo>;
    assetsProducts: Array<AssetsProducts>;
    assetsNotes: Array<AssetsNotes>;
    assetsInternalAttachments: Array<AssetsInternalAttachments>;
    assetsProjects: Array<AssetsProjects>;
    assetsMaintenanceBudget: Array<AssetsMaintenanceBudget>;
    contacts: Array<Contacts>;

    protected constructor(entity?: any) {
        super(entity);

        this.assetId = entity && entity.assetId || null;
        this.assetTypeId = entity && entity.assetTypeId || null;
        this.assetName = entity && entity.assetName || null;
        this.operatorId = entity && entity.operatorId || null;
        this.originPhysAddressV1 = entity && entity.originPhysAddressV1 || null;
        this.originPhysAddressV2 = entity && entity.originPhysAddressV2 || null;
        this.originPhysCity = entity && entity.originPhysCity || null;
        this.originPhysState = entity && entity.originPhysState || null;
        this.originPhysPostalCode = entity && entity.originPhysPostalCode || null;
        this.originPhysCounty = entity && entity.originPhysCounty || null;
        this.originPhysCountry = entity && entity.originPhysCountry || null;
        this.originLatitude = (entity && entity.originLatitude) ?? null;
        this.originLongitude = (entity && entity.originLongitude) ?? null;
        this.originLatLonVerified = entity && entity.originLatLonVerified || null;
        this.origOffshoreStateDesignation = entity && entity.origOffshoreStateDesignation || null;
        this.originAssetId = entity && entity.originAssetId || null;
        this.originAssetTypeId = entity && entity.originAssetTypeId || null;
        this.originOffshoreBlockV1 = entity && entity.originOffshoreBlockV1 || null;
        this.originOffshoreBlockV2 = entity && entity.originOffshoreBlockV2 || null;
        this.originOffshoreFieldName = entity && entity.originOffshoreFieldName || null;
        this.originOffshoreWaterbodyId = entity && entity.originOffshoreWaterbodyId || null;
        this.originOffshoreFieldId = entity && entity.originOffshoreFieldId || null;
        this.originOffshoreAreaId = entity && entity.originOffshoreAreaId || null;
        this.originOffshore = entity && entity.originOffshore || false;
        this.recordStatus = entity && entity.recordStatus || null;
        this.startupDate = entity && entity.startupDate || null;
        this.startupDateResolution = entity && entity.startupDateResolution || null;
        this.shutdownDate = entity && entity.shutdownDate || null;
        this.shutdownDateResolution = entity && entity.shutdownDateResolution || null;
        this.operationalDate = entity && entity.operationalDate || null;
        this.operationalDateResolution = entity && entity.operationalDateResolution || null;
        this.completionDate = entity && entity.completionDate || null;
        this.completionDateResolution = entity && entity.completionDateResolution || null;
        this.phoneCc = entity && entity.phoneCc || null;
        this.phoneNo = entity && entity.phoneNo || null;
        this.phoneExt = entity && entity.phoneExt || null;
        this.phoneMobile = entity && entity.phoneMobile || null;
        this.faxCc = entity && entity.faxCc || null;
        this.faxNo = entity && entity.faxNo || null;
        this.faxExt = entity && entity.faxExt || null;
        this.entryUser = entity && entity.entryUser || null;
        this.entryCompany = entity && entity.entryCompany || null;
        this.entryDate = entity && entity.entryDate || null;
        this.lastUser = entity && entity.lastUser || null;
        this.lastCompany = entity && entity.lastCompany || null;
        this.lastDate = entity && entity.lastDate || null;
        this.prevUser = entity && entity.prevUser || null;
        this.prevCompany = entity && entity.prevCompany || null;
        this.prevDate = entity && entity.prevDate || null;
        this.qcUser = entity && entity.qcUser || null;
        this.qcDate = entity && entity.qcDate || null;
        this.liveDate = entity && entity.liveDate || null;
        this.releaseDate = entity && entity.releaseDate || null;
        this.releaseUser = entity && entity.releaseUser || null;
        this.amendmentDate = entity && entity.amendmentDate || null;
        this.amendmentUser = entity && entity.amendmentUser || null;
        this.initialCapitalInvestment = (entity && entity.initialCapitalInvestment) ?? null;
        this.initialCapitalCurrency = entity && entity.initialCapitalCurrency || null;

        this.assetsContactInfo = entity && entity.assetsContactInfo || [];
        this.assetsCompanyInfo = entity && entity.assetsCompanyInfo || [];
        this.assetsProducts = entity && entity.assetsProducts || [];
        this.assetsNotes = entity && entity.assetsNotes || [];
        this.assetsInternalAttachments = entity && entity.assetsInternalAttachments || [];
        this.assetsProjects = entity && entity.assetsProjects || [];
        this.assetsMaintenanceBudget = entity && entity.assetsMaintenanceBudget || [];

        this.contacts = entity && entity.contacts || [];
    }
}
