// Models
import { Address } from '../../address';
import { OperationType } from '../../enumerations/operation-type';
import { Companies } from '../save/companies';
import { CompaniesCompanyInfoDisplay } from './companies-company-info-display';
import { CompaniesSICCodesDisplays } from '../display/sic-codes-info-display';
import { CompaniesNotesDisplay } from './companies-notes-display';
import { CompaniesSalesInfoDisplay } from '../display/companies-sales-info-displays';
import { CompaniesCompanyTypesDisplay } from '../display/companies-company-types-display';
import { CompaniesContactInfoDisplay } from './companies-contact-info-display';
import { ContactsDisplay } from '../../contact/display/contacts-display';
import { CompaniesFaxInfoDisplay } from './companies-fax-info-display';
import { CompaniesPhoneInfoDisplay } from './companies-phone-info-display';
import { IAuditEntityData } from 'src/app/core/interfaces/iaudit-entity-data';
import { EntityName } from '../../enumerations/entity-name';
import { CompaniesGreenhouseGasEmissionsDisplay } from '../display/companies-greenhouse-gas-emissions-display';

export class CompaniesDisplay extends Companies {
    readonly amendUser: string;
    readonly defCountryCode: string;
    readonly physCountry: string;
    readonly physCountryDesc: string;
    readonly physAddress: string;
    readonly physState: string;
    readonly physStateDesc: string;
    readonly physCity: string;
    readonly mailCountry: string;
    readonly telephone: string;
    readonly mailCity: string;
    readonly mailState: string;
    readonly mailCountryDesc: string;
    readonly mailStateDesc: string;
    readonly fax: string;
    readonly physicalAddress: Address;
    readonly physicalEmail: Address;

    companiesSalesInfoDisplays: Array<CompaniesSalesInfoDisplay>;
    companiesSICCodesDisplays: Array<CompaniesSICCodesDisplays>;
    companiesCompanyInfoDisplays: Array<CompaniesCompanyInfoDisplay>;
    companiesContactInfoDisplays: Array<CompaniesContactInfoDisplay>;
    companiesNotesDisplays: Array<CompaniesNotesDisplay>;
    companiesFaxInfoDisplays: Array<CompaniesFaxInfoDisplay>;
    companiesPhoneInfoDisplays: Array<CompaniesPhoneInfoDisplay>;
    companiesCompanyTypesDisplays: Array<CompaniesCompanyTypesDisplay>;
    companiesGreenhouseGasEmissionsDisplay: Array<CompaniesGreenhouseGasEmissionsDisplay>;

    contactsDisplays: Array<ContactsDisplay>;

    private constructor(entity?: any) {
        super(entity);

        this.amendUser = entity && entity.amendUser || null;
        this.defCountryCode = entity && entity.defCountryCode || null;
        this.physCountry = entity && entity.physCountry || null;
        this.physAddress = entity && entity.physAddress || null;
        this.physState = entity && entity.physState || null;
        this.physCity = entity && entity.physCity || null;
        this.telephone = entity && entity.telephone || null;
        this.mailCountry = entity && entity.mailCountry || null;
        this.mailCity = entity && entity.mailCity || null;
        this.mailState = entity && entity.mailState || null;
        this.mailCountryDesc = entity && entity.mailCountryDesc || null;
        this.mailStateDesc = entity && entity.mailStateDesc || null;
        this.fax = entity && entity.fax || null;
        this.physicalAddress = entity && entity.physicalAddress || null;
        this.physicalEmail = entity && entity.physicalEmail || null;

        this.physicalAddress = Address.BuildPhysicalAddress(entity);
        this.physicalEmail = Address.BuildMailingAddress(entity);

        this.companiesSalesInfoDisplays = entity && entity.companiesSalesInfoDisplays || [];
        this.companiesCompanyInfoDisplays = entity && entity.companiesCompanyInfoDisplays || [];
        this.companiesSICCodesDisplays = entity && entity.companiesSICCodesDisplays || [];
        this.companiesNotesDisplays = entity && entity.companiesNotesDisplays || [];
        this.companiesCompanyTypesDisplays = entity && entity.companiesCompanyTypesDisplays || [];
        this.companiesContactInfoDisplays = entity && entity.companiesContactInfoDisplays || [];
        this.companiesFaxInfoDisplays = entity && entity.companiesFaxInfoDisplays || [];
        this.companiesPhoneInfoDisplays = entity && entity.companiesPhoneInfoDisplays || [];
        this.companiesGreenhouseGasEmissionsDisplay = entity && entity.companiesGreenhouseGasEmissionsDisplay || [];
        
        this.contactsDisplays = entity && entity.contactsDisplays || [];
    }

    static BuildCompanyDisplay(entity: any): CompaniesDisplay {
        return new CompaniesDisplay(entity);
    }

    static BuildNewCompany(response: any): CompaniesDisplay {
        return new CompaniesDisplay({
            companyId: response[0].COMPANY_ID,
            operation: OperationType.INSERT,
            intDataDepValState: OperationType.INSERT,
            stockExchange: '0'
        });
    }

    get auditEntityBean(): IAuditEntityData {
        return {
            lastUser: this.lastUser,
            entryUser: this.entryUser,
            prevUser: this.prevUser,
            qcUser: this.qcUser,
            lastDate: this.lastDate,
            entryDate: this.entryDate,
            prevDate: this.prevDate,
            qcDate: this.qcDate,
            releaseUser: this.releaseUser,
            releaseDate: this.releaseDate,
            amendmentUser: this.amendmentUser,
            amendmentDate: this.amendmentDate,
            lastUserEmailAddress: this.lastUserEmailAddress,
            entryUserEmailAddress: this.entryUserEmailAddress,
            previousUserEmailAddress: this.previousUserEmailAddress,
            qcUserEmailAddress: this.qcUserEmailAddress,
            releaseUserEmailAddress: this.releaseUserEmailAddress,
            amendmentUserEmailAddress: this.amendmentUserEmailAddress,
            lastUpdateStatus: '',
            entityName: EntityName.COMPANY,
            entityId: this.companyId
        };
    }

    setPhysicalAddress(address: Address): void {
        this.physPostalCode = address.zipCode;
        this.physAddressV1 = address.address1;
        this.physAddressV2 = address.address2;
        this.pecZoneSimplex = address.pecZoneId;
        this.physCountryId = address.c_id;
        this.physStateId = address.s_id;
        this.physCityId = address.ci_id;
        this.physCountyId = address.co_id;
    }

    setMailingAddress(address: Address): void {
        this.mailPostalCode = address.zipCode;
        this.mailAddressV1 = address.address1;
        this.mailAddressV2 = address.address2;
        this.mailCountryId = address.c_id;
        this.mailStateId = address.s_id;
        this.mailCityId = address.ci_id;
    }

    setDetails(detailsForms: any) {
        this.dunsNumber = detailsForms.dunsNumber;
        this.numEmployees = detailsForms.numEmployees;
        this.yearEstablished = detailsForms.yearEstablished;
    }

    prepareSaveCollection() {
        this.companiesGreenhouseGasEmissions = this.companiesGreenhouseGasEmissionsDisplay;
    }
}

