<!-- Message Dialog Component for display Info -->
<app-message-dialog></app-message-dialog>
<p-dialog [(visible)]="displayDialog" modal="true" [style]="{width: '750px'}" [closable]="false">
    <ng-template pTemplate="header">{{ title }}</ng-template>

    <div class="title-desc">{{ "saveModal.title" | translate }}</div>

    <div class="grid">
        <div class="col-4 mb-6 xl:mb-3 lg:mb-3 xs text-xs xl:col-3 lg:col-3">
            <button pButton pRipple label="{{'saveModal.button.wip'|translate}}" class="w-full" (click)="wip()" [disabled]="!wipButtonEnabled"></button>
        </div>

        <div class="col-8 text-sm">{{ "saveModal.wip" | translate }}</div>
    </div>

    <div class="grid">
        <div class="col-4 mb-6 xl:mb-3 lg:mb-3 text-xs xl:col-3 lg:col-3">
            <button pButton pRipple label="{{'saveModal.button.update'|translate}}" class="w-full" (click)="update()" [disabled]="!updateButtonEnabled"></button>
        </div>

        <div class="col-8 text-sm">{{ "saveModal.update" | translate }}</div>
    </div>

    <div class="grid">
        <div class="col-4 mb-6 xl:mb-3 lg:mb-3 text-xs xl:col-3 lg:col-3">
            <button pButton pRipple label="{{'saveModal.button.qc'|translate}}" class="w-full" (click)="qc()" [disabled]="!qcButtonEnabled"></button>
        </div>

        <div class="col-8 text-sm">{{ "saveModal.qc" | translate }}</div>
    </div>

    <div class="grid">
        <div class="col-4 mb-6 xl:mb-3 lg:mb-3 text-xs xl:col-3 lg:col-3">
            <button pButton pRipple label="{{'saveModal.button.justSave'|translate}}" class="w-full" (click)="justSave()" [disabled]="!justSaveButtonEnabled"></button>
        </div>

        <div class="col-8 text-sm">{{ "saveModal.justSave" | translate }}</div>
    </div>

    <div class="grid">
        <div class="col-4 mb-6 xl:mb-3 lg:mb-3 text-xs xl:col-3 lg:col-3">
            <button pButton pRipple label="{{'saveModal.button.amendment'|translate}}" class="w-full" (click)="ammend()" [disabled]="!amendButtonEnabled"></button>
        </div>

        <div class="col-8 text-sm">{{ "saveModal.amendment" | translate }}</div>
    </div>

    <div class="grid">
        <div class="col-4 mb-6 xl:mb-3 lg:mb-3 text-xs xl:col-3 lg:col-3">
            <button pButton pRipple label="{{'saveModal.button.cancel'|translate}}" class="w-full" [autofocus]="true" (click)="cancel()"></button>
        </div>

        <div class="col-8 text-sm">{{ "saveModal.cancel" | translate }}</div>
    </div>

    <div class="alert-lbl -mt-2 text-sm" *ngIf="entityName === ENTITY_PLANT || entityName === ENTITY_OFFLINE">{{ "saveModal.note" | translate }}</div>

</p-dialog>