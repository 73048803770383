<form [formGroup]="formGroupAddress">
        <div class="main-section">
            <div class="header" *ngIf="!showInContacts">
                <h3>{{addressTitle}}</h3>
                <a target="_blank" (click)="openModalAddressImport()">
                    <img *ngIf="assetId && !matchAddress && !newEntity" src="assets/images/orange-warning-triangle.png" alt="" pTooltip={{switchTooltipMessage()}}>
                </a>
            </div>
            <img class="header-icon-copy" src="assets/icons/pi-copy.png" alt="" *ngIf="showChkSameAsPhys && !offshore" (click)="copyPhysicalAddress()" style="float: right;cursor: pointer" pTooltip="Copy Physical to Mailing">
            <div class="mr-2 text-sm flex justify-content-end">
                <a *ngIf="addressType == 1 && operatorId != null" (click)="getCompanyAddress()"
                    [ngClass]="formGroupAddress.disabled ? 'not-active': ''" class="cursor-pointer basic-link">{{'site.sameAsOperator'|translate}}
                </a>
            </div>

            <div [ngClass]="{'mt-3': !showInContacts}">
                <!-- Country -->
                <div class="grid">
                    <label class="col-4 flex justify-content-end font-bold text-xs px-0 mt-1">{{'country'| translate}}:</label>
                    <div class="col-6 px-1" #countryDiv>
                        <input 
                            class="form__input-field-style w-4"
                            [tabindex]="baseIndex"
                            #focusCountry
                            formControlName="countryId"
                            appUppercase maxlength="4"
                            pInputText
                            (focusout)="countryChangeFocusOut()"
                            (keydown.enter)="countryChangeOnEnter()"
                            (keypress)="checkNoSymbols($event)"
                            (keyup)="cleanAddress()"
                            [style.color]="colors['countryId']" />
                        <input
                            [ngClass]="showInContacts ? 'disabled-field-color': ''"
                            class="form__input-field-style w-8"
                            formControlName="countryName"
                            pInputText
                            [style.color]="colors['countryName']" />
                    </div>
                    <div class="col-2 px-0">
                        <span class="required-field" *ngIf="showRequiredContactFields">*</span>
                        <button
                            class="p-button-text icon-container ml-2"
                            type="button"
                            pButton
                            pRipple
                            icon="pi pi-search"
                            [tabindex]="baseIndex + 1"
                            [disabled]="formGroupAddress.disabled"
                            (click)="openCountrySearchModal()">
                        </button>
                    </div>
                </div>
                <!-- Postal Code -->
                <div class="grid -mt-3 pt-1">
                    <label class="col-4 flex justify-content-end font-bold text-xs px-0 mt-1">{{'postal_code'| translate}}:</label>
                    <div class="col-6 px-1">
                        <input
                        class="form__input-field-style w-full"
                        #postalCodeFocus
                        appUppercase
                        [(ngModel)]="postal_code"
                        (input)="removeForbiddenChars()"
                        (keydown)="preventForbiddenChars($event)"
                        (paste)="handleChars($event)"
                        maxlength="11"
                        type="text"
                        formControlName="zipCode"
                        pInputText
                        [tabindex]="baseIndex + 2"
                        [style.color]="colors['zipCode']"/>
                    </div>
                    <div class="col-2 px-0">
                        <span class="required-field" *ngIf="showRequiredContactFields">*</span>
                    </div>
                </div>
                <!-- Address -->
                <div class="grid -mt-3 pt-1" *ngIf="!showInContacts">
                    <label class="col-4 flex justify-content-end font-bold text-xs px-0 mt-1">{{'address'| translate}}:</label>
                    <div class="col-6 px-1">
                        <input
                            class="form__input-field-style w-full"
                            type="text"
                            formControlName="address1"
                            [tabindex]="baseIndex + 3"
                            pInputText/>
                    </div>
                    <div class="col-2 px-0">
                        <span class="required-field">*</span>
                    </div>

                </div>
                <div class="grid -mt-3 pt-1" *ngIf="showInContacts">
                    <label class="col-4 flex justify-content-end font-bold text-xs px-0 mt-1">{{'address'| translate}}:</label>
                    <div class="col-6 px-1">
                        <input
                            class="form__input-field-style w-full"
                            type="text"
                            formControlName="address1"
                            pInputText
                            [tabindex]="baseIndex + 4"
                            [style.color]="colors['address1']"/>
                    </div>
                    <div class="col-2 px-0" *ngIf="showRequiredContactFields">
                        <span class="required-field">*</span>
                    </div>
                </div>
                <div class="grid -mt-3 pt-1">
                    <div class="col-4 px-0 mt-1"></div>
                    <div class="col-6 px-1">
                        <input
                            class="form__input-field-style w-full"
                            type="text"
                            type="text"
                            formControlName="address2"
                            pInputText
                            [tabindex]="baseIndex + 5"
                            [style.color]="colors['address2']"/>
                    </div>
                    <div class="col-2 px-0"></div>
                </div>
                <!-- State -->
                <div class="grid -mt-3 pt-1">
                    <label class="col-4 flex justify-content-end font-bold text-xs px-0 mt-1">{{'state'| translate}}:</label>
                    <div class="col-6 px-1">
                        <input
                            class="form__input-field-style w-4"
                            #focusState
                            type="text"
                            formControlName="stateAbbrev"
                            pInputText
                            appUppercase maxlength="4"
                            (focusout)="stateChangeFocusOut()"
                            (keydown.enter)="stateChangeOnEnter()"
                            (keyup)="cleanCityCountyAndPecZone()"
                            [tabindex]="baseIndex + 6"
                            [style.color]="colors['stateAbbrev']"/>
                        <input
                            [ngClass]="showInContacts ? 'disabled-field-color': ''"
                            class="form__input-field-style w-8"
                            type="text"
                            formControlName="stateName"
                            pInputText
                            [style.color]="colors['stateName']"/>

                    </div>
                    <div class="col-2 px-0">
                        <span class="required-field" *ngIf="showRequiredContactFields">*</span>
                    <button
                        class="p-button-text icon-container ml-2"
                        type="button"
                        pButton
                        pRipple
                        icon="pi pi-search"
                        [tabindex]="baseIndex + 7"
                        [disabled]="formGroupAddress.disabled"
                        (click)="stateChangeButton()">
                    </button>
                </div>
                </div>
                <!-- City -->
                <div class="grid -mt-3 pt-1">
                    <label class="col-4 flex justify-content-end font-bold text-xs px-0 mt-1">{{'city'| translate}}:</label>
                    <div class="col-6 px-1">
                        <input
                            class="form__input-field-style w-full"
                            #focusCity
                            type="text"
                            formControlName="cityName"
                            pInputText
                            (keydown.enter)="cityChangeEnterKey()"
                            (focusout)="cityChangeFocusOut()"
                            [tabindex]="baseIndex + 8"
                            [style.color]="colors['cityName']"
                            (keyup)="cleanCountyAndPecZone()"/>
                    </div>
                    <div class="col-2 px-0">
                    <span class="required-field" *ngIf="showRequiredContactFields">*</span>
                    <button
                        class="p-button-text icon-container ml-2"
                        type="button"
                        pButton
                        pRipple
                        icon="pi pi-search"
                        [tabindex]="baseIndex + 9"
                        [disabled]="formGroupAddress.disabled"
                        (click)="openCitySearchModal()">
                    </button>
                    </div>
                </div>
            </div>
            <!-- County -->
            <div class="grid -mt-3 pt-1" *ngIf="isNotMailingAddress()">
                <label class="col-4 flex justify-content-end font-bold text-xs px-0 mt-1">{{'county'| translate}}:</label>
                <div class="col-6 px-1">
                    <input
                        class="form__input-field-style w-full"
                        type="text"
                        formControlName="countyName"
                        pInputText/>
                </div>
                <div class="col-2 px-0"></div>
            </div>
            <!-- PEC Zone -->
            <div class="grid -mt-3 pt-1" *ngIf="showPecZone===true">
                <label class="col-4 flex justify-content-end font-bold text-xs px-0 mt-1">{{'pec_zone'| translate}}:</label>
                <div class="col-6 px-1">
                    <input
                        class="form__input-field-style w-full"
                        type="text"
                        formControlName="pecZoneId"
                        pInputText/>
                </div>
                <div class="col-2 px-0"></div>
            </div>
        </div>
</form>
