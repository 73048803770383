import { IOutput } from 'src/app/core/interfaces/ioutput';
import { PlantsOutputInfo } from '../save/plants-output-info'

export class PlantsOutputInfoDisplay extends PlantsOutputInfo implements IOutput {
    productDesc: string;
    lastVerifiedQcDate: string;
    lastVerifiedQcUser: string;
    verifiedQcDate: string;
    isAlertShow: boolean;
    previousOutputProdId: string;

    protected constructor(entity?: any) {
        super(entity);

        this.productDesc = entity && entity.productDesc || null;
        this.lastVerifiedQcDate = entity && entity.lastVerifiedQcDate || null;
        this.lastVerifiedQcUser = entity && entity.lastVerifiedQcUser || null;
        this.verifiedQcDate = entity && entity.verifiedQcDate || null;
        this.isAlertShow = entity && entity.isAlertShow || null;
        this.initialData = entity;
    }

    set plantProductId(value: string) {
        this.outputHsProduct = value;
    }

    get plantProductId(): string {
        return this.outputHsProduct;
    }

    set outputProdId(value: string) {
        this.outputHsProduct = value;
    }

    get outputProdId(): string {
        return this.outputHsProduct;
    }

    static CreateInstance(entity: any): PlantsOutputInfoDisplay {
        return new PlantsOutputInfoDisplay(entity);
    }

    static BuildFromList(entity: Array<any>): Array<PlantsOutputInfoDisplay> {
        return entity.map(item => PlantsOutputInfoDisplay.CreateInstance(item));
    }
}
