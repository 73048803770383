<div class="right-card">
    <div class="grid cont-user-date">
            <label class="col-3 flex justify-content-end font-bold text-xs px-0 ">{{'common.lastUser'|translate}}:</label>
            
            <label class="col-3 label-user" *ngIf="!displayLink(auditEntityBean?.lastUserEmailAddress)" for="">{{ auditEntityBean?.lastUser }}</label>
                <a class="col-3 basic-link label-user" *ngIf="displayLink(auditEntityBean?.lastUserEmailAddress)" (click)="openUserModal(auditEntityBean?.lastUser)">{{ auditEntityBean?.lastUser }}</a>
            
            <label class="col-5 date-user px-0">{{ auditEntityBean?.lastDate | date: 'dd-MMM-y HH:mm:ss' }}</label>
    </div>

    <div class="grid cont-user-date ">
            <label class="col-3 flex justify-content-end font-bold text-xs px-0 ">{{'common.prevUser' | translate}}:</label>
            
            <label class="col-3 label-user" *ngIf="!displayLink(auditEntityBean?.previousUserEmailAddress)" for="">{{ auditEntityBean?.prevUser }}</label>
                <a class="col-3 basic-link label-user" *ngIf="displayLink(auditEntityBean?.previousUserEmailAddress)" (click)="openUserModal(auditEntityBean?.prevUser)">{{ auditEntityBean?.prevUser }}</a>
            
            <label class="col-5 date-user px-0">{{ auditEntityBean?.prevDate | date: 'dd-MMM-y HH:mm:ss' }}</label>
    </div>

    <div class="grid cont-user-date ">
            <label class="col-3 flex justify-content-end font-bold text-xs px-0 ">{{'common.entryUser'| translate}}:</label>
            
            <label class="col-3 label-user" *ngIf="!displayLink(auditEntityBean?.entryUserEmailAddress)" for="">{{ auditEntityBean?.entryUser }}</label>
                <a class="col-3 basic-link label-user" *ngIf="displayLink(auditEntityBean?.entryUserEmailAddress)" (click)="openUserModal(auditEntityBean?.entryUser)">{{ auditEntityBean?.entryUser }}</a>
            
            <label class="col-5 date-user px-0">{{ auditEntityBean?.entryDate | date: 'dd-MMM-y HH:mm:ss' }}</label>
    </div>

    <div class="grid cont-user-date">
            <label class="col-3 flex justify-content-end font-bold text-xs px-0 ">{{'common.qcUser'| translate}}:</label>
            
            <label class="col-3 label-user" *ngIf="!displayLink(auditEntityBean?.qcUserEmailAddress)" for="">{{ auditEntityBean?.qcUser }}</label>
                <a class="col-3  basic-link label-user" *ngIf="displayLink(auditEntityBean?.qcUserEmailAddress)" (click)="openUserModal(auditEntityBean?.qcUser)">{{ auditEntityBean?.qcUser }}</a>
            
            <label class="col-5 date-user px-0">{{ auditEntityBean?.qcDate | date: 'dd-MMM-y HH:mm:ss' }}</label>
    </div>

    <div class="grid cont-user-date">
            <label class="col-3 flex justify-content-end font-bold text-xs px-0 ">{{'common.releaseUser'| translate}}:</label>
            
            <label class="col-3 label-user" *ngIf="!displayLink(auditEntityBean?.releaseUserEmailAddress)" for="">{{ auditEntityBean?.releaseUser }}</label>
                <a class="col-3 basic-link label-user" *ngIf="displayLink(auditEntityBean?.releaseUserEmailAddress)" (click)="openUserModal(auditEntityBean?.releaseUser)">{{ auditEntityBean?.releaseUser }}</a>
            <div class="col-5 date-user px-0">
                <label>{{ auditEntityBean?.releaseDate | date: 'dd-MMM-y HH:mm:ss' }}</label>
                <label class="ml-1" *ngIf="auditEntityBean?.lastUpdateStatus" for="" pTooltip="{{'common.lastReleaseStatusTooltip'|translate}}"> ({{ auditEntityBean?.lastUpdateStatus }})</label>
            </div>
    </div>

    <div class="grid cont-user-date">
            <label class="col-3 flex justify-content-end font-bold text-xs px-0 ">{{'common.amendUser'|translate}}:</label>
            
            <label class="col-3 label-user" *ngIf="!displayLink(auditEntityBean?.amendmentUserEmailAddress)" for="">{{ auditEntityBean?.amendmentUser }}</label>
                <a class="col-3 basic-link label-user" *ngIf="displayLink(auditEntityBean?.amendmentUserEmailAddress)" (click)="openUserModal(auditEntityBean?.amendmentUser)">{{ auditEntityBean?.amendmentUser }}</a>
            
            <label class="col-5 date-user px-0">{{ auditEntityBean?.amendmentDate | date: 'dd-MMM-y HH:mm:ss'}}</label>
    </div>
</div>
