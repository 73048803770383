import {OperationType} from "../../enumerations/operation-type";
import { Contacts } from "../save/contacts";
import { ContactsCommentsDisplay } from "./contacts-comments-display";

export class ContactsDisplay extends Contacts {
    nationalityName: string;
    marketingEmailStatus: string;
    marketingEmailOverrideStatus: string;
    marketingOverrideDate: string;
    marketingOverridePerson: string;
    lastUserEmailAddress: string;
    entryUserEmailAddress: string;
    previousUserEmailAddress: string;

    contactsCommentsDisplay: Array<ContactsCommentsDisplay>;

    private constructor(entity?: any) {
        super(entity);

        this.nationalityName = entity && entity.nationalityName || null;
        this.marketingEmailStatus = entity && entity.marketingEmailStatus || null;
        this.marketingEmailOverrideStatus = entity && entity.marketingEmailOverrideStatus || null;
        this.marketingOverrideDate = entity && entity.marketingOverrideDate || null;
        this.marketingOverridePerson = entity && entity.marketingOverridePerson || null;
        this.lastUserEmailAddress = entity && entity.lastUserEmailAddress || null;
        this.entryUserEmailAddress = entity && entity.entryUserEmailAddress || null;
        this.previousUserEmailAddress = entity && entity.previousUserEmailAddress || null;

        this.contactsCommentsDisplay = entity && entity.contactsCommentsDisplay || [];
    }

    static CreateInstance(entity: any): ContactsDisplay {
        const cDisplay = new ContactsDisplay(entity);
        if(cDisplay.contactsCommentsDisplay.length > 0) {
            cDisplay.contactsCommentsDisplay = ContactsCommentsDisplay.BuildFromList(cDisplay.contactsCommentsDisplay);
        }
        return cDisplay;
    }

    static BuildFromList(entity: Array<any>): Array<ContactsDisplay> {
        return entity.map(item => ContactsDisplay.CreateInstance(item));
    }

    static BuildNewContact(response: any): ContactsDisplay {
      return new ContactsDisplay({
        contactId: response[0].CONTACT_ID,
        operation: OperationType.TEMPORAL,
        intDataDepValState: OperationType.TEMPORAL
      });
    }

    merge(from: ContactsDisplay): void {
        this.contactId = from.contactId;
        this.firstName = from.firstName;
        this.middleName = from.middleName;
        this.lastName = from.lastName;
        this.nickName = from.nickName;
        this.jrSrIII = from.jrSrIII;
        this.salutation = from.salutation;
        this.nationality = from.nationality;
        this.matronName = from.matronName;
        this.emailAddress = from.emailAddress;
        this.linkedInId = from.linkedInId;
        this.prevUser = from.prevUser;
        this.prevDate = from.prevDate;
        this.lastUser = from.lastUser;
        this.entryUser = from.entryUser;
        this.entryDate = from.entryDate;
        this.nationalityName = from.nationalityName;
        this.lastUserEmailAddress = from.lastUserEmailAddress;
        this.previousUserEmailAddress = from.previousUserEmailAddress;
        this.entryUserEmailAddress = from.entryUserEmailAddress;
        this.marketingEmailStatus = from.marketingEmailStatus;
        this.marketingEmailOverrideStatus = from.marketingEmailOverrideStatus;
        this.marketingOverrideDate = from.marketingOverrideDate;
        this.marketingOverridePerson = from.marketingOverridePerson;
        this.lastCompany = from.lastCompany;
        this.prevCompany = from.prevCompany;
        this.entryCompany = from.entryCompany;
        this.lastDate = from.lastDate;
        this.releaseDate = from.releaseDate;
        this.contactOptedOutBool = from.contactOptedOutBool;
        this.changedFields = from.changedFields;
        this.setOperation(from.getOperation());

        this.lockError = from.lockError;
        this.lockId = from.lockId;
        this.lockMode = from.lockMode;
        this.lockSameUser = from.lockSameUser;
        this.lockSameSession = from.lockSameSession;

        let tempContactsCommentsDisplay = [];
        for (let item of from.contactsCommentsDisplay) {
            if (item.getOperation() == OperationType.TEMPORAL) {
                continue;
            }
            const itemAux: ContactsCommentsDisplay = ContactsCommentsDisplay.CreateInstance(item);
            tempContactsCommentsDisplay.push(itemAux);
        }
        this.contactsCommentsDisplay = tempContactsCommentsDisplay;
    }

    hasSameComments(aContact: ContactsDisplay) {
        const tComments = this.contactsCommentsDisplay.length;
        const aComments = aContact.contactsCommentsDisplay.length;

        if(tComments !== aComments) {
            return false;
        }

        let result = true;
        for (let i = 0; i < this.contactsCommentsDisplay.length; i++) {
            const tComment = this.contactsCommentsDisplay[i];
            const aComment = aContact?.contactsCommentsDisplay.find(p => p.mvOrder === tComment.mvOrder);
            result =  tComment.equals(aComment) && result;
        }
        return result;
    }

    prepareSaveCollection() {
        this.contactsComments = this.contactsCommentsDisplay;
    }
}
