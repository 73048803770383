import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
// Rxjs
import { Observable, Subscription, interval } from 'rxjs';
import { map } from 'rxjs/operators';
// Environment
import { environment } from 'src/environments/environment';
import { AlertDTO, EntityAlertHistory } from '../models/alerts';
import { AlertKindValue, AlertStatusValue } from '../models/constants';
import { OfflineEventsPlantsDisplay } from '../models/offline-event/display/offline-event-plants-display';
import { OfflineEventsAssetsDisplay } from '../models/offline-event/display/offline-events-assets-display';
import { StringUtils } from '../utils/string-utils';
import { OfflineEventsUnitsDisplay } from '../models/offline-event/display/offline-events-units-display';
import { OfflineEventsDisplay } from '../models/offline-event/display/offline-event-display';


@Injectable({
    providedIn: 'root'
})
export class AlertService {

    private alertTimer: Subscription = null;

    constructor(private http: HttpClient) { }

    getAlertHistoryInfo(entityId: number, alertKind: string): Observable<EntityAlertHistory[]> {
        return this.http.get<EntityAlertHistory>(`${environment.apiUrl}alert/alertHistoryByEntityID/${entityId}/${alertKind}`)
            .pipe(
                map((data: any) => EntityAlertHistory.BuildiList(data.response))
            );
    }

    private savePlantAlert(alert: AlertDTO): Observable<any>{
        return this.http.post<any>(`${environment.apiUrl}alert/savePlantAlert`, alert);
        
    }

    private saveOfflineAlert(alert: AlertDTO): Observable<any>{
        return this.http.post<any>(`${environment.apiUrl}alert/saveOfflineEventAlert`, alert);
        
    }

    saveAlert(alert: AlertDTO): Observable<any>{
        return alert.alertKind === AlertKindValue.OfflineEvent ? 
            this.saveOfflineAlert(alert) : this.savePlantAlert(alert);
        
    }

    retryAlert(iseScId: number, alertKind: string): Observable<any>{
        const httpParams = new HttpParams()
        .append('iseScId', iseScId)
        .append('alertKind', alertKind);

        return this.http.post<any>(`${environment.apiUrl}alert/retryAlert`, httpParams);
    }

    deleteAlert(alertId: number, alertKind: string): Observable<any>{
        const httpParams = new HttpParams()
            .append('alertId', alertId)
            .append('alertKind', alertKind);

        return this.http.post<any>(`${environment.apiUrl}alert/deleteAlert`, httpParams);
    }

    updateAlert(alertId: number, alertKind: string, subject: string, comments: string): Observable<any>{
        const httpParams = new HttpParams()
            .append('alertId', alertId)
            .append('alertKind', alertKind)
            .append('alertTitle', subject)
            .append('comments', comments);
        return this.http.post<any>(`${environment.apiUrl}alert/updateAlert`, httpParams);
    }

    initAlertTimer(entityId: number, alertHistory: Array<EntityAlertHistory>, callerComponent: any): void {
        if(alertHistory.some(alert => alert.statusDesc === AlertStatusValue.Queued ||
                                    alert.statusDesc === AlertStatusValue.Scheduled ||
                                    alert.statusDesc === AlertStatusValue.Processing)){
            if(!this.alertTimer){
                this.alertTimer = interval(2000).subscribe(
                    timer => callerComponent.loadAlerts(entityId, false)
                );
            }
        } else {
            this.endAlertTimer();
        }
    }

    endAlertTimer() {
        this.alertTimer?.unsubscribe();
        this.alertTimer = null;
    }

    getOfflineEventSubject(offlineEventDisplay: OfflineEventsDisplay): string{
        let firstOfflineEventsUnitsDisplay: OfflineEventsUnitsDisplay = offlineEventDisplay.getFirstOfflineEventsUnitsDisplay();
        let subject: string = '';
        if(firstOfflineEventsUnitsDisplay != null && !StringUtils.isEmpty(firstOfflineEventsUnitsDisplay.unitId)){
            if(firstOfflineEventsUnitsDisplay.plantName != null && firstOfflineEventsUnitsDisplay.plantName != "") {
                subject = firstOfflineEventsUnitsDisplay.plantName + " ";
            }
            if(firstOfflineEventsUnitsDisplay.unitName != null && firstOfflineEventsUnitsDisplay.unitName != "") {
                subject += firstOfflineEventsUnitsDisplay.unitName + " ";
            }
            let offlineCapacity = firstOfflineEventsUnitsDisplay.getCapacityOffline();
            if(!StringUtils.isEmpty(offlineCapacity)) {
                // pattern for removal of integer's decimal point and zero decimal values, and non-integer's trailing zero decimal values
                subject += offlineCapacity.replace(/\.?0*$/g, "") + " ";
            }
            if(firstOfflineEventsUnitsDisplay.capacityUom != null && firstOfflineEventsUnitsDisplay.capacityUom != "") {
                subject += firstOfflineEventsUnitsDisplay.capacityUom;
            }
        } else {
            let firstOfflineEventsPlantsDisplay: OfflineEventsPlantsDisplay = offlineEventDisplay.getFirstOfflineEventsPlantsDisplay();
            if(firstOfflineEventsPlantsDisplay != null && !StringUtils.isEmpty(firstOfflineEventsPlantsDisplay.plantId)){
                if(firstOfflineEventsPlantsDisplay.plantName != null && firstOfflineEventsPlantsDisplay.plantName != "") {
                    subject = firstOfflineEventsPlantsDisplay.plantName;
                }
            } else {
                let firstOfflineEventsAssetsDisplay: OfflineEventsAssetsDisplay = offlineEventDisplay.getFirstOfflineEventsAssetsDisplay();
                if(firstOfflineEventsAssetsDisplay != null && !StringUtils.isEmpty(firstOfflineEventsAssetsDisplay.assetId)){					
                    if(firstOfflineEventsAssetsDisplay.assetName != null && firstOfflineEventsAssetsDisplay.assetName != "") {
                        subject = firstOfflineEventsAssetsDisplay.assetName;
                    }
                }
            }
        }

        subject = subject.trim().substring(0, 100);
        return subject;
    }

}
