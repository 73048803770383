import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
// Rxjs
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
// Environment
import { environment } from 'src/environments/environment';
// Model
import { MessageType } from '../components/messages/message-handler/message-handler.component';
import { MessageHandlerService } from '../components/messages/message-handler/message-handler.service';
import { SaveType } from '../core/models/enumerations/save-type';
// Interface
import { TranslateService } from '@ngx-translate/core';
import { Cacheable, LocalStorageStrategy } from 'ts-cacheable';
import { LoadWheelService } from '../components/load-wheel/load-wheel.service';
import { IDriveSearchParams } from '../core/interfaces/search/idrive-search-params';
import { News } from '../core/models/common';
import { ConfirmationValues, DriveCategoryValue, DriveTypeValue } from '../core/models/constants';
import { DriveCategory, DriveSearch, DriveSearchAdapter, DriveType } from '../core/models/drive';
import { DrivesDisplay } from '../core/models/drive/display/drives-display';
import { EntityName } from '../core/models/enumerations/entity-name';
import { Firm } from '../core/models/firm';
import { Manufacturer, ManufacturerEquipmentType, ManufacturerSearchParams } from '../core/models/manufacturer';
import { FuelTypeSearch } from '../core/models/search/plant-search';
import { SpinnerProcess } from '../core/models/spinner-process';
import { RecordLockingFlow } from '../core/record-locking/record-locking-flow';
import { EntityCommonService } from '../core/services/entity-common.service';
import { LockSameUserMessageService, LockSameUserResult } from '../core/services/lock-same-user-message.service';
import { TelemetryService } from '../core/services/telemetry.service';
import { EntityUtilsService } from '../core/utils/entity-utils.service';

@Injectable({
    providedIn: 'root'
})
export class DriveSectionService {

    searchParams: IDriveSearchParams = {
        driveId:'',
        unitName: '',
        ownerName: '',
        unitId: '',
        areaId: '',
        plantId: '',
        plantName: '',
        driveType: null,
        driveCategory: null,
        sicCode: null,
        industryCode: null,
        driveName:'',
        recordStatus: null,
        recordedSearch: false
    }

    constructor(
        private adapter: DriveSearchAdapter,
        private recordLockingFlow: RecordLockingFlow,
        private http: HttpClient,
        private messageHandlerService: MessageHandlerService,
        private loadWheelService: LoadWheelService,
        private entityCommonService: EntityCommonService,
        private translate: TranslateService,
        private driveSearchAdapter: DriveSearchAdapter,
        private entityUtilsService: EntityUtilsService,
        private lockSameUserMessageService: LockSameUserMessageService,
        private telemetry: TelemetryService,
    ) {
    }

    getDriveDetailsById(driveId: string, isReload: boolean = false, quickAccess: boolean = false): Promise<DrivesDisplay> {
        let mainLockID: number = 0;

        if (isReload) {
            mainLockID = this.recordLockingFlow.getLockID(EntityName.TURBINE, driveId);
        }

        return new Promise<DrivesDisplay>((resolve) => {
            this.lockSameUserMessageService.validateEntityIsLockedByUser(this.recordLockingFlow, EntityName.TURBINE, driveId, mainLockID, quickAccess).then((lockSameUserResult: LockSameUserResult) => {
                if(lockSameUserResult.openEntity) {
                    this.getEntityDetailsById(driveId, mainLockID, false).subscribe((entityDisplay: DrivesDisplay) => {
                        resolve(entityDisplay);
                    });
                } else {
                    resolve(null);
                }
            });
        });
    }

    private getEntityDetailsById(driveId: string, mainLockID: number, onlyForRead: boolean): Observable<DrivesDisplay> {
        let wheel: SpinnerProcess = this.loadWheelService.showWheel(this.translate.instant("loading.drive"));

        return this.http.get<DrivesDisplay>(`${environment.apiUrl}drive/${driveId}/${mainLockID}/${onlyForRead}`)
            .pipe(
                map((data: any) => {
                    let driveDisplay = null;
                    if (data.response) {
                        driveDisplay = DrivesDisplay.BuildDriveDisplay(data.response);

                        if (driveDisplay.turbineId) {
                            if (driveDisplay.hasDiffUserLockError()) {
                                this.messageHandlerService.show(driveDisplay.lockError, MessageType.INFO);
                            }

                            // Set the locking info into lock global list.
                            this.recordLockingFlow.setLockItem(EntityName.TURBINE, driveDisplay.turbineId, driveDisplay.lockId, driveDisplay.lockMode);
                            this.recordLockingFlow.initLockRefreshTimer(EntityName.TURBINE, driveDisplay.turbineId, driveDisplay.lockId);
                        } else {
                            this.messageHandlerService.show(driveDisplay.lockError, MessageType.ERROR);
                        }
                    } else {
                        const errorType = MessageHandlerService.errorType(data);
                        if (errorType === MessageType.INFO) {
                            this.entityCommonService.sendEntityNotFoundEvent();
                        } else if (errorType !== MessageType.SESSION_INVALID) {
                            this.messageHandlerService.show(MessageHandlerService.errorMessage(data), errorType);
                        }
                    }
                    this.loadWheelService.hideWheel(wheel);
                    return driveDisplay;
                }),

                catchError((error) => {
                    this.loadWheelService.hideWheel(wheel);
                    return null;
                })

            );
    }

    getDriveById(driveId: number): Observable<DriveSearch> {
        let wheel: SpinnerProcess = this.loadWheelService.showWheel(this.translate.instant("loading.drive"));
        return this.http.get<DriveSearch>(`${environment.apiUrl}drive/${driveId}`)
            .pipe(
                map((data: any) => {
                        this.loadWheelService.hideWheel(wheel);
                        return this.adapter.adapt(data.response[0]);
                })
            );
    }

    saveDriveDetails(driveDisplay: DrivesDisplay): Observable<DrivesDisplay> {
        return this.http.put<DrivesDisplay>(`${environment.apiUrl}drive/`, driveDisplay)
            .pipe(
                map((data: any) => {
                    if (data.response === null) {
                        if (data.exception !== null) {
                            this.messageHandlerService.setExceptionData(data);
                            this.messageHandlerService.show(MessageHandlerService.errorMessage(data), MessageType.EXCEPTION);
                        } else if (data.message !== null) {
                            if (driveDisplay.saveType === SaveType.TYPE_QC || driveDisplay.saveType === SaveType.UPDATE) {
                                //When the user attempts to QC a record while the lock was already cleared,
                                //this//the above warning message should still be thrown; however, the record should not reload.
                                if (data.messageType !== MessageType.WARNING.valueOf()) {
                                    this.entityCommonService.sendReloadEvent();
                                }
                            } else {
                                driveDisplay.intDataDepMigError = data.message;
                            }

                        }
                    } else {
                        const isQcUpdateOrAmend = driveDisplay.saveType === SaveType.TYPE_QC ||
                            driveDisplay.saveType === SaveType.AMENDMENT ||
                            driveDisplay.saveType === SaveType.UPDATE;

                        const afterClearFunc = () => this.entityCommonService.sendPreReleaseEvent();
                        this.recordLockingFlow.clearLockFlow(EntityName.TURBINE, String(driveDisplay.turbineId), isQcUpdateOrAmend, afterClearFunc);
                    }
                    return data.response;
                })
            );
    }

    driveNextId(): Observable<DrivesDisplay> {
        return this.http.get<any>(`${environment.apiUrl}drive/nextId`)
            .pipe(
                map((data: any) => {
                    const driveDisplay = DrivesDisplay.BuildNewDrive(data.response);
                    if (driveDisplay && driveDisplay.turbineId) {
                        // Set the locking info into lock global list.
                        this.recordLockingFlow.setLockItem(EntityName.TURBINE, driveDisplay.turbineId, -1);
                    }
                    return driveDisplay;
                }
                ));
    }

    getNewsInfo(driveId: number): Observable<News[]> {
        return this.http.get<News>(`${environment.apiUrl}drive/news/${driveId}`)
            .pipe(
                map((data: any) => News.BuildNew(data.response))
            );
    }

    @Cacheable({
        storageStrategy: LocalStorageStrategy
    })
    getTurbineSubtypes(): Observable<DriveType[]> {
        return this.http.get<DriveType>(`${environment.apiUrl}drive/turbineSubtypes`)
            .pipe(
                map((data: any) => DriveType.BuildDriveType(data.response))
            );
    }

    @Cacheable({
        storageStrategy: LocalStorageStrategy
    })
    getDriveCategories(): Observable<DriveCategory[]> {
        return this.http.get<DriveCategory>(`${environment.apiUrl}drive/turbineCategories`)
            .pipe(
                map((data: any) => DriveCategory.BuildDriveCategory(data.response))
            );
    }

    searchManufacturer(params: ManufacturerSearchParams, wheel: SpinnerProcess, acManufacturerEquipTypes: ManufacturerEquipmentType[]): Observable<Manufacturer[]> {
        const httpParams = new HttpParams()
            .append('manufacturerName', params.name)
            .append('shortName', params.shortName);
        if(!this.entityUtilsService.checkForValueRequired(httpParams, wheel)){
            return of([]);
        }

        return this.http.get<Manufacturer[]>(`${environment.apiUrl}drive/searchManufacturer`, { params: httpParams })
            .pipe(
                map((data: any) => {
                    return Manufacturer.BuildManufacturer(data.response, acManufacturerEquipTypes);
                })
            )
    }

    manufacturerByID(manufacturerId: number, acManufacturerEquipTypes: ManufacturerEquipmentType[] = []): Observable<Manufacturer> {
        let wheel: SpinnerProcess = this.loadWheelService.showWheel(this.translate.instant("loading.manufacturer"));
        return this.http.get<Manufacturer>(`${environment.apiUrl}drive/manufacturerID/${manufacturerId}`)
            .pipe(
                map((data: any) => {
                        this.loadWheelService.hideWheel(wheel);
                        if(data.response && data.response.length > 0){
                            return Manufacturer.CreateInstance(data.response[0], acManufacturerEquipTypes);
                        }
                        return null;
                })
            );
    }

    aecFirmsID(firmId: number): Observable<Firm> {
        let wheel: SpinnerProcess = this.loadWheelService.showWheel(this.translate.instant("loading.firm"));
        return this.http.get<Firm>(`${environment.apiUrl}drive/aecFirmsID/${firmId}`)
            .pipe(
                map((data: any) => {
                        this.loadWheelService.hideWheel(wheel);
                        if(data.response && data.response.length > 0){
                            return Firm.CreateInstance(data.response[0]);
                        }
                        return null;
                })
            );
    }

    searchDrives(params: IDriveSearchParams, wheel: SpinnerProcess): Observable<DriveSearch[]> {
        const searchStart: number = performance.now();

        const httpParams = new HttpParams()
            .append('unitName', params.unitName)
            .append('plantName', params.plantName)
            .append('ownerName', params.ownerName)
            .append('areaId', params.areaId)
            .append('unitId', params.unitId)
            .append('plantId', params.plantId)
            .append('turbineType', params.driveType !== null ? params.driveType : '')
            .append('driveCategory', params.driveCategory !== null ? params.driveCategory : '')
            .append('sicCode', params.sicCode !== null ? params.sicCode : '')
            .append('industryCode', params.industryCode !== null ? params.industryCode : '')
            .append('turbineName', params.driveName)
            .append('recordStatus', params.recordStatus !== null ? params.recordStatus : '');
        if(!this.entityUtilsService.checkForValueRequired(httpParams, wheel)){
            return of(null);
        }

        return this.http.get<DriveSearch[]>(`${environment.apiUrl}drive/search`, { params: httpParams })
            .pipe(
                map((data: any) => {
                    this.entityUtilsService.verifyMaxResult(data);
                    let drives: Array<any> = data.response;

                    if (drives.length > 1) {
                        drives = this.removeDuplicatedDrives(drives);
                        drives = this.sortDrivesByDriveName(drives);
                    }
                    
                    this.telemetry.entitySearchTime("drive", performance.now() - searchStart);

                    return drives.map(item => this.driveSearchAdapter.adapt(item));
                })
            )
    }

    @Cacheable({
        storageStrategy: LocalStorageStrategy
    })
    getAllFuelTypes(): Observable<FuelTypeSearch[]> {
        return this.getFuelTypes(null, null);
    }

    getFuelTypes(fuelTypeId: number, fuelTypeDesc: string): Observable<FuelTypeSearch[]> {
        let wheel: SpinnerProcess = this.loadWheelService.showWheel(this.translate.instant("loading.fuelType"));
        let params = new HttpParams().append('fuelTypeId', (fuelTypeId !== null ? fuelTypeId : ''))
            .append('fuelTypeDesc', (fuelTypeDesc !== null ? fuelTypeDesc : ''));

        return this.http.get<FuelTypeSearch>(`${environment.apiUrl}drive/searchFuelTypes`, { params })
            .pipe(
                map((data: any) => {
                    this.loadWheelService.hideWheel(wheel);
                    return FuelTypeSearch.BuildFuelTypeSearch(data.response || []);
                })
            );
    }

    private removeDuplicatedDrives(drives: Array<any>): Array<any> {
        let uniqueDrives: Array<any> = [];
        drives.forEach(drive => {
            if (!uniqueDrives.some(uniqueDrive => uniqueDrive.TURBINE_ID == drive.TURBINE_ID)) {
                uniqueDrives.push(drive);
            }
        });
        return uniqueDrives;
    }

    private sortDrivesByDriveName(drives: Array<any>): Array<any> {
        return drives.sort((a, b) => {
            if (a.NAME < b.NAME) return -1;
            if (a.NAME > b.NAME) return 1;
            return 0;
        });
    }

    isGeneratorMfgAssociatedToDriveCategory(driveCategoryId: number, manufacturer: Manufacturer): boolean {
        let hasManufaturerCat: boolean = false;
        if(driveCategoryId !== null && manufacturer !== null){
            if(driveCategoryId === DriveCategoryValue.Generator){
                hasManufaturerCat = manufacturer.generator === ConfirmationValues.YES;
            } else if (driveCategoryId === DriveCategoryValue.Pump){
                hasManufaturerCat = manufacturer.pump === ConfirmationValues.YES;
            } else if (driveCategoryId === DriveCategoryValue.Compressor){
                hasManufaturerCat = manufacturer.compressor === ConfirmationValues.YES;
            }
        }
        return driveCategoryId === null  ||
                (driveCategoryId !== null && hasManufaturerCat);
    }

    isDriveMfgAssociatedToDriveType(driveTypeId: number, manufacturer: Manufacturer): boolean {
        let hasManufaturerType: boolean = false;
        if(driveTypeId !== null && manufacturer !== null){
            if(driveTypeId === DriveTypeValue.AeroderivativeCombustionTurbine){
                hasManufaturerType = manufacturer.aeroCombTurbine === ConfirmationValues.YES;
            } else if (driveTypeId === DriveTypeValue.STSteamTurbine){
                hasManufaturerType = manufacturer.stSteamTurbine === ConfirmationValues.YES;
            } else if (driveTypeId === DriveTypeValue.HTHydraulicTurbine){
                hasManufaturerType = manufacturer.htHydraulicTurbine === ConfirmationValues.YES;
            } else if (driveTypeId === DriveTypeValue.ICInternalCombustionEngine){
                hasManufaturerType = manufacturer.internalCombEngine === ConfirmationValues.YES;
            } else if (driveTypeId === DriveTypeValue.HeavyFrameCombustionTurbine){
                hasManufaturerType = manufacturer.hfCombTurbine === ConfirmationValues.YES;
            } else if (driveTypeId === DriveTypeValue.WTWindTurbine){
                hasManufaturerType = manufacturer.windTurbine === ConfirmationValues.YES;
            } else if (driveTypeId === DriveTypeValue.EMElectricMotor){
                hasManufaturerType = manufacturer.emElectMotor === ConfirmationValues.YES;
            } else if (driveTypeId === DriveTypeValue.TurboExpander){
                hasManufaturerType = manufacturer.turboExpander === ConfirmationValues.YES;
            }
        }
        return driveTypeId === null  ||
                (driveTypeId !== null && hasManufaturerType);
    }




    resetSearchParams(): void {
        this.searchParams = {
            driveId:'',
            unitName: '',
            ownerName: '',
            unitId: '',
            areaId: '',
            plantId: '',
            plantName: '',
            driveType: null,
            driveCategory: null,
            sicCode: null,
            industryCode: null,
            driveName:'',
            recordStatus: null,
            recordedSearch: false
        };
    }

}
