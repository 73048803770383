import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CompanySectionService } from 'src/app/company-section/company-section.service';
import { SicCode } from 'src/app/core/models/common';
import { PlantSectionService } from 'src/app/plant-section/plant-section.service';
import { MessageType } from '../../messages/message-handler/message-handler.component';
import { MessageHandlerService } from '../../messages/message-handler/message-handler.service';

@Component({
    selector: 'app-sic-code-lookup',
    templateUrl: './sic-code-lookup.component.html',
    styleUrls: ['./sic-code-lookup.component.css'],
})

export class SicCodeLookupComponent implements OnInit, AfterViewInit {
    sicCode: string = '';
    sicDescription: string = '';
    industryCode: string = '';

    availableSicCodes = [];
    selectedInAvailableSicCodes = [];
    selectedSicCodes = [];
    selectedInSelectedSicCodes = [];

    @ViewChildren('tabIndexSetted') tabIndexSetted: QueryList<any>;
    @ViewChild('listbox') listbox: ElementRef;
    @ViewChild('searchBtn') searchBtn: ElementRef;
    @ViewChild('addBtn') addBtn: ElementRef;
    @ViewChild('removeBtn') removeBtn: ElementRef;
    @ViewChild('selectedSCodes') selectedSCodes: ElementRef;


    constructor(public companySectionService: CompanySectionService,
        public plantService: PlantSectionService,
        public activeModal: DynamicDialogRef,
        private messageHandler: MessageHandlerService,
        public config: DynamicDialogConfig,
        private translate: TranslateService,
        private primengConfig: PrimeNGConfig,
        private cdr: ChangeDetectorRef) {
        this.selectedSicCodes = config.data;
    }

    @HostListener('window:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (event.key === 'Tab') {
            if (event.shiftKey) {
                this.focusToPrevious(event);
            } else {
                this.focusToNext(event);
            }
        }
    }

    ngOnInit(): void {
        this.primengConfig.ripple = false;
        this.initSearch();
    }

    ngAfterViewInit() {
        this.setTabIndex();
        this.cdr.detectChanges();
    }

    setTabIndex() {
        this.tabIndexSetted.forEach((element, index) => {
            element.nativeElement.tabIndex = index;
        });
    }

    handleListboxKeydown(event: KeyboardEvent) {
        event.preventDefault();
        event.stopPropagation();
        if (event.key === 'Tab') {
            this.searchBtn.nativeElement.focus();
        } else if (event.shiftKey && event.key === 'Tab') {
            this.addBtn.nativeElement.focus();
        }
    }

    focusToNext(event: KeyboardEvent) {
        event.preventDefault();
        let actualElement = event.target as HTMLElement;
        let actualIndex = this.tabIndexSetted.toArray().findIndex(elem => elem.nativeElement === actualElement);

        if (actualIndex === this.tabIndexSetted.length - 1) {
            this.tabIndexSetted.first.nativeElement.focus();
        } else {
            this.tabIndexSetted.toArray()[actualIndex + 1].nativeElement.focus();
        }
    }

    focusToPrevious(event: KeyboardEvent) {
        event.preventDefault();

        let actualElement = event.target as HTMLElement;
        let actualIndex = this.tabIndexSetted.toArray().findIndex(elem => elem.nativeElement === actualElement);

        if (actualIndex === 0) {
            this.tabIndexSetted.last.nativeElement.focus();
        } else {
            this.tabIndexSetted.toArray()[actualIndex - 1].nativeElement.focus();
        }
    }

    initSearch() {
        this.plantService.getSicCodes().subscribe((data) => {
            if (data !== null) {
                this.availableSicCodes = data;
                this.filterSelectedSICCodes();
            }
        });
    }

    search() {
        this.plantService.searchSicCodes(this.sicCode, this.sicDescription, this.industryCode).subscribe((data) => {
            if (data !== null) {
                this.availableSicCodes = data;
                this.filterSelectedSICCodes();
                if (this.availableSicCodes.length === 0) {
                    this.messageHandler.show(this.translate.instant('common.searchNotFound'), MessageType.INFO);
                }
            }
        });
    }

    closeModal() {
        this.activeModal.close();
    }

    addRowSingleClick() {
        this.activeModal.close(this.selectedSicCodes);
    }

    add() {
        this.selectedSicCodes = this.selectedSicCodes.concat(this.selectedInAvailableSicCodes.filter(item => !this.listContainsSICCode(this.selectedSicCodes, item.sicCode)));
        this.filterSelectedSICCodes();
    }

    remove() {
        this.availableSicCodes = this.availableSicCodes.concat(this.selectedInSelectedSicCodes);
        this.selectedSicCodes = this.selectedSicCodes.filter(item => !this.selectedInSelectedSicCodes.includes(item));
    }

    private listContainsSICCode(list: SicCode[], sicCode: string): boolean {
        return list.some(item => item.sicCode === sicCode);
    }

    private filterSelectedSICCodes(): void {
        this.availableSicCodes = this.availableSicCodes.filter(selectable =>
            !this.selectedSicCodes.some(selected => selected.sicCode === selectable.sicCode));
    }

}
