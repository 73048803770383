import { Bean } from "../../../bean";
import { Assets } from "../../base/save/assets";

export class AssetsSites extends Bean {
    assetSiteId: number;
    assetId: number;
    siteStatus: string;
    mailAddressV1: string;
    mailAddressV2: string;
    mailCountry: string;
    mailState: string;
    mailCity: string;
    mailPostalCode: string;

    entryCompany: string;
    entryUser: string;
    entryDate: string;
    lastCompany: string;
    lastUser: string;
    lastDate: string;
    prevCompany: string;
    prevUser: string;
    prevDate: string;
    qcUser: string;
    qcDate: string;
    liveDate: string;
    releaseUser: string;
    amendmentDate: string;
    amendmentUser: string;

    asset: Assets;

    constructor(entity: any) {
        super(entity);

        this.assetSiteId = entity && entity.assetSiteId || null;
        this.assetId = entity && entity.assetId || null;
        this.siteStatus = entity && entity.siteStatus || null;
        this.mailAddressV1 = entity && entity.mailAddressV1 || null;
        this.mailAddressV2 = entity && entity.mailAddressV2 || null;
        this.mailCountry = entity && entity.mailCountry || null;
        this.mailState = entity && entity.mailState || null;
        this.mailCity = entity && entity.mailCity || null;
        this.mailPostalCode = entity && entity.mailPostalCode || null;

        this.entryCompany = entity && entity.entryCompany || null;
        this.entryUser = entity && entity.entryUser || null;
        this.entryDate = entity && entity.entryDate || null;
        this.lastCompany = entity && entity.lastCompany || null;
        this.lastUser = entity && entity.lastUser || null;
        this.lastDate = entity && entity.lastDate || null;
        this.prevCompany = entity && entity.prevCompany || null;
        this.prevUser = entity && entity.prevUser || null;
        this.prevDate = entity && entity.prevDate || null;
        this.qcUser = entity && entity.qcUser || null;
        this.qcDate = entity && entity.qcDate || null;
        this.liveDate = entity && entity.liveDate || null;
        this.releaseUser = entity && entity.releaseUser || null;
        this.amendmentDate = entity && entity.amendmentDate || null;
        this.amendmentUser = entity && entity.amendmentUser || null;

        this.asset = entity && entity.asset || null;
    }
}
