import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
// Rxjs
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
// Environment
import { environment } from 'src/environments/environment';
// Model
import { MessageType } from '../components/messages/message-handler/message-handler.component';
import { MessageHandlerService } from '../components/messages/message-handler/message-handler.service';
import { SaveType } from '../core/models/enumerations/save-type';
import { CompanyOwners, Pipeline, Plants, Projects, TransmissionLine } from '../core/models/index';
import { UserDetail } from '../core/models/user-detail';
// Interface
import { TranslateService } from '@ngx-translate/core';
import { Cacheable } from 'ts-cacheable';
import { LoadWheelService } from '../components/load-wheel/load-wheel.service';
import { IProjectSearchParams } from '../core/interfaces/search/iproject-search-params';
import { AlertInfo } from '../core/models/alerts';
import { Areas, Boilers, Drives, News, OfflineEvent, ShalePlay, SicCode, Units } from '../core/models/common';
import { ProbAssociation } from '../core/models/prob-association';
import { ProjectRecordStatus, ProjectStatusReason, ProjectType } from '../core/models/project';
import { ProjectsDisplay } from '../core/models/project/display/projects-display';
import { ProjectSearch, ProjectSearchAdapter } from '../core/models/search/project-search';
import { SpinnerProcess } from '../core/models/spinner-process';
import { RecordLockingFlow } from '../core/record-locking/record-locking-flow';
import { EntityCommonService } from '../core/services/entity-common.service';
import { LockSameUserMessageService, LockSameUserResult } from '../core/services/lock-same-user-message.service';
import { TelemetryService } from '../core/services/telemetry.service';
import { WebsiteService } from '../core/services/website.service';
import { EntityUtilsService } from '../core/utils/entity-utils.service';

export const NEW_PROJECT_KEY = 'newProject';

@Injectable({
    providedIn: 'root'
})
export class ProjectSectionService {

    searchParams: IProjectSearchParams = {
        projectID: null,
        plantID: null,
        ownerID: null,
        ownerName: '',
        projectName: '',
        pecZone: '',
        country: null,
        state: null,
        offshore: '0',
        fieldName: '',
        waterBody: null,
        recordStatus: null,
        projectStatus: '',
        recordedSearch: false
    }

    constructor(
        private adapter: ProjectSearchAdapter,
        private recordLockingFlow: RecordLockingFlow,
        private http: HttpClient,
        private messageHandlerService: MessageHandlerService,
        private loadWheelService: LoadWheelService,
        private entityCommonService: EntityCommonService,
        private translate: TranslateService,
        private projectSearchAdapter: ProjectSearchAdapter,
        private website: WebsiteService,
        private entityUtilsService: EntityUtilsService,
        private lockSameUserMessageService: LockSameUserMessageService,
        private telemetry: TelemetryService,
    ) { }

    getProjectDetailsById(projectId: string, entityName: string, isReload: boolean = false, quickAccess: boolean = false): Promise<ProjectsDisplay> {
        let mainLockID: number = 0;

        if (isReload) {
            mainLockID = this.recordLockingFlow.getLockID(entityName, projectId);
        }

        return new Promise<ProjectsDisplay>((resolve) => {
            this.lockSameUserMessageService.validateEntityIsLockedByUser(this.recordLockingFlow, entityName, projectId, mainLockID, quickAccess).then((lockSameUserResult: LockSameUserResult) => {
                if(lockSameUserResult.openEntity) {
                    this.getEntityDetailsById(entityName, projectId, mainLockID).subscribe((entityDisplay: ProjectsDisplay) => {
                        this.lockSameUserMessageService.clearContactsLockedsBySameUser(lockSameUserResult, entityDisplay?.projectsCompanyDisplay);
                        resolve(entityDisplay);
                    });
                } else {
                    resolve(null);
                }
            });
        });
    }

    private getEntityDetailsById(entityName: string, entityId: string, mainLockID: number): Observable<ProjectsDisplay> {

        let wheel: SpinnerProcess = this.loadWheelService.showWheel(this.translate.instant("loading.project"));

        return this.http.get<ProjectsDisplay>(`${environment.apiUrl}project/${entityId}/${mainLockID}/false`)
            .pipe(
                map((data: any) => {
                    let projectDisplay = null;
                    if (data.response) {
                        projectDisplay = ProjectsDisplay.BuildProjectDisplay(data.response);

                        if (projectDisplay.projectId) {
                            if (projectDisplay.hasDiffUserLockError()) {
                                this.messageHandlerService.show(projectDisplay.lockError, MessageType.INFO);
                            }

                            // Set the locking info into lock global list.
                            this.recordLockingFlow.setLockItem(entityName, projectDisplay.projectId, projectDisplay.lockId, projectDisplay.lockMode);
                            this.recordLockingFlow.initLockRefreshTimer(entityName, projectDisplay.projectId, projectDisplay.lockId);
                        } else {
                            this.messageHandlerService.show(projectDisplay.lockError, MessageType.ERROR);
                        }
                    } else {
                        const errorType = MessageHandlerService.errorType(data);
                        if (errorType === MessageType.INFO) {
                            this.entityCommonService.sendEntityNotFoundEvent();
                        } else if (errorType !== MessageType.NONE && errorType !== MessageType.SESSION_INVALID) {
                            this.messageHandlerService.show(MessageHandlerService.errorMessage(data), errorType);
                        }
                    }
                    this.loadWheelService.hideWheel(wheel);
                    return projectDisplay;
                }),

                catchError((error) => {
                    this.loadWheelService.hideWheel(wheel);
                    return null;
                })

            );
    }

    getProjectById(projectId: number): Observable<ProjectSearch> {
        let wheel: SpinnerProcess = this.loadWheelService.showWheel(this.translate.instant("loading.project"));
        return this.http.get<ProjectSearch>(`${environment.apiUrl}project/${projectId}`)
            .pipe(
                map((data: any) => {
                    this.loadWheelService.hideWheel(wheel);
                    const projects = data.response;
                    if (projects && projects.length > 0) {
                        return this.projectSearchAdapter.adapt(projects[0]);
                    }
                    return null;
                })
            );
    }

    saveProjectDetails(projectDisplay: ProjectsDisplay, entityName: string): Observable<ProjectsDisplay> {
        return this.http.put<ProjectsDisplay>(`${environment.apiUrl}project/`, projectDisplay)
            .pipe(
                map((data: any) => {
                    if (data.response === null) {
                        if (data.exception !== null) {
                            this.messageHandlerService.setExceptionData(data);
                            this.messageHandlerService.show(MessageHandlerService.errorMessage(data), MessageType.EXCEPTION);
                        } else if (data.message !== null) {
                            if (projectDisplay.saveType === SaveType.TYPE_QC || projectDisplay.saveType === SaveType.UPDATE) {
                                //When the user attempts to QC a record while the lock was already cleared,
                                //this//the above warning message should still be thrown; however, the record should not reload.
                                if (data.messageType !== MessageType.WARNING.valueOf()) {
                                    this.entityCommonService.sendReloadEvent();
                                }
                            } else {
                                projectDisplay.intDataDepMigError = data.message;
                            }

                        }
                    } else {
                        const isQcUpdateOrAmend = projectDisplay.saveType === SaveType.TYPE_QC ||
                            projectDisplay.saveType === SaveType.AMENDMENT ||
                            projectDisplay.saveType === SaveType.UPDATE;

                        const afterClearFunc = () => this.entityCommonService.sendPreReleaseEvent();
                        this.recordLockingFlow.clearLockFlow(entityName, String(projectDisplay.projectId), isQcUpdateOrAmend, afterClearFunc);
                    }
                    return data.response;
                })
            );
    }

    newProject(path:string, plantId: number, entityName: string) {
        this.projectNextId(entityName).subscribe((newProject: ProjectsDisplay) => {
            newProject.plantId = plantId ? Number(plantId) : null;
            localStorage.setItem(NEW_PROJECT_KEY, JSON.stringify(newProject));

            window.open(location.pathname+ `#/ED/${path}`, `${path}_new_${plantId}`);
        });
    }

    getNewProject(): ProjectsDisplay {
        const newProject = localStorage.getItem(NEW_PROJECT_KEY);
        if (newProject) {
            localStorage.removeItem(NEW_PROJECT_KEY);
            return JSON.parse(newProject);
        }
        return null;
    }

    projectNextId(entityName: string): Observable<ProjectsDisplay> {
        return this.http.get<any>(`${environment.apiUrl}project/nextId`)
            .pipe(
                map((data: any) => {
                    const projectDisplay = ProjectsDisplay.BuildNewProject(data.response);
                    if (projectDisplay && projectDisplay.projectId) {
                        // Set the locking info into lock global list.
                        this.recordLockingFlow.setLockItem(entityName, projectDisplay.projectId, -1);
                    }
                    return projectDisplay;
                }
                ));
    }

    getAlertInfo(projectId: number): Observable<AlertInfo> {
        return this.http.get<AlertInfo>(`${environment.apiUrl}project/loadProjectRegionInformationByProjectId/${projectId}`)
            .pipe(
                map((data: any) => AlertInfo.CreateInstance(data.response[0]))
            );
    }

    getTLineInfo(projectId: number): Observable<TransmissionLine[]> {
        return this.http.get<TransmissionLine>(`${environment.apiUrl}project/tlines/${projectId}`)
            .pipe(
                map((data: any) => TransmissionLine.BuildTransmissionLine(data.response))
            );
    }

    getProjecsInfo(projectId: number) {
        return this.http.get<Projects>(`${environment.apiUrl}project/projects/${projectId}`)
            .pipe(
                map((data: any) => Projects.BuildProjects(data.response))
            );
    }

    getCompaniesInfo(plantId: number) {
        return this.http.get<CompanyOwners>(`${environment.apiUrl}plant/companies/${plantId}`)
            .pipe(
                map((data: any) => CompanyOwners.BuildCompOwners(data.response))
            );
    }

    getUmbrellaProjects(umbrellaId: number) {
        return this.http.get<Projects>(`${environment.apiUrl}project/umbrella/${umbrellaId}`)
            .pipe(
                map((data: any) => Projects.BuildProjects(data.response))
            );
    }

    @Cacheable()
    getProjectTypes(): Observable<ProjectType[]> {
        return this.http.get<ProjectType>(`${environment.apiUrl}project/types`)
            .pipe(
                map((data: any) => {
                    return ProjectType.BuildProjectType(data.response);
                })
            );
    }

    @Cacheable()
    getProjectStatusReasons(): Observable<ProjectStatusReason[]> {
        return this.http.get<ProjectStatusReason>(`${environment.apiUrl}project/statusReasons`)
            .pipe(
                map((data: any) => {
                    return ProjectStatusReason.BuildProjectStatusReason(data.response);
                })
            );
    }

    private getProjectRequest(plantId: number, isUnconfirmed: boolean, shouldFilter: boolean) {
        return this.http.get<Projects>(`${environment.apiUrl}plant/projects/${plantId}/${isUnconfirmed}/${shouldFilter}`);
    }


    getProjectsInfo(plantId: number, isUnconfirmed: boolean = false, shouldFilter: boolean = false) {
        return this.getProjectRequest(plantId, isUnconfirmed, shouldFilter)
            .pipe(
                map((data: any) => Projects.BuildProjects(data.response))
            );
    }

    getAssociatedPlants(projectId: number) {
        return this.getPlantsRequest(projectId)
            .pipe(
                map((data: any) => ProbAssociation.BuildFromPlantResult(data.response))
            );
    }

    getAssociatedPlantsByNewProjects(plantId){
        return this.getPlantsRequestByNewProjects(plantId)
        .pipe(
            map((data: any) => ProbAssociation.BuildFromPlantResult(data.response))
        );
    }

    private getPlantsRequestByNewProjects(plantId: number) {
        return this.http.get<Projects>(`${environment.apiUrl}plant/${plantId}`);
    }

    private getPlantsRequest(projectId: number) {
        return this.http.get<Projects>(`${environment.apiUrl}project/plants/${projectId}`);
    }

    getAssociatedProjects(projectId: number, isUnconfirmed: boolean = false, shouldFilter: boolean = false) {
        return this.getProjectRequest(projectId, isUnconfirmed, shouldFilter)
            .pipe(
                map((data: any) => ProbAssociation.BuildFromProjectResult(data.response))
            );
    }

    getProjectPlants(projectId: number) {
        return this.getPlantsRequest(projectId)
            .pipe(
                map((data: any) => Plants.BuildPlants(data.response))
            );
    }

    getAreasInfo(plantId: number) {
        return this.http.get<Areas>(`${environment.apiUrl}plant/areas/${plantId}`)
            .pipe(
                map((data: any) => Areas.BuildAreas(data.response))
            );
    }

    getUnitsInfo(plantId: number) {
        return this.http.get<Units>(`${environment.apiUrl}plant/units/${plantId}`)
            .pipe(
                map((data: any) => Units.BuildUnits(data.response))
            );
    }

    getBoilersInfo(plantId: number) {
        return this.http.get<Boilers>(`${environment.apiUrl}plant/boilers/${plantId}`)
            .pipe(
                map((data: any) => Boilers.BuildBoilers(data.response))
            );
    }

    getDrivesInfo(plantId: number) {
        return this.http.get<Drives>(`${environment.apiUrl}plant/drives/${plantId}`)
            .pipe(
                map((data: any) => Drives.BuildDrives(data.response))
            );
    }

    getOfflineEventsInfo(projectId: number) {
        return this.http.get<OfflineEvent>(`${environment.apiUrl}project/offlineEvents/${projectId}`)
            .pipe(
                map((data: any) => OfflineEvent.BuildOfflineEvents(data.response))
            );
    }

    getPipelineInfo(projectId: number): Observable<Pipeline[]> {
        return this.http.get<Pipeline>(`${environment.apiUrl}project/pipelines/${projectId}`)
            .pipe(
                map((data: any) => Pipeline.BuildPipeline(data.response))
            );
    }

    getTransmissionLinesInfo(projectId: number): Observable<TransmissionLine[]> {
        return this.http.get<TransmissionLine>(`${environment.apiUrl}project/transmissionLines/${projectId}`)
            .pipe(
                map((data: any) => TransmissionLine.BuildTransmissionLine(data.response))
            );
    }

    getNewsInfo(projectId: number): Observable<News[]> {
        return this.http.get<News>(`${environment.apiUrl}project/news/${projectId}`)
            .pipe(
                map((data: any) => News.BuildNew(data.response))
            );
    }

    getShalesPlaysByBasinsIds(basinsIds: number[]): Observable<ShalePlay[]> {
        return this.http.post<ShalePlay>(`${environment.apiUrl}project/shalePlaysByBasinIds`, basinsIds)
            .pipe(
                map((data: any) => ShalePlay.BuildShalePlays(data.response))
            );
    }

    getTivForProject(kickoffDate: string, currency: string, tiv: string, targetCurrency: string ): Observable<number> {
        const params = new HttpParams()
            .append('kickoffDate', kickoffDate)
            .append('currency', currency)
            .append('tiv', tiv)
            .append('targetCurrency', targetCurrency);
        return this.http.get<string>(`${environment.apiUrl}project/getTivForProject/`, {params})
            .pipe(
                map((data: any) => data.response && data.response.length > 0 ?
                        data.response[0].PAST_TIV_USD : '')
            );
    }

    getUserDetailInfo(userName: string): Observable<UserDetail> {
        return this.http.get<UserDetail>(`${environment.apiUrl}registration/user/details/${userName}`).pipe(
            map((data: any) => {
                const userInfo = UserDetail.BuildUserDetailData(data.response[0]);
                return userInfo;
            }),
        );
    }

    getSicCodesFilteredBySicCode(sicCodeID?, sicCodeName?, industryCode?) {
        let wheel: SpinnerProcess = this.loadWheelService.showWheel(this.translate.instant("loading.sicCodes"));
        return this.http.get(`${environment.apiUrl}project/search/sicCode?sicCodeID=${sicCodeID}&sicCodeName=${sicCodeName}&industryCode=${industryCode}`)
            .pipe(
                map((data: any) => {
                    this.loadWheelService.hideWheel(wheel);
                    return data.response
                }),
                catchError((error) => {
                    this.loadWheelService.hideWheel(wheel);
                    return null;
                })
            );
    }

    searchSicCodes(sicCodeID?, sicCodeName?, industryCode?): Observable<SicCode[]> {
        return this.getFilteredSicCodes(sicCodeID, sicCodeName, industryCode)
            .pipe(
                map((data: any) => {
                    if (data && data !== null) {
                        return SicCode.BuildSicCode(data);
                    }
                    return data;
                })
            );
    }

    private getFilteredSicCodes(sicCodeID?, sicCodeName?, industryCode?) {
        let wheel: SpinnerProcess = this.loadWheelService.showWheel(this.translate.instant("loading.sicCodes"));
        const httpParams = new HttpParams()
            .append('sicCodeID', sicCodeID)
            .append('sicCodeName', sicCodeName)
            .append('industryCode', industryCode);

        if(!this.entityUtilsService.checkForValueRequired(httpParams, wheel)){
            return of([]);
        }

        return this.http.get(`${environment.apiUrl}project/search/sicCode`, { params: httpParams })
            .pipe(
                map((data: any) => {
                    this.loadWheelService.hideWheel(wheel);
                    return data.response
                }),
                catchError((error) => {
                    this.loadWheelService.hideWheel(wheel);
                    return null;
                })
            );
    }

    private filterProjectsByMvOrderAndOwnerName(projects: Array<any>): Array<any> {
        return projects.filter(project => {
            return project.MV_ORDER == '1' || ((project.OWNER_NAME == null || project.OWNER_NAME == '') && (project.MV_ORDER == null || project.MV_ORDER == ''));
        });
    }

    private removeDuplicatedProjects(projects: Array<any>): Array<any> {
        let uniqueProjects: Array<any> = [];
        projects.forEach(project => {
            if (!uniqueProjects.some(uniqueProject => uniqueProject.PROJECT_ID == project.PROJECT_ID)) {
                uniqueProjects.push(project);
            }
        });
        return uniqueProjects;
    }

    private sortProjectsByProjectName(projects: Array<any>): Array<any> {
        return projects.sort((a, b) => {
            if (a.PROJECT_NAME < b.PROJECT_NAME) return -1;
            if (a.PROJECT_NAME > b.PROJECT_NAME) return 1;
            return 0;
        });
    }

    searchProject(params: IProjectSearchParams, wheel: SpinnerProcess): Observable<ProjectSearch[]> {
        const searchStart: number = performance.now();

        const httpParams = new HttpParams()
            .append('projectId', params.projectID === null ? '' : params.projectID)
            .append('plantId', params.plantID === null ? '' : params.plantID)
            .append('ownerId', params.ownerID === null ? '' :  params.ownerID)
            .append('ownerName', params.ownerName)
            .append('projectName', params.projectName)
            .append(params.pecZone === '' ? '' : 'pecZone', params.pecZone)
            .append(params.country !== null && params.country !== '' ? 'country': '' , params.country)
            .append('state', params.state === null ? '' : params.state)
            .append('offshore', params.offshore)
            .append('fieldName', params.fieldName)
            .append('waterBody', params.waterBody !== null && String(params.waterBody) !== '0' ? params.waterBody : '')
            .append('recordStatus', params.recordStatus !== null ? params.recordStatus : '')
            .append('projectStatus', params.projectStatus);
        if(!this.entityUtilsService.checkForValueRequired(httpParams, wheel)){
            return of(null);
        }

        return this.http.get<ProjectSearch[]>(`${environment.apiUrl}project/search`, {params: httpParams})
            .pipe(
                map((data: any) => {
                    this.entityUtilsService.verifyMaxResult(data);
                    const projects: Array<ProjectSearch> = data.response;

                    this.telemetry.entitySearchTime("project", performance.now() - searchStart);

                    return projects.map(item => this.projectSearchAdapter.adapt(item));
                })
            )
    }

    @Cacheable()
    getSicCodes(): Observable<SicCode[]> {
        return this.http.get<SicCode>(`${environment.apiUrl}project/sicCodes`)
            .pipe(
                map((data: any) => SicCode.BuildSicCode(data.response))
            );
    }

    @Cacheable()
    getProjectRecordStatus(): Observable<ProjectRecordStatus[]> {
        return this.http.get<ProjectRecordStatus>(`${environment.apiUrl}project/projectRecordStatus`)
            .pipe(
                map((data: any) => ProjectRecordStatus.BuildProjectRecordStatus(data.response))
            );
    }




    getPipelinesByProjectId(projectId: number): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}project/pipelines/${projectId}`)
            .pipe(
                map((data: any) => Pipeline.BuildPipeline(data.response))
            );

    }

    resetSearchParams(): void{
        this.searchParams = {
            projectID: null,
            plantID: null,
            ownerID: null,
            ownerName: '',
            projectName: '',
            pecZone: '',
            country: null,
            state: null,
            offshore: '0',
            fieldName: '',
            waterBody: null,
            recordStatus: null,
            projectStatus: '',
            recordedSearch: false,
        };
    }

    validateDunsFormat(value: string): boolean {
        const pattern = /^\(?([0-9]{2})[-]([0-9]{3})[-]([0-9]{4})$/g;
        return pattern.test(value);
    }

    openFlow(row: any) {
        this.website.goToWebsite(environment.flowUrl + `?edMode=true&code=project_pipe_meter_flow&meterNo=${row.meterNo}`);
    }

    openTotalFlow(row: any[], projectId: number) {
        const meterNos: string = row.map(e => e.meterNo).join(',');
        this.website.goToWebsite(environment.flowUrl + `?edMode=true&code=project_pipe_meter_flow_ed_all&entityId=${projectId}&meterNo=${meterNos}`);
    }

}
