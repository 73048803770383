import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'primeng/dynamicdialog';
import { AuthService } from 'src/app/auth/auth.service';
import { EntityName } from 'src/app/core/models/enumerations/entity-name';
import { Subscription, interval } from 'rxjs';
import { NotificationApiService } from 'src/app/core/services/notification-api.service';
import { SystemNotification } from 'src/app/core/models/system-notification';
import { RouterUrl } from 'src/app/core/models/constants';
import { SessionAttribute, SessionService } from 'src/app/core/services/session.service';
import { VersionService } from 'src/app/core/services/version.service';
import { WebsiteService } from 'src/app/core/services/website.service';
import { GeographyRequestModalComponent } from 'src/app/components/modals/geography-request-modal/geography-request-modal.component';
import { MessageHandlerService } from 'src/app/components/messages/message-handler/message-handler.service';
import { MessageType } from 'src/app/components/messages/message-handler/message-handler.component';

@Component({
    selector: 'app-top-nav',
    templateUrl: './top-nav.component.html',
    styleUrls: ['./top-nav.component.scss']
})

export class TopNavComponent implements OnInit, OnDestroy {
    
    showUpdateLink: boolean;
    currentDate: Date;
    clientNotificationCount: number = 0;
    serverNotificationCount: number = 0;

    hasCompanyScreen: boolean = false;
    hasSiteScreen: boolean = false;
    hasPipelineScreen: boolean = false;
    hasTLineScreen: boolean = false;
    hasPlantScreen: boolean = false;
    hasAreaScreen: boolean = false;
    hasUnitScreen: boolean = false;
    hasBoilerScreen: boolean = false;
    hasDriveScreen: boolean = false;
    hasTankScreen: boolean = false;
    hasMEquipmentScreen: boolean = false;
    hasOEquipmentScreen: boolean = false;
    hasOfflineEventScreen: boolean = false;
    hasConfirmedProjectScreen: boolean = false;
    hasUnconfirmedProjectScreen: boolean = false;
    hasPlantLTSAScreen: boolean = false;
    hasUnitLTSAScreen: boolean = false;
    hasPipelineLTSAScreen: boolean = false;
    hasReleaseScreen: boolean = false;

    private notificationChange: Subscription;
    private sessionChange: Subscription;
    private currentDateUpdater: Subscription;

    constructor(public router: Router,
        private authService: AuthService,
        private messageHandlerService: MessageHandlerService,
        private dialogService: DialogService,
        private translate: TranslateService,
        private notificationService: NotificationApiService,
        private versionService: VersionService,
        private session: SessionService,
        private website: WebsiteService,
        )
    {
        this.showUpdateLink = this.session.getAttribute(SessionAttribute.UpdateAvailable) ?? false;
    }

    ngOnInit(){

        this.authService.hasUserScreen(EntityName.COMPANY).subscribe(b => this.hasCompanyScreen = b);
        this.authService.hasUserScreen(EntityName.SITE).subscribe(b => this.hasSiteScreen = b);
        this.authService.hasUserScreen(EntityName.ASSET_PIPELINE).subscribe(b => this.hasPipelineScreen = b);
        this.authService.hasUserScreen(EntityName.ASSET_TRANSMISSION_LINE).subscribe(b => this.hasTLineScreen = b);
        this.authService.hasUserScreen(EntityName.PLANT).subscribe(b => this.hasPlantScreen = b);
        this.authService.hasUserScreen(EntityName.AREA).subscribe(b => this.hasAreaScreen = b);
        this.authService.hasUserScreen(EntityName.UNIT).subscribe(b => this.hasUnitScreen = b);
        this.authService.hasUserScreen(EntityName.BOILER).subscribe(b => this.hasBoilerScreen = b);
        this.authService.hasUserScreen(EntityName.TURBINE).subscribe(b => this.hasDriveScreen = b);
        this.authService.hasUserScreen(EntityName.TANK).subscribe(b => this.hasTankScreen = b);
        this.authService.hasUserScreen(EntityName.MEQUIPMENT).subscribe(b => this.hasMEquipmentScreen = b);
        this.authService.hasUserScreen(EntityName.OEQUIPMENT).subscribe(b => this.hasOEquipmentScreen = b);
        this.authService.hasUserScreen(EntityName.OFFLINE_EVENT).subscribe(b => this.hasOfflineEventScreen = b);
        this.authService.hasUserScreen(EntityName.PROJECT_CONFIRMED).subscribe(b => this.hasConfirmedProjectScreen = b);
        this.authService.hasUserScreen(EntityName.PROJECT_UNCONFIRMED).subscribe(b => this.hasUnconfirmedProjectScreen = b);
        this.authService.hasUserScreen(EntityName.PLANT_LTSA).subscribe(b => this.hasPlantLTSAScreen = b);
        this.authService.hasUserScreen(EntityName.UNIT_LTSA).subscribe(b => this.hasUnitLTSAScreen = b);
        this.authService.hasUserScreen(EntityName.PIPELINE_LTSA).subscribe(b => this.hasPipelineLTSAScreen = b);
        this.authService.hasUserScreen(EntityName.RELEASE).subscribe(b => this.hasReleaseScreen = b);
        
        this.notificationChange = this.notificationService.notificationChange$.subscribe((notList: Array<SystemNotification>) => {
            this.clientNotificationCount = notList.filter(n => !n.server).length;
            this.serverNotificationCount = notList.filter(n => n.server).length;
        });
        
        this.sessionChange = this.session.attributeChange$.subscribe((csa) => {
            if(csa && csa.name === SessionAttribute.UpdateAvailable)
            {
               this.showUpdateLink = csa.newValue;
            }
        });        
        
        this.currentDate = new Date();
        this.currentDateUpdater = interval(5 * 60 * 1000).subscribe(() => {
            this.currentDate = new Date(); 
        });

    }

    ngOnDestroy(): void {
        this.notificationChange?.unsubscribe();
        this.sessionChange?.unsubscribe();
        this.currentDateUpdater?.unsubscribe();
    }

    goDashboard() {
        window.open(location.pathname + '#' + RouterUrl.Dashboard, EntityName.DASHBOARD);
    }

    goCompany() {
        if(this.hasCompanyScreen){
            this.goTo('company', EntityName.COMPANY);
        }
    }

    goSite() {
        if(this.hasSiteScreen){
            this.goTo('site', EntityName.SITE);
        }
    }

    goPipeline() {
        if(this.hasPipelineScreen){
            this.goTo('pipeline', EntityName.ASSET_PIPELINE);
        }
    }

    goTLine() {
        if(this.hasTLineScreen) {
            this.goTo('transmissionLine', EntityName.ASSET_TRANSMISSION_LINE);
        }
    }

    goPlant() {
        if(this.hasPlantScreen) {
            this.goTo('plant', EntityName.PLANT);
        }
    }

    goArea() {
        if(this.hasAreaScreen) {
            this.goTo('area', EntityName.AREA);
        }
    }

    goConfirmedProject() {
        if(this.hasConfirmedProjectScreen) {
            this.goTo('confirmedProject', EntityName.PROJECT_CONFIRMED);
        }
    }

    goUnconfirmedProject() {
        if(this.hasUnconfirmedProjectScreen) {
            this.goTo('unconfirmedProject', EntityName.PROJECT_UNCONFIRMED);
        }
    }
    
    goUnit() {
        if(this.hasUnitScreen) {
            this.goTo('unit', EntityName.UNIT);
        }
    }

    goPlantLtsa() {
        if(this.hasPlantLTSAScreen) {
            this.goTo('plantLtsa', EntityName.PLANT_LTSA);
        }
    }

    goUnitLtsa() {
        if(this.hasUnitLTSAScreen) {
            this.goTo('unitLtsa', EntityName.UNIT_LTSA);
        }
    }

    goPipelineLtsa() {
        if(this.hasPipelineLTSAScreen) {
            this.goTo('pipelineLtsa', EntityName.PIPELINE_LTSA);
        }
    }

    goBoiler() {
        if(this.hasBoilerScreen) {
            this.goTo('boiler', EntityName.BOILER);
        }
    }

    goDrive() {
        if(this.hasDriveScreen) {
            this.goTo('drive', EntityName.TURBINE);
        }
    }

    goTank() {
        if(this.hasTankScreen) {
            this.goTo('tank', EntityName.TANK);
        }
    }

    goMEquipment() {
        if(this.hasMEquipmentScreen) {
            this.goTo('miningEquipment', EntityName.MEQUIPMENT);
        }
    }

    goOtherEquipment() {
        if(this.hasOEquipmentScreen) {
            this.goTo('otherEquipment', EntityName.OEQUIPMENT);
        }
    }

    goOfflineEvent() {
        if(this.hasOEquipmentScreen) {
            this.goTo('offlineEvent', EntityName.OFFLINE_EVENT);
        }
    }

    goUmbrellaProject() {
        if(this.hasConfirmedProjectScreen || this.hasUnconfirmedProjectScreen) {
            //this.goTo('umbrellaProject', EntityName.PROJECT_UMBRELLA);
        }
    }
    
    goRelease() {
        if(this.hasReleaseScreen) {
            this.goTo('release', EntityName.RELEASE);
        }
    }

    goPreferences() {
        this.goTo('preferences', EntityName.PREFERENCES);
    }

    private goTo(path: string, target: string):void{
        window.open(location.pathname+ `#/ED/${path}`, `${target}`);
    }
    
    updateCheck(): void {
        this.versionService.loadServerVersion(false).subscribe(() => {
           if(this.versionService.isUpToDate())
           {
              this.messageHandlerService.show('Application is up to date', MessageType.ALERT_TYPE_SYSTEM_MESSAGE);
           }           
        });
    }
    
    doUpdate(): void {
        this.versionService.doUpdate();
    }    

    showNotifications() {
        this.notificationService.showNotifications();
    }
    
    getNotifyImageSrc(): string {
       return this.notificationService.hasUnreadNotification() ? "assets/icons/bell_animated.svg" : "assets/icons/bell.svg";
    }
    
    goToWebsite(path?: string):void {
        this.website.goToWebsite(path);
    }

    openGeographyRequest(): void {
        const modalRef = this.dialogService.open(GeographyRequestModalComponent, {
            header: this.translate.instant('geographyRequest.title'),
            width: '40rem',
            data: {},
            dismissableMask: true,
            draggable: true,
            keepInViewport: true
        });
    }
}
