import { IAuditEntityData } from "src/app/core/interfaces/iaudit-entity-data";
import { EntityName } from "../../enumerations/entity-name";
import { OperationType } from "../../enumerations/operation-type";
import { Drives } from "../save/drives";
import { TurbinesFuelDisplay } from "./turbines-fuel-display";

export class DrivesDisplay extends Drives {
    plantId: number;
    areaId: string;
    ownerId: number;
    ownerName: string;
    unitName: string;
    unitType: string;
    unitTypeName: string;
    unitStartupDate: string;
    unitStartUpResolution: string;
    unitShutDownDate: string;
    unitShutDownResolution: string;
    unitStatus: string;
    unitStatusDesc: string;
    unitCategoryId: string;
    plantName: string;
    areaName: string;
    physAddressV1: string;
    physAddressV2: string;
    manufacturerName: string;
    prevManufacturerName: string;
    generatorManufacturerName: string;
    designFirmName: string;
    constructorFirmName: string;
    phoneNo: string;
    phoneNoV1: string;
    aecFirmName: string;
    physCity: string;
    physState: string;
    physCountry: string;
    physCountryName: string;
    physPostalCode: string;
    plantOffshore: boolean;
    offshoreBlockV1: string;
    offshoreWaterbodyName: string;
    offshoreFieldName: string;
    offshoreAreaName: string;
    typeDescription: string;
    lastUserEmailAddress: string;
    entryUserEmailAddress: string;
    previousUserEmailAddress: string;
    qcUserEmailAddress: string;
    releaseUserEmailAddress: string;
    amendmentUserEmailAddress: string;
    lastUpdateStatus: string;
    manufacturerIdLiveData: number;
    modelLiveData: string;
    genManufacturerIdLiveData: number;
    prevManufacturerIdLiveData: number;
    seriesLiveData: string;
    genModelLiveData: string;

    // MVTs
    turbinesFuelDisplay: Array<TurbinesFuelDisplay>;

    private constructor(entity?: any) {
        super(entity);

        this.plantId = (entity && entity.plantId) ?? null;
        this.areaId = (entity && entity.areaId) ?? null;
        this.ownerId = (entity && entity.ownerId) ?? null;
        this.ownerName = (entity && entity.ownerName) ?? null;
        this.unitName = (entity && entity.unitName) ?? null;
        this.unitType = (entity && entity.unitType) ?? null;
        this.unitTypeName = (entity && entity.unitTypeName) ?? null;
        this.unitStartupDate = (entity && entity.unitStartupDate) ?? null;
        this.unitStartUpResolution = (entity && entity.unitStartUpResolution) ?? null;
        this.unitShutDownDate = (entity && entity.unitShutDownDate) ?? null;
        this.unitShutDownResolution = (entity && entity.unitShutDownResolution) ?? null;
        this.unitStatus = (entity && entity.unitStatus) ?? null;
        this.unitStatusDesc = (entity && entity.unitStatusDesc) ?? null;
        this.unitCategoryId = (entity && entity.unitCategoryId) ?? null;
        this.plantName = (entity && entity.plantName) ?? null;
        this.areaName = (entity && entity.areaName) ?? null;
        this.physAddressV1 = (entity && entity.physAddressV1) ?? null;
        this.physAddressV2 = (entity && entity.physAddressV2) ?? null;
        this.manufacturerName = (entity && entity.manufacturerName) ?? null;
        this.prevManufacturerName = (entity && entity.prevManufacturerName) ?? null;
        this.generatorManufacturerName = (entity && entity.generatorManufacturerName) ?? null;
        this.designFirmName = (entity && entity.designFirmName) ?? null;
        this.constructorFirmName = (entity && entity.constructorFirmName) ?? null;
        this.phoneNo = (entity && entity.phoneNo) ?? null;
        this.phoneNoV1 = (entity && entity.phoneNoV1) ?? null;
        this.aecFirmName = (entity && entity.aecFirmName) ?? null;
        this.physCity = (entity && entity.physCity) ?? null;
        this.physState = (entity && entity.physState) ?? null;
        this.physCountry = (entity && entity.physCountry) ?? null;
        this.physCountryName = (entity && entity.physCountryName) ?? null;
        this.physPostalCode = (entity && entity.physPostalCode) ?? null;
        this.plantOffshore = (entity && entity.plantOffshore) ?? false;
        this.offshoreBlockV1 = (entity && entity.offshoreBlockV1) ?? null;
        this.offshoreWaterbodyName = (entity && entity.offshoreWaterbodyName) ?? null;
        this.offshoreFieldName = (entity && entity.offshoreFieldName) ?? null;
        this.offshoreAreaName = (entity && entity.offshoreAreaName) ?? null;
        this.typeDescription = (entity && entity.typeDescription) ?? null;
        this.lastUserEmailAddress = (entity && entity.lastUserEmailAddress) ?? null;
        this.entryUserEmailAddress = (entity && entity.entryUserEmailAddress) ?? null;
        this.previousUserEmailAddress = (entity && entity.previousUserEmailAddress) ?? null;
        this.qcUserEmailAddress = (entity && entity.qcUserEmailAddress) ?? null;
        this.releaseUserEmailAddress = (entity && entity.releaseUserEmailAddress) ?? null;
        this.amendmentUserEmailAddress = (entity && entity.amendmentUserEmailAddress) ?? null;
        this.lastUpdateStatus = (entity && entity.lastUpdateStatus) ?? '';
        this.manufacturerIdLiveData = (entity && entity.manufacturerIdLiveData) ?? null;
        this.modelLiveData = (entity && entity.modelLiveData) ?? null;
        this.genManufacturerIdLiveData = (entity && entity.genManufacturerIdLiveData) ?? null;
        this.prevManufacturerIdLiveData = (entity && entity.prevManufacturerIdLiveData) ?? null;
        this.seriesLiveData = (entity && entity.seriesLiveData) ?? null;
        this.genModelLiveData = (entity && entity.genModelLiveData) ?? null;
        this.turbinesFuelDisplay = (entity && entity.turbinesFuelDisplay) ?? [];
        
    }

    static BuildDriveDisplay(entity: any): DrivesDisplay {
        return new DrivesDisplay(entity);
    }

    static BuildNewDrive(response: any): DrivesDisplay {
        return new DrivesDisplay({
            turbineId: response[0].TURBINE_ID,
            operation: OperationType.INSERT,
            intDataDepValState: OperationType.INSERT
        });
    }

    prepareSaveCollection() {
        this.turbinesFuel = this.turbinesFuelDisplay;
    }

    get auditEntityBean(): IAuditEntityData {
        return {
            lastUser: this.lastUser,
            entryUser: this.entryUser,
            prevUser: this.prevUser,
            qcUser: this.qcUser,
            lastDate: this.lastDate,
            entryDate: this.entryDate,
            prevDate: this.prevDate,
            qcDate: this.qcDate,
            releaseUser: this.releaseUser,
            releaseDate: this.releaseDate,
            amendmentUser: this.amendmentUser,
            amendmentDate: this.amendmentDate,
            lastUserEmailAddress: this.lastUserEmailAddress,
            entryUserEmailAddress: this.entryUserEmailAddress,
            previousUserEmailAddress: this.previousUserEmailAddress,
            qcUserEmailAddress: this.qcUserEmailAddress,
            releaseUserEmailAddress: this.releaseUserEmailAddress,
            amendmentUserEmailAddress: this.amendmentUserEmailAddress,
            lastUpdateStatus: this.lastUpdateStatus,
            entityName: EntityName.TURBINE,
            entityId: this.turbineId
        };
    }
    
}
