import { Bean } from "../../bean";

export class PlantsOutputInfo extends Bean {
    mvId: number;
    plantId: number;
    outputActualCapacity: string;
    outputDesignCapacity: string;
    outputHsProduct: string;
    outputMultiplier: string;
    outputProduct: string;
    outputProductMeasure: string;
    outputTimeMeasure: string;
    outputUnitMeasure: string;
    mvOrder: number;
    intdatadepamendstate: string;
    isInsert: boolean;
    lastDate: string;
    capacityQcDate: string;
    capacityQcUser: string;

    protected constructor(entity?: any) {
        super(entity);

        this.mvId = entity && entity.mvId || null;
        this.plantId = entity && entity.plantId || null;
        this.outputActualCapacity = entity && entity.outputActualCapacity || null;
        this.outputDesignCapacity = entity && entity.outputDesignCapacity || null;
        this.outputHsProduct = entity && entity.outputHsProduct || null;
        this.outputMultiplier = entity && entity.outputMultiplier || null;
        this.outputProduct = entity && entity.outputProduct || null;
        this.outputProductMeasure = entity && entity.outputProductMeasure || null;
        this.outputTimeMeasure = entity && entity.outputTimeMeasure || null;
        this.outputUnitMeasure = entity && entity.outputUnitMeasure || null;
        this.mvOrder = entity && entity.mvOrder || null;
        this.intdatadepamendstate = entity && entity.intdatadepamendstate || null;
        this.isInsert = entity && entity.isInsert || null;
        this.lastDate = entity && entity.lastDate || null;
        this.capacityQcDate = entity && entity.capacityQcDate || null;
        this.capacityQcUser = entity && entity.capacityQcUser || null;
    }

}
