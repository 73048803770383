import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
// Rxjs
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthAction, AuthService } from "src/app/auth/auth.service";
import { MessageEvent, MessageResponse, MessageType } from "src/app/components/messages/message-handler/message-handler.component";
import { MessageHandlerService } from "src/app/components/messages/message-handler/message-handler.service";
/// Environment
import { environment } from 'src/environments/environment';
import { Constants } from "../models/constants";
import { EntityName } from "../models/enumerations/entity-name";
import { SessionAttribute, SessionService } from "../services/session.service";
import { LockDetails } from "./lock-details";


@Injectable({
    providedIn: 'root'
})
export class RecordLockingService {

    constructor(private http: HttpClient,
                 private authService: AuthService,
                 private session: SessionService,
                 private messageHandlerService: MessageHandlerService) { 
        this.authService.loginChange$.subscribe((action: AuthAction) => {
            if (action === AuthAction.LOGIN) {
                this.otherSessionLockDetails().subscribe((lItems: Array<LockDetails>) => {
                    this.clearLocksConfirm(lItems);
                });
            }
        });
    }

    clearLock(locksIds: Array<Number>): Observable<any> {
      return this.http.put(`${environment.apiUrl}recordlocking/clear`, locksIds)
        .pipe(
            map((data: any) => {
                return data.response;
            })
        );
    }

    clearLockDuringUnload(locksIds: Array<Number>): void {
        const token:string = this.session.getAttribute(SessionAttribute.ServerAuthToken);
        
        // We would use sendBeacon() here, but that must be a POST & cannot include auth, so we do it the more verbose way with fetch():
        fetch(`${environment.apiUrl}recordlocking/clear`, {
            keepalive: true,
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(locksIds),
        });
    }

    otherSessionLockDetails(): Observable<LockDetails[]> {
      return this.http.get<LockDetails>(`${environment.apiUrl}recordlocking/locks/othersession`)
        .pipe(
            map((data: any) => {
                return data.response;
            })
        );
    }

    

    getEntityLockBySameUser(entityType: string, entityId: string): Observable<LockDetails> {
        return this.validateEntityIsLockedBySameUser(entityType, [entityId])
            .pipe(
                map((locks: LockDetails[]) => {
                    if (locks && locks.length > 0) {
                        return locks[0];
                    } else {
                        return null;
                    }
                })
            );
    }

    private validateEntityIsLockedBySameUser(entityType: string, entityIDs: string[]): Observable<LockDetails[]> {
        const httpParams = new HttpParams()
            .append('entityType', entityType)
            .append('entityIDs', entityIDs.join(","));

        return this.http.get<LockDetails[]>(`${environment.apiUrl}recordlocking/validateEntityIsLockedBySameUser`, { params: httpParams })
            .pipe(
                map((data: any) => {
                    return data.response;
                })
            );
    }

    clearContactsLockedsBySameUser(contactsArray: any[]) {
        if(!contactsArray || contactsArray.length === 0) {
            return;
        }

        const contactIds: string[] = contactsArray.filter(contact => contact.contactId).map(contact => contact.contactId.toString());
        if(contactIds.length > 0)
        {
            this.validateEntityIsLockedBySameUser(EntityName.CONTACT, contactIds)
                .subscribe((locks: LockDetails[]) => {
                    if(locks != null && locks.length > 0) {
                        this.clearLock(locks.map(lock => lock.lockID)).subscribe();
                    }
                });
        }
    }

    private clearLocksConfirm(lockedItems:Array<LockDetails>) {
        if (lockedItems && lockedItems.length > 0) {
            const lockIds = lockedItems.map(p => p.lockID);
            const lhFunction = (resp: MessageResponse): void => {
                if (resp.event === MessageEvent.YES) {
                    this.clearLock(lockIds).subscribe();
                }
            };
            const entities = lockedItems.map(p => p.entityType + ' ' + p.entityID).join('<br>');
            this.messageHandlerService.show(Constants.replace(Constants.CONFIRM_CLEAR_LOCKS, [entities]), MessageType.CONFIRM, lhFunction);
        }
    }
}
