import { Injectable } from "@angular/core";
import { Adapter } from "../../adapter";

export class PlantSearch {
    constructor(
        public plantId: string,
        public plantName: string,

        public plantPhysAddressV1: string,
        public plantPhysAddressV2: string,
        public plantPhysCity: string,
        public plantPhysStateId: string,
        public plantPhysState: string,
        public plantPhysStateName: string,
        public plantPhysCountry: string,
        public plantPhysCountryId: string,
        public plantPhysCountryName: string,
        public plantPhysCityId: string,
        public plantPhysPostalCode: string,
        public plantCountyName: string,
        public pecZoneSimplex: string,

        public plantOffshoreStateDesignation: string,

        public plantOffshoreCtryDesigName: string,
        public plantOffshoreStateDesigName: string,
        public plantOffshoreWaterBodyId: string,
        public plantOffshoreFieldId: string,
        public plantOffshoreAreaId: string,
        public plantOffshoreBlockV1: string,
        public plantOffshoreBlockV2: string,
        public plantOffshore: string,
        public plantArea: string,
        public plantWaterBody: string,
        public plantFieldName: string,

        public mailAddressV1: string,
        public mailAddressV2: string,
        public mailCityId: string,
        public mailCity: string,
        public mailCountryId: string,
        public mailCountry: string,
        public mailCountryName: string,
        public mailStateId: string,
        public mailState: string,
        public mailStateName: string,
        public mailPostalCode: string,

        public fax: string,
        public faxCc: string,
        public faxExt: string,
        public phone: string,
        public phoneCc: string,
        public phoneExt: string,
        public phoneMobile: string,
        public qcDate: string,
        public noEmployees: string,
        public recordStatus: string,
        public recordStatusDesc: string,
        public operatorId: string,
        public operatorName: string,
        public parentCompanyId: string,
        public parentCompanyName: string,
        public ownerId: string,
        public ownerName: string,
        public sicCode: string,
        public plantStatusDesc: string,

        public kv1Desc: string,
        public kv2Desc: string,

        public plantLatitude: string,
        public plantLongitude: string,
        public industryCode: string,
        public releaseDate: string
    ) { }

    get physAddress(): string {
        return this.concatAddress(this.plantPhysAddressV1, this.plantPhysAddressV2);
    }

    get mailAddress(): string {
        return this.concatAddress(this.mailAddressV1, this.mailAddressV2);
    }

    get quadrantBlock(): string {
        return this.concatAddress(this.plantOffshoreBlockV1, this.plantOffshoreBlockV2);
    }

    private concatAddress(v1: string, v2: string): string {
        return (v1 || '') + ' ' + (v2 || '');
    }
}


@Injectable({
    providedIn: 'root',
})
export class PlantSearchAdapter implements Adapter<PlantSearch> {
    adapt(item: any): PlantSearch {
        return new PlantSearch(
            item.PLANT_ID,
            item.PLANT_NAME,

            item.PHYS_ADDRESS_V1,
            item.PHYS_ADDRESS_V2,
            item.PHYS_CITY,
            item.PHYS_STATE_ID,
            item.PHYS_STATE,
            item.STATE_NAME || item.PHYS_STATE_NAME,
            item.PHYS_COUNTRY,
            item.PHYS_COUNTRY_ID,
            item.COUNTRY_NAME || item.PHYS_COUNTRY_NAME,
            item.PHYS_CITY_ID || item.PHYS_CITY_CI_ID,
            item.PHYS_POSTAL_CODE,
            item.COUNTY_NAME,
            item.PEC_ZONE_SIMPLEX,

            item.OFFSHORE_STATE_DESIGNATION,
            item.OFFSHORE_COUNTRY_DESIG_NAME,
            item.OFFSHORE_STATE_DESIG_NAME,
            item.OFFSHORE_WATERBODY_ID,
            item.OFFSHORE_FIELD_ID,
            item.OFFSHORE_AREA_ID,
            item.OFFSHORE_BLOCK_V1,
            item.OFFSHORE_BLOCK_V2,
            item.OFFSHORE,
            item.OFFSHORE_AREA_NAME || item.AREA_NAME,
            item.OFFSHORE_WATERBODY_NAME || item.WATERBODY_NAME,
            item.OFFSHORE_FIELD_NAME || item.FIELD_NAME,

            item.MAIL_ADDRESS_V1,
            item.MAIL_ADDRESS_V2,
            item.MAIL_CITY_CI_ID,
            item.MAIL_CITY,
            item.MAIL_COUNTRY_C_ID,
            item.MAIL_COUNTRY,
            item.MAIL_COUNTRY_NAME,
            item.MAIL_STATE_S_ID,
            item.MAIL_STATE,
            item.MAIL_STATE_NAME,

            item.MAIL_POSTAL_CODE,

            item.FAX || item.FAX_NO_V1,
            item.FAX_CC || item.FAX_CC_V1,
            item.FAX_EXT || item.FAX_EXT_V1,
            item.PHONE || item.PHONE_NO_V1,
            item.PHONE_CC || item.PHONE_CC_V1,
            item.PHONE_EXT || item.PHONE_EXT_V1,
            item.PHONE_MOBILE,
            item.QC_DATE,
            item.NO_EMPLOYEES,
            item.RECORD_STATUS,
            item.RECORD_STATUS_DESC,
            item.OPERATOR_ID,
            item.OPERATOR_NAME,
            item.PARENT_ID,
            item.PARENT_COMPANY_NAME || item.PARENT_NAME,
            item.OWNER_ID,
            item.OWNER_NAME,
            item.SIC_CODE,
            item.PLANT_STATUS_DESC || item.STATUS_DESC,

            item.KV1_DESC,
            item.KV2_DESC,

            item.LATITUDE,
            item.LONGITUDE,
            item.INDUSTRY_CODE,
            item.RELEASE_DATE
        );
    }
}

export class FuelTypeSearch {
    public constructor(
        public description: string,
        public fuelTypesId: string,
        public rn: number,
        public fuelTypeConcat?: string
    ) { }

    static BuildFuelTypeSearch(fuelType: Array<any>): Array<FuelTypeSearch> {
        return fuelType.map(item => FuelTypeSearch.CreateInstance(item));
    }

    static CreateInstance(entity: any): FuelTypeSearch {
        return new FuelTypeSearch(
            entity.DESCRIPTION,
            entity.FUEL_TYPES_ID,
            entity.RN,
            `${entity.FUEL_TYPES_ID} - ${entity.DESCRIPTION}`,
        );
    }
    
}
