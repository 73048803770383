    <div class="header">
        <h3>{{ titleKey | translate }} </h3> <label class="required-field main-required-field" *ngIf="required">*</label>
    </div>
    <!-- Message Dialog Component for display Info -->
    <app-message-dialog></app-message-dialog>
    <div class="mt-2">
        <button class="btn-row" type="button" [disabled]="isDisabledButton()" (click)="addRow()" [tabindex]="baseIndex">{{ "common.addRow" | translate }}</button>
    </div>

    <div class="mt-3">

        <p-table [value]="companyOwnerFiltered()"
            [columns]="cols"
            [resizableColumns]="true"
            styleClass="p-datatable-gridlines"
            (onEditComplete)="onEditComplete($event)"
            focusable
            [focusManager]="ownershipFocusManager"
            (onRowReorder)="onRowReorder($event)"
            [tabindex]="baseIndex + 1"
            [reorderableColumns]="true">

            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns" [style.text-align]="col.headerAlign" [style.flex-basis]="col.width" pReorderableColumn pResizableColumn>
                        {{col.header}}
                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="colgroup" let-columns>
                <colgroup>
                    <col *ngFor="let col of columns" [style.flex-basis]="col.width">
                </colgroup>
            </ng-template>
 
            <ng-template pTemplate="body" let-columns="columns" let-index="rowIndex" let-rowData>
                <tr [pReorderableRow]="index" >
                    <td *ngFor="let col of columns"
                        [style.text-align]="col.bodyAlign"
                        [pEditableColumn]="rowData" [pEditableColumnField]="col.field" [pEditableColumnRowIndex]="index" class="{{col.field}} px-2"
                        [style.flex-basis]="col.width" [style.white-space]="col.wSpace || 'normal'">
                        <div *ngIf="col.draggable && tableEditEnabled" class="draggable-col">
                            <span class="pi pi-bars" pReorderableRowHandle title="Reorder Row"></span>
                        </div>
                        <div *ngIf="(!col.editable || !tableEditEnabled) && col.field !== 'qcDate'">
                            {{rowData[col.field]}}
                        </div>
                        <div *ngIf="(!col.editable || !tableEditEnabled) && col.field === 'qcDate'">
                            {{ rowData[col.field] | date: 'dd-MMM-y' }}
                        </div>
                        <p-cellEditor *ngIf="col.editable && tableEditEnabled"
                                    [ngClass]="col.bodyAlign === 'right' ? 'justify-content-end': ''">
                            <ng-template pTemplate="input" class="flex">
                                <input type="text" class="w-5 max-w-5rem icon-container"
                                    (focus)="onEditInit()"
                                    *ngIf="col.field == 'ownerId'"
                                    [(ngModel)]="rowData[col.field]"
                                    (keydown.enter)="onEnter(col, rowData, index)"
                                    (focusout)="onFocusOut(col, rowData, index)"
                                    (keydown)="this.restrictArrowKeyNavigation($event)"
                                    maxlength="7" oninput="this.value=this.value.replace(/[^0-9]/g,'')">
                                <button pButton pRipple *ngIf="col.field == 'ownerId'"
                                        type="button"
                                        icon="pi pi-search"
                                        class="ml-1 p-button-text icon-container"
                                        (click)="openCompanySearchModal(rowData, index)">
                                </button>
                                <input type="text"
                                    (focus)="onEditInit()"
                                    *ngIf="col.field == 'ownerPct'"
                                    [(ngModel)]="rowData[col.field]"
                                    (keydown.enter)="onEnter(col, rowData, index)"
                                    (focusout)="onFocusOut(col, rowData, index)"
                                    (keydown)="this.restrictArrowKeyNavigation($event)"
                                    percentage [rowData]="rowData"
                                    [rowField]="col.field">
                                </ng-template>
                            <ng-template pTemplate="output">
                                <span *ngIf="col.field !== 'qcDate'">{{rowData[col.field]}}</span>
                                <span *ngIf="col.field === 'qcDate' && entityName === 'Plant'">{{ rowData[col.field] | date: 'dd-MMM-y' }}</span>
                            </ng-template>
                        </p-cellEditor>
                        <div class="text-center" *ngIf="col.delete && tableEditEnabled">
                            <button pButton pRipple type="button" icon="pi pi-trash" class="p-button-text icon-container" (click)="deleteCompanyOwner(rowData)" (keydown.enter)="deleteCompanyOwner(rowData)" tabindex="-1" autofocus></button>
                        </div>

                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td [attr.colspan]="columns.length + 1">
                        <div class="not-result-msg">
                            <span>{{ "ownership.addRowOwnership" | translate }}</span>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>

    </div>
