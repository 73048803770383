<div class="container-modal">
    <form [formGroup]="contactModalForm">
        <div class="Ex-content-modal">
            <!-- Radio buttons -->
            <div formGroupName="OptionsFilters" class="grid flex flex-nowrap">
                <!-- formGroupName="OptionsFilters" -->
                <div class="col-7 min-w-max">
                    <p-radioButton formControlName="Filter" name="Filter" value="Area" label="{{'common.area'|translate}}" inputId="optArea" class="pt-1"></p-radioButton>

                    <p-radioButton formControlName="Filter" name="Filter" value="Unit" label="{{'common.unit'|translate}}" inputId="optUnit" class="pl-2 pt-1"></p-radioButton>

                    <p-radioButton formControlName="Filter" name="Filter" value="Plant" label="{{'common.plant'|translate}}" inputId="optPlant" class="pl-2 pt-1"></p-radioButton>

                    <p-radioButton formControlName="Filter" name="Filter" value="Company" label="{{'common.company'|translate}}" inputId="optCompany" class="pl-2 pt-1"></p-radioButton>

                    <p-radioButton formControlName="Filter" name="Filter" value="Site" label="{{'common.site'|translate}}" inputId="optSite" class="pl-2 pt-1"></p-radioButton>
                </div>
                <div class="col-5 flex justify-content-center">
                    <p-radioButton formControlName="Filter" name="Filter" value="All" label="{{'common.allEntities'|translate}}" inputId="optAll" class="pl-2 pt-1"></p-radioButton>
                </div>
            </div>
            <!-- Form inpunts -->
            <div formGroupName="InputsFilters" class="mt-3">
                <div class="grid">
                    <div class="col-12 xl:col-6">
                        <!-- First (Given) -->
                        <div class="grid">
                            <label class="col-4 xl:col-3 font-bold text-xs mt-1 text-right pr-1">{{'contact.common.firstName'|translate}}:</label>
                            <div class="col-4 xl:col-5 -ml-2">
                                <input
                                    class="form__input-field-style w-full"
                                    formControlName="FirstName"
                                    #inputFirstName
                                    (keydown.Tab)="changeFocusLastName($event)"
                                    pInputText
                                    (keydown.enter)="search($event.srcElement)"
                                    autofocus appFocusElement
                                    tabindex="1"
                                />
                            </div>
                            <div class="col-4 lg:col-3 xl:col-3"></div>
                        </div>
                        <!-- Contact ID -->
                        <div class="grid">
                            <label class="col-4 xl:col-3 font-bold text-xs mt-1 text-right pr-1">{{'contact.common.contactId'|translate}}:</label>
                            <div class="col-4 xl:col-5 -ml-2">
                                <input
                                    class="form__input-field-style w-full"
                                    type="text"
                                    #inputContactId
                                    formControlName="ContactID"
                                    (keydown.Tab)="changeFocusToEmail($event)"
                                    pInputText
                                    (keydown.enter)="search($event.srcElement)"
                                    oninput="this.value=this.value.replace(/[^0-9]/g,'')"
                                    tabindex="3"
                                />
                            </div>
                            <div class="col-4 lg:col-3 xl:col-3"></div>
                        </div>
                        <!-- Company -->
                        <div class="grid">
                            <label class="col-4 xl:col-3 font-bold text-xs mt-1 text-right pr-1">{{'common.company'| translate}}:</label>
                            <div class="col-4 xl:col-5 -ml-2">
                            <input
                                class="form__input-field-style w-full"
                                type="text"
                                #inputCompanyName
                                formControlName="CompanyName"
                                (keydown.Tab)="changeFocusToSearch($event)"
                                size="75"
                                pInputText
                                (keydown.enter)="search($event.srcElement)"
                                tabindex="5"
                            />
                            </div>
                            <div class="col-4 lg:col-3 xl:col-3">
                                <button
                                    type="button"
                                    #buttonSearch
                                    class="btn-custom-form"
                                    (keydown.Tab)="changeFocusToClear($event)"
                                    (click)="search()"
                                    [tabindex]="6">
                                        {{'common.search'| translate}}
                                </button>
                                <button
                                    type="button"
                                    #buttonClear
                                    class="btn-custom-form ml-1"
                                    (keydown.Tab)="changeFocusToFirstName($event)"
                                    (click)="clear()"
                                    [tabindex]="7">
                                        {{'common.clear'| translate}}
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 xl:col-6 -mt-3 xl:mt-0">
                        <!-- Last (Surname/Family) -->
                        <div class="grid">
                            <label class="col-4 xl:col-3 font-bold text-xs mt-1 text-right pr-1">{{'contact.common.lastName'| translate}}:</label>
                            <div class="col-4 xl:col-5 -ml-2">
                                <input
                                    class="form__input-field-style w-full"
                                    type="text"
                                    #inputLastName
                                    formControlName="LastName"
                                    pInputText
                                    (keydown.Tab)="changeFocusToContactId($event)"
                                    (keydown.enter)="search($event.srcElement)"
                                    tabindex="2"
                                />
                            </div>
                            <div class="col-4 lg:col-3 xl:col-3"></div>
                        </div>
                        <!-- Email -->
                        <div class="grid">
                            <label class="col-4 xl:col-3 font-bold text-xs mt-1 text-right pr-1">{{'common.email'| translate}}:</label>
                            <div class="col-4 xl:col-5 -ml-2">
                                <input
                                    class="form__input-field-style w-full"
                                    type="text"
                                    formControlName="Email"
                                    #inputEmail
                                    pInputText
                                    (keydown.Tab)="changeFocusToCompany($event)"
                                    (keydown.enter)="search($event.srcElement)"
                                    tabindex="4"
                                />
                            </div>
                            <div class="col-4 lg:col-3 xl:col-3 px-0">
                                <img src="assets/icons/icon_info.png" width="15px" height="15px" alt="info" data-toggle="tooltip" data-placement="right" pTooltip="{{'contact.contactModal.emailTooltip'| translate}}" class="mt-1">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <!-- Text zones -->
    <div class="grid mb-2 mt-2">
            <div class="grid">
                <div class="col-6 text-xs">
                    <span>{{'contact.contactModal.infoChoose'| translate}}</span>
                </div>
                <div class="col-6 text-xs">
                    <span>{{'contact.contactModal.infoView' | translate}}</span>
                </div>
                <label class="opted-out-color text-xs pl-2">{{'contact.contactModal.infoRed'| translate}}</label>
            </div>
    </div>
    <!-- Data table -->
    <div class="container-table-modal">
        <div class="company-table">
            <p-table #searchTable
                [value]="companyContacts"
                [columns]="cols"
                styleClass="p-datatable-striped"
                [rows]="rowsPerPage"
                (onPage)="onPageChange($event)"
                [showCurrentPageReport]="true"
                [rowsPerPageOptions]="rowsPerPageOptions"
                [paginator]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [resizableColumns]="true"
                styleClass="p-datatable-gridlines"
                selectionMode="single"
                [(selection)]="this.additionalInfo"
                [reorderableColumns]="true">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" pReorderableColumn pResizableColumn pSortableColumn="{{col.field}}" tabindex="-1">
                            {{col.header}}
                            <p-sortIcon field="{{col.field}}"></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="colgroup" let-columns>
                    <colgroup>
                        <col *ngFor="let col of columns" [style]="col.style">
                    </colgroup>
                </ng-template>
                <ng-template pTemplate="body" let-rows let-rowData let-columns="columns">
                    <tr #tr
                        [pSelectableRow]="rowData"
                        [ngClass]="getRowClass(rowData)"
                        (click)="onRowclick(rowData)"
                        (dblclick)="onRowDblclick(rowData)"
                        (keydown.enter)="onRowDblclick(rowData)"
                        (keydown)="changeSelectionOnArrowKey($event, companyContacts)">
                        <td *ngFor="let col of columns" [title]='getColumnTitle(rowData)' [style.white-space]="col.wSpace || 'normal'">
                            {{col.field != 'QcDate' ? rowData[col.field] : rowData[col.field] | date: 'dd-MMM-y'}}
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-columns>
                    <tr>
                        <td [attr.colspan]="columns.length + 1">
                            <div class="table-msg mt-1">
                                <span>{{'common.noDataTable' | translate}}</span>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
    <!-- Design table -->
    <div class="container-info-modal main-content">
        <div class="grid card-modal mt-1 ml-0">
            <!-- Header -->
            <div class="col-12 header-info text-xs font-bold -mt-2">
                <span>{{'additional_info'| translate}}:</span>
            </div>
            <!-- Left & Right zones -->
            <!-- Left zone -->
            <div class="col-12 lg:col-6 xl:col-6 -mt-1">
                <div class="content-card">
                    <label class="custom-label-card-right-short">Entity Name:</label>
                    <label class="custom-label-card-info text-xs" for="">{{additionalInfo?.Entity_Name}}</label>
                </div>

                <div class="content-card">
                    <label class="custom-label-card-right-short">Address: </label>
                    <label class="custom-label-card-info text-xs" for="">{{additionalInfo?.EntityAddressV1}}</label>
                </div>

                <div class="content-card">
                    <label class="custom-label-card-right-short">Country: </label>
                    <label class="custom-label-card-info text-xs" for="">{{additionalInfo?.Country_Name}}</label>
                </div>

                <div class="content-card">
                    <label class="custom-label-card-right-short">Email: </label>
                    <label class="custom-label-card-info text-xs" for="">{{additionalInfo?.EmailAddress}}</label>
                </div>
            </div>
            <!-- Right zone -->
            <div class="col-12 lg:col-6 xl:col-6">
                <div class="content-card">
                    <label class="custom-label-card-right-short">Entity ID:</label>
                    <label class="custom-label-card-info text-xs" for="">{{additionalInfo?.EntityId}}</label>
                </div>

                <div class="content-card">
                    <label class="custom-label-card-right-short">City: </label>
                    <label class="custom-label-card-info text-xs" for="">{{additionalInfo?.EntityCity}}</label>
                </div>

                <div class="content-card">
                    <label class="custom-label-card-right-short">State:</label>
                    <label  class="custom-label-card-info text-xs" for="">{{additionalInfo?.StateName}}</label>
                </div>

                <div class="content-card">
                    <label class="custom-label-card-right-short">Postal code: </label>
                    <label class="custom-label-card-info text-xs" for="">{{additionalInfo?.MailPostalCode}}</label>
                </div>
            </div>
            <!-- View Complete Contact Info button -->
            <div class="col-12 text-center">
                <button class="btn-custom ml" label="View Complete Contact Info" (click)="viewCompleteContactInfo()" [disabled]="!additionalInfo" [tabindex]="13">{{'contact.contactModal.viewComplete'|translate}}</button>
            </div>
            <!-- Modal footer -->
            <div class="col-12 flex-none md:flex lg:flex xl:flex justify-content-end" *ngIf="allowNewContacts">
                <div class="mt-1 mb-1 flex justify-content-end flex-wrap">
                    <label class="custom-label">{{'contact.contactModal.infoCreate'| translate}}
                        <strong>{{'contact.contactModal.infoDuplicate'| translate}}</strong>
                    </label>
                </div>
                <div class="flex justify-content-end flex-wrap ml-2">
                <button class="btn-custom " [disabled]="disableBtnNewContact" type="button" (click)="openNewContactModal()" [tabindex]="14">
                    {{'contact.contactModal.newContact'|translate}}
                </button>
            </div>
            </div>            
        </div>
    </div>
</div>
