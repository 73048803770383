import { Injectable } from '@angular/core';
import { HttpClient, HttpParams} from '@angular/common/http';
// Rxjs
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// Environment
import { environment } from 'src/environments/environment';
// Model
import {Country, State } from '../../core/models/index';
import { CitiesWithMessage, City, County, PecZone} from 'src/app/core/models/common';
// Component
import { Cacheable, LocalStorageStrategy } from 'ts-cacheable';

@Injectable({
    providedIn: 'root'
})
export class AddressService {
 
    constructor(
        private http: HttpClient
    ) { }

    @Cacheable({
      storageStrategy: LocalStorageStrategy
    })
    getCountries(applyCoverage: boolean = true): Observable<Country[]> {
        return this.http.get<Country>(`${ environment.apiUrl }shared/countries?applyCoverage=${applyCoverage}`)
                        .pipe(
                            map((data: any) => Country.BuildCountry(data.response || []))
                        );
    }

    @Cacheable({
      storageStrategy: LocalStorageStrategy
    })
    getAllCountries(): Observable<Country[]> {
        return this.http.get<Country>(`${ environment.apiUrl }shared/allCountries`)
                        .pipe(
                            map((data: any) => Country.BuildCountry(data.response || []))
                        );
    }

    searchCountries(countryId:string, countryName:string, applyCoverage: boolean = true): Observable<Country[]> {
        const params = new HttpParams()
                    .append('countryId', countryId)
                    .append('countryName', countryName)
                    .append('applyCoverage', applyCoverage);
        return this.http.get<Country>(`${ environment.apiUrl }shared/country/search`, { params })
                        .pipe(
                            map((data: any) => Country.BuildCountry(data.response || []))
                        );
    }

    getStateByCountry(countryId: string, stateName:string, applyCoverage:boolean): Observable<State[]> {
        const params = new HttpParams()
                    .append('countryId', countryId)
                    .append('stateName', stateName)
                    .append('applyCoverage', applyCoverage);
                    
        return this.http.get<State>(`${ environment.apiUrl }shared/state/search`, { params } )
                        .pipe(
                            map((data: any) => {
                                let sites:State[] = [];
                                if(data.response !== null){
                                    sites = State.BuildState(data.response || []);
                                }
                                return sites;
                            })
                        );
    }

    getCityByCountryState(countryId: string, stateId:string, cityName:string, applyCoverage:boolean): Observable<CitiesWithMessage> {
        const params = new HttpParams()
                    .append('countryId', countryId)
                    .append('stateId', stateId)
                    .append('cityName', cityName)
                    .append('applyCoverage', applyCoverage);
        return this.http.get<CitiesWithMessage>(`${ environment.apiUrl }shared/city/search`, { params } )
                        .pipe(
                            map((data: any) => {
                                let cities:City[] = [];
                                let message:string = null;
                                let exception: string = null;
                                if(data.response != null) {
                                    cities = City.BuildCity(data.response);
                                } else if(data.message != null) {
                                    message = data.message;
                                    exception = data.exception;
                                }
                                return new CitiesWithMessage(cities, message, exception);
                            })
                        );
    }

    countyByCityId(ciId: string): Observable<County[]> {
        return this.http.get<County>(`${ environment.apiUrl }shared/county/${ciId}`)
                        .pipe(
                            map((data: any) => County.BuildCounty(data.response || []))
                        );
    }

    pecZoneByCountyId(coId: string): Observable<PecZone[]> {
        return this.http.get<PecZone>(`${ environment.apiUrl }shared/peczone/${coId}`)
                        .pipe(
                            map((data: any) => PecZone.BuildPecZone(data.response || []))
                        );
    }

}
