import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { SharedService } from 'src/app/core/services/shared.service';
import { RelatedInfoBase } from '../related-info.base';
import { ColumnEntityInfo, ColumnEntityInfoEditableType } from 'src/app/shared/components/base/mvt-entity-associator/mvt-entity-associator.component';
import { OfflineEventsDisplay } from 'src/app/core/models/offline-event/display/offline-event-display';
import { EntityName } from 'src/app/core/models/enumerations/entity-name';

@Component({
    selector: 'app-offline-event',
    templateUrl: './offline-event.component.html',
    styleUrls: ['./offline-event.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class RelatedInfoOfflineEventComponent extends RelatedInfoBase implements OnInit {
    entityTableKey =EntityName.OFFLINE_EVENT;
    @Input() showAddButton: boolean = false;

    constructor(

        sharedService: SharedService
    ) { super(sharedService); }


    ngOnInit(): void {
        this.initBase();
    }

    getColumnsInfo(): ColumnEntityInfo[] {
        let columnsInfo: ColumnEntityInfo[] = [];
        if(this.callerIsUnit || this.callerIsPipeline || this.callerIsTransmissionLine) {
            columnsInfo.push({ entityPropName: 'eventID', columnHeader: 'relatedInfo.offline.eventID', routerEntityName: EntityName.OFFLINE_EVENT, widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'eventTypeDesc', columnHeader: 'relatedInfo.offline.eventType', widthPercentage: 13});
            columnsInfo.push({ entityPropName: 'eventStatusDesc', columnHeader: 'relatedInfo.offline.eventStatus', widthPercentage: 13});
            columnsInfo.push({ entityPropName: 'eventStartDate', columnHeader: 'relatedInfo.offline.eventStartDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 13});
            columnsInfo.push({ entityPropName: 'eventEndDate', columnHeader: 'relatedInfo.offline.eventEndDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 13});
            columnsInfo.push({ entityPropName: 'eventDuration', columnHeader: 'relatedInfo.offline.duration', editableType: ColumnEntityInfoEditableType.nonEditableField, headerAlign:'right', bodyAlign:'right', widthPercentage: 12});
            columnsInfo.push({ entityPropName: 'eventQCDate', columnHeader: 'relatedInfo.common.qcDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 13});
            columnsInfo.push({ entityPropName: 'eventReleaseDate', columnHeader: 'relatedInfo.common.releaseDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 13});

        } else {
            columnsInfo.push({ entityPropName: 'eventID', columnHeader: 'relatedInfo.offline.eventID', routerEntityName: EntityName.OFFLINE_EVENT, widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'eventTypeDesc', columnHeader: 'relatedInfo.offline.eventType', widthPercentage: 13});
            columnsInfo.push({ entityPropName: 'eventStatusDesc', columnHeader: 'relatedInfo.offline.eventStatus', widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'eventStartDate', columnHeader: 'relatedInfo.offline.eventStartDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 12});
            columnsInfo.push({ entityPropName: 'eventEndDate', columnHeader: 'relatedInfo.offline.eventEndDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 12});
            columnsInfo.push({ entityPropName: 'eventDuration', columnHeader: 'relatedInfo.offline.duration', widthPercentage: 9, headerAlign: 'right', bodyAlign: 'right'});
            columnsInfo.push({ displayValueFunc: this.displayEntityUnitName, columnHeader: 'relatedInfo.offline.unitName', widthPercentage: 10, getColumnTitle: this.getUnitColumnTitle});
            columnsInfo.push({ entityPropName: 'eventQCDate', columnHeader: 'relatedInfo.common.qcDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 12});
            columnsInfo.push({ entityPropName: 'eventReleaseDate', columnHeader: 'relatedInfo.common.releaseDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 12});
        }

        return columnsInfo;
    }

    displayEntityUnitName(implementedComponent: RelatedInfoOfflineEventComponent, rowData: any): string {
        let unitName:string = '';
        if (rowData.unitName) {
            return OfflineEventsDisplay.getUnitName(rowData.unitName, OfflineEventsDisplay.countOfflineEventsForItem(rowData.unitExtraNames));
        } 
        return unitName;
    }

    getUnitColumnTitle(implementedComponent: RelatedInfoOfflineEventComponent, rowData: any, rowIndex: number): string {
        let tooltip = '';
        if(rowData.unitExtraNames) {
            rowData.unitExtraNames.split('\n').every((unitName, index, names)=> {
                if(index < 10) {
                    tooltip += unitName + '\n';
                    return true;
                } else {
                    tooltip += OfflineEventsDisplay.getUnitCount((names.length-index)+1) + '\n';
                    return false;
                }
            });
        }
        return  tooltip;
    }

}
