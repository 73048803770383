import { Bean } from "../../bean";
import { Contacts } from "../../contact/save/contacts";
import { PlantsActivityInfo } from "./plants-activity-info";
import { PlantsBasins } from "./plants-basins";
import { PlantsCompanyInfo } from "./plants-company-info";
import { PlantsConnections } from "./plants-connections";
import { PlantsContactInfo } from "./plants-contact-info";
import { PlantsElectricalBusRating } from "./plants-electrical-busrating";
import { PlantsFaxInfo } from "./plants-fax-info";
import { PlantsFeedInfo } from "./plants-feed-info";
import { PlantsFuelTypes } from "./plants-fuel-types";
import { PlantsGreenhouseGasEmissions } from "./plants-greenhouse-gas-emissions";
import { PlantsGreenhouseGasFacilities } from "./plants-greenhouse-gas-facilities";
import { PlantsInternalAttachments } from "./plants-internal-attachments";
import { PlantsNotes } from "./plants-notes";
import { PlantsOutputInfo } from "./plants-output-info";
import { PlantsPhoneInfo } from "./plants-phone-info";
import { PlantsPipelineMeters } from "./plants-pipeline-meters";
import { PlantsProductInfo } from "./plants-product-info";
import { PlantsShalePlays } from "./plants-shale-plays";
import { PlantsSicCodes } from "./plants-sic-codes";
import { PlantsSicProduct } from "./plants-sic-product";
import { PlantsSic7Codes } from "./plants-sic7-codes";

export class Plants extends Bean {
    plantId: number;
    plantName: string;
    siteId: number;
    industryCode: string;
    dunsNumber: string;
    ultimateParentId: number;
    operatorId: number;
    faxCc: string;
    faxNo: string;
    faxExt: string;
    mailAddressV1: string;
    mailAddressV2: string;
    mailPostalCode: string;
    physAddressV1: string;
    physAddressV2: string;
    physPostalCode: string;
    latitude: number;
    longitude: number;
    latLonVerified: string;
    nercRegion: string;
    nercSubregion: string;
    controlAreaId: string;
    pecZoneSimplex: string;
    plantSource: string;
    plantStatus: string;
    recordStatus: string;
    plantProbability: string;
    plantType: string;
    noEmployees: string;
    peakEmployees: string;
    unionFacility: string;
    wastewater: string;
    trHwy: number;
    trRail: number;
    trDock: number;
    trPipe: number;
    trAir: number;

    maintStaff: string;
    maintContract: number;
    shiftsDay: number;
    shiftLength: number;
    automotive: string;
    koDate: string;
    startupDate: string;
    shutdownDate: string;
    entryUser: string;
    entryCompany: string;
    entryDate: string;
    lastUser: string;
    lastCompany: string;
    lastDate: string;
    prevUser: string;
    prevCompany: string;
    prevDate: string;
    qcUser: string;
    qcDate: string;
    triId: string;
    epaId: string;
    millId: string;
    heatId: string;
    electConn: string;
    electConsumption: string;
    energyProducerType: string;
    steamProd: string;
    ngConn: string;
    ngConsumption: string;
    ngConsumptionActual: string;
    ngPipeline: string;
    majorUpdate: string;
    liveDate: string;
    releaseDate: string;
    releaseUser: string;
    intdatadepamendstate: string;
    amendmentDate: string;
    amendmentUser: string;
    koResolution: string;
    startupDateResolution: string;
    shutdownResolution: string;
    eiaId: string;
    intdatadepmigerror: string;
    isInsert: boolean;

    miningAboveGround: number;
    miningPlacer: number;
    miningOpenPit: number;
    miningQuarry: number;
    miningStrip: number;
    miningAuger: number;
    miningHydraulic: number;
    miningDredging: number;
    miningBorehole: number;
    miningUnderground: number;
    miningDrift: number;
    miningShaft: number;
    miningSlope: number;
    miningLongwall: number;
    miningRoomPillar: number;
    miningCutFill: number;
    miningCaving: number;
    miningStoping: number;
    miningInSituSolution: number;
    miningCoalUseMetallurgical: number;
    miningCoalUseThermal: number;

    offshoreBlockV1: string;
    offshoreBlockV2: string;
    offshoreWaterbodyId: string;
    offshoreFieldId: string;
    offshoreAreaId: string;
    offshoreStateDesignation: string;
    offshorePlatformType: number;
    waterDepth: string;
    waterDepthUom: string;
    distanceFromShore: string;
    distanceFromShoreUom: string;
    railCompanyId: string;

    noProjActivityDate: string;
    noProjActivityUser: string;
    noEnergyProducerDate: string;
    noEnergyProducerUser: string;
    noLtsaActivityDate: string;
    noLtsaActivityUser: string;
    existingSqFtDate: string;
    existingSqFtUser: string;
    existingSqFt: string;

    mailCountryId: number;
    mailStateId: number;
    mailCityId: number;
    physCountryId: number;
    physStateId: number;
    physCityId: number;
    physCountyId: number;
    noFaxVerifiedDate: string;
    noFaxVerifiedUser: string;

    // lists of beans - MVTs
    plantsCompanyInfo: Array<PlantsCompanyInfo>;
    plantsContactInfo: Array<PlantsContactInfo>;
    plantsElectricalBusRating: Array<PlantsElectricalBusRating>;
    plantsNotes: Array<PlantsNotes>;
    plantsFaxInfo: Array<PlantsFaxInfo>;
    plantsFeedInfo: Array<PlantsFeedInfo>;
    plantsFuelTypes: Array<PlantsFuelTypes>;
    plantsShalePlays: Array<PlantsShalePlays>;
    plantsBasins: Array<PlantsBasins>;
    plantsOutputInfo: Array<PlantsOutputInfo>;
    plantsPhoneInfo: Array<PlantsPhoneInfo>;
    plantsProductInfo: Array<PlantsProductInfo>;
    plantsSic7Codes: Array<PlantsSic7Codes>;
    plantsSicCodes: Array<PlantsSicCodes>;
    plantsSicProduct: Array<PlantsSicProduct>;
    plantsConnections: Array<PlantsConnections>;
    plantsPipelineMeters: Array<PlantsPipelineMeters>;
    plantsGreeenhouseGasEmissions: Array<PlantsGreenhouseGasEmissions>;
    plantsGreeenhouseGasFacilities: Array<PlantsGreenhouseGasFacilities>;

    contacts: Array<Contacts>;

    plantsInternalAttachments: Array<PlantsInternalAttachments>;
    plantsActivityInfo: Array<PlantsActivityInfo>;


    protected constructor(entity?: any) {
        super(entity);

        this.plantId = entity && entity.plantId || null;
        this.plantName = entity && entity.plantName || null;
        this.siteId = entity && entity.siteId || null;
        this.industryCode = entity && entity.industryCode || null;
        this.dunsNumber = entity && entity.dunsNumber || null;
        this.ultimateParentId = entity && entity.ultimateParentId || null;
        this.operatorId = entity && entity.operatorId || null;
        this.faxCc = entity && entity.faxCc || null;
        this.faxNo = entity && entity.faxNo || null;
        this.faxExt = entity && entity.faxExt || null;
        this.mailAddressV1 = entity && entity.mailAddressV1 || null;
        this.mailAddressV2 = entity && entity.mailAddressV2 || null;
        this.mailPostalCode = entity && entity.mailPostalCode || null;
        this.physAddressV1 = entity && entity.physAddressV1 || null;
        this.physAddressV2 = entity && entity.physAddressV2 || null;
        this.physPostalCode = entity && entity.physPostalCode || null;
        this.latitude = (entity && entity.latitude) ?? null;
        this.longitude = (entity && entity.longitude) ?? null;
        this.latLonVerified = entity && entity.latLonVerified || null;
        this.nercRegion = entity && entity.nercRegion || null;
        this.nercSubregion = entity && entity.nercSubregion || null;
        this.controlAreaId = entity && entity.controlAreaId || null;
        this.pecZoneSimplex = entity && entity.pecZoneSimplex || null;
        this.plantSource = entity && entity.plantSource || null;
        this.plantStatus = entity && entity.plantStatus || null;
        this.recordStatus = entity && entity.recordStatus || null;
        this.plantProbability = entity && entity.plantProbability || null;
        this.plantType = entity && entity.plantType || null;
        this.noEmployees = (entity && entity.noEmployees) ?? null;
        this.peakEmployees = (entity && entity.peakEmployees) ?? null;
        this.unionFacility = entity && entity.unionFacility  === 0 ? 0 : entity.unionFacility || null;
        this.wastewater = entity && entity.wastewater || null;
        this.trHwy = entity && entity.trHwy || null;
        this.trRail = entity && entity.trRail || null;
        this.trDock = entity && entity.trDock || null;
        this.trPipe = entity && entity.trPipe || null;
        this.trAir = entity && entity.trAir || null;

        this.maintStaff = (entity && entity.maintStaff) ?? null;
        this.maintContract = entity && entity.maintContract || null;
        this.shiftsDay = entity && entity.shiftsDay || null;
        this.shiftLength = entity && entity.shiftLength || null;
        this.automotive = entity && entity.automotive || null;
        this.koDate = entity && entity.koDate || null;
        this.startupDate = entity && entity.startupDate || null;
        this.shutdownDate = entity && entity.shutdownDate || null;
        this.entryUser = entity && entity.entryUser || null;
        this.entryCompany = entity && entity.entryCompany || null;
        this.entryDate = entity && entity.entryDate || null;
        this.lastUser = entity && entity.lastUser || null;
        this.lastCompany = entity && entity.lastCompany || null;
        this.lastDate = entity && entity.lastDate || null;
        this.prevUser = entity && entity.prevUser || null;
        this.prevCompany = entity && entity.prevCompany || null;
        this.prevDate = entity && entity.prevDate || null;
        this.qcUser = entity && entity.qcUser || null;
        this.qcDate = entity && entity.qcDate || null;
        this.triId = entity && entity.triId || null;
        this.epaId = entity && entity.epaId || null;
        this.millId = entity && entity.millId || null;
        this.heatId = entity && entity.heatId || null;
        this.electConn = entity && entity.electConn || null;
        this.electConsumption = entity && entity.electConsumption || null;
        this.energyProducerType = entity && entity.energyProducerType || null;
        this.steamProd = entity && entity.steamProd || null;
        this.ngConn = entity && entity.ngConn || null;
        this.ngConsumption = entity && entity.ngConsumption || null;
        this.ngConsumptionActual = (entity && entity.ngConsumptionActual) ?? null;
        this.ngPipeline = entity && entity.ngPipeline || null;
        this.majorUpdate = entity && entity.majorUpdate || null;
        this.liveDate = entity && entity.liveDate || null;
        this.releaseDate = entity && entity.releaseDate || null;
        this.releaseUser = entity && entity.releaseUser || null;
        this.intdatadepamendstate = entity && entity.intdatadepamendstate || null;
        this.amendmentDate = entity && entity.amendmentDate || null;
        this.amendmentUser = entity && entity.amendmentUser || null;
        this.koResolution = entity && entity.koResolution || null;
        this.startupDateResolution = entity && entity.startupDateResolution || null;
        this.shutdownResolution = entity && entity.shutdownResolution || null;
        this.eiaId = entity && entity.eiaId || null;
        this.intdatadepmigerror = entity && entity.intdatadepmigerror || null;
        this.isInsert = entity && entity.isInsert || null;

        this.miningAboveGround = entity && entity.miningAboveGround || null;
        this.miningPlacer = entity && entity.miningPlacer || null;
        this.miningOpenPit = entity && entity.miningOpenPit || null;
        this.miningQuarry = entity && entity.miningQuarry || null;
        this.miningStrip = entity && entity.miningStrip || null;
        this.miningAuger = entity && entity.miningAuger || null;
        this.miningHydraulic = entity && entity.miningHydraulic || null;
        this.miningDredging = entity && entity.miningDredging || null;
        this.miningBorehole = entity && entity.miningBorehole || null;
        this.miningUnderground = entity && entity.miningUnderground || null;
        this.miningDrift = entity && entity.miningDrift || null;
        this.miningShaft = entity && entity.miningShaft || null;
        this.miningSlope = entity && entity.miningSlope || null;
        this.miningLongwall = entity && entity.miningLongwall || null;
        this.miningRoomPillar = entity && entity.miningRoomPillar || null;
        this.miningCutFill = entity && entity.miningCutFill || null;
        this.miningCaving = entity && entity.miningCaving || null;
        this.miningStoping = entity && entity.miningStoping || null;
        this.miningInSituSolution = entity && entity.miningInSituSolution || null;
        this.miningCoalUseMetallurgical = entity && entity.miningCoalUseMetallurgical || null;
        this.miningCoalUseThermal = entity && entity.miningCoalUseThermal || null;

        this.offshoreBlockV1 = entity && entity.offshoreBlockV1 || null;
        this.offshoreBlockV2 = entity && entity.offshoreBlockV2 || null;
        this.offshoreWaterbodyId = entity && entity.offshoreWaterbodyId || null;
        this.offshoreFieldId = entity && entity.offshoreFieldId || null;
        this.offshoreAreaId = entity && entity.offshoreAreaId || null;
        this.offshoreStateDesignation = entity && entity.offshoreStateDesignation || null;
        this.offshorePlatformType = entity && entity.offshorePlatformType || null;
        this.waterDepth = entity && entity.waterDepth === 0 ? 0 : entity.waterDepth || null;
        this.waterDepthUom = entity && entity.waterDepthUom || null;
        this.distanceFromShore = entity && entity.distanceFromShore === 0 ? 0 : entity.distanceFromShore || null;
        this.distanceFromShoreUom = entity && entity.distanceFromShoreUom || null;
        this.railCompanyId = entity && entity.railCompanyId || null;

        this.noProjActivityDate = entity && entity.noProjActivityDate || null;
        this.noProjActivityUser = entity && entity.noProjActivityUser || null;
        this.noEnergyProducerDate = entity && entity.noEnergyProducerDate || null;
        this.noEnergyProducerUser = entity && entity.noEnergyProducerUser || null;
        this.noLtsaActivityDate = entity && entity.noLtsaActivityDate || null;
        this.noLtsaActivityUser = entity && entity.noLtsaActivityUser || null;
        this.existingSqFtDate = entity && entity.existingSqFtDate || null;
        this.existingSqFtUser = entity && entity.existingSqFtUser || null;
        this.existingSqFt = (entity && entity.existingSqFt) ?? null;

        this.mailCountryId = entity && entity.mailCountryId || null;
        this.mailStateId = entity && entity.mailStateId || null;
        this.mailCityId = entity && entity.mailCityId || null;
        this.physCountryId = entity && entity.physCountryId || null;
        this.physStateId = entity && entity.physStateId || null;
        this.physCityId = entity && entity.physCityId || null;
        this.physCountyId = entity && entity.physCountyId || null;
        this.noFaxVerifiedDate = entity && entity.noFaxVerifiedDate || null;
        this.noFaxVerifiedUser = entity && entity.noFaxVerifiedUser || null;

        this.plantsCompanyInfo = entity && entity.plantsCompanyInfo || [];
        this.plantsContactInfo = entity && entity.plantsContactInfo || [];
        this.plantsElectricalBusRating = entity && entity.plantsElectricalBusRating || [];
        this.plantsNotes = entity && entity.plantsNotes || [];
        this.plantsFaxInfo = entity && entity.plantsFaxInfo || [];
        this.plantsFeedInfo = entity && entity.plantsFeedInfo || [];
        this.plantsFuelTypes = entity && entity.plantsFuelTypes || [];
        this.plantsShalePlays = entity && entity.plantsShalePlays || [];
        this.plantsBasins = entity && entity.plantsBasins || [];
        this.plantsOutputInfo = entity && entity.plantsOutputInfo || [];
        this.plantsPhoneInfo = entity && entity.plantsPhoneInfo || [];
        this.plantsProductInfo = entity && entity.plantsProductInfo || [];
        this.plantsSic7Codes = entity && entity.plantsSic7Codes || [];
        this.plantsSicCodes = entity && entity.plantsSicCodes || [];
        this.plantsSicProduct = entity && entity.plantsSicProduct || [];
        this.plantsConnections = entity && entity.plantsConnections || [];
        this.plantsPipelineMeters = entity && entity.plantsPipelineMeters || [];
        this.plantsGreeenhouseGasEmissions = entity && entity.plantsGreeenhouseGasEmissions || [];
        this.plantsGreeenhouseGasFacilities = entity && entity.plantsGreeenhouseGasFacilities || [];

        this.contacts = entity && entity.contacts || [];

        this.plantsInternalAttachments = entity && entity.plantsInternalAttachments || [];
        this.plantsActivityInfo = entity && entity.plantsActivityInfo || [];
    }

}
