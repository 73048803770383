
<div *ngIf="showSearchPanel" class="main-content">
    <div class="grid mt-3 pt-1">
        <div *ngIf="tKeyLabelEntityId && tKeyLabelEntityId !== null && tKeyLabelEntityId !== ''"
            class="col-12 flex xl:-ml-8 lg:-ml-8 md:-ml-8 sm:-ml-8 -ml-6 -mt-4">
            <label class="col-5 flex justify-content-end font-bold text-xs mt-1 -mr-2">{{this.tKeyLabelEntityId| translate}}:</label>
            <div class="col-5 flex justify-content-start">
                <input
                    *ngIf="IdFieldType.entityIdOnlyNumbers === idFieldType"
                    appBasicNumber
                    class="form__input-field-style w-full"
                    type="text"
                    name="inputEntityId"
                    #focusEntityId
                    (keyup.enter)="search()"
                    [(ngModel)]="inputEntityId"
                    pInputText
                    tabindex="1">
                <input
                    *ngIf="IdFieldType.entityCompositeId ===  idFieldType"
                    compositeId
                    class="form__input-field-style w-full"
                    type="text"
                    name="inputEntityId"
                    #focusEntityId
                    (keyup.enter)="search()"
                    [(ngModel)]="inputEntityId"
                    pInputText
                    tabindex="2">
                <input
                    *ngIf="IdFieldType.entityTextId ===  idFieldType"
                    class="form__input-field-style w-full"
                    type="text"
                    name="inputEntityId"
                    #focusEntityId
                    (keyup.enter)="search()"
                    [(ngModel)]="inputEntityId"
                    pInputText
                    tabindex="3">
            </div>
            <div class="col-2"></div>
        </div>

        <div class="col-12 flex xl:-ml-8 lg:-ml-8 md:-ml-8 sm:-ml-8 -ml-6 -mt-4" *ngIf="tKeyLabelEntityName && tKeyLabelEntityName !== null && tKeyLabelEntityName !== ''">
            <label class="col-5 flex justify-content-end font-bold text-xs mt-1 -mr-2">{{this.tKeyLabelEntityName| translate}}:</label>
            <div class="col-5 flex justify-content-start">
                <input
                    class="form__input-field-style w-full"
                    type="text"
                    name="inputEntityName"
                    (keyup.enter)="search()"
                    [(ngModel)]="inputEntityName"
                    pInputText
                    tabindex="4"
                />
            </div>
            <div class="col-2 -ml-0 px-0">
                <button (click)="search()" class="btn-row" [tabindex]="5">{{'search'|translate}}</button>
            </div>
        </div>

        <div class="col-12 flex xl:-ml-8 lg:-ml-8 md:-ml-8 sm:-ml-8 -ml-6 -mt-4" *ngIf="tKeyLabelDropdown && tKeyLabelDropdown !== null && tKeyLabelDropdown !== ''">
            <label class="col-5 flex justify-content-end font-bold text-xs mt-1 -mr-2">{{this.tKeyLabelDropdown| translate}}:</label>
            <div class="col-5 flex justify-content-start">
                <p-dropdown
                    [options]="dropdownOptions"
                    [optionValue]="dropdownValueId"
                    [optionLabel]="dropdownLabel"
                    [filter]="true"
                    showClear="true"
                    [placeholder]="'common.selectOne' | translate"
                    class="form__container-fields-layout--selects w-full"
                    (keyup.enter)="search()"
                    [(ngModel)]="dropdownSelectedValue"
                    [tabindex]="6">
                </p-dropdown>
            </div>
            <div class="col-2 -ml-1" *ngIf="!tKeyLabelDropdown || tKeyLabelDropdown === null || tKeyLabelDropdown === ''">
                <button (click)="search()" class="btn-row pt-1" [tabindex]="7">{{'search'|translate}}</button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!showSearchPanel" class="mt-4"></div>
<div>
    <div class="-mt-1">
        <span *ngIf="entityNamePlural && entityNamePlural !== null && entityNamePlural !== ''">{{ 'lookUpModal.tableTitle'| translate:{entityNamePlural: entityNamePlural} }}</span>
        <div class="container-table-modal custom-scrollable-table-modal">
            <p-table
                [value]="entitiesArrayFiltered()"
                styleClass="p-datatable-gridlines"
                [reorderableColumns]="false"
                [resizableColumns]="false"
                selectionMode="single"
                [(selection)]="this.selectedRowData"
                (onRowSelect)="onRowSelect($event)">

                <ng-template pTemplate="body" let-item tabindex="8">
                    <tr #tr
                        [pSelectableRow]="item"
                        (dblclick)="addRow(item)"
                        (keydown.enter)="addRow(item)"
                        (keydown)="changeSelectionOnArrowKey($event, tableProvider, false)">
                        <td>
                            {{ getCellDisplayValue(implementedComponent, item) }}
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
    <div class="text-center mt-2">
            <button class="btn-row" (click)="addRowSingleClick()" [tabindex]="9">{{'save'| translate}}</button>
            <button class="btn-row ml-2" (click)="closeModal()" [tabindex]="10">{{'cancel'| translate}}</button>
    </div>
</div>
