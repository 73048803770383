<!-- Only Confirm OK button -->
<div *ngIf="type == MessageType.ERROR ||
            type == MessageType.INFO ||
            type == MessageType.SAVE_ERROR ||
            type == MessageType.WARNING ||
            type == MessageType.MAP_IT_VALIDATION_ERROR ||
            type == MessageType.ALERT_TYPE_SYSTEM_MESSAGE" class="w-28rem border-round-md">
    <div class="handler-background font-bold text-lg text-white text-left w-full h-3rem pt-2 pl-2">
        {{ title }}
    </div>
    <div class="text-white text-left ml-4 mt-3 mb-4 mr-4">
      <p [innerHTML]="text" class="p-text" >
    </div>
    <div class="text-center pb-3">
        <button (click)="ok()" #okButton
        class="handler-button confirm-text" autofocus>OK</button>
    </div>
</div>

<!-- Only VALIDATION_ERROR button -->
<div *ngIf="type == MessageType.RELEASE_ERROR ||
            type == MessageType.VALIDATION_ERROR" class="w-28rem">
    <div class="handler-background font-bold text-lg text-white text-left w-32rem h-3rem pt-2 pl-2">
        {{ title }}
    </div>
    <div class="text-white text-left ml-4 mt-3 mb-4 mr-4" >
        <p [innerHTML]="text" >
    </div>
    <div class="text-center pb-3">
        <button (click)="ok()"
        class="handler-button confirm-text" autofocus>OK</button>
    </div>
</div>

<!-- CONFIRM/CANCEL button -->
<div *ngIf="type == MessageType.CONFIRM_CANCEL ||
            type == MessageType.WIDGET_REMOVE_CONFIRM ||
            type == MessageType.CONFIRM_VALIDATION ||
            type == MessageType.CLOSE_CONFIRMATION ||
            type == MessageType.LOGOUT_CONFIRMATION" class="w-28rem border-round-md">
    <div class="handler-background font-bold text-lg text-white text-left w-full h-3rem pt-2 pl-2">
        {{ title }}
    </div>
    <div class="text-white text-left ml-4 mt-3 mb-4 mr-4">
        <p [innerHTML]="text" class="p-text">
    </div>
    <div class="text-center pb-3">
        <button (click)="ok()" class="handler-background text-white text-md w-5rem h-2rem border-round-md" autofocus>Ok</button>
        <button (click)="cancel()" class="handler-background text-white text-md w-5rem h-2rem border-round-md ml-3">Cancel</button>
    </div>
</div>

<!-- YES/NO buttons -->
<div *ngIf="type == MessageType.CONFIRM ||
            type == MessageType.CLOSE ||
            type == MessageType.CAUTION ||
            type == MessageType.INFO_CONFIRM ||
            type == MessageType.PDF_SELECT_PREFERENCE" class="w-28rem">
    <div class="handler-background font-bold text-lg text-white text-left w-full h-3rem pt-2 pl-2">
        {{ title }}
    </div>

    <div class="text-white text-left ml-4 mt-3 mb-4 mr-4">
        <p [innerHTML]="text" class="p-text">
    </div>
    <div class="text-center pb-3">
        <button (click)="yes()" class="handler-background text-white text-md w-5rem h-2rem border-round-md" autofocus>Yes</button>
        <button (click)="no()" class="handler-background text-white text-md w-5rem h-2rem border-round-md ml-3">No</button>
    </div>
</div>

<!-- YES/NO/CANCEL buttons -->
<div *ngIf="type == MessageType.CONFIRM_OK_CANCEL" class="w-28rem border-round-md">
    <div class="handler-background font-bold text-lg text-white text-left w-28rem h-3rem pt-2 pl-2">
        {{ title }}
    </div>
    <div class="text-white text-left ml-4 mt-3 mb-4 mr-4">
      <p [innerHTML]="text" class="p-text">
    </div>
    <div class="text-center pb-3">
        <button (click)="yes()" class="handler-background text-white text-md w-5rem h-2rem border-round-md" autofocus>Yes</button>
        <button (click)="no()" class="handler-background text-white text-md w-5rem h-2rem border-round-md ml-3">No</button>
        <button (click)="cancel()" class="handler-background text-white text-md w-5rem h-2rem border-round-md ml-3">Cancel</button>
    </div>
</div>

<!-- REPORT/ADMIN -->
<div *ngIf="type == MessageType.EXCEPTION" class="w-28rem">
  <div class="handler-background font-bold text-lg text-white text-left h-3rem pt-2 pl-2">
      {{ title }}
  </div>
  <div class="text-white text-left ml-4 mt-3 mb-4 mr-4">
    <p [innerHTML]="text" class="p-text">
  </div>
  <div class="text-center pb-3">
      <button (click)="report()" class="handler-button report-text w-15rem">Report to System Administrator</button>
      <button (click)="cancel()" class="handler-background text-white text-md w-5rem h-2rem border-round-md ml-3" autofocus>Close</button>
    </div>
</div>

<!-- CUSTOM_OK_CANCEL -->
<div *ngIf="type == MessageType.CUSTOM_OK_CANCEL" class="w-28rem">
    <div class="handler-background font-bold text-lg text-white text-left w-28rem h-3rem pt-2 pl-2">
        {{ title }}
    </div>
    <div class="text-white text-left ml-4 mt-3 mb-4 mr-4">
      <p [innerHTML]="text" class="p-text">
    </div>
    <div class="text-center pb-3 large-button-cointainer">
        <button (click)="execAction(actions[0].event)" class="handler-button large-left-text" autofocus>{{ actions[0].label }}</button>
        <button (click)="execAction(actions[1].event)" class="handler-button large-right-text">{{ actions[1].label }}</button>
    </div>
</div>

<!-- MAP_IT_PREFERENCES -->
<div *ngIf="type == MessageType.MAP_IT_PREFERENCES || type == MessageType.CUSTOM_INFO" class="w-28rem">
    <div class="handler-background font-bold text-lg text-white text-left w-28rem h-3rem pt-2 pl-2">
        {{ title }}
    </div>
    <div class="text-white text-left ml-4 mt-3 mb-4 mr-4">
      <p [innerHTML]="text" class="p-text">
    </div>
    <div class="flex justify-content-center pb-3 large-button-cointainer">
        <button (click)="execAction(actions[0].event)" class="handler-button mappit-left-button mr-3 w-11rem" autofocus>{{ actions[0].label }}</button>
        <button (click)="execAction(actions[1].event)"class="handler-button mappit-right-button w-13rem">{{ actions[1].label }}</button>
    </div>
</div>

<!-- CUSTOM_MESSAGE -->
<div *ngIf="type == MessageType.CUSTOM_MESSAGE" class="w-28rem border-round-md">
    <div class="handler-background font-bold text-lg text-white text-left w-full h-3rem pt-2 pl-2">{{ title }}</div>
    <div class="text-white text-left ml-4 mt-3 mb-4 mr-4">
        <p [innerHTML]="text" class="p-text">
    </div>
    <div class="text-center pb-3 large-button-cointainer">
        <button *ngFor="let action of actions; let i=index" [ngClass]="i === 0 ? 'w-12rem' : 'w-14rem ml-2'" (click)="execAction(action.event)" class="handler-button custom-button" [autofocus]="i === 0">{{action.label}}</button>
    </div>
    <div class="text-white text-left ml-4 mb-3 mr-4">{{ footer }}</div>
</div>

<!-- INPUT -->
<div *ngIf="type == MessageType.INPUT" class="w-30rem">
    <div *ngIf="title && title !== null && title !== ''" class="handler-background font-bold text-lg text-white text-left w-30rem h-3rem pt-2 pl-2">
        {{ title }}
    </div>
    <div *ngIf="text && text !== null && text !== ''"  class="text-white text-left ml-4 mt-3 mb-4 mr-4">
        <p [innerHTML]="text" class="p-text">
    </div>

    <div class="input-content mt-4">
        <textarea 
            [rows]="5"
            class="mt-1 ml-3 w-28rem h-12rem text-xs"
            autoResize="true"
            inputTextArea  
            [disabled]="disabled"
            maxlength="2000"
            [(ngModel)]="actualInputValue"></textarea>
    </div>
    <div *ngIf="text && text !== null && text !== ''"  class="text-white text-right ml-4 mt-1 mb-4 mr-4">
        <p class="p-text">{{ 'release.actionColumns.maxCharacters' | translate }}
    </div>

    <div class="text-center pb-3 mt-1">
        <button (click)="execAction(actions[0].event)" class="handler-background text-white text-md w-5rem h-2rem border-round-md" autofocus [disabled]="disabled || isInputEmpty()">{{ actions[0].label }}</button>
        <button (click)="execAction(actions[1].event)" class="handler-background text-white text-md w-5rem h-2rem border-round-md ml-3">{{ actions[1].label }}</button>
    </div>
</div> 

