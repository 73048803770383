import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/core/services/shared.service';
import { RelatedInfoBase } from '../related-info.base';
import { ColumnEntityInfo, ColumnEntityInfoEditableType } from 'src/app/shared/components/base/mvt-entity-associator/mvt-entity-associator.component';
import { EntityName } from 'src/app/core/models/enumerations/entity-name';

@Component({
    selector: 'app-mining-equipment',
    templateUrl: './mining-equipment.component.html',
    styleUrls: ['./mining-equipment.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RelatedInfoMiningEquipmentComponent extends RelatedInfoBase implements OnInit {
    entityTableKey =EntityName.MEQUIPMENT;
    constructor(
        public sharedService: SharedService) {
        super(sharedService);
    }

    ngOnInit(): void {
        this.initBase();
    }

    getColumnsInfo(): ColumnEntityInfo[] {
        let columnsInfo: ColumnEntityInfo[] = [];
        columnsInfo.push({ entityPropName: 'MiningEquipId', columnHeader: 'relatedInfo.miningEquipment.miningEquipmentId', getColumnTitle: this.getColumnTitleEntityNotAvailable, routerEntityName: EntityName.MEQUIPMENT, widthPercentage: 10});
        columnsInfo.push({ entityPropName: 'MiningEquipName', columnHeader: 'relatedInfo.miningEquipment.miningEquipmentName', widthPercentage: 15});
        columnsInfo.push({ entityPropName: 'MiningEquipType', columnHeader: 'relatedInfo.miningEquipment.miningEquipmentType', widthPercentage: (this.callerIsUnit ? 10 : 15)});
        columnsInfo.push({ entityPropName: 'VendorName', columnHeader: 'relatedInfo.miningEquipment.vendorName', widthPercentage: 20});
        columnsInfo.push({ entityPropName: 'StartupDate', columnHeader: 'relatedInfo.common.startupDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 10});
        columnsInfo.push({ entityPropName: 'MiningEquipStatus', columnHeader: 'relatedInfo.miningEquipment.miningEquipmentStatus', widthPercentage: 10});
        columnsInfo.push({ entityPropName: 'QcDate', columnHeader: 'relatedInfo.common.qcDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 10});
        columnsInfo.push({ entityPropName: 'ReleaseDate', columnHeader: 'relatedInfo.common.releaseDate', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage: 10});
        if(this.callerIsUnit) {
            columnsInfo.push(this.createDuplicateColumn('MiningEquipId', EntityName.MEQUIPMENT, 5));
        }
        return columnsInfo;
    }

}
