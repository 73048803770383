import { Bean } from "../../../bean";

export class AssetsProjects extends Bean {
    assetId: number;
    projectId: number;
    mvId: number;
    mvOrder: number;
    lastDate: string;

    constructor(entity: any) {
        super(entity);

        this.assetId = entity && entity.assetId || null;
        this.projectId = entity && entity.projectId || null;
        this.mvId = entity && entity.mvId || null;
        this.mvOrder = entity && entity.mvOrder || null;
        this.lastDate = entity && entity.lastDate || null;
    }
}
