import { IAuditEntityData } from "src/app/core/interfaces/iaudit-entity-data";
import { ContactsDisplay } from "../../contact/display/contacts-display";
import { OperationType } from "../../enumerations/operation-type";
import { Projects } from "../save/projects";
import { ProjectsBidderDisplay } from "./projects-bidder-display";
import { ProjectsCapacityDisplay } from "./projects-capacity-display";
import { ProjectsCompanyDisplay } from "./projects-company-display";
import { ProjectsContractingNeedsDisplay } from "./projects-contracting-needs-display";
import { ProjectsDefinitiveKeyNeedsDisplay } from "./projects-definitive-key-needs-display";
import { ProjectsEngineeringNeedsDisplay } from "./projects-engineering-needs-display";
import { ProjectsFaxInfoDisplay } from "./projects-fax-info-display";
import { ProjectsIndustryFlagDisplay } from "./projects-industry-flag-display";
import { ProjectsInternalAttachmentsDisplay } from "./projects-internal-attachments-display";
import { ProjectsKeyNeedsDisplay } from "./projects-key-needs-display";
import { ProjectsMarketTrendsDisplay } from "./projects-market-trends-display";
import { ProjectsMatterStatesDisplay } from "./projects-matter-states-display";
import { ProjectsNotesDisplay } from "./projects-notes-display";
import { ProjectsOfflineEventsDisplay } from "./projects-offline-events-display";
import { ProjectsPhoneInfoDisplay } from "./projects-phone-info-display";
import { ProjectsPipesInstallationDisplay } from "./projects-pipes-installation-display";
import { ProjectsUnitIdsDisplay } from "./projects-unit-ids-display";
import { MVTOperations } from "src/app/core/mvt-operations";
import { ProjectBidderStatusValue, ProjectStatusValue } from "../../constants";
import { EntityName } from "../../enumerations/entity-name";

export class ProjectsDisplay extends Projects {

    umbrellaName: string;
    projectSicCode: string;
    activityDesc: string;
    pecTimingPa: string;
    statusDesc: string;
    physAddressV1: string;
    physAddressV2: string;
    plantOffshore: boolean;
    offshoreBlockV1: string;
    offshoreWaterbodyName: string;
    offshoreFieldName: string;
    offshoreAreaName: string;
    offshoreCountryName: string;
    plantPhysAddressV1: string;
    plantPhysAddressV2: string;
    plantPhysCity: string;
    stateName: string;
    plantPhysState: string;
    phoneNo: string;
    phoneNoV1: string;
    phoneExt: string;
    phoneExtV1: string;
    phoneMobile: string;
    phoneMobileV1: string;
    faxNo: string;
    faxNoV1: string;
    faxExt: string;
    faxExtV1: string;
    physCountry: string;
    physState: string;
    physCounty: string;
    physCity: string;

    plantPhysPostalCode: string;
    countryName: string;
    plantPhysCountry: string;
    plantPhysCounty: string;
    pecZoneSimplex: string;
    pecZoneName: string;
    companyId: number;
    ownerCompanyId: string;
    companyName: string;
    ownerCompanyName: string;
    parentOwnerCompanyId: string;
    parentOwnerCompanyName: string;
    productDesc: string;
    sicCode: string;
    phoneCc: string;
    faxCc: string;
    telephoneCc: string;
    projectProductSicCodeDesc: string;
    pecActivitiesId: string;
    pecZonesId: string;
    plantStatusId: string;
    countryId: number;
    hsProductsId: string;
    authorName: string;
    contribAuthorName: string;
    lastUpdateStatus: string;
    tivQcDateLast: string;
    tivQcUserLast: string;
    tivQcSelected: boolean = false;
    scopeQcDateLast: string;
    scopeQcUserLast: string;
    scopeQcSelected: boolean = false;
    scheduleQcDateLast: string;
    scheduleQcUserLast: string;

    scheduleQcSelected: boolean = false;
    tivQcDatePrev: string;
    tivQcUserPrev: string;
    scopeQcDatePrev: string;
    scopeQcUserPrev: string;
    scheduleQcDatePrev: string;
    scheduleQcUserPrev: string;
    projectFuelTypeDesc: string;
    defCountryCode: string;
    plantName: string;
    pecTiming: string;
    lastUserEmailAddress: string;
    entryUserEmailAddress: string;
    previousUserEmailAddress: string;
    qcUserEmailAddress: string;
    releaseUserEmailAddress: string;
    amendmentUserEmailAddress: string;
    buildingExistingSqft: number;
    counterCurrency: string;
    tivPrevQcSelection: boolean = false;
    scopePrevQcSelection: boolean = false;
    schedulePrevQcSelection: boolean = false;
    originalProjectProbabilityId: string;

    projectsNotesDisplay: Array<ProjectsNotesDisplay>
    projectsKeyNeedsDisplay: Array<ProjectsKeyNeedsDisplay>;
    projectsPhoneInfoDisplay: Array<ProjectsPhoneInfoDisplay>;
    projectsCompanyDisplay: Array<ProjectsCompanyDisplay>;
    projectsContractingNeedsDisplay: Array<ProjectsContractingNeedsDisplay>;
    projectsDefinitiveKeyNeedsDisplay: Array<ProjectsDefinitiveKeyNeedsDisplay>;
    projectsEngineeringNeedsDisplay: Array<ProjectsEngineeringNeedsDisplay>;
    projectsFaxInfoDisplay: Array<ProjectsFaxInfoDisplay>;
    projectsIndustryFlagDisplay: Array<ProjectsIndustryFlagDisplay>;
    projectsMatterStatesDisplay: Array<ProjectsMatterStatesDisplay>;
    projectsUnitIdsDisplay: Array<ProjectsUnitIdsDisplay>;
    projectsInternalAttachmentsDisplay: Array<ProjectsInternalAttachmentsDisplay>;
    projectsOfflineEventsDisplay: Array<ProjectsOfflineEventsDisplay>;
    projectsBidderDisplay: Array<ProjectsBidderDisplay>;
    projectsCapacityDisplay: Array<ProjectsCapacityDisplay>;
    projectsPipesInstallationDisplay: Array<ProjectsPipesInstallationDisplay>;
    projectsMarketTrendsDisplay: Array<ProjectsMarketTrendsDisplay>;

    contactsDisplays: Array<ContactsDisplay>;

    protected constructor(entity: any) {
        super(entity);

        this.umbrellaName = entity && entity.umbrellaName || null;
        this.projectSicCode = entity && entity.projectSicCode || null;
        this.activityDesc = entity && entity.activityDesc || null;
        this.pecTimingPa = entity && entity.pecTimingPa || null;
        this.statusDesc = entity && entity.statusDesc || null;
        this.physAddressV1 = entity && entity.physAddressV1 || null;
        this.physAddressV2 = entity && entity.physAddressV2 || null;
        this.plantOffshore = entity && entity.plantOffshore || null;
        this.offshoreBlockV1 = entity && entity.offshoreBlockV1 || null;
        this.offshoreWaterbodyName = entity && entity.offshoreWaterbodyName || null;
        this.offshoreFieldName = entity && entity.offshoreFieldName || null;
        this.offshoreAreaName = entity && entity.offshoreAreaName || null;
        this.offshoreCountryName = entity && entity.offshoreCountryName || null;
        this.plantPhysAddressV1 = entity && entity.plantPhysAddressV1 || null;
        this.plantPhysAddressV2 = entity && entity.plantPhysAddressV2 || null;
        this.plantPhysCity = entity && entity.plantPhysCity || null;
        this.stateName = entity && entity.stateName || null;
        this.plantPhysState = entity && entity.plantPhysState || null;
        this.phoneNo = entity && entity.phoneNo || null;
        this.phoneNoV1 = entity && entity.phoneNoV1 || null;
        this.phoneExt = entity && entity.phoneExt || null;
        this.phoneExtV1 = entity && entity.phoneExtV1 || null;
        this.phoneMobile = entity && entity.phoneMobile || null;
        this.phoneMobileV1 = entity && entity.phoneMobileV1 || null;
        this.faxNo = entity && entity.faxNo || null;
        this.faxNoV1 = entity && entity.faxNoV1 || null;
        this.faxExt = entity && entity.faxExt || null;
        this.faxExtV1 = entity && entity.faxExtV1 || null;
        this.physCountry = entity && entity.physCountry || null;
        this.physState = entity && entity.physState || null;
        this.physCounty = entity && entity.physCounty || null;
        this.physCity = entity && entity.physCity || null;

        this.plantPhysPostalCode = entity && entity.plantPhysPostalCode || null;
        this.countryName = entity && entity.countryName || null;
        this.plantPhysCountry = entity && entity.plantPhysCountry || null;
        this.plantPhysCounty = entity && entity.plantPhysCounty || null;
        this.pecZoneSimplex = entity && entity.pecZoneSimplex || null;
        this.pecZoneName = entity && entity.pecZoneName || null;
        this.companyId = entity && entity.companyId || null;
        this.ownerCompanyId = entity && entity.ownerCompanyId || null;
        this.companyName = entity && entity.companyName || null;
        this.ownerCompanyName = entity && entity.ownerCompanyName || null;
        this.parentOwnerCompanyId = entity && entity.parentOwnerCompanyId || null;
        this.parentOwnerCompanyName = entity && entity.parentOwnerCompanyName || null;
        this.productDesc = entity && entity.productDesc || null;
        this.sicCode = entity && entity.sicCode || null;
        this.phoneCc = entity && entity.phoneCc || null;
        this.faxCc = entity && entity.faxCc || null;
        this.telephoneCc = entity && entity.telephoneCc || null;
        this.projectProductSicCodeDesc = entity && entity.projectProductSicCodeDesc || null;
        this.pecActivitiesId = entity && entity.pecActivitiesId || null;
        this.pecZonesId = entity && entity.pecZonesId || null;
        this.plantStatusId = entity && entity.plantStatusId || null;
        this.countryId = entity && entity.countryId || null;
        this.hsProductsId = entity && entity.hsProductsId || null;
        this.authorName = entity && entity.authorName || null;
        this.contribAuthorName = entity && entity.contribAuthorName || null;
        this.lastUpdateStatus = entity && entity.lastUpdateStatus || '';
        this.tivQcDateLast = entity && entity.tivQcDateLast || null;
        this.tivQcUserLast = entity && entity.tivQcUserLast || null;
        this.tivQcSelected = entity && entity.tivQcSelected || false;
        this.scopeQcDateLast = entity && entity.scopeQcDateLast || null;
        this.scopeQcUserLast = entity && entity.scopeQcUserLast || null;
        this.scopeQcSelected = entity && entity.scopeQcSelected || false;
        this.scheduleQcDateLast = entity && entity.scheduleQcDateLast || null;
        this.scheduleQcUserLast = entity && entity.scheduleQcUserLast || null;
        this.scheduleQcSelected = entity && entity.scheduleQcSelected || false;
        this.tivQcDatePrev = entity && entity.tivQcDatePrev || null;
        this.tivQcUserPrev = entity && entity.tivQcUserPrev || null;
        this.scopeQcDatePrev = entity && entity.scopeQcDatePrev || null;
        this.scopeQcUserPrev = entity && entity.scopeQcUserPrev || null;
        this.scheduleQcDatePrev = entity && entity.scheduleQcDatePrev || null;
        this.scheduleQcUserPrev = entity && entity.scheduleQcUserPrev || null;
        this.projectFuelTypeDesc = entity && entity.projectFuelTypeDesc || null;
        this.defCountryCode = entity && entity.defCountryCode || null;
        this.plantName = entity && entity.plantName || null;
        this.pecTiming = entity && entity.pecTiming || null;
        this.lastUserEmailAddress = entity && entity.lastUserEmailAddress || null;
        this.entryUserEmailAddress = entity && entity.entryUserEmailAddress || null;
        this.previousUserEmailAddress = entity && entity.previousUserEmailAddress || null;
        this.qcUserEmailAddress = entity && entity.qcUserEmailAddress || null;
        this.releaseUserEmailAddress = entity && entity.releaseUserEmailAddress || null;
        this.amendmentUserEmailAddress = entity && entity.amendmentUserEmailAddress || null;
        this.buildingExistingSqft = entity && entity.buildingExistingSqft || null;
        this.counterCurrency = entity && entity.counterCurrency || null;
        this.tivPrevQcSelection = entity && entity.tivPrevQcSelection || false;
        this.scopePrevQcSelection = entity && entity.scopePrevQcSelection || false;
        this.schedulePrevQcSelection = entity && entity.schedulePrevQcSelection || false;
        this.originalProjectProbabilityId = entity && entity.projectProbabilityId || null;

        this.projectsNotesDisplay = entity && entity.projectsNotesDisplay || [];
        this.projectsKeyNeedsDisplay = entity && entity.projectsKeyNeedsDisplay || [];
        this.projectsPhoneInfoDisplay = entity && entity.projectsPhoneInfoDisplay || [];
        this.projectsCompanyDisplay = entity && entity.projectsCompanyDisplay || [];
        this.projectsContractingNeedsDisplay = entity && entity.projectsContractingNeedsDisplay || [];
        this.projectsDefinitiveKeyNeedsDisplay = entity && entity.projectsDefinitiveKeyNeedsDisplay || [];
        this.projectsEngineeringNeedsDisplay = entity && entity.projectsEngineeringNeedsDisplay || [];
        this.projectsFaxInfoDisplay = entity && entity.projectsFaxInfoDisplay || [];
        this.projectsIndustryFlagDisplay = entity && entity.projectsIndustryFlagDisplay || [];
        this.projectsMatterStatesDisplay = entity && entity.projectsMatterStatesDisplay || [];
        this.projectsUnitIdsDisplay = entity && entity.projectsUnitIdsDisplay || [];
        this.projectsInternalAttachmentsDisplay = entity && entity.projectsInternalAttachmentsDisplay || [];
        this.projectsOfflineEventsDisplay = entity && entity.projectsOfflineEventsDisplay || [];
        this.projectsBidderDisplay = entity && entity.projectsBidderDisplay || [];
        this.projectsCapacityDisplay = entity && entity.projectsCapacityDisplay || [];
        this.projectsPipesInstallationDisplay = entity && entity.projectsPipesInstallationDisplay || [];
        this.projectsMarketTrendsDisplay = entity && entity.projectsMarketTrendsDisplay || [];

        this.contactsDisplays = entity && entity.contactsDisplays || [];
    }

    static BuildProjectDisplay(entity: any): ProjectsDisplay {
        return new ProjectsDisplay(entity);
    }

    static BuildNewProject(response: any): ProjectsDisplay {
        return new ProjectsDisplay({
            projectId: response[0].PROJECT_ID,
            operation: OperationType.INSERT,
            intDataDepValState: OperationType.INSERT
        });
    }

    prepareSaveCollection() {
        this.projectsNotes = this.projectsNotesDisplay;
        this.projectsKeyNeeds = this.projectsKeyNeedsDisplay;
        this.projectsPhone = this.projectsPhoneInfoDisplay;
        this.projectsCompany = this.projectsCompanyDisplay;
        this.projectsContractingNeeds = this.projectsContractingNeedsDisplay;
        this.projectsDefinitiveKeyNeeds = this.projectsDefinitiveKeyNeedsDisplay;
        this.projectsEngineeringNeeds = this.projectsEngineeringNeedsDisplay;
        this.projectsFax = this.projectsFaxInfoDisplay;
        this.projectsIndustryFlag = this.projectsIndustryFlagDisplay;
        this.projectsMatterStates = this.projectsMatterStatesDisplay;
        this.projectsUnitIds = this.projectsUnitIdsDisplay;
        this.projectsInternalAttachments = this.projectsInternalAttachmentsDisplay;
        this.projectsOfflineEvents = this.projectsOfflineEventsDisplay;
        this.projectsBidder = this.projectsBidderDisplay;
        this.projectsCapacity = this.projectsCapacityDisplay;
        this.projectsPipesInstallation = this.projectsPipesInstallationDisplay;
        this.projectsMarketTrends = this.projectsMarketTrendsDisplay;

        this.contacts = this.contactsDisplays;
    }

    get auditEntityBean(): IAuditEntityData {
        return {
            lastUser: this.lastUser,
            entryUser: this.entryUser,
            prevUser: this.prevUser,
            qcUser: this.qcUser,
            lastDate: this.lastDate,
            entryDate: this.entryDate,
            prevDate: this.prevDate,
            qcDate: this.qcDate,
            releaseUser: this.releaseUser,
            releaseDate: this.releaseDate,
            amendmentUser: this.amendmentUser,
            amendmentDate: this.amendmentDate,
            lastUserEmailAddress: this.lastUserEmailAddress,
            entryUserEmailAddress: this.entryUserEmailAddress,
            previousUserEmailAddress: this.previousUserEmailAddress,
            qcUserEmailAddress: this.qcUserEmailAddress,
            releaseUserEmailAddress: this.releaseUserEmailAddress,
            amendmentUserEmailAddress: this.amendmentUserEmailAddress,
            lastUpdateStatus: this.lastUpdateStatus,
            entityName: this.entityType(this.status),
            entityId: this.projectId
        };
    }

    private entityType(status: string): string{
        return status === ProjectStatusValue.Unconfirmed ||
        status === ProjectStatusValue.UnableToConfirm ? 
        EntityName.PROJECT_UNCONFIRMED : EntityName.PROJECT_CONFIRMED
    }

    hasProjectsUnitIdsDisplay():boolean {
        return MVTOperations.filterByDeleted(this.projectsUnitIdsDisplay).length > 0;
    }

    public sortBidderData(): void {
        this.setStatusOrderCollection();
      
        const fieldsToOrder: any[] = [
          { name: "statusOrder", numeric: true },
          { name: "companyName" },
          { name: "companyId", numeric: true }
        ];
      
        this.projectsBidderDisplay = MVTOperations.sortArrayByFields(this.projectsBidderDisplay, 'asc', fieldsToOrder);
      }
      
      private setStatusOrderCollection(): void {
        const statusMapping = {
          [ProjectBidderStatusValue.Future]: 0,
          [ProjectBidderStatusValue.Awarded]: 1,
          [ProjectBidderStatusValue.Shortlisted]: 2,
          [ProjectBidderStatusValue.BidInProgress]: 3,
          [ProjectBidderStatusValue.Withdrawn]: 4,
          [ProjectBidderStatusValue.RevokedReplaced]: 5,
          [ProjectBidderStatusValue.NotAwarded]: 6
        };
      
        for (const projectBidderDisplay of this.projectsBidderDisplay) {
            projectBidderDisplay.statusOrder = statusMapping[projectBidderDisplay.status.toString()];
        }
      }
      
}
