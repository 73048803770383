import { Bean } from "../../../bean";
import { Assets } from "../../base/save/assets";
import { AssetsPipesConnections } from "./assets-pipes-connections";
import { AssetsPipesLaterals } from "./assets-pipes-laterals";

export class AssetsPipes extends Bean {
    assetPipesId: number;
    assetId: number;
    pipelineSystemId: number;
    parentId: number;
    diameterIn: number;
    diameterOut: number;
    diameterUom: string;
    destinationPhysAddressV1: string;
    destinationPhysAddressV2: string;
    destinationPhysCity: number;
    destinationPhysState: number;
    destinationPhysPostalCode: string;
    destinationPhysCounty: number;
    destinationPhysCountry: number;
    destinationOffshore: boolean;
    destOffshoreStateDesignation: number;
    destinationLatitude: number;
    destinationLongitude: number;
    destinationAssetId: number;
    destinationAssetTypeId: number;
    destinationOffshoreBlockV1: string;
    destinationOffshoreBlockV2: string;
    destinationOffshoreWaterbody: number;
    destinationOffshoreFieldId: number;
    destinationOffshoreAreaId: number;
    aboveGround: string;
    belowGround: string;
    subsea: string;
    pipelineType: string;
    pipelineLength: number;
    pipelineLengthUom: string;
    interIntraState: string;
    pipelineStatus: string;
    materialUsed: string;
    designPresurePsi: number;
    insulated: string;

    asset: Assets;
    assetsPipesConnections: Array<AssetsPipesConnections>;
    assetsPipesLaterals: Array<AssetsPipesLaterals>;

    constructor(entity: any) {
        super(entity);

        this.assetPipesId = entity && entity.assetPipesId || null;
        this.assetId = entity && entity.assetId || null;
        this.pipelineSystemId = entity && entity.pipelineSystemId || null;
        this.parentId = entity && entity.parentId || null;
        this.diameterIn = (entity && entity.diameterIn) ?? null;
        this.diameterOut = (entity && entity.diameterOut) ?? null;
        this.diameterUom = entity && entity.diameterUom || null;
        this.destinationPhysAddressV1 = entity && entity.destinationPhysAddressV1 || null;
        this.destinationPhysAddressV2 = entity && entity.destinationPhysAddressV2 || null;
        this.destinationPhysCity = entity && entity.destinationPhysCity || null;
        this.destinationPhysState = entity && entity.destinationPhysState || null;
        this.destinationPhysPostalCode = entity && entity.destinationPhysPostalCode || null;
        this.destinationPhysCounty = entity && entity.destinationPhysCounty || null;
        this.destinationPhysCountry = entity && entity.destinationPhysCountry || null;
        this.destinationOffshore = entity && entity.destinationOffshore || false;
        this.destOffshoreStateDesignation = entity && entity.destOffshoreStateDesignation || null;
        this.destinationLatitude = entity && entity.destinationLatitude || null;
        this.destinationLongitude = entity && entity.destinationLongitude || null;
        this.destinationAssetId = entity && entity.destinationAssetId || null;
        this.destinationAssetTypeId = entity && entity.destinationAssetTypeId || null;
        this.destinationOffshoreBlockV1 = entity && entity.destinationOffshoreBlockV1 || null;
        this.destinationOffshoreBlockV2 = entity && entity.destinationOffshoreBlockV2 || null;
        this.destinationOffshoreWaterbody = entity && entity.destinationOffshoreWaterbody || null;
        this.destinationOffshoreFieldId = entity && entity.destinationOffshoreFieldId || null;
        this.destinationOffshoreAreaId = entity && entity.destinationOffshoreAreaId || null;
        this.aboveGround = entity && entity.aboveGround || null;
        this.belowGround = entity && entity.belowGround || null;
        this.subsea = entity && entity.subsea || null;
        this.pipelineType = entity && entity.pipelineType || null;
        this.pipelineLength = (entity && entity.pipelineLength) ?? null;
        this.pipelineLengthUom = entity && entity.pipelineLengthUom || null;
        this.interIntraState = entity && entity.interIntraState || null;
        this.pipelineStatus = entity && entity.pipelineStatus || null;
        this.materialUsed = entity && entity.materialUsed || null;
        this.designPresurePsi = entity && entity.designPresurePsi || null;
        this.insulated = entity && entity.insulated || null;

        this.asset = entity && entity.asset || null;
        this.assetsPipesConnections = entity && entity.assetsPipesConnections || [];
        this.assetsPipesLaterals = entity && entity.assetsPipesLaterals || [];
    }
}
